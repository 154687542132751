import React, { memo, useCallback, useMemo } from "react"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { getDateForFiles } from "../../../../../../actions/modules/warehouse/warehouses/warehouses-actions"

const InventoryGroupExcelButton = ({ jsonData, groupName, parameterNames, isDisabled }) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)

	const handleExportExcel = () => {
		dispatch(getDateForFiles()).then((res) => {
			exportToExcel(res.payload?.date)
		})
	}

	const getHeaders = useMemo(() => {
		if (!Array.isArray(parameterNames)) return
		const headers = [t("Warehouses.material")]
		parameterNames.forEach((parameter) => headers.push(parameter.name))
		headers.push(t("Warehouses.warehouseState"), t("Warehouses.factState"))

		return headers
	}, [parameterNames])

	const getConcatenateParameterValues = (values) => {
		let parameter = ""

		values.forEach((value, index) => {
			parameter += value
			if (values.length - 1 === index) return
			parameter += " X "
		})
		return parameter
	}

	const exportToExcel = useCallback(
		(date) => {
			const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
			const fileExtension = ".xlsx"
			const headers = getHeaders

			const wsData = XLSX.utils.aoa_to_sheet([[currentWarehouse?.name], [groupName], headers])

			jsonData.forEach((item) => {
				const parameters = item.parameter_values.map((parameterValue) =>
					getConcatenateParameterValues(parameterValue.values),
				)

				XLSX.utils.sheet_add_aoa(wsData, [[item.name, ...parameters, item.warehouse_state, item.fact_state]], {
					origin: "A",
				})
			})

			const materialRowMaxWidth = jsonData.reduce((w, r) => Math.max(w, r.name.length), 20)
			const parameterRowMaxWidth = jsonData.reduce(
				(_w, r) =>
					r.parameter_values.reduce(
						(wM, rM) => Math.max(wM, getConcatenateParameterValues(rM.values).length),
						20,
					),
				20,
			)
			const warehouseStateRowMaxWidth = jsonData.reduce(
				(w, r) => Math.max(w, r.warehouse_state.toString().length),
				20,
			)
			const factStateRowMaxWidth = jsonData.reduce((w, r) => Math.max(w, r.fact_state.toString().length), 20)

			wsData["!cols"] = [
				{ wch: materialRowMaxWidth },
				...parameterNames.map(() => ({ wch: parameterRowMaxWidth })),
				{ wch: warehouseStateRowMaxWidth },
				{ wch: factStateRowMaxWidth },
			]

			const wb = XLSX.utils.book_new()
			XLSX.utils.book_append_sheet(wb, wsData, "data")
			const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
			const dataBlob = new Blob([excelBuffer], { type: fileType })
			FileSaver.saveAs(dataBlob, `${currentWarehouse?.name}_${date}` + fileExtension)
		},
		[jsonData],
	)

	return (
		<Button
			isDisabled={isDisabled}
			fontSize={[13, 14, 15, 16]}
			fontWeight={"400"}
			h={["25px", "35px", "40px"]}
			bgColor={"#FFF"}
			borderRadius={"4px"}
			boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
			onClick={handleExportExcel}
		>
			Excel
		</Button>
	)
}

export default memo(InventoryGroupExcelButton)
