import { Box, Button, Flex, Grid, GridItem, Input, Text, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { memo, useCallback, useRef } from "react"
import { Form, Formik } from "formik"
import * as Yup from "yup"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import SelectField from "../../../../common/SelectField"
import TextField from "../../../../common/TextField"
import TextArea from "../../../../common/TextArea"
import { createNewOrder, updateOrder } from "../../../../../actions/modules/orders/shop/shop-actions"
import SelectWithSearching from "../../../../common/SelectWithSearching"
import PdfIcon from "../../../../../assets/icons/shop/PdfIcon"
import IoEyeOutline from "../../../../../assets/icons/login/IoEyeOutline"
import BinIcon from "../../../../../assets/icons/materials/BinIcon"
import { warningToast } from "../../../../../utils/notifications/warningToast"
import AlertCancelChanges from "../../../../common/AlertCancelChanges"
import MultiSelect from "../../../../common/MultiSelect"

const NewOrderForm = ({ initialValues, gettedData }) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const formRef = useRef()
	const fileInputRef = useRef()
	const { isOpen, onOpen, onClose } = useDisclosure()

	const handleOnChangeProject = (address) => {
		if (!formRef.current) return
		formRef.current.setFieldValue("building_address", address ? address : "")
	}

	const handleConfirmCancel = () => {
		!location.state ? navigate("../") : navigate(location.state.location)
	}

	const bytesToKB = useCallback((bytes) => {
		if (bytes === 0) return "0 KB"

		const k = 1024
		const sizes = ["B", "KB", "MB", "GB", "TB"]

		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)), 10)
		return `${(bytes / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`
	}, [])

	const onSelectingAttachments = () => {
		fileInputRef.current.click()
	}

	const onFileUpload = useCallback(
		(e) => {
			if (formRef.current) {
				let files = []
				let filesSizeInMB = 0
				if (formRef.current.values.attachments.length > 0) {
					formRef.current.values.attachments.forEach((attachment) => {
						filesSizeInMB += Number((attachment.attachment.size / (1024 * 1024)).toFixed(0))
					})
				}
				Array.from(e.target.files).forEach((file) => {
					filesSizeInMB += Number((file.size / (1024 * 1024)).toFixed(0))
					files.push({ attachment: file })
				})
				if (filesSizeInMB >= 100) {
					warningToast(t("Shop.tooBigFiles"))
				}
				if (filesSizeInMB < 100) {
					formRef.current.setFieldValue("attachments", [...formRef.current.values.attachments, ...files])
				}
				fileInputRef.current.value = null
			}
		},
		[t],
	)

	const openPdfInNewTab = (attachment) => {
		if (attachment.file) {
			window.open(attachment.file, "_blank")
		} else {
			const fileURL = URL.createObjectURL(attachment)
			window.open(fileURL, "_blank")
		}
	}

	const handleRemoveAttachment = (attachment) => {
		if (!formRef.current || formRef.current.isSubmitting) return
		const attachments = formRef.current.values.attachments.filter((att) => att !== attachment)
		formRef.current.setFieldValue("attachments", attachments)
	}

	const getAttachments = (attachments, isSubmitting) =>
		attachments.map((attachment, index) => (
			<Flex key={index} alignItems='center' mt={2} fontSize={[13, 14, 15, 16]}>
				<PdfIcon color='red.500' boxSize={[4, 5, 5]} me={2} />
				<Text
					_hover={{ color: "#2C7A7B" }}
					cursor='pointer'
					onClick={() => openPdfInNewTab(attachment.attachment)}
				>
					{attachment.attachment.name}
				</Text>
				<Text color='#2C7A7B' ms={2}>
					{bytesToKB(attachment.attachment.size)}
				</Text>
				<IoEyeOutline
					ms={4}
					boxSize={[4, 4, 5]}
					cursor='pointer'
					onClick={() => openPdfInNewTab(attachment.attachment)}
				/>
				<BinIcon
					color={isSubmitting ? "gray.300" : "red.500"}
					cursor={isSubmitting ? "not-allowed" : "pointer"}
					ms={2}
					boxSize={[4, 4, 5]}
					onClick={() => handleRemoveAttachment(attachment)}
				/>
			</Flex>
		))

	const handleSave = useCallback(
		(values, actions) => {
			actions.setSubmitting(true)
			const path = location.pathname.split("/")
			!location.state || location.state.isCopy
				? dispatch(createNewOrder({ values, path: location.pathname, navigate })).then(() =>
						actions.setSubmitting(false),
					)
				: dispatch(updateOrder(values)).then(() => {
						navigate(`/${path[1]}/${path[2]}/${path[3]}`)
						actions.setSubmitting(false)
					})
		},
		[dispatch, location.pathname, location.state, navigate],
	)

	return (
		<Animation>
			<Formik
				innerRef={formRef}
				initialValues={initialValues}
				validationSchema={Yup.object({
					project_id: Yup.string().required(t("Shop.projectIsRequired")),
					building_address: Yup.string().required(t("Shop.buildingAddressIsRequired")),
					delivery_date: Yup.string()
						.required(t("Shop.deliveryDateIsRequired"))
						.matches(
							/^(19[0-9]{2}|20[0-9]{2}|2100)-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
							t("Shop.invalidDateFormat"),
						)
						.test({
							name: "validYear",
							exclusive: true,
							message: t("Shop.invalidDateFormat"),
							test: (value) => {
								const year = parseInt(value.split("-")[0])
								return year >= 1900 && year <= 2100
							},
						}),
				})}
				onSubmit={(values, actions) => {
					handleSave(values, actions)
				}}
			>
				{({ values, isSubmitting }) => (
					<Form encType='multipart/form-data'>
						<Box
							bgColor='#FFF'
							mt={5}
							boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"}
							borderRadius='4px'
							px={5}
							py={7}
						>
							<Grid templateColumns={"repeat(2, 1fr)"} mb={4} display='flex' gap={2} w='100%'>
								<GridItem display='flex' w='20%' alignItems='center'>
									<Text fontSize={[13, 14, 15, 16]} w='100%' color='#2C7A7B'>
										{t("Shop.project")}
									</Text>
								</GridItem>
								<GridItem w='80%'>
									<SelectWithSearching
										isDisabled={isSubmitting}
										name='project_id'
										placeholder={t("Shop.projectPlaceholder")}
										values={gettedData.projects}
										onChangeProject={handleOnChangeProject}
									/>
								</GridItem>
							</Grid>
							<Grid templateColumns={"repeat(2, 1fr)"} mb={4} display='flex' gap={2} w='100%'>
								<GridItem display='flex' w='20%' alignItems='center'>
									<Text fontSize={[13, 14, 15, 16]} w='100%' color='#2C7A7B'>
										{t("Shop.part")}
									</Text>
								</GridItem>
								<GridItem w='80%'>
									<TextArea
										disabled={isSubmitting}
										minH={"60px"}
										h={["60px", "65px", "70px", "75px", "80px"]}
										fontSize={[13, 14, 15, 16]}
										placeholder={t("Shop.addDescription")}
										name='part'
									/>
								</GridItem>
							</Grid>
							<Grid templateColumns={"repeat(2, 1fr)"} mb={4} display='flex' gap={2} w='100%'>
								<GridItem display='flex' w='20%' alignItems='center'>
									<Text fontSize={[13, 14, 15, 16]} w='100%' color='#2C7A7B'>
										{t("Shop.buildingAddress")}
									</Text>
								</GridItem>
								<GridItem w='80%'>
									<TextArea
										disabled={isSubmitting}
										minH={"60px"}
										h={["60px", "65px", "70px", "75px", "80px"]}
										fontSize={[13, 14, 15, 16]}
										placeholder={t("Shop.addAddress")}
										name='building_address'
									/>
								</GridItem>
							</Grid>
							<Grid templateColumns={"repeat(2, 1fr)"} mb={4} display='flex' gap={2} w='100%'>
								<GridItem display='flex' w='20%' alignItems='center'>
									<Text fontSize={[13, 14, 15, 16]} w='100%' color='#2C7A7B'>
										{t("Shop.orderFor")}
									</Text>
								</GridItem>
								<GridItem w='80%'>
									<SelectWithSearching
										isDisabled={isSubmitting}
										name='order_for'
										placeholder={t("Shop.orderForPlaceholder")}
										values={gettedData.order_for}
									/>
								</GridItem>
							</Grid>
							<Grid templateColumns={"repeat(2, 1fr)"} mb={4} display='flex' gap={2} w='100%'>
								<GridItem display='flex' w='20%' alignItems='center'>
									<Text fontSize={[13, 14, 15, 16]} w='100%' color='#2C7A7B'>
										{t("Shop.contactPerson")}
									</Text>
								</GridItem>
								<GridItem w='80%'>
									<MultiSelect
										isDisabled={isSubmitting}
										name='contact_person'
										placeholder={t("Shop.contactPersonPlaceholder")}
										values={gettedData.contact_person}
									/>
								</GridItem>
							</Grid>
							<Grid templateColumns={"repeat(2, 1fr)"} mb={4} display='flex' gap={2} w='100%'>
								<GridItem display='flex' w='20%' alignItems='center'>
									<Text fontSize={[13, 14, 15, 16]} w='100%' color='#2C7A7B'>
										{t("Shop.commentsOptional")}
									</Text>
								</GridItem>
								<GridItem w='80%'>
									<TextArea
										disabled={isSubmitting}
										minH={"60px"}
										h={["60px", "65px", "70px", "75px", "80px"]}
										fontSize={[13, 14, 15, 16]}
										placeholder={t("Shop.addComments")}
										name='comments'
									/>
								</GridItem>
							</Grid>
							<Grid templateColumns={"repeat(2, 1fr)"} mb={4} display='flex' gap={2} w='100%'>
								<GridItem display='flex' w='20%' alignItems='center'>
									<Text fontSize={[13, 14, 15, 16]} w='100%' color='#2C7A7B'>
										{t("Shop.deliveryDate")}
									</Text>
								</GridItem>
								<GridItem w='80%'>
									<TextField
										cursor='pointer'
										aria-label='Delivery date'
										disabled={isSubmitting}
										fontSize={[13, 14, 15, 16]}
										h={["30px", "35px", "40px"]}
										name='delivery_date'
										type='date'
									/>
								</GridItem>
							</Grid>
							<Grid templateColumns={"repeat(2, 1fr)"} mb={8} display='flex' gap={2} w='100%'>
								<GridItem display='flex' w='20%' alignItems='center'>
									<Text fontSize={[13, 14, 15, 16]} w='100%' color='#2C7A7B'>
										{t("Shop.priority")}
									</Text>
								</GridItem>
								<GridItem w='80%'>
									<SelectField
										disabled={isSubmitting}
										fontSize={[13, 14, 15, 16]}
										h={["30px", "35px", "40px"]}
										name='priority'
										cursor='pointer'
										aria-label='Priority select'
										options={[
											{ id: 1, value: 1, name: t("Shop.low") },
											{ id: 2, value: 2, name: t("Shop.standard") },
											{ id: 3, value: 3, name: t("Shop.high") },
										]}
									/>
								</GridItem>
							</Grid>
							<Grid templateColumns={"repeat(2, 1fr)"} display='flex' gap={2} w='100%'>
								<GridItem display='flex' w='20%'>
									<Text mt={1} fontSize={[13, 14, 15, 16]} w='100%' color='#2C7A7B'>
										{t("Shop.attachments")}
									</Text>
								</GridItem>
								<GridItem w='80%'>
									<Button
										isDisabled={isSubmitting}
										fontSize={[13, 14, 15, 16]}
										p={[1, 1, 2, 3]}
										h={["30px", "35px", "40px"]}
										colorScheme='teal'
										variant='outline'
										color='#2C7A7B'
										onClick={onSelectingAttachments}
									>
										<PdfIcon color='red.500' boxSize={[4, 5, 5]} me={2} />
										{t("Shop.selectFile")}
									</Button>
									<Input
										display='none'
										ref={fileInputRef}
										type='file'
										multiple
										accept='.pdf'
										onChange={onFileUpload}
									/>
									{getAttachments(values.attachments, isSubmitting)}
								</GridItem>
							</Grid>
						</Box>
						<Flex justifyContent='end' my={4} pb={6} gap={2}>
							<Button
								isDisabled={isSubmitting}
								h={["30px", "35px", "40px"]}
								fontSize={[13, 14, 15, 16]}
								bgColor='red.600'
								_hover={{ backgroundColor: "red.700" }}
								colorScheme='red'
								color='#FFF'
								onClick={onOpen}
							>
								{t("Shop.cancel")}
							</Button>
							<Button
								isDisabled={isSubmitting}
								h={["30px", "35px", "40px"]}
								fontSize={[13, 14, 15, 16]}
								type='submit'
								bgColor='green.600'
								_hover={{ backgroundColor: "green.700" }}
								colorScheme='green'
								color='#FFF'
							>
								{t("Shop.saveAndGoFurther")}
								<ArrowForwardIcon ms={1} boxSize={[4, 4, 5]} />
							</Button>
						</Flex>
						<AlertCancelChanges
							isOpen={isOpen}
							onClose={onClose}
							handleConfirmCancel={handleConfirmCancel}
						/>
					</Form>
				)}
			</Formik>
		</Animation>
	)
}

export default memo(NewOrderForm)
