import { Container, Flex, Heading } from "@chakra-ui/react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import GroupForm from "../../../../../components/Modules/Users/Groups/GroupForm/GroupForm"
import BackForwardButtons from "../../../../../components/common/BackForwardButtons"
import { editGroup, getGroup } from "../../../../../actions/modules/users/groups/groups-actions"
import { clearGettedGroup } from "../../../../../store/modules/users/groups/groups-slice"
import LoadingHOC from "../../../../../components/common/LoadingHOC"

const EditGroup = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const gettedGroup = useSelector((state) => state.groups.gettedGroup)

	useEffect(() => {
		const id = location.pathname.split("/")[4]
		dispatch(getGroup(id))

		return () => dispatch(clearGettedGroup())
	}, [dispatch, location.pathname])

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3} mt={2}>
				<Flex alignItems={"center"} gap={3}>
					<BackForwardButtons />
					<Heading fontWeight={600} fontSize={["15", "16", "17", "18", "19"]}>
						{gettedGroup?.name}
					</Heading>
				</Flex>
				<LoadingHOC isLoading={gettedGroup}>
					<GroupForm initialValues={gettedGroup} onSubmit={editGroup} isPatchMethod={true} />
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default EditGroup
