import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react"
import { memo, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FieldArray } from "formik"
import i18next from "i18next"

import StaticSearch from "../../../../common/StaticSearch"
import SelectField from "../../../../common/SelectField"

const UsersInGroups = ({ config, groups }) => {
	const { t } = useTranslation("global")
	const [searchingTerm, setSearchingTerm] = useState("")
	const [usersCopy] = useState(config)
	const [users, setUsers] = useState(config)

	const handleSearching = (filteredUsers) => setUsers(filteredUsers)

	const getSelectOption = useMemo(() => {
		const optionsArray = groups?.config.map((group) => ({
			id: group.id,
			value: group.id,
			name: group[`name_${i18next.language}`],
		}))
		optionsArray?.unshift({ id: 0, value: 0, name: t("Common.select") })
		return optionsArray
	}, [groups, i18next.language])

	const handleBlockSubmitValues = (e) => {
		if (e.key !== "Enter") return

		e.preventDefault()
	}

	const usersInGroups = useMemo(
		() =>
			users?.map((user, index) => (
				<FieldArray key={index} name='users'>
					{({ form }) => (
						<Grid
							templateColumns={["repeat(3, 1fr)", "repeat(2, 1fr)"]}
							my={5}
							py={2}
							bgColor='#F2F2EF'
							borderRadius='4px'
							alignItems='center'
						>
							<GridItem ms={4} alignSelf='center'>
								<Text fontSize={[13, 14, 15, 16]}>{user.user}</Text>
							</GridItem>
							<GridItem ms={[4, 0]} me={4} colSpan={[2, 1]}>
								<SelectField
									aria-label='Select group'
									disabled={form.isSubmitting}
									bgColor='#FFF'
									me={2}
									mb={2}
									fontSize={[13, 14, 15, 16]}
									cursor='pointer'
									h={["30px", "35px", "40px"]}
									name={`users[${form.values.users.findIndex(
										(item) => item.id === user.id,
									)}].user_group_permission_id`}
									options={getSelectOption}
								/>
							</GridItem>
						</Grid>
					)}
				</FieldArray>
			)),
		[groups, users, i18next.language],
	)

	return (
		<>
			<Grid mt={[4, 5]} templateColumns={["repeat(3, 1fr)", "repeat(2, 1fr)"]}>
				<GridItem>
					<Text ms={3} fontSize={[13, 14, 15, 16]} color='teal.600'>
						{t("UsersSettings.user")}
					</Text>
				</GridItem>
				<GridItem justifySelf={["center", "start"]} w='100%' colSpan={[2, 1]}>
					<Flex justifyContent={"space-between"} flexWrap={["wrap", "wrap", "nowrap"]} w='100%'>
						<Text
							ms={[5, 1]}
							me={[4, 0]}
							fontSize={[13, 14, 15, 16]}
							color='teal.600'
							mb={{ base: 4, md: 0 }}
						>
							{t("UsersSettings.group")}
						</Text>

						<StaticSearch
							fieldsToFilter={["user"]}
							isDisabled={!usersCopy?.length}
							searchText={searchingTerm}
							setSearchText={setSearchingTerm}
							handleSetResults={handleSearching}
							originalArray={usersCopy}
							onKeyDown={handleBlockSubmitValues}
						/>
					</Flex>
				</GridItem>
			</Grid>
			<Box maxH={["300px", "350px", "450px", "500px"]} overflowY={"auto"}>
				{usersInGroups}
			</Box>
		</>
	)
}

export default memo(UsersInGroups)
