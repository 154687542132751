import { Box, Checkbox, Flex, Grid, GridItem, Image, Text, useMediaQuery } from "@chakra-ui/react"
import { memo } from "react"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"

import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"
import LanguageInputs from "../../../../common/LanguageInputs"
import LanguageTextArea from "../../../../common/LanguageTextArea"
import TabsFrame from "../../../../common/TabsFrame"
import MaterialTabPanel from "./MaterialTabPanel"
import Materials from "../../../../../assets/icons/materials/Materials"
import ParametersPanels from "./ParametersPanels"

const MaterialGroupDetailsShow = ({ language, initialTab }) => {
	const { t } = useTranslation("global")
	const { values } = useFormikContext()
	const [isLargerThan640] = useMediaQuery("(min-width: 640px)", {
		ssr: false,
	})

	const getObjectURLFromImg = (icon) => {
		if (!icon || typeof icon === "string") return icon
		return URL.createObjectURL(icon)
	}

	const groupInputs = (
		<Grid w='100%' templateColumns={"repeat(8, 1fr)"} gap={5}>
			<GridItem>
				<Text minW={["85px", "100px"]} fontSize={[13, 14, 15, 15, 16]} color='#2C7A7B'>
					{t("Materials.groupName", { lng: language })}
				</Text>
			</GridItem>
			<GridItem colSpan={7}>
				<Flex gap={2}>
					<Text maxW={["80%", "90%", "90%", "70%", "60%"]} fontSize={[13, 14, 15, 15, 16]}>
						{values[`name_${language}`]}
					</Text>
					<LanguageInputs
						isShowPreview={true}
						name='name'
						title={t("Materials.groupName", { lng: language })}
					/>
				</Flex>
			</GridItem>
			<GridItem>
				<Text minW={["85px", "100px"]} fontSize={[13, 14, 15, 15, 16]} color='#2C7A7B'>
					{t("Materials.description", { lng: language })}
				</Text>
			</GridItem>
			<GridItem colSpan={7}>
				<Flex gap={2}>
					<Text maxW={["80%", "90%", "90%", "70%", "60%"]} fontSize={[13, 14, 15, 15, 16]}>
						{values[`description_${language}`]}
					</Text>
					<LanguageTextArea
						isShowPreview={true}
						name='description'
						title={t("Materials.description", { lng: language })}
					/>
				</Flex>
			</GridItem>
			<GridItem>
				<Text minW={["85px", "100px"]} fontSize={[13, 14, 15, 15, 16]} color='#2C7A7B'>
					{t("Materials.turnedOffFromWarehouse", { lng: language })}
				</Text>
			</GridItem>
			<GridItem colSpan={7} my={{ base: "auto", xl: 1 }}>
				<Checkbox
					aria-label='checkbox'
					className='materials-checkbox'
					isChecked={values.is_out_of_warehouse}
					disabled
					type='checkbox'
					name='is_out_of_warehouse'
					colorScheme='teal'
					size='lg'
				/>
			</GridItem>
		</Grid>
	)

	const groupImage = (
		<Grid ms={1}>
			<GridItem justifySelf={"center"} mt={!isLargerThan640 && 2}>
				<Box
					w={["90px", "110px", "147px", "197px"]}
					h={["140px", "160px", "187px", "208px"]}
					border='1px solid #F2F2EF'
					borderRadius='8px'
					p={2}
				>
					{values.image ? (
						<Image
							w='100%'
							h='100%'
							objectFit={"contain"}
							alt='Group image'
							borderRadius='4px'
							mx='auto'
							src={getObjectURLFromImg(values.image)}
						/>
					) : (
						<LackImageIcon w='100%' h='100%' alt='Lack of group image' mx='auto' borderRadius='4px' />
					)}
				</Box>
			</GridItem>
		</Grid>
	)

	const getTabs = values.parameters?.map((parameter) => ({
		name: parameter[`name_${language}`],
		icon: parameter.icon ? (
			getObjectURLFromImg(parameter.icon)
		) : (
			<LackImageIcon alt='Tab icon' boxSize={[4, 5, 6]} />
		),
	}))

	getTabs.unshift({
		name: t("Materials.materials", { lng: language }),
		icon: <Materials alt='Tab icon' boxSize={[4, 5, 6]} />,
	})

	const tabs = (
		<TabsFrame initialTab={initialTab} tabs={getTabs}>
			<MaterialTabPanel getObjectURLFromImg={getObjectURLFromImg} language={language} />
			{values.parameters?.map((parameter, index) => (
				<ParametersPanels
					key={index}
					parameter={parameter}
					parameterId={index}
					getObjectURLFromImg={getObjectURLFromImg}
					language={language}
				/>
			))}
		</TabsFrame>
	)

	return (
		<Box>
			<Box
				display={isLargerThan640 ? "inline-flex" : "block"}
				w='100%'
				my={5}
				px={4}
				py={6}
				borderRadius='4px'
				boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
				bgColor='#FFF'
			>
				{groupInputs}
				{groupImage}
			</Box>
			{tabs}
		</Box>
	)
}

export default memo(MaterialGroupDetailsShow)
