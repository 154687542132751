import { createAsyncThunk } from "@reduxjs/toolkit"

import { handleRequest } from "../../../utils/api/api"

/**
 * Send email to reset password
 */
export const sendEmail = createAsyncThunk("resetPassword/sendEmail", async ({ email }) => {
	const responseData = await handleRequest({
		endPoint: "api/forgot-password",
		method: "POST",
		body: {
			email,
		},
	})
	return responseData
})

/**
 * Change password function
 */
export const changePassword = createAsyncThunk(
	"resetPassword/changePassword",
	// eslint-disable-next-line no-unused-vars
	async ({ email, token, values, navigate }) => {
		const responseData = await handleRequest({
			endPoint: "api/forgot-password",
			method: "POST",
			body: {
				email,
				token,
				password: values.password,
				password_confirmation: values.confirmationPassword,
			},
		})
		return responseData
	},
)
