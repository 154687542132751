import { Button, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { BrowserView } from "react-device-detect"
import { PDFViewer } from "@react-pdf/renderer"

import GroupPDF from "./GroupPDF"
import PDFModal from "../../../../common/Modal/PDFModal"

const GroupPrintButton = ({ data }) => {
	const { t } = useTranslation("global")
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<BrowserView>
			<Button
				isDisabled={!data?.length}
				fontSize={[13, 14, 15, 16]}
				fontWeight={"400"}
				h={["25px", "35px", "40px"]}
				bgColor={"#FFF"}
				borderRadius={"4px"}
				boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
				onClick={onOpen}
			>
				{t("Warehouses.print")}
			</Button>
			<PDFModal isOpen={isOpen} onClose={onClose}>
				<PDFViewer style={{ width: "100%", height: "75vh" }}>
					<GroupPDF
						data={data}
						warehouseString={t("Warehouses.warehouse")}
						warehouseName={currentWarehouse?.name}
						tableHeaders={[t("Warehouses.material"), t("Warehouses.quantity"), t("Warehouses.value")]}
					/>
				</PDFViewer>
			</PDFModal>
		</BrowserView>
	)
}

export default GroupPrintButton
