import { memo, useState } from "react"
import { Box, Collapse } from "@chakra-ui/react"
import { HexColorPicker } from "react-colorful"

import TriangleIcon from "../../assets/icons/common/TriangleIcon"

const ColorPicker = ({ isOpen, color, setColor, boxRef, triangleRef, bottom = true }) => {
	const [position] = useState(bottom ? { bottom: "35px" } : { top: "35px" })
	return (
		<Collapse
			in={isOpen}
			animateOpacity
			style={{
				position: "absolute",
				zIndex: 2,
				transform: "bottom" in position && "rotateX(180deg)",
				...position,
			}}
		>
			<TriangleIcon
				ref={triangleRef}
				position={"relative"}
				zIndex={3}
				top={"1px"}
				left={"9.5px"}
				boxSize={5}
				viewBox='0 8 22 18'
			/>
			<Box
				style={{ transform: "bottom" in position ? "rotateX(180deg)" : "none" }}
				ref={boxRef}
				p={4}
				border='1px solid gray'
				borderRadius={"8px"}
				bgColor={"#FFF"}
			>
				<HexColorPicker color={color} onChange={setColor} />
			</Box>
		</Collapse>
	)
}

export default memo(ColorPicker)
