import { AddIcon, CopyIcon, EditIcon } from "@chakra-ui/icons"
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Checkbox,
	Divider,
	Flex,
	Grid,
	GridItem,
	Image,
	Input,
	Select,
	Text,
} from "@chakra-ui/react"
import { FastField, Field, FieldArray, useFormikContext } from "formik"
import imageCompression from "browser-image-compression"
import { useTranslation } from "react-i18next"
import TextFieldWithDebounce from "../../../../common/TextFieldWithDebounce"
import LanguageInputs from "../../../../common/LanguageInputs"
import BinIcon from "../../../../../assets/icons/materials/BinIcon"
import LanguageTextArea from "../../../../common/LanguageTextArea"
import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"
import { memo, useRef, useState } from "react"
import { warningToast } from "../../../../../utils/notifications/warningToast"
import AddImageIcon from "../../../../../assets/icons/materials/AddImageIcon"

const EditMaterialTabPanel = ({ getObjectURLFromImg, language, handleDragImage }) => {
	const { t } = useTranslation("global")
	const { values, isSubmitting, setFieldValue } = useFormikContext()
	const materialFileRef = useRef(null)
	const [clickedMaterialId, setClickedMaterialId] = useState()

	const handleUploadMaterialImage = async (e) => {
		if (e.target.files.length === 0) return

		const fileSizeInMB = (e.target.files[0].size / (1024 * 1024)).toFixed(0)
		if (fileSizeInMB >= 100) {
			warningToast(t("Materials.tooBigFile"))
			materialFileRef.current.value = null
		} else {
			const options = {
				maxSizeMB: 100,
				maxWidthOrHeight: 800,
				useWebWorker: true,
			}
			try {
				const compressedFile = await imageCompression(e.target.files[0], options)
				setFieldValue(
					`materials[${clickedMaterialId}].image`,
					new File([compressedFile], e.target.files[0].name, {
						type: "image/webp",
					}),
				)
				materialFileRef.current.value = null
			} catch (error) {
				console.error(error)
			}
		}
	}

	const handleRemoveMaterialImage = (id) => {
		const materialsCopy = [...values.materials]
		materialsCopy[id].image = null
		materialFileRef.current.value = null
		setFieldValue(`materials[${id}].image`, undefined)
	}

	const handleAddMaterialImage = (id) => {
		setClickedMaterialId(id)
		materialFileRef.current.click()
	}

	const handleDropMaterialImage = async (id, e) => {
		e.preventDefault()
		if (e.dataTransfer.files.length === 0) return

		const fileSizeInMB = (e.dataTransfer.files[0].size / (1024 * 1024)).toFixed(0)
		if (fileSizeInMB >= 100) {
			warningToast(t("Materials.tooBigFile"))
			materialFileRef.current.value = null
		} else {
			const fileName = e.dataTransfer.files[0].name
			const options = {
				maxSizeMB: 100,
				maxWidthOrHeight: 1200,
				useWebWorker: true,
			}
			try {
				const compressedFile = await imageCompression(e.dataTransfer.files[0], options)
				setFieldValue(`materials[${id}].image`, new File([compressedFile], fileName, { type: "image/webp" }))
				materialFileRef.current.value = null
			} catch (error) {
				console.error(error)
			}
		}
	}

	const parametersValues = (parameter) =>
		parameter.values.map((value, valueId) => {
			if (value.name === "") return null
			return (
				<option key={valueId} value={valueId + 1}>
					{valueId + 1}
				</option>
			)
		})

	const getMaterialsParameters = (clickedMaterialId, parameterCounter) =>
		parameterCounter?.map((parameter, index) => (
			<Box key={index} mb={2}>
				<Flex
					ms={[3, 3, 5, 7, 10]}
					flexDirection={["column", "column", "row"]}
					flexWrap={["wrap", "wrap", "nowrap"]}
					justifyContent='center'
					alignItems='center'
				>
					{parameter.icon ? (
						<Image
							alt='Parameter icon'
							me={2}
							w='100%'
							h='100%'
							objectFit={"contain"}
							src={getObjectURLFromImg(parameter.icon)}
							boxSize={[5, 6, 7]}
						/>
					) : (
						<LackImageIcon me={2} boxSize={[5, 6, 7]} />
					)}
					<Text
						w={["80px", "100px", "200px", "300px", "300px"]}
						whiteSpace={"pre-wrap"}
						textAlign={"center"}
						wordBreak={"break-word"}
						fontSize={[13, 14, 15, 15, 16]}
						me={[2, 2, 5, 7, 10]}
					>
						{parameter[`name_${language}`]}
					</Text>
					<TextFieldWithDebounce
						data-testid={`materials[${clickedMaterialId}].parameter[${index}]`}
						disabled={isSubmitting}
						fontSize={[13, 14, 15, 15, 16]}
						h={["30px", "35px", "40px"]}
						as={Select}
						w='100px'
						name={`materials[${clickedMaterialId}].parameter_counter[${index}].count`}
						bgColor='#FFF'
					>
						<option value={0}>0</option>
						{values.parameters[index].values && parametersValues(values.parameters[index])}
					</TextFieldWithDebounce>
				</Flex>
			</Box>
		))

	const getMaterialCopy = (material) => ({
		description_pl: material.description_pl,
		description_en: material.description_en,
		description_nl: material.description_nl,
		index: material.index,
		image: material.image,
		is_in_warehouse: material.is_in_warehouse,
		name_pl: material.name_pl,
		name_en: material.name_en,
		name_nl: material.name_nl,
		is_used: false,
		parameter_counter: material.parameter_counter.map((parameter) => ({
			name_pl: parameter.name_pl,
			name_en: parameter.name_en,
			name_nl: parameter.name_nl,
			count: parameter.count,
			icon: parameter.icon,
		})),
	})

	const getEmptyMaterial = {
		name_pl: "",
		name_en: "",
		name_nl: "",
		index: 0,
		image: null,
		description_pl: "",
		description_en: "",
		description_nl: "",
		is_in_warehouse: false,
		is_used: false,
		parameter_counter: values.parameters?.map((parameter) => {
			return {
				name_pl: parameter.name_pl,
				name_en: parameter.name_en,
				name_nl: parameter.name_nl,
				count: 0,
				icon: parameter.icon,
			}
		}),
	}

	const getMaterialsItems = (
		<FieldArray name={`materials`}>
			{({ remove, push, form }) =>
				form.values.materials?.length ? (
					<>
						{form.values.materials?.map((material, index) => (
							<GridItem key={index} colSpan={4} mb={4} borderRadius='4px' bgColor='#F2F2EF' px={4}>
								<Accordion allowMultiple>
									<AccordionItem border='none'>
										<Grid templateColumns={"repeat(4, 1fr)"}>
											<FastField name={`materials[${index}].name_${language}`}>
												{() => (
													<GridItem colSpan={[3, 3, 2, 1]} py={7}>
														<Flex me={2}>
															<Box w={["100%", "100%", "80%", "75%", "60%"]} me={2}>
																<TextFieldWithDebounce
																	data-testid={`materials[${index}].name_${language}`}
																	disabled={isSubmitting}
																	fontSize={[13, 14, 15, 15, 16]}
																	h={["30px", "35px", "40px"]}
																	maxLength={191}
																	bgColor='#FFF'
																	name={`materials[${index}].name_${language}`}
																	placeholder={t("Materials.productName", {
																		lng: language,
																	})}
																/>
															</Box>
															<LanguageInputs
																name={`materials[${index}].name`}
																maxLength={191}
																title={t("Materials.productName", {
																	lng: language,
																})}
															/>
														</Flex>
													</GridItem>
												)}
											</FastField>
											<GridItem display={["none", "none", "none", "grid"]}>
												{/* <Box py={7}>
                                                                                        {
                                                                                            isEditPreview ? (
                                                                                                <NumberInput type='number' name='materialIndex'
                                                                                                h='100%' w='20%' value={material.index} onChange={(e) => handleMaterialNumberInput(index, e)} defaultValue={0} min={0} >
                                                                                                <NumberInputField bgColor='#FFF'/>
                                                                                                <NumberInputStepper>
                                                                                                    <NumberIncrementStepper/>
                                                                                                    <NumberDecrementStepper/>
                                                                                                </NumberInputStepper>
                                                                                                </NumberInput> 
                                                                                            ) : (
                                                                                                <Text ms={3}>{material.index}</Text>
                                                                                            )
                                                                                        }
                                                                                    </Box> */}
											</GridItem>
											<FastField key={language} name={`materials[${index}].image`}>
												{() => (
													<GridItem colSpan={1} justifySelf={["end", "end", "start"]} py={7}>
														{material.image ? (
															<Flex
																display={["block", "block", "block", "flex"]}
																h={["100px", "110px", "120px", "100px"]}
																w={["85px", "95px", "110px", "200px"]}
																bgColor='#FFF'
																border='1px solid #DEDED9'
																borderRadius='4px'
																alignItems='center'
																p={2}
																gap={2}
																onDragOver={handleDragImage}
																onDrop={(e) => handleDropMaterialImage(index, e)}
															>
																<Box
																	h={{ base: "70%", sm: "65%", lg: "100%" }}
																	w={{ base: "100%", lg: "60%" }}
																>
																	<Image
																		data-testid={`material[${index}]Image`}
																		alt='Material image'
																		w='100%'
																		h='100%'
																		objectFit={"contain"}
																		mx='auto'
																		src={getObjectURLFromImg(material.image)}
																	/>
																</Box>
																<Flex
																	display={["flex"]}
																	mt={[1, 1, 1, 0]}
																	gap={[0, 0, 0, 2]}
																>
																	<Button
																		aria-label='Edit material image button'
																		isDisabled={isSubmitting}
																		mx='auto'
																		bgColor='#F2F2EF'
																		minW='25px'
																		boxSize={[
																			"25px",
																			"30px",
																			"32px",
																			"35px",
																			"40px",
																		]}
																		p={0}
																		onClick={() => handleAddMaterialImage(index)}
																	>
																		<EditIcon boxSize={[4, 5, 5, 6]} />
																	</Button>
																	<Button
																		aria-label='Remove material image button'
																		isDisabled={isSubmitting}
																		bgColor='#F2F2EF'
																		mx='auto'
																		minW='25px'
																		boxSize={[
																			"25px",
																			"30px",
																			"32px",
																			"35px",
																			"40px",
																		]}
																		p={0}
																		onClick={() => handleRemoveMaterialImage(index)}
																	>
																		<BinIcon
																			boxSize={[4, 5, 5, 6]}
																			color='red.500'
																		/>
																	</Button>
																</Flex>
															</Flex>
														) : (
															<Box
																bgColor='#FFF'
																h={["75px", "85px", "95px", "100px"]}
																w={["85px", "95px", "110px", "130px"]}
																p={1}
																border='1px solid #DEDED9'
																borderRadius='4px'
																onDragOver={handleDragImage}
																onDrop={(e) => handleDropMaterialImage(index, e)}
															>
																<Button
																	data-testid={`addMaterialImageBtn[${index}]`}
																	isDisabled={isSubmitting}
																	bgColor='#F2F2EF'
																	display='block'
																	h={["65px", "75px", "85px", "90px"]}
																	w={["75px", "85px", "100px", "120px"]}
																	onClick={() => handleAddMaterialImage(index)}
																>
																	<AddImageIcon boxSize={[4, 5, 6]} />
																	<Text
																		aria-label='Add material image button'
																		whiteSpace={"pre-wrap"}
																		fontSize={[13, 14, 15, 15, 16]}
																		fontWeight={400}
																		mt={[0, 2, 3, 4]}
																	>
																		{t("Materials.addImage", { lng: language })}
																	</Text>
																</Button>
															</Box>
														)}
													</GridItem>
												)}
											</FastField>
											<GridItem colSpan={[4, 4, 1]} py={7} justifySelf='end'>
												<Box display='inline-flex'>
													<Button
														aria-label='Copy material button'
														isDisabled={isSubmitting}
														bgColor='transparent'
														onClick={() => push(getMaterialCopy(material))}
														display={"flex"}
														boxSize={["30px", "35px", "37px", "40px"]}
													>
														<CopyIcon boxSize={[3, 4, 5]} />
													</Button>
													<Button
														aria-label='Remove material button'
														isDisabled={isSubmitting}
														bgColor='transparent'
														display={material.is_used ? "none" : "flex"}
														boxSize={["30px", "35px", "37px", "40px"]}
														onClick={() => remove(index)}
													>
														<BinIcon color='red.500' boxSize={[4, 5, 6]} />
													</Button>
													<AccordionButton
														data-testid={`material[${index}]AccordionBtn`}
														aria-label='Accordion button'
														borderRadius='4px'
														bgColor='#FFF'
														boxSize={["30px", "35px", "37px", "40px"]}
														p={0}
													>
														<AccordionIcon boxSize={[5, 6, 6, 7]} mx='auto' />
													</AccordionButton>
												</Box>
											</GridItem>
											<GridItem colSpan={4}>
												<AccordionPanel p={0}>
													<Divider borderColor='#FFF' />
													<Box
														display='flex'
														flexWrap={["wrap", "wrap", "nowrap"]}
														h='100%'
														alignItems='center'
														my={5}
														w='100%'
													>
														<FastField
															key={language}
															name={`materials[${index}].is_in_warehouse`}
														>
															{() => (
																<>
																	<Text
																		fontSize={[13, 14, 15, 15, 16]}
																		w={["80%", "80%", "8%"]}
																	>
																		{t("Materials.inWarehouse", {
																			lng: language,
																		})}
																	</Text>
																	<Flex>
																		<Field
																			data-testid={`material[${index}]IsInWarehouse`}
																			aria-label='Is out of magazine checkbox'
																			disabled={isSubmitting}
																			className='materials-checkbox'
																			as={Checkbox}
																			type='checkbox'
																			isChecked={material.is_in_warehouse}
																			name={`materials[${index}].is_in_warehouse`}
																			size='lg'
																			colorScheme='teal'
																			bgColor='#FFF'
																		/>
																	</Flex>
																</>
															)}
														</FastField>
														<FastField name={`materials[${index}].description_${language}`}>
															{() => (
																<Box
																	display={"flex"}
																	w='100%'
																	alignItems='center'
																	mt={[3, 3, 0]}
																>
																	<Text
																		fontSize={[13, 14, 15, 15, 16]}
																		ms={[0, 0, 10]}
																		me={2}
																	>
																		{t("Materials.description", {
																			lng: language,
																		})}
																	</Text>
																	<Flex gap={2} w='100%'>
																		<TextFieldWithDebounce
																			data-testid={`material[${index}]Description`}
																			maxLength={65535}
																			disabled={isSubmitting}
																			fontSize={[13, 14, 15, 15, 16]}
																			h={["30px", "35px", "40px"]}
																			bgColor='#FFF'
																			name={`materials[${index}.description_${language}]`}
																			placeholder={t(
																				"Materials.descriptionPlaceholder",
																				{ lng: language },
																			)}
																		/>
																		<LanguageTextArea
																			name={`materials[${index}].description`}
																			maxLength={65535}
																			title={t("Materials.description", {
																				lng: language,
																			})}
																		/>
																	</Flex>
																</Box>
															)}
														</FastField>
													</Box>
													<FastField key={language} name='parameters'>
														{() =>
															form.values.parameters?.length > 0 && (
																<>
																	<Divider borderColor='#FFF' />
																	<Flex mt={4} mb={1}>
																		<Box display='inline-flex'>
																			<Text
																				fontSize={[13, 14, 15, 15, 16]}
																				my={2}
																			>
																				{t("Materials.parameters", {
																					lng: language,
																				})}
																			</Text>
																		</Box>
																		<Box>
																			{getMaterialsParameters(
																				index,
																				form.values.parameters,
																			)}
																		</Box>
																	</Flex>
																</>
															)
														}
													</FastField>
												</AccordionPanel>
											</GridItem>
										</Grid>
									</AccordionItem>
								</Accordion>
							</GridItem>
						))}
						<GridItem>
							<Button
								isDisabled={isSubmitting}
								bgColor='#2B807E'
								_hover={{ backgroundColor: "teal.700" }}
								h={["30px", "35px", "40px"]}
								fontSize={[13, 14, 15, 16]}
								colorScheme='teal'
								color='#FFF'
								onClick={() => push(getEmptyMaterial)}
							>
								<AddIcon boxSize={3} me={2} />
								<Text aria-label='Add new material button'>
									{t("Materials.addNew", { lng: language })}
								</Text>
							</Button>
						</GridItem>
					</>
				) : (
					<GridItem colSpan={4}>
						<Button
							data-testid={"addFirstMaterialBtn"}
							isDisabled={isSubmitting}
							onClick={() => push(getEmptyMaterial)}
							w='100%'
							h={["90px", "100px", "120px", "150px", "200px"]}
							mb={4}
							borderRadius='4px'
							bgColor='#F2F2EF'
							px={4}
							_hover={{ boxShadow: "5px 5px 25px rgba(10,10,10, 0.5)" }}
							boxShadow='5px 5px 25px rgba(30,30,30, 0.3)'
						>
							<Flex h='100%' justifyContent='center' alignItems='center'>
								<Text
									aria-label='Add new material button'
									letterSpacing='2px'
									color='rgba(5, 5, 5, 0.6)'
									fontSize={["14px", "16px", "18px", "20px"]}
								>
									{t("Materials.addMaterial", { lng: language })}
								</Text>
							</Flex>
						</Button>
					</GridItem>
				)
			}
		</FieldArray>
	)

	return (
		<Box display='inline-flex' w='100%' minH={["300px", "300px", "350px", "400px"]}>
			<Grid w='100%'>
				<GridItem>
					<Grid templateColumns={"repeat(4, 1fr)"}>
						<FastField key={language} name='materials'>
							{() => (
								<>
									<GridItem ms={4} mb={3}>
										<Text fontSize={[13, 14, 15, 16]}>
											{t("Materials.materialName", { lng: language })}
										</Text>
									</GridItem>
									<GridItem ms={4}>
										{/* <Text>{t("Materials.index", {lng: language})}</Text> */}
									</GridItem>
									<GridItem ms={4} colSpan={2} me={[12, 12, 0]} justifySelf={["end", "end", "start"]}>
										<Text fontSize={[13, 14, 15, 16]}>
											{t("Materials.image", { lng: language })}
										</Text>
									</GridItem>
								</>
							)}
						</FastField>
						{getMaterialsItems}
					</Grid>
				</GridItem>
			</Grid>
			<Input
				data-testid={`materials[${clickedMaterialId}].image`}
				display='none'
				name={`materials[${clickedMaterialId}].image`}
				accept='image/*'
				type='file'
				ref={materialFileRef}
				onChange={(e) => handleUploadMaterialImage(e)}
			/>
		</Box>
	)
}

export default memo(EditMaterialTabPanel)
