import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

export const useGetCurrentLimitedWarehouse = (warehouseId) => {
	const limitedWarehouses = useSelector((state) => state.auth.warehouse_module.inventoried_warehouses)
	const [limitedWarehouse, setLimitedWarehouse] = useState(
		limitedWarehouses?.find((limitedWarehouse) => limitedWarehouse.id == warehouseId),
	)

	useEffect(() => {
		const inventoryData = limitedWarehouses?.find((limitedWarehouse) => limitedWarehouse.id == warehouseId)
		setLimitedWarehouse(inventoryData)
	}, [limitedWarehouses])

	return limitedWarehouse
}
