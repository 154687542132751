import { Box, Container, Flex, Heading } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import BackForwardButtons from "../../../../../components/common/BackForwardButtons"
import WarehouseEditForm from "../../../../../components/Modules/Warehouse/Warehouses/Edit/WarehouseEditForm"
import { getEditWarehouse } from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import LoadingHOC from "../../../../../components/common/LoadingHOC"

const EditWarehouse = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const { warehouseId } = useParams()
	const [initValues, setInitValues] = useState()
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		dispatch(getEditWarehouse(warehouseId)).then((res) => {
			setInitValues(res.payload.warehouse)
			setIsLoading(false)
		})
	}, [])

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box display='inline-flex' alignItems='flex-start' mb={[2, 2, 2, 0]}>
					<Flex alignItems={"center"}>
						<BackForwardButtons />
						<Heading fontSize={["15", "16", "17", "18", "19"]} ms={2}>
							{t("Warehouses.editWarehouse")}
						</Heading>
					</Flex>
				</Box>
				<LoadingHOC isLoading={!isLoading}>
					<WarehouseEditForm initValues={initValues} />
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default EditWarehouse
