import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from "@chakra-ui/react"
import { useRef } from "react"
import { useTranslation } from "react-i18next"

const ConfirmModal = ({ isOpen, onClose, handleConfirm }) => {
	const cancelRef = useRef()
	const { t } = useTranslation("global")

	return (
		<AlertDialog
			motionPreset='slideInBottom'
			leastDestructiveRef={cancelRef}
			onClose={onClose}
			isOpen={isOpen}
			isCentered
		>
			<AlertDialogOverlay />

			<AlertDialogContent>
				<AlertDialogHeader fontSize={[14, 16, 18, 20]}>{t("Order.confirmCreateOrder")}</AlertDialogHeader>
				<AlertDialogBody fontSize={[13, 14, 14, 16]}>{t("Order.confirmMessage")}</AlertDialogBody>
				<AlertDialogFooter>
					<Button
						fontSize={[13, 14, 14, 16]}
						colorScheme='red'
						h={["30px", "35px", "40px"]}
						ref={cancelRef}
						onClick={onClose}
					>
						{t("Order.cancelCreateOrder")}
					</Button>
					<Button
						fontSize={[13, 14, 14, 16]}
						h={["30px", "35px", "40px"]}
						colorScheme='green'
						ml={3}
						onClick={handleConfirm}
					>
						{t("Order.createOrder")}
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export default ConfirmModal
