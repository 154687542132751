import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Checkbox,
	Divider,
	Flex,
	Grid,
	GridItem,
	Image,
	Text,
} from "@chakra-ui/react"
import LanguageInputs from "../../../../common/LanguageInputs"
import { useTranslation } from "react-i18next"
import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"
import { useFormikContext } from "formik"
import { memo } from "react"

const MaterialTabPanel = ({ language, getObjectURLFromImg }) => {
	const { t } = useTranslation("global")
	const { values } = useFormikContext()

	const getMaterialsParameters = (parameterCounter) =>
		parameterCounter?.map((parameter, index) => (
			<Box key={index} mb={2} mt={2}>
				<Flex
					ms={[3, 3, 5, 7, 10]}
					flexWrap={["wrap", "wrap", "nowrap"]}
					justifyContent={"center"}
					alignItems='center'
					mb={4}
				>
					{parameter.icon ? (
						<Image
							me={2}
							w='100%'
							h='100%'
							objectFit={"contain"}
							src={getObjectURLFromImg(parameter.icon)}
							alt='Parameter icon'
							boxSize={[5, 6, 7]}
						/>
					) : (
						<LackImageIcon me={2} boxSize={[5, 6, 7]} w='100%' h='100%' alt='Lack of parameter icon' />
					)}
					<Text
						w={["60px", "100px", "200px", "300px", "300px"]}
						whiteSpace={"pre-wrap"}
						textAlign={"center"}
						wordBreak={"break-word"}
						fontSize={[13, 14, 15, 15, 16]}
						me={[2, 2, 5, 7, 10]}
					>
						{values.parameters[index][`name_${language}`]}
					</Text>
					<Text fontSize={[13, 14, 15, 15, 16]}>{parameter.count ? parameter.count : 0}</Text>
				</Flex>
			</Box>
		))

	const getMaterialsItems = values.materials.map((material, index) => (
		<GridItem key={index} colSpan={4} mb={4} borderRadius='4px' bgColor='#F2F2EF' px={4}>
			<Accordion allowMultiple>
				<AccordionItem border='none'>
					<Grid templateColumns={"repeat(4, 1fr)"}>
						<GridItem colSpan={[3, 3, 2, 1]} py={7}>
							<Flex gap={2}>
								<Text
									maxW={["80%", "80%", "80%", "140px", "200px"]}
									fontSize={[13, 14, 15, 15, 16]}
									ms={2}
								>
									{material[`name_${language}`]}
								</Text>
								<LanguageInputs
									isShowPreview={true}
									name={`materials[${index}].name`}
									title={t("Materials.productName", { lng: language })}
								/>
							</Flex>
						</GridItem>
						<GridItem display={["none", "none", "none", "grid"]}>
							{/* <Box py={7}>
                                                                                    {
                                                                                        isEditPreview ? (
                                                                                            <NumberInput type='number' name='materialIndex'
                                                                                            h='100%' w='20%' value={material.index} onChange={(e) => handleMaterialNumberInput(index, e)} defaultValue={0} min={0} >
                                                                                            <NumberInputField bgColor='#FFF'/>
                                                                                            <NumberInputStepper>
                                                                                                <NumberIncrementStepper/>
                                                                                                <NumberDecrementStepper/>
                                                                                            </NumberInputStepper>
                                                                                            </NumberInput> 
                                                                                        ) : (
                                                                                            <Text ms={3}>{material.index}</Text>
                                                                                        )
                                                                                    }
                                                                                </Box> */}
						</GridItem>
						<GridItem colSpan={1} justifySelf={["end", "end", "start"]} py={7}>
							<Box h={["50px", "60px", "70px", "80px"]} w={["70px", "80px", "90px", "100px"]}>
								{material.image ? (
									<Image
										w='100%'
										h='100%'
										objectFit={"contain"}
										alt='Material image'
										src={getObjectURLFromImg(material.image)}
									/>
								) : (
									<LackImageIcon w='100%' h='100%' alt='Lack of material image' mx='auto' />
								)}
							</Box>
						</GridItem>
						<GridItem colSpan={[4, 4, 1]} py={7} justifySelf='end'>
							<Box display='inline-flex'>
								<AccordionButton
									aria-label='Accordion button'
									borderRadius='4px'
									bgColor='#FFF'
									boxSize={["30px", "35px", "37px", "40px"]}
									p={0}
								>
									<AccordionIcon boxSize={[5, 6, 6, 7]} mx='auto' />
								</AccordionButton>
							</Box>
						</GridItem>
						<GridItem colSpan={4}>
							<AccordionPanel p={0}>
								<Divider borderColor='#FFF' />
								<Box
									display='flex'
									flexWrap={["wrap", "wrap", "nowrap"]}
									h='100%'
									alignItems='center'
									my={5}
									w='100%'
								>
									<Text fontSize={[13, 14, 15, 15, 16]} w={["80%", "80%", "8%"]}>
										{t("Materials.inWarehouse", { lng: language })}
									</Text>
									<Checkbox
										aria-label='checkbox'
										className='materials-checkbox'
										disabled
										type='checkbox'
										isChecked={material.is_in_warehouse}
										name={`materials[${index}].is_in_warehouse`}
										size='lg'
										colorScheme='teal'
										bgColor='#FFF'
									/>
									<Flex mt={[3, 3, 3, 0]} gap={2}>
										<Text fontSize={[13, 14, 15, 15, 16]} ms={[0, 0, 10]}>
											{t("Materials.description", { lng: language })}
										</Text>
										<Text maxW={["6%", "13%", "24%", "35%", "50%"]} fontSize={[13, 14, 15, 15, 16]}>
											{material[`description_${language}`]}
										</Text>
										<LanguageInputs
											isShowPreview={true}
											name={`materials[${index}].description`}
											title={t("Materials.description", { lng: language })}
										/>
									</Flex>
								</Box>
								{values.parameters.length > 0 && (
									<>
										<Divider borderColor='#FFF' />
										<Flex mt={4} mb={1}>
											<Box display='inline-flex'>
												<Text fontSize={[13, 14, 15, 15, 16]} my={2}>
													{t("Materials.parameters", { lng: language })}
												</Text>
											</Box>
											<Box>{getMaterialsParameters(material.parameter_counter)}</Box>
										</Flex>
									</>
								)}
							</AccordionPanel>
						</GridItem>
					</Grid>
				</AccordionItem>
			</Accordion>
		</GridItem>
	))

	return (
		<Box display='inline-flex' w='100%' minH={["300px", "300px", "350px", "400px"]}>
			<Grid w='100%'>
				<GridItem>
					<Grid templateColumns={"repeat(4, 1fr)"}>
						<GridItem ms={4} mb={3}>
							<Text fontSize={[13, 14, 15, 16]}>{t("Materials.materialName", { lng: language })}</Text>
						</GridItem>
						<GridItem ms={4}>{/* <Text>{t("Materials.index", {lng: language})}</Text> */}</GridItem>
						<GridItem ms={4} colSpan={2} me={[7, 8, 0]} justifySelf={["end", "end", "start"]}>
							<Text fontSize={[13, 14, 15, 16]}>{t("Materials.image", { lng: language })}</Text>
						</GridItem>
						{getMaterialsItems}
					</Grid>
				</GridItem>
			</Grid>
		</Box>
	)
}

export default memo(MaterialTabPanel)
