import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useMediaQuery } from "@chakra-ui/react"
import React, { memo } from "react"
import { useTranslation } from "react-i18next"

import ClothesForm from "../ClothesForm/ClothesForm"
import BasicDatesForm from "../BasicDatesForm/BasicDatesForm"
import SwipeableTabs from "../../../../common/SwipeableTabs"
import HoursForm from "../HoursForm/HoursForm"
import DiplomaForm from "../DiplomaForm/DiplomaForm"

const EmployeeTabs = ({ isPatchMethod = false, initialValues, employeeInitialDates, onSubmit, employeeCoffee }) => {
	const { t } = useTranslation("global")
	const [isDesktopSize] = useMediaQuery("(min-width: 992px)")
	console.log("employeeInitialDates", employeeInitialDates)
	console.log("employeeInitialDates", employeeCoffee)
	const getTabPanels = (styles) => (
		<TabPanels style={styles}>
			<TabPanel p={0}>
				<BasicDatesForm
					isPatchMethod={isPatchMethod}
					initialValues={initialValues}
					employeeInitialDates={employeeInitialDates}
					onSubmit={onSubmit}
				/>
			</TabPanel>
			<TabPanel p={0}>
				<HoursForm coffee={employeeCoffee} onSubmit={onSubmit} />
			</TabPanel>
			<TabPanel p={0}>
				<ClothesForm />
			</TabPanel>
			<TabPanel p={0}>
				<DiplomaForm />
			</TabPanel>
		</TabPanels>
	)
	return isDesktopSize ? (
		<Tabs display={["block", "inline-flex"]} w='100%'>
			<TabList display={["flex", "block"]} w={["auto", "16%"]} border='none'>
				<Tab
					w='100%'
					minH='55px'
					borderRadius='4px'
					border='none'
					bgColor='rgba(255, 255, 255, 0.50)'
					borderColor='rgba(255, 255, 255, 0.50)'
					position={"relative"}
					_selected={{
						w: "102%",
						color: "#000",
						bgColor: "#FFF",
						borderRightRadius: 0,
						zIndex: 1,
					}}
				>
					<Box w='100%' p={[0, 0, 0, 0, 2]}>
						<Text fontSize={[13, 14, 15, 16]} fontWeight={600} ms={2}>
							{t("Employees.basicDates")}
						</Text>
					</Box>
				</Tab>
				<Tab
					w='100%'
					minH='55px'
					my={4}
					borderRadius='4px'
					border='none'
					bgColor='rgba(255, 255, 255, 0.50)'
					borderColor='rgba(255, 255, 255, 0.50)'
					position={"relative"}
					_selected={{
						w: "102%",
						color: "#000",
						bgColor: "#FFF",
						borderRightRadius: 0,
						zIndex: 1,
					}}
				>
					<Box w='100%' p={[0, 0, 0, 0, 2]}>
						<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
							{t("Employees.hours")}
						</Text>
					</Box>
				</Tab>
				<Tab
					w='100%'
					minH='55px'
					my={4}
					borderRadius='4px'
					border='none'
					bgColor='rgba(255, 255, 255, 0.50)'
					borderColor='rgba(255, 255, 255, 0.50)'
					position={"relative"}
					_selected={{
						w: "102%",
						color: "#000",
						bgColor: "#FFF",
						borderRightRadius: 0,
						zIndex: 1,
					}}
				>
					<Box w='100%' p={[0, 0, 0, 0, 2]}>
						<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
							{t("Employees.clothes")}
						</Text>
					</Box>
				</Tab>
				<Tab
					w='100%'
					minH='55px'
					my={4}
					borderRadius='4px'
					border='none'
					bgColor='rgba(255, 255, 255, 0.50)'
					borderColor='rgba(255, 255, 255, 0.50)'
					position={"relative"}
					_selected={{
						w: "102%",
						color: "#000",
						bgColor: "#FFF",
						borderRightRadius: 0,
						zIndex: 1,
					}}
				>
					<Box w='100%' p={[0, 0, 0, 0, 2]}>
						<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
							{t("Employees.diplomas")}
						</Text>
					</Box>
				</Tab>
			</TabList>
			{getTabPanels({ width: "84%", paddingBottom: 4 })}
		</Tabs>
	) : (
		<SwipeableTabs tabs={[{ name: t("Employees.basicDates") }]}>{getTabPanels({ paddingBottom: 2 })}</SwipeableTabs>
	)
}

export default memo(EmployeeTabs)
