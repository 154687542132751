import { Menu, MenuItem } from "@chakra-ui/react"
import i18next from "i18next"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

const PermssionsOptions = ({ styles, hover, ...props }) => {
	const permissionsOptions = useSelector((state) => state.auth.permission_options)

	return (
		<Menu px={0}>
			{permissionsOptions?.map((option, index) => (
				<Link key={index} to={option.url} {...props}>
					<MenuItem style={styles} _hover={hover}>
						{option[`name_${i18next.language}`]}
					</MenuItem>
				</Link>
			))}
		</Menu>
	)
}

export default PermssionsOptions
