import { Box, Container, Divider, useMediaQuery } from "@chakra-ui/react"
import Animation from "../../../../utils/animations/Routes/AnimatedPage"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import BackForwardButtons from "../../../../components/common/BackForwardButtons"
import ModuleHeading from "../../../../components/common/ModuleHeading"
import LoadingHOC from "../../../../components/common/LoadingHOC"
import TabsFrame from "../../../../components/common/TabsFrame"
import { clearConfig } from "../../../../store/modules/users/settings/settings-slice"
import WarehouseRolesForm from "../../../../components/Modules/Warehouse/WarehouseSettings/WarehouseRoles/WarehouseRolesForm"
import WarehouseUsersInGroupsForm from "../../../../components/Modules/Warehouse/WarehouseSettings/UsersInGroupsForm/WarehouseUsersInGroupsForm"
import AccessToWarehouse from "../../../../components/Modules/Warehouse/WarehouseSettings/AccessToWarehouse/AccessToWarehouse"
import { getWarehouseSettings } from "../../../../actions/modules/warehouse/settings/settings-actions"

const WarehouseSettings = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const config = useSelector((state) => state.warehouseSettings.config)
	const [isGettedSettings, setIsGettedSettings] = useState(false)
	const [isLargerThan480] = useMediaQuery("(min-width: 480px)", { ssr: false })

	useEffect(() => {
		dispatch(getWarehouseSettings()).then(() => setIsGettedSettings(true))
		return () => dispatch(clearConfig())
	}, [dispatch])

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box display='inline-flex' alignItems='center' mb={[2, 2, 2, 0]}>
					<BackForwardButtons />
					<ModuleHeading heading={t("Common.settingsHeading")} />
				</Box>
				<Box h={["25px", "auto"]}>
					<Divider borderColor='#FFF' borderWidth={["1px", "2px"]} my={[2, 3, 4]} />
				</Box>
				<LoadingHOC isLoading={isGettedSettings}>
					<TabsFrame
						tabs={[
							{ name: t("UsersSettings.usersRoles") },
							{ name: t("UsersSettings.usersInGroups") },
							{ name: t("WarehouseSettings.warehouseAccess") },
						]}
						tabPanelsStyles={{ width: isLargerThan480 ? "84%" : "auto" }}
					>
						<WarehouseRolesForm initialData={config?.user_group_permissions} />
						<WarehouseUsersInGroupsForm
							initialData={config?.user_warehouse_group_permissions}
							groups={config?.user_group_permissions}
						/>
						<AccessToWarehouse data={config?.access_to_warehouses} />
					</TabsFrame>
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default WarehouseSettings
