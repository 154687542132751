import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { getDateAndTime } from "../../actions/system/system-actions"
import { changeLang } from "../../actions/lang/lang-actions"
import i18next from "i18next"
import { auth, login, logout } from "../../actions/auth/auth/auth-actions"

/**
 * Slice for system
 */
const systemSlice = createSlice({
	name: "system",
	initialState: {
		dateTime: null,
	},
	reducers: {
		clearDateTime(state) {
			state.dateTime = null
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(logout.fulfilled, (state) => {
				state.dateTime = null
			})
			.addCase(getDateAndTime.fulfilled, (state, action) => {
				state.dateTime = action.payload
			})
			.addCase(changeLang.fulfilled, (_state, action) => {
				i18next.changeLanguage(action.meta.arg)
			})
			.addMatcher(isAnyOf(login.fulfilled, auth.fulfilled), (state, action) => {
				state.dateTime = { time: action.payload.time, week: action.payload.week }
			})
	},
})

export const { clearDateTime } = systemSlice.actions

export default systemSlice
