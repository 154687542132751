import { Box, Button, Divider, Flex, Heading, Text, useDisclosure } from "@chakra-ui/react"
import { memo, useRef, useState } from "react"
import { Formik, Form, FieldArray } from "formik"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import BinIcon from "../../../../../assets/icons/materials/BinIcon"
import { AddIcon } from "@chakra-ui/icons"
import {
	removeAutoEmailUser,
	setAutomaticEmails,
} from "../../../../../actions/modules/orders/ordersSettings/orders-settings-actions"
import OrderSettingsSelect from "../OrderSettingsSelect/OrderSettingsSelect"
import RemoveAlert from "../../../../common/RemoveAlert"

const OrdersAutoEmailForm = ({ initialData }) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [userId, setUserId] = useState()
	const formRef = useRef()

	const handleOpenDialog = (id, remove, index) => {
		if (!id) remove(index)
		else {
			setUserId(id)
			onOpen()
		}
	}

	const handleCloseDialog = () => {
		setUserId(null)
		onClose()
	}

	const handleRemoveItem = () => {
		formRef.current.setSubmitting(true)
		userId && dispatch(removeAutoEmailUser(userId)).then(() => formRef.current.setSubmitting(false))
		setUserId(null)
		onClose()
	}

	const emailAddreses = (
		<FieldArray name='user_id'>
			{({ push, remove, form }) => (
				<>
					{form.values.user_id?.map((userId, index) => (
						<Flex
							key={index}
							my={2}
							p={3}
							bgColor='#F2F2EF'
							borderRadius='4px'
							justifyContent='space-between'
							alignItems='center'
						>
							<OrderSettingsSelect
								isDisabled={form.isSubmitting}
								iconStyles={{ width: "10%" }}
								w={["70%", "50%", "30%"]}
								fontSize={[13, 14, 15, 16]}
								h={["30px", "35px", "40px"]}
								name={`user_id[${index}]`}
								placeholder={t("OrdersSettings.selectUser")}
								allValues={initialData?.all_users}
								values={initialData?.filtered_users.map((user) => ({
									id: user.id,
									value: user.id,
									name: user.name,
								}))}
								onChange={(e) => handleSave(e, userId.id)}
							/>
							<Button
								aria-label='Remove button'
								isDisabled={form.isSubmitting}
								bgColor='transparent'
								minW='30px'
								boxSize={["30px", "35px", "40px"]}
								p={0}
								color='red.500'
								onClick={() => handleOpenDialog(userId.id, remove, index)}
							>
								<BinIcon boxSize={[5, 6]} />
							</Button>
						</Flex>
					))}
					<Button
						isDisabled={!initialData?.filtered_users.length || form.isSubmitting}
						fontSize={[13, 14, 15, 16]}
						_hover={{ backgroundColor: "teal.700" }}
						bgColor='#2B807E'
						h={["30px", "35px", "40px"]}
						p={2}
						my={2}
						colorScheme='teal'
						color='#FFF'
						onClick={() => push("")}
					>
						<AddIcon boxSize={3} me={2} />
						<Text aria-label='Add new row button'>{t("OrdersSettings.addNew")}</Text>
					</Button>
				</>
			)}
		</FieldArray>
	)

	const handleSave = (userId, id) => {
		formRef.current.setSubmitting(true)
		const data = {
			id: id,
			user_id: userId,
		}

		if (!id) delete data.id

		dispatch(setAutomaticEmails(data)).then(() => formRef.current.setSubmitting(false))
	}

	return (
		<Formik
			innerRef={formRef}
			enableReinitialize={true}
			initialValues={{
				user_id: initialData?.config.length ? initialData.config : [""],
			}}
		>
			{() => (
				<Form>
					<Box minH={["300px", "400px", "500px", "700px"]}>
						<Heading fontSize={[14, 15, 16, 17]} fontWeight={400}>
							{t("OrdersSettings.ordersAutoEmail")}
						</Heading>
						<Text fontSize={[13, 14, 15, 16]} color='teal.600'>
							{t("OrdersSettings.usersList")}
						</Text>
						<Divider my={2} w='100%' borderColor='#D4D4CD' borderWidth='1px' />
						<Text fontSize={[13, 14, 15, 16]} color='teal.600' ms={4} my={[3, 4]}>
							{t("OrdersSettings.usersNames")}
						</Text>
						{emailAddreses}
						<RemoveAlert
							isOpen={isOpen}
							onClose={handleCloseDialog}
							handleConfirmRemove={handleRemoveItem}
						/>
					</Box>
				</Form>
			)}
		</Formik>
	)
}

export default memo(OrdersAutoEmailForm)
