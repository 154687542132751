import { Button, Flex, Heading, useBreakpointValue } from "@chakra-ui/react"
import BsSortUp from "../../assets/icons/permissions/BsSortUp"
import BsSortDown from "../../assets/icons/permissions/BsSortDown"
import { memo } from "react"

const RecordsList = ({ headers, records, ...props }) => {
	const columnWidthBreakpoints = {
		base: 100,
		sm: 125,
		md: 150,
		lg: 200,
		xl: 250,
	}
	const columnWidth = useBreakpointValue(columnWidthBreakpoints)

	const columnGapBreakpoints = {
		base: 10,
	}
	const columnGap = useBreakpointValue(columnGapBreakpoints)

	return (
		<Flex flexDirection={"column"} overflowX={"auto"} gap={2}>
			<Flex justifyContent={"space-between"} gap={`${columnGap}px`} px={5}>
				{headers?.map((header, index) => (
					<Flex
						key={index}
						w={`${columnWidth}px`}
						minW={`${columnWidth}px`}
						gap={1}
						alignItems={"center"}
						justifyContent={header.justifyContent || "flex-start"}
					>
						<Heading
							fontSize={{ base: "12px", md: "13px", lg: "14px" }}
							fontWeight={400}
							whiteSpace={"pre-wrap"}
							wordBreak={"break-all"}
						>
							{header.name}
						</Heading>
						{header.sortingMethod && (
							<Button
								aria-label='Sorting button by group'
								p={3}
								minW={"20px"}
								boxSize={{ base: "20px", lg: "30px" }}
								bgColor='transparent'
								_hover={{
									bgColor: "#FFF",
								}}
								onClick={header.sortingMethod}
							>
								{header.isDescSort ? (
									<BsSortUp boxSize={{ base: "14px", md: "16px", lg: "18px" }} />
								) : (
									<BsSortDown boxSize={{ base: "14px", md: "16px", lg: "18px" }} />
								)}
							</Button>
						)}
					</Flex>
				))}
			</Flex>
			<Flex flexDirection={"column"} gap={2} mb={2} w='100%'>
				{records?.map((record, index) => (
					<Flex
						key={index}
						minH='44px'
						justifyContent={"space-between"}
						gap={`${columnGap}px`}
						borderRadius={"4px"}
						bgColor={"#FFF"}
						px={5}
						minW={`${record.childrens.length * (columnWidth + columnGap) + 30}px`}
						boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
						{...props}
					>
						{record.childrens.map((children, index) => (
							<Flex
								key={index}
								w={`${columnWidth}px`}
								minW={`${columnWidth}px`}
								gap={1}
								alignItems={"center"}
								fontSize={{ base: "12px", md: "13px", lg: "14px" }}
								justifyContent={children.justifyContent || "flex-start"}
							>
								{children.body}
							</Flex>
						))}
					</Flex>
				))}
			</Flex>
		</Flex>
	)
}

export default memo(RecordsList)
