import { createAsyncThunk } from "@reduxjs/toolkit"
import { handleRequest } from "../../../../utils/api/api"

/**
 * Get initial dates to employee form
 */
export const getEmployees = createAsyncThunk("employee/getEmployees", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/users/employees",
	})
	return responseData
})

/**
 * Get current employee function
 */
export const getEmployeeById = createAsyncThunk("employee/getEmployeeById", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/users/employees/${id}`,
	})
	return responseData
})

/**
 * Get initial dates to employee form
 */
export const getEmployeeFormInitialDates = createAsyncThunk("employee/getEmployeeFormInitialDates", async () => {
	const responseData = await handleRequest({
		endPoint: `/api/users/newEmployee`,
	})
	return responseData
})

/**
 * Create new employee
 */
export const createNewEmployee = createAsyncThunk("employee/createNewEmployee", async (values) => {
	await handleRequest({
		endPoint: "/api/users/employees",
		method: "POST",
		body: values,
	})
})

/**
 * Create new employee
 */
export const editEmployee = createAsyncThunk("employee/editEmployee", async (values) => {
	await handleRequest({
		endPoint: `/api/users/employees/${values.id}`,
		method: "PATCH",
		body: values,
	})
})
