import { Box, Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react"
import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"
import LanguageInputs from "../../../../common/LanguageInputs"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"
import { memo } from "react"

const ParametersPanels = ({ parameter, parameterId, getObjectURLFromImg, language }) => {
	const { t } = useTranslation("global")
	const { values } = useFormikContext()

	const getParametersValues = (parameterId) =>
		values.parameters[parameterId].values.map((value, index) => (
			<Grid
				key={index}
				templateColumns={"repeat(2, 1fr)"}
				w='100%'
				bgColor='#F2F2EF'
				my={4}
				p={3}
				borderRadius='4px'
			>
				<GridItem>
					<Box display='block' alignItems='center'>
						<Flex gap={2} mt={["1", "1", "1", "2"]}>
							<Text wordBreak={"break-word"} fontSize={[13, 14, 15, 16]}>
								{value[`name_${language}`]}
							</Text>
							<LanguageInputs
								isShowPreview={true}
								name={`parameters[${parameterId}].values[${index}].name`}
								title={t("Materials.parameterValue", { lng: language })}
							/>
						</Flex>
					</Box>
				</GridItem>
			</Grid>
		))

	return (
		parameter && (
			<Box key={parameterId}>
				<Grid w='100%'>
					<GridItem w='100%' justifyContent='end' pe={1}>
						<Box display='flex' w='100%' alignItems='center'>
							<Box
								border='1px solid #F2F2EF'
								borderRadius='4px'
								p={[1, 1.5]}
								w={["55px", "65px", "70px", "90px"]}
								h={["55px", "65px", "70px", "90px"]}
							>
								{parameter.icon ? (
									<Image
										src={getObjectURLFromImg(parameter.icon)}
										alt='Parameter icon'
										w='100%'
										h='100%'
										objectFit={"contain"}
									/>
								) : (
									<LackImageIcon
										border='1px solid #000'
										alt='Lack of parameter icon'
										borderRadius='4px'
										w='100%'
										h='100%'
									/>
								)}
							</Box>
							<Box border='1px solid #F2F2EF' borderRadius='4px' p={1.5} ms={5}>
								<Flex gap={parameter[`name_${language}`] && 2} alignItems={"center"}>
									<Text wordBreak={"break-word"} fontSize={[13, 14, 15, 16]}>
										{parameter[`name_${language}`]}
									</Text>
									<LanguageInputs
										isShowPreview={true}
										name={`parameters[${parameterId}].name`}
										title={t("Materials.parameterName", { lng: language })}
									/>
								</Flex>
							</Box>
						</Box>
					</GridItem>
				</Grid>
				{getParametersValues(parameterId)}
			</Box>
		)
	)
}

export default memo(ParametersPanels)
