/* eslint-disable no-undef */
import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer"

import InterRegular from "../../../../../assets/fonts/Inter-Regular.ttf"
import InterBold from "../../../../../assets/fonts/Inter-Bold.ttf"
import React from "react"

const MaterialPDF = ({
	data,
	warehouseString,
	tableHeaders,
	warehouseName,
	materialGroupString,
	materialGroup,
	tableFields,
}) => {
	Font.register({
		family: "Inter",
		fonts: [
			{ src: InterRegular, fontWeight: 400 },
			{ src: InterBold, fontWeight: 600 },
		],
	})

	const calculateColumnWidth = (numColumns) => {
		return `${100 / numColumns}%`
	}

	const concatenatedParameterValues = (values) => {
		let valuesString = ""

		values.forEach((value, index) => {
			valuesString += value
			if (index === values.length - 1) return
			valuesString += " x "
		})

		return valuesString
	}

	const styles = StyleSheet.create({
		page: {
			fontFamily: "Inter",
			padding: 10,
		},
		container: {
			height: "100%",
			border: "1px solid #000000",
			padding: 10,
			gap: 17,
		},
		infoPanel: {
			flexDirection: "row",
			justifyContent: "space-between",
		},
		mainDivider: {
			borderBottomWidth: 3,
			borderBottomColor: "#000000",
			marginBottom: 10,
		},
		tableDivider: {
			borderBottomWidth: 1,
			borderBottomColor: "#000000",
			marginTop: 10,
			marginBottom: 10,
		},
		tableView: {
			alignItems: "center",
		},
		table: {
			display: "table",
			fontSize: 14,
			width: "100%",
			borderStyle: "solid",
			borderWidth: 1,
			borderRightWidth: 0,
			borderBottomWidth: 0,
		},
		tableRow: {
			flexDirection: "row",
			justifyContent: "space-between",
		},
		tableCol: {
			borderStyle: "solid",
			borderWidth: 1,
			borderLeftWidth: 0,
			borderTopWidth: 0,
			padding: 5,
		},
		tableHeaderCell: {
			backgroundColor: "#2C5282",
			color: "#FFF",
			fontWeight: "bold",
			fontSize: 13,
			textAlign: "center",
			width: calculateColumnWidth(tableHeaders?.length),
		},
		tableBodyCell: {
			fontSize: 11,
			textAlign: "center",
			width: calculateColumnWidth(tableHeaders?.length),
		},
	})

	return (
		<Document title={warehouseName}>
			<Page size='A4' style={styles.page}>
				<View style={styles.container}>
					<View style={styles.infoPanel}>
						<View style={{ gap: 15 }}>
							<Text style={{ fontWeight: "bold", fontSize: 20 }}>{process.env.REACT_APP_API_KEY}</Text>
							<Text style={{ fontWeight: "bold", fontSize: 18 }}>
								{warehouseString}: {warehouseName}
							</Text>
							<Text style={{ fontWeight: "bold", fontSize: 18 }}>
								{materialGroupString}: {materialGroup}
							</Text>
						</View>
						<Image
							src={`${process.env.PUBLIC_URL}/logos/${process.env.REACT_APP_API_KEY}-LOGO-192.png`}
							style={{ width: 100, height: 100 }}
						/>
					</View>
					<View style={styles.mainDivider} />
					{data?.map((material, index) => (
						<React.Fragment key={index}>
							{index !== 0 && <View style={styles.tableDivider} />}
							<View style={styles.tableView}>
								<View style={styles.table}>
									<View style={styles.tableRow}>
										{tableHeaders?.map((header, index) => (
											<View key={index} style={[styles.tableCol, styles.tableHeaderCell]}>
												<Text>{header}</Text>
											</View>
										))}
									</View>
									<View key={index} style={styles.tableRow}>
										{tableHeaders?.map((_, index) => (
											<View key={index} style={[styles.tableCol, styles.tableBodyCell]}>
												<Text>
													{index > 0 && index < material.parameter_values.length + 1
														? concatenatedParameterValues(
																material.parameter_values[index - 1].values,
															)
														: material[tableFields[index]]}
												</Text>
											</View>
										))}
									</View>
								</View>
							</View>
						</React.Fragment>
					))}
				</View>
			</Page>
		</Document>
	)
}

export default MaterialPDF
