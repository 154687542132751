import {
	Box,
	Button,
	Divider,
	Flex,
	Grid,
	GridItem,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	Text,
} from "@chakra-ui/react"
import { memo, useCallback, useMemo, useState } from "react"
import { Formik, Form, FieldArray } from "formik"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import i18next from "i18next"

import SelectField from "../../../../common/SelectField"
import { SearchIcon } from "@chakra-ui/icons"
import { setUsersRoles } from "../../../../../actions/modules/orders/ordersSettings/orders-settings-actions"

const OrdersUsersInGroupForm = ({ initialData, groups }) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const [searchingTerm, setSearchingTerm] = useState("")
	const [usersCopy] = useState(initialData?.config)
	const [users, setUsers] = useState(initialData?.config)

	const handleSearching = useCallback(
		(searchValue) => {
			if (!users) return
			setSearchingTerm(searchValue)
			if (searchValue === "") {
				setUsers(usersCopy)
			} else {
				const filteredOrders = usersCopy.filter((user) =>
					user.user.toLowerCase().includes(searchValue.toLowerCase()),
				)
				setUsers(filteredOrders)
			}
		},
		[users, usersCopy],
	)

	const handlePressEnter = (e) => {
		if (e.key !== "Enter") return

		e.preventDefault()
	}

	const getSelectOption = useMemo(() => {
		const optionsArray = groups?.config.map((group) => ({
			id: group.id,
			value: group.id,
			name: group[`name_${i18next.language}`],
		}))
		optionsArray?.unshift({ id: 0, value: 0, name: t("Common.select") })
		return optionsArray
	}, [groups, i18next.language])

	const usersInGroups = useMemo(
		() =>
			users?.map((user, index) => (
				<FieldArray key={index} name='users'>
					{({ form }) => (
						<Grid
							templateColumns={["repeat(3, 1fr)", "repeat(2, 1fr)"]}
							my={5}
							py={2}
							bgColor='#F2F2EF'
							borderRadius='4px'
							alignItems='center'
						>
							<GridItem ms={4} alignSelf='center'>
								<Text fontSize={[13, 14, 15, 16]}>{user.user}</Text>
							</GridItem>
							<GridItem ms={[4, 0]} me={4} colSpan={[2, 1]}>
								<SelectField
									aria-label='Select group'
									disabled={form.isSubmitting}
									bgColor='#FFF'
									me={2}
									mb={2}
									fontSize={[13, 14, 15, 16]}
									cursor='pointer'
									h={["30px", "35px", "40px"]}
									name={`users[${form.values.users.findIndex(
										(item) => item.id === user.id,
									)}].order_group_permission_id`}
									options={getSelectOption}
								/>
							</GridItem>
						</Grid>
					)}
				</FieldArray>
			)),
		[groups, users, i18next.language],
	)

	const handleSave = (values, actions) => {
		actions.setSubmitting(true)

		const data = values.users.map((user) => ({
			id: user.id,
			order_group_permission_id: Number(user.order_group_permission_id),
		}))

		dispatch(setUsersRoles(data)).then(() => actions.setSubmitting(false))
	}

	return (
		<Formik
			initialValues={{
				users: users,
			}}
			onSubmit={(values, actions) => {
				handleSave(values, actions)
			}}
		>
			{(formik) => (
				<Form>
					<Box minH={["300px", "400px", "500px", "700px"]}>
						<Heading fontSize={[14, 15, 16, 17]} fontWeight={400}>
							{t("OrdersSettings.usersInGroups")}
						</Heading>
						<Text fontSize={[13, 14, 15, 16]} color='teal.600'>
							{t("OrdersSettings.usersListAndRoles")}
						</Text>
						<Divider my={2} w='100%' borderColor='#D4D4CD' borderWidth='1px' />

						<Grid mt={[4, 5]} templateColumns={["repeat(3, 1fr)", "repeat(2, 1fr)"]} color='teal.600'>
							<GridItem>
								<Text ms={3} fontSize={[13, 14, 15, 16]}>
									{t("OrdersSettings.user")}
								</Text>
							</GridItem>
							<GridItem justifySelf={["center", "start"]} w='100%' colSpan={[2, 1]}>
								<Flex justifyContent={"space-between"} flexWrap={["wrap", "wrap", "nowrap"]} w='100%'>
									<Text ms={[5, 1]} me={[4, 0]} fontSize={[13, 14, 15, 16]}>
										{t("OrdersSettings.group")}
									</Text>
									<InputGroup justifyContent='end' mt={[3, 3, 0]} me={[0, 0, 7]} pb={2} color='#000'>
										<Input
											disabled={formik.isSubmitting}
											bgColor={"#fff"}
											w={["150px", "170px", "auto"]}
											h={["30px", "35px", "40px"]}
											mb={[0, 0, 2]}
											boxShadow={
												"0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);"
											}
											borderRadius={"4px"}
											placeholder={t("OrderList.searchPlaceholder")}
											value={searchingTerm}
											onKeyDown={handlePressEnter}
											onChange={(e) => handleSearching(e.target.value)}
										/>
										<InputRightElement h={["30px", "35px", "40px"]}>
											<SearchIcon />
										</InputRightElement>
									</InputGroup>
								</Flex>
							</GridItem>
						</Grid>
						<Box maxH={["300px", "350px", "450px", "500px"]} overflowY={"auto"}>
							{usersInGroups}
						</Box>
						<Flex
							h='100%'
							minH={["100px", "150px", "200px", "150px"]}
							alignItems='end'
							justifyContent='end'
						>
							<Button
								type='submit'
								fontSize={[13, 14, 15, 16]}
								bgColor='green.600'
								_hover={{ backgroundColor: "green.700" }}
								colorScheme='green'
								h={["30px", "35px", "40px"]}
							>
								<Text aria-label='Save button'>{t("OrdersSettings.save")}</Text>
							</Button>
						</Flex>
					</Box>
				</Form>
			)}
		</Formik>
	)
}

export default memo(OrdersUsersInGroupForm)
