import { createAsyncThunk } from "@reduxjs/toolkit"

import { handleRequest } from "../../../../utils/api/api"

/**
 * Get order list
 */
export const getOrderList = createAsyncThunk("orderList/getOrderList", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/order",
	})
	return responseData
})

/**
 * Remove Order
 */
export const removeOrderFromOrderList = createAsyncThunk("orderList/removeOrder", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/order/${id}`,
		method: "DELETE",
	})
	return responseData
})
