import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react"
import { Field, useField } from "formik"

import { Textarea } from "@chakra-ui/textarea"

const TextArea = ({ label, ...props }) => {
	const [field, meta] = useField(props)

	const fieldProps = {
		...field,
		...props,
	}
	return (
		<FormControl isInvalid={meta.error && meta.touched}>
			<FormLabel my='0'>{label}</FormLabel>
			<Field as={Textarea} my='0' autoComplete='true' {...fieldProps} />
			<FormErrorMessage my='0'>{meta.error}</FormErrorMessage>
		</FormControl>
	)
}

export default TextArea
