import { createIcon } from "@chakra-ui/react"

const IoEyeOutline = createIcon({
	displayName: "IoEyeOutline",
	viewBox: "0 0 22 22",
	path: (
		<g id='IoEyeOutline'>
			<path
				id='Vector (Stroke)'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.932793 10.2341C3.72863 6.22647 7.35373 4.125 10.9854 4.125C15.2507 4.125 18.9249 6.90369 21.0926 10.2517L21.0936 10.2532C21.237 10.4761 21.3133 10.7356 21.3133 11.0006C21.3133 11.2652 21.2373 11.5242 21.0944 11.7467C18.9286 15.1379 15.2782 17.875 10.9854 17.875C6.64702 17.875 3.06698 15.1436 0.906479 11.7624C0.759679 11.5344 0.683539 11.2682 0.687659 10.997C0.691789 10.7252 0.776395 10.4607 0.930785 10.237L0.932786 10.2341L0.932793 10.2341ZM2.06251 11.0179L2.06442 11.0209L2.06441 11.0209C4.05993 14.1446 7.25829 16.5 10.9854 16.5C14.6708 16.5 17.9381 14.1346 19.936 11.006L19.9374 11.0039C19.938 11.0029 19.9383 11.0018 19.9383 11.0006C19.9383 10.9995 19.938 10.9984 19.9374 10.9975C17.9319 7.90087 14.6379 5.5 10.9854 5.5C7.91995 5.5 4.67523 7.27426 2.06251 11.0179Z'
				fill='black'
			/>
			<path
				id='Vector (Stroke)_2'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25ZM6.875 11C6.875 8.72183 8.72183 6.875 11 6.875C13.2782 6.875 15.125 8.72183 15.125 11C15.125 13.2782 13.2782 15.125 11 15.125C8.72183 15.125 6.875 13.2782 6.875 11Z'
				fill='black'
			/>
		</g>
	),
})

export default IoEyeOutline
