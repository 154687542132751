import { Slide, toast } from "react-toastify"

export const infoToast = (message) =>
	toast.info(message, {
		isLoading: false,
		position: "bottom-center",
		autoClose: 1500,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: true,
		limit: 5,
		transition: Slide,
		theme: "colored",
		progressStyle: {
			backgroundColor: "#FFF",
		},
		style: {
			color: "#FFFFFF",
		},
	})
