import { FormControl, FormErrorMessage, FormLabel, Select } from "@chakra-ui/react"
import { Field, useField } from "formik"

const SelectField = ({ label, options, ...props }) => {
	const [field, meta] = useField(props)

	return (
		<FormControl isInvalid={meta.error && meta.touched}>
			<FormLabel>{label}</FormLabel>
			<Field as={Select} my='0' {...field} {...props}>
				{options?.map((option) => (
					<option key={option.id} value={option.value}>
						{option.name}
					</option>
				))}
			</Field>
			<FormErrorMessage fontSize={[12, 13, 14, 15]} ms={1} my={0}>
				{meta.error}
			</FormErrorMessage>
		</FormControl>
	)
}

export default SelectField
