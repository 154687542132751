/* eslint-disable no-undef */
import { Box, Button, Image } from "@chakra-ui/react"

import Footer from "../../../components/Footer/Footer"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Animation from "../../../utils/animations/Routes/AnimatedPage"
import SendResetPasswordEmailForm from "../../../components/Auth/RememberPassword/SendResetPasswordEmailForm/SendResetPasswordEmailForm"
import LeftArrow from "../../../assets/icons/resetPassword/leftArrow"

const SendEmail = () => {
	const [t] = useTranslation("global")
	const navigate = useNavigate()

	const handleBackButton = () => {
		navigate("/")
	}

	return (
		<Animation>
			<main>
				<Box bg='#E5E5DF' h={["92vh", "87.3vh", "91vh", "93vh"]}>
					<Image
						w='100%'
						h='100%'
						objectFit={"cover"}
						src={require("../../../assets/images/login/login-background.webp")}
						alt='background-image'
					/>
					<Button
						position='absolute'
						top='2%'
						left='2%'
						h={["3vh", "5vh", "5vh", "4vh", "3vh"]}
						w={["13vw", "10vw", "9vw", "7vw", "5vw"]}
						fontSize={["10", "10", "12", "13", "14"]}
						color='#FFF'
						bgColor='#000'
						colorScheme='teal'
						onClick={handleBackButton}
					>
						<LeftArrow boxSize={[2, 3, 3, 3]} mr={2} />
						{t("RememberPassword.sendEmail.main.backButton")}
					</Button>
					<Box
						h={["auto", "auto"]}
						w={["60%", "40%", "50%", "30%", "16%"]}
						position='absolute'
						top={["43%", "43%", "45%", "46%", "50%"]}
						left='50%'
						transform='translate(-50%, -50%)'
						py={["1%", "2%", "2%"]}
						borderRadius='24'
						bgColor='#FFF'
						boxShadow='0px 4px 24px 0px rgba(189, 189, 189, 0.20)'
					>
						<Box display='flex' justifyContent='center'>
							<Image
								boxSize={["9", "12", "14", "60px", "16"]}
								mr={1}
								src={`${process.env.PUBLIC_URL}/logos/${process.env.REACT_APP_API_KEY}-LOGO-192.png`}
								alt='FireStop Logo'
							/>
							<Image
								w={["12", "16", "80px", "82px", "98px"]}
								h={["9", "12", "14", "60px", "16"]}
								ml={1}
								src={require("../../../assets/images/login/Poland (PL).png")}
								alt='Polish Flag'
							/>
						</Box>
						<SendResetPasswordEmailForm />
					</Box>
				</Box>
			</main>
			<Footer />
		</Animation>
	)
}

export default SendEmail
