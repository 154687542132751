import { Navigate, useLocation } from "react-router-dom"

import { useSelector } from "react-redux"
import Layout from "../layout/Layout"

/**
 *
 * @returns Navigate to allowed routes if user is login
 */
const ProtectedRoutes = () => {
	const location = useLocation()
	const user = useSelector((state) => state.auth.user)

	return user ? <Layout /> : <Navigate to='/' replace state={{ from: location }} />
}

export default ProtectedRoutes
