import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from "@chakra-ui/react"
import { useRef } from "react"
import { useTranslation } from "react-i18next"

const AlertCancelChanges = ({ isOpen, onClose, handleConfirmCancel }) => {
	const cancelRef = useRef()
	const { t } = useTranslation("global")

	return (
		<AlertDialog
			motionPreset='slideInBottom'
			leastDestructiveRef={cancelRef}
			onClose={onClose}
			isOpen={isOpen}
			isCentered
		>
			<AlertDialogOverlay />

			<AlertDialogContent>
				<AlertDialogHeader fontSize={[14, 16, 18, 20]}>
					{t("AlertCancelChanges.acceptCancelChanges")}
				</AlertDialogHeader>
				<AlertDialogBody fontSize={[13, 14, 14, 16]}>
					{t("AlertCancelChanges.cancelChangesMessage")}
				</AlertDialogBody>
				<AlertDialogFooter>
					<Button fontSize={[13, 14, 14, 16]} h={["30px", "35px", "40px"]} ref={cancelRef} onClick={onClose}>
						{t("AlertCancelChanges.cancelChangesCancelText")}
					</Button>
					<Button
						fontSize={[13, 14, 14, 16]}
						h={["30px", "35px", "40px"]}
						colorScheme='red'
						ml={3}
						onClick={handleConfirmCancel}
					>
						{t("AlertCancelChanges.cancelChangesConfirmText")}
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export default AlertCancelChanges
