import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from "@chakra-ui/react"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"

const InventoryModal = ({ isOpen, onClose }) => {
	const cancelRef = useRef()
	const { t } = useTranslation("global")
	const { submitForm } = useFormikContext()

	const handleSubmitForm = () => {
		submitForm().then(() => {
			onClose()
		})
	}

	return (
		<AlertDialog
			motionPreset='slideInBottom'
			leastDestructiveRef={cancelRef}
			onClose={onClose}
			isOpen={isOpen}
			isCentered
		>
			<AlertDialogOverlay />

			<AlertDialogContent>
				<AlertDialogHeader fontSize={[14, 16, 18, 20]}>{t("Warehouses.inventory")}</AlertDialogHeader>
				<AlertDialogBody fontSize={[13, 14, 14, 16]}>{t("Warehouses.inventoryMessage")}</AlertDialogBody>
				<AlertDialogFooter>
					<Button
						fontSize={[13, 14, 14, 16]}
						colorScheme='red'
						h={["30px", "35px", "40px"]}
						ref={cancelRef}
						onClick={onClose}
					>
						{t("Warehouses.cancel")}
					</Button>
					<Button
						fontSize={[13, 14, 14, 16]}
						h={["30px", "35px", "40px"]}
						colorScheme='green'
						ml={3}
						onClick={handleSubmitForm}
					>
						OK
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export default InventoryModal
