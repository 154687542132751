import { createAsyncThunk } from "@reduxjs/toolkit"
import { handleRequest } from "../../../../utils/api/api"

/**
 * Get users settings
 */
export const getUsersSettings = createAsyncThunk("usersSettings/getUsersSettings", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/users/allConfig",
	})
	return responseData
})

/**
 * Add roles to settings
 */
export const addRoles = createAsyncThunk("usersSettings/addRoles", async (values) => {
	const responseData = await handleRequest({
		endPoint: "/api/users/userGroupPermissions",
		method: "POST",
		body: values,
	})
	return responseData
})

/**
 * Set user role
 */
export const setUsersRoles = createAsyncThunk("usersSettings/setUsersRoles", async (values) => {
	const responseData = await handleRequest({
		endPoint: "/api/users/userUserGroupPermissions",
		method: "PATCH",
		body: values,
	})
	return responseData
})
