import { Box, Container, Divider } from "@chakra-ui/react"
import Animation from "../../../../utils/animations/Routes/AnimatedPage"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import BackForwardButtons from "../../../../components/common/BackForwardButtons"
import ModuleHeading from "../../../../components/common/ModuleHeading"
import LoadingHOC from "../../../../components/common/LoadingHOC"
import TabsFrame from "../../../../components/common/TabsFrame"
import { clearConfig } from "../../../../store/modules/users/settings/settings-slice"
import { getUsersSettings } from "../../../../actions/modules/users/settings/settings-actions"
import UsersRolesForm from "../../../../components/Modules/Users/UsersSettings/UsersRoles/UsersRolesForm"
import UsersInGroupsForm from "../../../../components/Modules/Users/UsersSettings/UsersInGroupsForm/UsersInGroupsForm"

const UsersSettings = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const config = useSelector((state) => state.usersSettings.config)
	const [isGettedSettings, setIsGettedSettings] = useState(false)

	useEffect(() => {
		dispatch(getUsersSettings()).then(() => setIsGettedSettings(true))
		return () => dispatch(clearConfig())
	}, [dispatch])

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box display='inline-flex' alignItems='center' mb={[2, 2, 2, 0]}>
					<BackForwardButtons />
					<ModuleHeading heading={t("Common.settingsHeading")} />
				</Box>
				<Box h={["25px", "auto"]}>
					<Divider borderColor='#FFF' borderWidth={["1px", "2px"]} my={[2, 3, 4]} />
				</Box>
				<LoadingHOC isLoading={isGettedSettings}>
					<TabsFrame
						tabs={[{ name: t("UsersSettings.usersRoles") }, { name: t("UsersSettings.usersInGroups") }]}
					>
						<UsersRolesForm
							initialData={config?.user_group_permissions}
							userInGroups={config?.user_user_group_permissions}
						/>
						<UsersInGroupsForm
							initialData={config?.user_user_group_permissions}
							groups={config?.user_group_permissions}
						/>
					</TabsFrame>
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default UsersSettings
