import { createSlice } from "@reduxjs/toolkit"
import { getGroup, getGroups, removeGroup } from "../../../../actions/modules/users/groups/groups-actions"

/**
 * Slice for groups
 */
const groupsSlice = createSlice({
	name: "groups",
	initialState: {
		groups: null,
		gettedGroup: null,
	},
	reducers: {
		clearGroups(state) {
			state.groups = null
		},
		clearGettedGroup(state) {
			state.gettedGroup = null
		},
		groupsSorting(state, action) {
			state.groups = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getGroup.fulfilled, (state, action) => {
				state.gettedGroup = action.payload.group
			})
			.addCase(getGroups.fulfilled, (state, action) => {
				state.groups = action.payload.groups
			})
			.addCase(removeGroup.fulfilled, (state, action) => {
				const filteredGroups = state.groups.filter((group) => group.id !== action.meta.arg)
				state.groups = filteredGroups
			})
	},
})

export const { clearGroups, clearGettedGroup, groupsSorting } = groupsSlice.actions

export default groupsSlice
