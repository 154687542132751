import { Box, Flex } from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import i18next from "i18next"

import Animation from "../../../../../../utils/animations/Routes/AnimatedPage"
import LoadingHOC from "../../../../../../components/common/LoadingHOC"
import { getDocuments } from "../../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import DocumentsList from "../../../../../../components/Modules/Warehouse/Warehouses/WarehouseDocuments/DocumentsList"
import DocumentsActionButtons from "../../../../../../components/Modules/Warehouse/Warehouses/WarehouseDocuments/DocumentsActionButtons"
import NoData from "../../../../../../components/common/NoData"
import DocumentsSearch from "../../../../../../components/Modules/Warehouse/Warehouses/WarehouseDocuments/DocumentsSearch"

const WarehouseDocumentsPage = () => {
	const dispatch = useDispatch()
	const [documents, setDocuments] = useState()
	const [documentsCopy, setDocumentsCopy] = useState()
	const [documentsLength, setDocumentsLength] = useState(0)
	const [isLoading, setIsLoading] = useState(true)
	const { warehouseId } = useParams()

	useEffect(() => {
		setIsLoading(true)
		dispatch(getDocuments(warehouseId)).then(({ payload }) => {
			setData(payload?.documents)
			setIsLoading(false)
		})
	}, [i18next.language])

	useEffect(() => {
		if (!documents) return
		const dataLength = getDataLength(documents)
		setDocumentsLength(dataLength)
	}, [documents])

	const setData = (data) => {
		const dataLength = getDataLength({ ...data })
		setDocumentsLength(dataLength)
		setDocuments({ ...data })
		setDocumentsCopy({ ...data })
	}

	const getDataLength = (data) =>
		Object.keys(data).reduce((accumulator, currentVal) => accumulator + data[currentVal].length, 0)

	return (
		<Animation styles={{ display: "flex", flexDirection: "column", height: "80%" }}>
			<Flex gap={2}>
				<DocumentsSearch
					array={documentsCopy}
					filterKey={"document_number"}
					handleSetResults={setDocuments}
					isDisabled={!documentsCopy || !getDataLength(documentsCopy)}
				/>
				<DocumentsActionButtons data={documents} documentsLength={documentsLength} />
			</Flex>
			<LoadingHOC isLoading={!isLoading}>
				<Box h='100%' my={10} pb={5}>
					{documentsLength ? (
						<DocumentsList
							documents={documents}
							setDocuments={setDocuments}
							setDocumentsCopy={setDocumentsCopy}
							setDocumentsLength={setDocumentsLength}
						/>
					) : (
						<NoData />
					)}
				</Box>
			</LoadingHOC>
		</Animation>
	)
}

export default WarehouseDocumentsPage
