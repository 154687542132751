import { createIcon } from "@chakra-ui/react"

const OrderList = createIcon({
	displayName: "OrderListIcon",
	viewBox: "0 -2 22 24",
	path: (
		<g>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.75 1.25001C10.4312 1.24849 9.12595 1.51526 7.91353 2.03408C6.70082 2.55303 5.60639 3.3133 4.69682 4.26863L4.68367 4.28244L3.68125 5.44861C3.41124 5.76273 3.44699 6.23625 3.76111 6.50626C4.07522 6.77627 4.54875 6.74052 4.81876 6.4264L5.79606 5.28945C6.56275 4.48783 7.48375 3.84956 8.50365 3.41312C9.52928 2.97423 10.6335 2.74862 11.7491 2.75001C16.3036 2.75001 20 6.44547 20 11C20 15.5545 16.3046 19.25 11.7501 19.25M11.75 1.25001C17.133 1.25001 21.5 5.61704 21.5 11C21.5 16.383 17.133 20.75 11.75 20.75C9.73355 20.7499 7.76664 20.1248 6.12006 18.9608C4.47348 17.7968 3.2281 16.1511 2.55532 14.2502C2.41712 13.8598 2.62163 13.4312 3.01211 13.293C3.40259 13.1548 3.83117 13.3593 3.96937 13.7498C4.53869 15.3584 5.59255 16.751 6.98592 17.736C8.37928 18.7209 10.0437 19.2499 11.7501 19.25'
				fill='black'
				stroke='black'
				strokeWidth='0.2'
			/>
			<path
				d='M1.25032 3.56666V8.7501C1.25032 8.94901 1.32933 9.13978 1.46999 9.28043C1.61064 9.42108 1.8014 9.5001 2.00032 9.5001H7.18375C7.85219 9.5001 8.18688 8.69244 7.71438 8.21994L2.53047 3.03604C2.05797 2.5626 1.25032 2.89822 1.25032 3.56666Z'
				fill='black'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.75 5C12.1642 5 12.5 5.32561 12.5 5.72727V11.5455H16.25C16.6642 11.5455 17 11.8711 17 12.2727C17 12.6744 16.6642 13 16.25 13H11.75C11.3358 13 11 12.6744 11 12.2727V5.72727C11 5.32561 11.3358 5 11.75 5Z'
				fill='black'
			/>
		</g>
	),
})

export default OrderList
