import { Flex } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import i18next from "i18next"

import GroupFilterSelect from "./GroupFilterSelect"
import StaticSearch from "../../../../common/StaticSearch"
import GroupWarehouseActionButtons from "./GroupWarehouseActionButtons"
import QuantitySelect from "./QuantitySelect"

const ActionsBar = ({
	data,
	groupsList,
	setRecordsPerPage,
	maxLength,
	setData,
	getConcatenatedMaterials,
	getGroupsFromConcatenatedMaterial,
}) => {
	const [searchingTerm, setSearchingTerm] = useState("")
	const [filterGroupValue, setFilterGroupValue] = useState("")

	useEffect(() => {
		setSearchingTerm("")
		setFilterGroupValue("")
	}, [i18next.language])

	const handleSetResults = (results) => {
		const groupsResults = getGroupsFromConcatenatedMaterial(results)
		setData(groupsResults)
	}

	return (
		<Flex w='100%' justifyContent={"end"} gap={2} flexWrap={{ base: "wrap", xl: "nowrap" }}>
			<QuantitySelect
				setRecordsPerPage={setRecordsPerPage}
				maxLength={maxLength}
				isDisabled={!groupsList?.length}
			/>
			<GroupFilterSelect
				isDisabled={!groupsList?.length}
				arrayToFilter={groupsList}
				searchingTerm={searchingTerm}
				handleSetResults={setData}
				filterGroupValue={filterGroupValue}
				setFilterGroupValue={setFilterGroupValue}
				getConcatenatedMaterials={getConcatenatedMaterials}
				getGroupsFromConcatenatedMaterial={getGroupsFromConcatenatedMaterial}
			/>
			<Flex>
				<StaticSearch
					fieldsToFilter={["name"]}
					isDisabled={!groupsList?.length}
					searchText={searchingTerm}
					setSearchText={setSearchingTerm}
					handleSetResults={handleSetResults}
					originalArray={getConcatenatedMaterials(groupsList)}
					dependencies={[{ name: "name", value: filterGroupValue }]}
				/>
			</Flex>
			<GroupWarehouseActionButtons data={data} />
		</Flex>
	)
}

export default memo(ActionsBar)
