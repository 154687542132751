import { TabList, TabPanels, Tabs, useMediaQuery } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode } from "swiper/modules"
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/pagination"

import AvailableTabs from "./AvailableTabs"

const SwipeableTabs = ({ tabs, children }) => {
	const [isLargerThan420] = useMediaQuery("(min-width: 420px)")

	return (
		<Tabs>
			<TabList display={"block"} w={"100%"} border='none'>
				<Swiper slidesPerView={isLargerThan420 ? 3 : 2} spaceBetween={15} freeMode={true} modules={[FreeMode]}>
					{tabs.map((tab, tabIndex) => (
						<SwiperSlide key={tabIndex}>
							<AvailableTabs tab={tab} index={tabIndex} />
						</SwiperSlide>
					))}
				</Swiper>
			</TabList>
			<TabPanels>{children}</TabPanels>
		</Tabs>
	)
}

export default SwipeableTabs
