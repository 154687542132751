import { Button } from "@chakra-ui/react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const MaterialCopyButton = ({ data, groupName, parameterNames }) => {
	const { t } = useTranslation("global")
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)

	const copyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {})
			.catch((error) => {
				console.error("Błąd podczas kopiowania do schowka: ", error)
			})
	}

	const getConcatenateParameterValues = (values) => {
		let concatenateValues = ""
		values.forEach((value, index) => {
			concatenateValues += value
			if (values.length - 1 === index) return
			concatenateValues += " X "
		})

		return concatenateValues
	}

	const formattedData = useMemo(() => {
		if (!data) return
		const dataKeys = [t("Warehouses.material")]
		parameterNames?.forEach((parameter) => dataKeys.push(parameter.name))
		dataKeys.push(t("Warehouses.quantity"), t("Warehouses.rack"), t("Warehouses.spaceOnTheRack"))

		const columnWidths = {}
		const materialMaxWidth = Math.max(...data.map((item) => item.name.length))
		columnWidths[t("Warehouses.material")] = Math.max(t("Warehouses.material").length, materialMaxWidth)
		parameterNames?.forEach((parameter, index) => {
			const parameterMaxWidth = Math.max(
				...data.map((item) => getConcatenateParameterValues(item.parameter_values[index].values).length),
			)
			columnWidths[parameter.name] = Math.max(parameter.name.length, parameterMaxWidth)
		})
		const quantityMaxWidth = Math.max(...data.map((item) => item.quantity.toString().length))
		columnWidths[t("Warehouses.quantity")] = Math.max(t("Warehouses.quantity").length, quantityMaxWidth)
		const rackMaxWidth = Math.max(...data.map((item) => item.rack.toString().length))
		columnWidths[t("Warehouses.rack")] = Math.max(t("Warehouses.rack").length, rackMaxWidth)
		const spaceOnTheRackMaxWidth = Math.max(...data.map((item) => item.space_on_the_rack.toString().length))
		columnWidths[t("Warehouses.spaceOnTheRack")] = Math.max(
			t("Warehouses.spaceOnTheRack").length,
			spaceOnTheRackMaxWidth,
		)

		let formattedText = `${currentWarehouse?.name}\n\n${groupName}\n`

		formattedText += dataKeys.map((header) => header.padEnd(columnWidths[header])).join("\t") + "\n"

		data.forEach((item) => {
			let body = ""
			body += String(item.name).padEnd(columnWidths[t("Warehouses.material")])
			body += "\t"
			item.parameter_values.forEach((parameter, index) => {
				;(body += String(getConcatenateParameterValues(parameter.values)).padEnd(
					columnWidths[parameterNames[index].name],
				)),
					(body += "\t")
			})
			body += String(item.quantity).padEnd(columnWidths[t("Warehouses.quantity")])

			body += "\t"
			body += String(item.rack).padEnd(columnWidths[t("Warehouses.rack")])

			body += "\t"
			body += String(item.space_on_the_rack).padEnd(columnWidths[t("Warehouses.spaceOnTheRack")])

			body += "\n"

			formattedText += body
		})

		return formattedText
	}, [data])

	const handleCopyClick = () => {
		copyToClipboard(formattedData)
	}

	return (
		<Button
			isDisabled={!data?.length}
			fontSize={[13, 14, 15, 16]}
			fontWeight={"400"}
			h={["25px", "35px", "40px"]}
			bgColor={"#FFF"}
			borderRadius={"4px"}
			boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
			onClick={handleCopyClick}
		>
			{t("Warehouses.copy")}
		</Button>
	)
}

export default MaterialCopyButton
