import {
	Button,
	Flex,
	Image,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Textarea,
	useDisclosure,
} from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"
import { useCallback, useEffect, useMemo, useState } from "react"
import i18next from "i18next"
import get from "lodash/get"

import LanguageIcon from "../../assets/icons/languageInputs/LanguageIcon"

const LanguageTextArea = ({ name, title, isShowPreview, ...props }) => {
	const { t } = useTranslation("global")
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { setFieldValue, values, isSubmitting } = useFormikContext()
	const [langValues, setLangValues] = useState(
		i18next.options.store.map((lang) => ({
			[`${name}_${lang}`]: get(values, `${name}_${lang}`),
		})),
	)

	useEffect(() => {
		if (isOpen) {
			const newValues = i18next.options.store.map((lang) => ({
				[`${name}_${lang}`]: get(values, `${name}_${lang}`),
			}))
			setLangValues(newValues)
		}
	}, [isOpen, name, values])

	const handleChangeInput = useCallback(
		(e, lang) => {
			const value = e.target.value
			setLangValues((prevState) => {
				const newState = [...prevState]
				newState.find((item) => Object.keys(item)[0] === `${name}_${lang}`)[`${name}_${lang}`] = value
				return newState
			})
		},
		[name],
	)

	const handleSaveValues = () => {
		langValues.forEach((item) => {
			const key = Object.keys(item)[0]
			const value = item[key]
			setFieldValue(key, value)
		})
		onClose()
	}

	const getFlag = useCallback((lang) => {
		let flag
		switch (lang) {
			case "en":
				flag = <Image me={2} boxSize={[4, 5, 6]} src={require("../../assets/icons/header/EnglandFlag.webp")} />
				break
			case "nl":
				flag = (
					<Image me={2} boxSize={[4, 5, 6]} src={require("../../assets/icons/header/NetherlandFlag.webp")} />
				)
				break
			case "pl":
				flag = <Image me={2} boxSize={[4, 5, 6]} src={require("../../assets/icons/header/PolandFlag.webp")} />
				break
			default:
				flag = <Image me={2} boxSize={[4, 5, 6]} src={require("../../assets/icons/header/EnglandFlag.webp")} />
				break
		}
		return flag
	}, [])

	const getInputs = useMemo(() => {
		const currentLang = i18next.language
		const languages = i18next.options.store.filter((lang) => lang !== currentLang)
		languages.unshift(currentLang)
		return languages.map((lang, index) => (
			<Flex key={index} alignItems='center' my={3}>
				{getFlag(lang)}
				<Textarea
					data-testid={`textarea_${lang}`}
					isDisabled={isShowPreview}
					minH='50px'
					h={["50px", "60px", "70px", "80px"]}
					fontSize={[13, 14, 15, 16]}
					placeholder={t("LanguageInputs.inputPlaceholder")}
					value={langValues.find((item) => Object.keys(item)[0] === `${name}_${lang}`)[`${name}_${lang}`]}
					onChange={(e) => handleChangeInput(e, lang)}
					{...props}
				/>
			</Flex>
		))
	}, [getFlag, handleChangeInput, isShowPreview, langValues, name, props, t])

	return (
		<>
			<Flex alignItems='center' mb={["1", "1", "1", "2"]}>
				<Button
					data-testid={`${name}LangBtn`}
					isDisabled={isSubmitting}
					bgColor='transparent'
					aria-label='Language textarea button'
					color='#000'
					p={0}
					minW='20px'
					boxSize={["20px", "25px", "30px"]}
					borderRadius='6px'
					boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 2px 0px 35px -1px rgba(0, 0, 0, 0.10)'
					onClick={onOpen}
				>
					<LanguageIcon boxSize={[4, 5, 6]} />
				</Button>
			</Flex>

			<Modal motionPreset='slideInBottom' autoFocus isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader fontSize={["14px", "16px", "18px", "20px"]} textAlign='center'>
						{title}
					</ModalHeader>
					<ModalBody>{getInputs}</ModalBody>

					<ModalFooter pt={0}>
						<Button
							colorScheme='red'
							h={["30px", "32px", "34px", "37px"]}
							fontSize={[13, 14, 15]}
							onClick={onClose}
						>
							{t("LanguageInputs.cancel")}
						</Button>
						<Button
							data-testid={"saveLangBtn"}
							isDisabled={isShowPreview}
							colorScheme='green'
							h={["30px", "32px", "34px", "37px"]}
							fontSize={[13, 14, 15]}
							ms={2}
							onClick={handleSaveValues}
						>
							{t("LanguageInputs.save")}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default LanguageTextArea
