import { memo } from "react"
import { Outlet } from "react-router-dom"

const Main = () => {
	return (
		<main style={{ position: "relative", zIndex: 0 }}>
			<Outlet />
		</main>
	)
}

export default memo(Main)
