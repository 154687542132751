import { Box, useBreakpointValue } from "@chakra-ui/react"
import { memo, useCallback } from "react"
import { circleAnimation, shadowAnimation } from "../../utils/animations/Loader/loaderAnimation"

const Circle = ({ index, size, animationDelay, isShadow }) => {
	const getCurrentBoxSize = (size) => {
		switch (size) {
			case "sm":
				return useBreakpointValue({ base: "8px", sm: "10px", lg: "12px" }, { ssr: false })
			case "md":
				return useBreakpointValue({ base: "12px", sm: "13px", lg: "14px" }, { ssr: false })
			case "lg":
				return useBreakpointValue({ base: "12px", sm: "15px", lg: "16px" }, { ssr: false })
			default:
				return useBreakpointValue({ base: "12px", sm: "13px", lg: "14px" }, { ssr: false })
		}
	}

	const getCurrentColor = (id) => {
		switch (id) {
			case 0:
				return "#000"
			case 1:
				return "#000"
			case 2:
				return "#000"
			default:
				return "#000"
		}
	}

	return (
		<Box
			width={getCurrentBoxSize(size)}
			height={isShadow ? "4px" : getCurrentBoxSize(size)}
			position='absolute'
			borderRadius={"50%"}
			backgroundColor={isShadow ? "rgba(0,0,0,0.9)" : getCurrentColor(index)}
			left={`${index * 33}%`}
			top={isShadow ? (size === "sm" ? "32px" : size === "md" ? "42px" : "52px") : "0%"}
			transformOrigin='50%'
			zIndex={isShadow ? -1 : 1}
			filter={isShadow ? "blur(1px)" : "none"}
			animation={
				isShadow
					? `${shadowAnimation(size)} .5s alternate ${animationDelay} infinite ease`
					: `${circleAnimation(size)} .5s alternate ${animationDelay}  infinite ease`
			}
		/>
	)
}

const Loader = ({ size = "md" }) => {
	const getCurrentWidth = useCallback(
		(size) => {
			switch (size) {
				case "sm":
					return useBreakpointValue({ base: "40px", sm: "50px", lg: "70px" }, { ssr: false })
				case "md":
					return useBreakpointValue({ base: "60px", sm: "70px", lg: "90px" }, { ssr: false })
				case "lg":
					return useBreakpointValue({ base: "70px", sm: "90px", lg: "110px" }, { ssr: false })
				default:
					return useBreakpointValue({ base: "60px", sm: "70px", lg: "90px" }, { ssr: false })
			}
		},
		[size],
	)

	return (
		<Box
			width={getCurrentWidth(size)}
			h={size === "sm" ? "35px" : size === "md" ? "45px" : "55px"}
			position='relative'
			top={"50%"}
			left={"50%"}
			transform={"translate(-50%, -50%)"}
			zIndex={1}
		>
			{[...Array(3)].map((_, index) => (
				<Circle key={index} index={index} size={size} animationDelay={`.${index === 0 ? 0 : index + 1}s`} />
			))}
			{[...Array(3)].map((_, index) => (
				<Circle
					key={index}
					index={index}
					size={size}
					animationDelay={`.${index === 0 ? 0 : index + 1}s`}
					isShadow
				/>
			))}
		</Box>
	)
}

export default memo(Loader)
