import { Collapse, Flex, useDisclosure } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import i18next from "i18next"

import GroupFilterSelect from "../Warehouse/GroupFilterSelect"
import QuantitySelect from "../Warehouse/QuantitySelect"
import StaticSearch from "../../../../common/StaticSearch"
import InventoryActionButtons from "./InventoryActionButtons"
import InventoryProductCreator from "./InventoryProductCreator"

const InventoryActionBar = ({
	data,
	groupsList,
	setRecordsPerPage,
	maxLength,
	setData,
	setDisabledForm,
	isDisabled,
	getConcatenatedMaterials,
	getGroupsFromConcatenatedMaterial,
}) => {
	const [searchingTerm, setSearchingTerm] = useState("")
	const [filterGroupValue, setFilterGroupValue] = useState("")
	const { isOpen, onToggle } = useDisclosure()

	useEffect(() => {
		setSearchingTerm("")
		setFilterGroupValue("")
	}, [i18next.language])

	const handleSetResults = (results) => {
		const groupsResults = getGroupsFromConcatenatedMaterial(results)
		setData(groupsResults)
	}

	return (
		<>
			<Flex w='100%' justifyContent={"end"} gap={2} flexWrap={{ base: "wrap", xl: "nowrap" }}>
				<QuantitySelect
					isDisabled={!groupsList?.length}
					setRecordsPerPage={setRecordsPerPage}
					maxLength={maxLength}
				/>
				<GroupFilterSelect
					isDisabled={!groupsList?.length}
					arrayToFilter={groupsList}
					searchingTerm={searchingTerm}
					handleSetResults={setData}
					filterGroupValue={filterGroupValue}
					setFilterGroupValue={setFilterGroupValue}
					getConcatenatedMaterials={getConcatenatedMaterials}
					getGroupsFromConcatenatedMaterial={getGroupsFromConcatenatedMaterial}
				/>
				<Flex>
					<StaticSearch
						fieldsToFilter={["name"]}
						isDisabled={!groupsList?.length}
						searchText={searchingTerm}
						setSearchText={setSearchingTerm}
						handleSetResults={handleSetResults}
						originalArray={getConcatenatedMaterials(groupsList)}
						dependencies={[{ name: "name", value: filterGroupValue }]}
					/>
				</Flex>
				<InventoryActionButtons data={data} onToggle={onToggle} />
			</Flex>
			<Collapse in={isOpen} animateOpacity startingHeight={10}>
				<InventoryProductCreator setDisabledForm={setDisabledForm} isDisabled={isDisabled} />
			</Collapse>
		</>
	)
}

export default InventoryActionBar
