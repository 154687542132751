import { Flex } from "@chakra-ui/react"
import { memo } from "react"

import MaterialExcelButton from "./MaterialExcelButton"
import MaterialCopyButton from "./MaterialCopyButton"
import MaterialPDFButton from "./MaterialPDFButton"
import MaterialPrintButton from "./MaterialPrintButton"

const MaterialWarehouseActionButtons = ({ data, groupName, parameterNames }) => {
	return (
		<Flex gap={2} w={["100%", "auto"]} flexWrap={["wrap", "wrap", "nowrap"]} justifyContent={"end"}>
			<MaterialExcelButton
				groupName={groupName}
				parameterNames={parameterNames}
				jsonData={data}
				fileName={"example"}
			/>
			<MaterialPDFButton groupName={groupName} data={data} parameterNames={parameterNames} />
			<MaterialCopyButton groupName={groupName} data={data} parameterNames={parameterNames} />
			<MaterialPrintButton data={data} groupName={groupName} parameterNames={parameterNames} />
		</Flex>
	)
}

export default memo(MaterialWarehouseActionButtons)
