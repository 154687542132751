import { Button } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { saveAs } from "file-saver"

import InventoryGroupPDF from "./InventoryGroupPDF"
import { getDateForFiles } from "../../../../../../actions/modules/warehouse/warehouses/warehouses-actions"

const InventoryGroupPDFButton = ({ data, groupName, parameterNames, isDisabled }) => {
	const { t } = useTranslation("global")
	const [parameters, setParameters] = useState([])
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)
	const dispatch = useDispatch()

	useEffect(() => {
		if (!parameterNames) return
		setParameters(parameterNames?.map((parameter) => parameter.name))
	}, [parameterNames])

	const handleExportPDF = (e, blob) => {
		e.preventDefault()
		dispatch(getDateForFiles()).then((res) => {
			saveAs(blob, `${currentWarehouse?.name}_${res.payload?.date}`)
		})
	}

	return (
		<PDFDownloadLink
			document={
				<InventoryGroupPDF
					data={data}
					warehouseString={t("Warehouses.warehouse")}
					warehouseName={currentWarehouse?.name}
					tableHeaders={[
						t("Warehouses.material"),
						...parameters,
						t("Warehouses.warehouseState"),
						t("Warehouses.factState"),
					]}
					tableFields={["name", ...parameters, "warehouse_state", "fact_state"]}
					materialGroupString={t("Warehouses.materialGroup")}
					materialGroup={groupName}
				/>
			}
		>
			{({ blob }) => (
				<Button
					isDisabled={isDisabled}
					fontSize={[13, 14, 15, 16]}
					fontWeight={"400"}
					h={["25px", "35px", "40px"]}
					bgColor={"#FFF"}
					borderRadius={"4px"}
					boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
					onClick={(e) => handleExportPDF(e, blob)}
				>
					PDF
				</Button>
			)}
		</PDFDownloadLink>
	)
}

export default memo(InventoryGroupPDFButton)
