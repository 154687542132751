/* eslint-disable no-unused-vars */
import { saveAs } from "file-saver"
import { createAsyncThunk } from "@reduxjs/toolkit"

import { handleRequest } from "../../../../utils/api/api"

/**
 * Get materials groups function with dispatch action
 */
export const getMaterialsGroups = createAsyncThunk("shop/getMaterialsGroups", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/shop",
	})
	return responseData
})

/**
 * Get materials from group function with dispatch action
 */
export const getMaterials = createAsyncThunk("shop/getMaterials", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/shop/${id}`,
	})
	return responseData
})

/**
 * Get orders function with dispatch action
 */
export const getNewOrderData = createAsyncThunk("shop/getNewOrderData", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/newOrder",
	})
	return responseData
})

/**
 * Create new order function with dispatch action
 */
export const createNewOrder = createAsyncThunk("shop/createNewOrder", async ({ values, path, navigate }) => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/order",
		method: "POST",
		body: values,
		config: {
			headers: {
				"content-type": "multipart/form-data",
			},
		},
	})
	return responseData
})

/**
 * Update order function with dispatch action
 */
export const updateOrder = createAsyncThunk("shop/updateOrder", async (values) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/order/${values.id}`,
		method: "POST",
		body: values,
		config: {
			headers: {
				"content-type": "multipart/form-data",
			},
		},
	})
	return responseData
})

/**
 * Generate pdf function
 */
export const generatePdf = createAsyncThunk("shop/generatePdf", async ({ id, isFirstStatus }) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/${isFirstStatus ? `generatePdfForStatusOne/${id}` : `generatePdf/${id}`}`,
	})
	return responseData
})

/**
 * Copy full order function
 */
export const copyFullOrder = createAsyncThunk("shop/copyFullOrder", async ({ id }) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/copyOrder/${id}`,
		method: "POST",
	})

	return responseData
})

/**
 * Update order status function
 */
export const updateOrderStatus = createAsyncThunk("shop/updateOrderStatus", async ({ id, orderStatus }) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/updateStatus/${id}`,
		method: "POST",
		body: {
			status: orderStatus,
		},
	})

	return responseData
})

/**
 * Add order function with dispatch action
 */
export const addOrder = createAsyncThunk("shop/addOrder", async (order) => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/shop",
		method: "POST",
		body: order,
	})

	return responseData
})

/**
 * Add order function with dispatch action
 */
export const addCustomOrder = createAsyncThunk("shop/addCustomOrder", async (order) => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/unsignedProduct",
		method: "POST",
		body: order,
	})

	return responseData
})

/**
 * Get order by id function with dispatch action
 */
export const getOrder = createAsyncThunk("shop/getOrder", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/order/${id}`,
	})

	return responseData
})

/**
 * Remove order by id function with dispatch action
 */
export const removeOrder = createAsyncThunk("shop/removeOrder", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/shop/${id}`,
		method: "DELETE",
	})

	return responseData
})

/**
 * Change order count function
 */
export const changeOrderCount = createAsyncThunk("shop/changeOrderCount", async ({ id, count }) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/orderRowCount/${id}`,
		method: "POST",
		body: {
			count: count,
		},
	})

	return responseData
})

/**
 * Change order count function
 */
export const changeOrderPackedCount = createAsyncThunk(
	"shop/changeOrderPackedCount",
	async ({ id, count, originalArray, setOrders }) => {
		const responseData = await handleRequest({
			endPoint: `/api/orders/changeCountPacked/${id}`,
			method: "POST",
			body: {
				count_packed: count,
			},
		})

		return responseData
	},
)

/**
 * Save comment function
 */
export const saveComment = createAsyncThunk("shop/saveComment", async ({ id, comment }) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/addComment/${id}`,
		method: "POST",
		body: {
			comment: comment,
		},
	})

	return responseData
})

/**
 * Change is packed function
 */
export const changeIsPacked = createAsyncThunk("shop/changeIsPacked", async ({ id, isPacked }) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/changeIsPacked/${id}`,
		method: "POST",
		body: {
			is_packed: isPacked,
		},
	})

	return responseData
})

/**
 * Change is packed all orders function
 */
export const changePackedAll = createAsyncThunk("shop/changeIsPackedAll", async ({ orderId, isPacked }) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/${orderId}/changeAllIsPacked`,
		method: "POST",
		body: { is_packed: isPacked },
	})

	return responseData
})

/**
 * Change order position function
 */
export const changeOrderPosition = createAsyncThunk("shop/changeOrderPosition", async (values) => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/dragAndDrop",
		method: "POST",
		body: values,
	})

	return responseData
})
