import { Flex } from "@chakra-ui/react"
import { memo } from "react"

import DocumentsExcelButton from "./DocumentsExcelButton"
import DocumentCopyButton from "./DocumentCopyButton"
import DocumentPDFButton from "./DocumentPDFButton"
import DocumentsPrintButton from "./DocumentsPrintButton"

const DocumentsActionButtons = ({ data, documentsLength }) => {
	return (
		<Flex gap={2} w={["100%", "auto"]} flexWrap={["wrap", "wrap", "nowrap"]} justifyContent={"end"}>
			<DocumentsExcelButton jsonData={data} isDisabled={!documentsLength} />
			<DocumentPDFButton data={data} isDisabled={!documentsLength} />
			<DocumentCopyButton data={data} isDisabled={!documentsLength} />
			<DocumentsPrintButton data={data} isDisabled={!documentsLength} />
		</Flex>
	)
}

export default memo(DocumentsActionButtons)
