import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Badge,
	Box,
	Button,
	Divider,
	Editable,
	EditableInput,
	EditablePreview,
	Flex,
	Grid,
	GridItem,
	Image,
	Input,
	Text,
	useBreakpointValue,
} from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import i18next from "i18next"
import { ArrowForwardIcon } from "@chakra-ui/icons"

import LackImageIcon from "../../../../../../assets/icons/materials/LackImageIcon"
import BsSortDown from "../../../../../../assets/icons/permissions/BsSortDown"
import BsSortUp from "../../../../../../assets/icons/permissions/BsSortUp"
import ExpandedIcon from "../../../../../../assets/icons/shop/ExpandedIcon"
import SearchBar from "../../../../../../components/common/SearchBar"
import Animation from "../../../../../../utils/animations/Routes/AnimatedPage"
import BinIcon from "../../../../../../assets/icons/materials/BinIcon"
import LoadingHOC from "../../../../../../components/common/LoadingHOC"
import {
	changeProductCount,
	changeProductPosition,
	getDocument,
	removeProduct,
	saveRWDocument,
} from "../../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import DocumentAccordion from "../../../../../../components/Modules/Warehouse/Warehouses/WarehouseDocuments/DocumentAccordion"
import { clearCurrentDocument } from "../../../../../../store/modules/warehouse/warehouses/warehouses-slice"
import RemoveOrderCreator from "../../../../../../components/Modules/Warehouse/Warehouses/WarehouseTake/RemoveOrderCreator"
import { useGetIsInventory } from "../../../../../../hooks/Modules/Warehouse/useGetIsInventory"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import EditableControls from "../../../../../../components/common/EditableControls"

const WarehouseTakeDocument = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const documentDetails = useSelector((state) => state.warehouses.currentDocument)
	const userWarehousePermissions = useSelector((state) => state.auth["warehouse_module_permissions"])
	const [language, setLanguage] = useState(i18next.language)
	const [orders, setOrders] = useState([])
	const [ordersCopy, setOrdersCopy] = useState([])
	const [isExpanded, setIsExpanded] = useState(false)
	const [accordionRef, setAccordionRef] = useState(null)
	const [isOrderDescSort, setIsOrderDescSort] = useState(false)
	const [searchText, setSearchText] = useState("")
	const [isGettedDetails, setIsGettedDetails] = useState(false)
	const [isOrdersGetted, setIsOrdersGetted] = useState(false)
	const [disabledForm, setDisabledForm] = useState(false)
	const navigate = useNavigate()
	const { documentId, warehouseId } = useParams()
	const searchBarBreakpoints = useBreakpointValue({ base: "30px", sm: "30px", md: "35px" })
	const { isInventory } = useGetIsInventory()
	const editablePreviewRef = useRef()

	useEffect(() => {
		if (documentDetails && i18next.language === language) return
		dispatch(getDocument(documentId)).then(({ payload }) => {
			const hasPermission = userWarehousePermissions.includes(
				payload.document?.status ? "document_preview" : "document_edit",
			)
			if (!hasPermission) {
				navigate("/")
				return
			}
		})
	}, [dispatch, documentId, documentDetails, language])

	useEffect(() => {
		return () => dispatch(clearCurrentDocument())
	}, [])

	useEffect(() => {
		setLanguage(i18next.language)
		setIsOrdersGetted(false)
	}, [i18next.language])

	useEffect(() => {
		if (documentDetails) {
			if (searchText !== "") {
				const results = documentDetails.order_rows.filter((item) => {
					return item.name.toString().toLowerCase().includes(searchText.toLowerCase())
				})
				setOrders(results)
			} else {
				setOrders(documentDetails.order_rows)
			}
			setOrdersCopy(documentDetails.order_rows)
			setIsGettedDetails(true)
			setAccordionRef(documentDetails.order_rows?.map(() => React.createRef()))
			setIsOrdersGetted(true)
		}
	}, [documentDetails])

	const handleChangeExpand = useCallback(() => {
		accordionRef.forEach((accordion) => {
			if (accordion.current && accordion.current.ariaExpanded === isExpanded.toString()) {
				accordion.current.click()
			}
		})
		setIsExpanded((prevState) => !prevState)
	}, [accordionRef, isExpanded])

	const handleRemoveOrder = useCallback(
		(orderId, id) => {
			setDisabledForm(true)
			dispatch(removeProduct(id)).then(() => setDisabledForm(false))
			setAccordionRef((prevState) => prevState.filter((accordion, index) => index !== orderId))
		},
		[dispatch],
	)

	const handleSorting = useCallback(() => {
		setDisabledForm(true)
		const sortedOrders = [...ordersCopy]
		if (isOrderDescSort) {
			sortedOrders.sort((a, b) => a.name.toString().localeCompare(b.name.toString()))
		} else {
			sortedOrders.sort((a, b) => b.name.toString().localeCompare(a.name.toString()))
		}
		const data = {
			order_rows: sortedOrders.map((order, index) => ({
				id: order.id,
				place: index + 1,
			})),
			warehouse_document_id: documentDetails?.id,
		}
		dispatch(changeProductPosition(data)).then(() => {
			setIsOrderDescSort((prevState) => !prevState)
			setDisabledForm(false)
		})
	}, [ordersCopy, isOrderDescSort, documentDetails?.id, dispatch])

	const getParameters = (order) =>
		order.parameters
			?.map((parameter) => {
				const prefix = ">>"
				const valuesString = parameter.values.map((value) => value.name).join(" × ")

				return `${prefix} ${valuesString}`
			})
			.join(" ")

	const handleKeyDown = (event) => {
		if (event.key === "." || event.key === "," || event.key === "-" || event.key === "+") {
			event.preventDefault()
		}
	}

	const handleOrderQuantityChange = useCallback(
		(order, value) => {
			// eslint-disable-next-line eqeqeq
			if (
				value === "" ||
				value == order.quantity ||
				value <= 0 ||
				value > order.quantity + order.available_quantity
			) {
				editablePreviewRef.current.textContent = order.quantity
				return
			}

			setDisabledForm(true)
			dispatch(changeProductCount({ id: order.id, quantity: Number(value) })).then(() => setDisabledForm(false))
		},
		[dispatch],
	)

	const handleSaveDocument = () => {
		dispatch(saveRWDocument(documentId)).then((res) => {
			if (res.error) return
			navigate(`/warehouse/warehouses/${warehouseId}/documents`)
		})
	}

	const getAccordionData = useMemo(
		() => ({
			id: documentDetails?.id,
			document_number: documentDetails?.document_number,
			project: documentDetails?.project,
			contractor: documentDetails?.contractor,
			recipient: documentDetails?.recipient,
			comments: documentDetails?.comments,
			document_date: documentDetails?.document_date,
		}),
		[documentDetails],
	)

	const DraggableAccordion = ({ order, index, orderId }) => {
		const [{ isDragging }, drag] = useDrag(() => ({
			type: !documentDetails?.status ? "ACCORDION" : "",
			item: { orderId: order.id },
			collect: (monitor) => {
				return {
					isDragging: !!monitor.isDragging(),
				}
			},
		}))

		const [, drop] = useDrop(() => ({
			accept: !documentDetails?.status ? "ACCORDION" : "",
			drop: (monitor) => {
				if (monitor.orderId === index) return
				setDisabledForm(true)
				const newOrdersCopy = [...ordersCopy]
				const draggedOrderCopy = newOrdersCopy.find((order) => order.id === monitor.orderId)
				const draggedOrderCopyIndex = newOrdersCopy.findIndex((order) => order.id === monitor.orderId)
				const newIndex = newOrdersCopy.findIndex((order) => order.id === index)
				newOrdersCopy.splice(draggedOrderCopyIndex, 1)
				newOrdersCopy.splice(newIndex, 0, draggedOrderCopy)

				const data = {
					order_rows: newOrdersCopy.map((order, index) => ({
						id: order.id,
						place: index + 1,
					})),
					warehouse_document_id: documentDetails.id,
				}
				dispatch(changeProductPosition(data)).then(() => setDisabledForm(false))
			},
		}))

		return (
			<Accordion
				ref={(node) => drag(drop(node))}
				allowMultiple
				cursor={"pointer"}
				border='1px solid var(--teal-500, #319795)'
				boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
				borderRadius='4px'
				my={[2, 2, 3]}
				style={{ opacity: isDragging ? 0.4 : 1 }}
			>
				<AccordionItem border='none'>
					<Flex
						justifyContent={"space-between"}
						alignItems={"center"}
						gap={[12]}
						px={[2, 3]}
						py={[2, 3]}
						onClick={() => accordionRef[orderId]?.current.click()}
					>
						<Text whiteSpace={"pre-wrap"} wordBreak={"break-all"} fontSize={[13, 14, 15, 16]}>
							{order.parameters?.length ? `${order.name} ${getParameters(order)}` : order.name}
						</Text>
						<Flex alignItems={"center"} gap={2}>
							<Text
								whiteSpace={"pre-wrap"}
								wordBreak={"break-all"}
								textAlign={"end"}
								minW='40px'
								maxW={["80px"]}
								fontSize={[13, 14, 15, 16]}
							>
								{order.quantity}
							</Text>
							<AccordionButton
								ref={accordionRef[orderId]}
								justifyContent='center'
								minW='35px'
								boxSize={["35px", "35px", "40px"]}
								borderRadius='8px'
								aria-label='Accordion button'
							>
								<AccordionIcon boxSize={[6, 6, 7]} />
							</AccordionButton>
						</Flex>
					</Flex>
					<AccordionPanel p={[1, 0]} px={[2, 3]} pb={[2, 3]}>
						<Divider mb={2} borderWidth='1px' borderColor='#E5E5DF' />
						<Flex gap={4}>
							{!order.is_custom &&
								(order.image ? (
									<Image
										src={order.image}
										alt='materialImage'
										w={["40px", "50px", "70px"]}
										h={["50px", "60px", "80px"]}
									/>
								) : (
									<LackImageIcon
										w={["40px", "50px", "70px"]}
										h={["50px", "60px", "80px"]}
										alt='Lack image icon'
									/>
								))}
							<Flex flexDirection={"column"} gap={2} w='100%'>
								<Flex display={!order.is_custom && order.description ? "flex" : "none"}>
									<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
										{t("Order.description")}:
									</Text>
									<Text
										whiteSpace={"pre-wrap"}
										wordBreak={"break-all"}
										fontSize={[13, 14, 15, 16]}
										ms={2}
									>
										{order.description}
									</Text>
								</Flex>
								<Flex gap={2}>
									<Editable
										display='inline-flex'
										flexWrap={"wrap"}
										alignItems='center'
										me={3}
										defaultValue={order.quantity}
										onSubmit={(e) => handleOrderQuantityChange(order, e)}
										fontSize='md'
										isPreviewFocusable={false}
									>
										<Text fontSize={[13, 14, 15, 16]} me={3} color='#2C7A7B'>
											{t("Warehouses.quantity")} ({order.available_quantity}):
										</Text>
										<EditablePreview
											whiteSpace={"pre-wrap"}
											wordBreak={"break-all"}
											fontSize={[13, 14, 15, 16]}
											ref={editablePreviewRef}
										/>
										<Input
											h={["25px", "25px", "30px"]}
											w='100px'
											fontSize={[13, 14, 15, 16]}
											type='number'
											onKeyDown={handleKeyDown}
											onPaste={(e) => e.preventDefault()}
											as={EditableInput}
										/>
										<EditableControls isDisabled={disabledForm} />
									</Editable>
								</Flex>
								<Flex gap={2}>
									<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
										{t("Warehouses.value")}:
									</Text>
									<Text fontSize={[13, 14, 15, 16]}>{order.total_price}</Text>
								</Flex>
								{!documentDetails?.status && (
									<Flex justifyContent={"end"}>
										<Button
											isDisabled={disabledForm}
											bgColor='#FFF'
											_hover={{ backgroundColor: "#F2F2EF" }}
											minW='35px'
											boxSize={["35px", "35px", "40px"]}
											onClick={() => handleRemoveOrder(orderId, order.id)}
											aria-label='Remove order button'
										>
											<BinIcon color='red.500' boxSize={[5, 5, 6]} />
										</Button>
									</Flex>
								)}
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		)
	}

	const draggableAccordionList = useMemo(
		() =>
			orders?.map((order, index) => (
				<DraggableAccordion key={index} index={order.id} order={order} orderId={index} />
			)),
		[orders],
	)

	return (
		<Animation styles={{ display: "flex", flexDirection: "column", height: "80%" }}>
			<DndProvider backend={HTML5Backend}>
				<LoadingHOC isLoading={isGettedDetails}>
					<Flex justifyContent={"space-between"} flexWrap={["wrap", "nowrap"]} gap={2}>
						<Badge
							h={{ base: "19px", md: "21px" }}
							fontSize={{ base: 13, md: 14, lg: 15 }}
							bgColor={"red.600"}
							color='#FFF'
							fontWeight={500}
						>
							{t("Warehouses.takeFromWarehouse")}
						</Badge>
						{!documentDetails?.status && (
							<Flex w='100%' justifyContent={"flex-end"}>
								<Button
									aria-label='Save button'
									isDisabled={disabledForm || isInventory || !ordersCopy?.length}
									type='submit'
									bgColor='green.600'
									_hover={{ backgroundColor: "green.700" }}
									colorScheme='green'
									color='#FFF'
									h={["30px", "35px", "40px"]}
									fontSize={[13, 14, 15, 16]}
									fontWeight={400}
									onClick={handleSaveDocument}
								>
									{t("Warehouses.confirm")}
									<ArrowForwardIcon ms={1} boxSize={[4, 4, 5]} />
								</Button>
							</Flex>
						)}
					</Flex>
					<Grid
						templateColumns={{
							base: "repeat(1,1fr)",
							lg: !documentDetails?.status ? "repeat(3,1fr)" : "repeat(1,1fr)",
						}}
						my={5}
					>
						<GridItem colSpan={2} me={!documentDetails?.status && 2}>
							<DocumentAccordion data={getAccordionData} status={documentDetails?.status} />
							{!documentDetails?.status && (
								<RemoveOrderCreator
									isDisabled={disabledForm || isInventory}
									orderId={documentDetails?.id}
									setAccordionRef={setAccordionRef}
									setDisabledForm={setDisabledForm}
									setIsOrdersGetted={setIsOrdersGetted}
									resetCreator={editablePreviewRef.current?.textContent}
								/>
							)}
						</GridItem>
						<GridItem mt={documentDetails?.status && 5}>
							<Box
								ms={[0, 0, 0, !documentDetails?.status && 2]}
								boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"}
								borderRadius='4px'
								bgColor='#FFF'
								maxH='80vh'
								overflowY={"auto"}
								mb={[2, 2, 2, 0]}
								p={[1, 3, 3, 4]}
							>
								<Flex
									justifyContent='space-between'
									flexWrap={{ base: "wrap", xl: "nowrap" }}
									alignItems='center'
									mb={4}
									gap={2}
								>
									<Flex gap={2} flexWrap={"wrap"}>
										<Text wordBreak={"break-word"} fontSize={[13, 14, 15, 16]} fontWeight={600}>
											{t("Warehouses.takenFromWarehouse")}
										</Text>
										<Text fontSize={[13, 14, 15, 16]} color='#2B807E'>
											({orders?.length})
										</Text>
									</Flex>
									<Box
										display='inline-flex'
										justifyContent={{ base: "flex-end" }}
										w={{ base: "100%", xl: "auto" }}
										gap={2}
									>
										<SearchBar
											fieldsToFilter={["name"]}
											isDisabled={disabledForm || isInventory}
											searchText={searchText}
											setSearchText={setSearchText}
											handleSetResults={setOrders}
											originalArray={ordersCopy}
											buttonStyles={{
												height: searchBarBreakpoints,
												width: searchBarBreakpoints,
											}}
											h={searchBarBreakpoints}
											type='search'
										/>
										{!documentDetails?.status && (
											<Button
												isDisabled={disabledForm || !ordersCopy?.length || isInventory}
												bgColor='#FFF'
												p={0}
												minW='30px'
												boxSize={["30px", "30px", "35px"]}
												border='1px solid #D4D4CD'
												borderRadius='4px'
												onClick={handleSorting}
												aria-label='Sorting button'
											>
												{isOrderDescSort ? <BsSortUp /> : <BsSortDown />}
											</Button>
										)}
										<Button
											bgColor='#FFF'
											p={0}
											minW='30px'
											boxSize={["30px", "30px", "35px"]}
											border='1px solid #D4D4CD'
											borderRadius='4px'
											onClick={handleChangeExpand}
											aria-label='Expand accordion button'
										>
											<ExpandedIcon boxSize={[4, 4, 5]} />
										</Button>
									</Box>
								</Flex>
								<LoadingHOC isLoading={isOrdersGetted} size='sm'>
									{draggableAccordionList}
								</LoadingHOC>
							</Box>
						</GridItem>
					</Grid>
				</LoadingHOC>
			</DndProvider>
		</Animation>
	)
}

export default WarehouseTakeDocument
