import { Navigate, Outlet } from "react-router-dom"

import { useSelector } from "react-redux"
import { useMemo } from "react"

const HasModulePermissionsRoutes = ({ permissions, allowedPermissions }) => {
	const userRoles = useSelector((state) => state.auth[`${permissions}_module_permissions`])
	const hasAccess = useMemo(
		() => allowedPermissions.some((permission) => userRoles.includes(permission)),
		[allowedPermissions, userRoles],
	)

	return hasAccess ? <Outlet /> : <Navigate to='/' />
}

export default HasModulePermissionsRoutes
