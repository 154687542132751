const pwaModule = (() => {
	let pwaEvent = null

	const handleBeforeInstallPrompt = (event) => {
		event.preventDefault()
		pwaEvent = event
	}

	window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt)

	return {
		get pwa() {
			return pwaEvent
		},
	}
})()

export default pwaModule
