import { Step, StepIndicator, StepSeparator, StepStatus, Stepper, useSteps } from "@chakra-ui/react"
import { useCallback, useEffect } from "react"
import { useSelector } from "react-redux"

const OrderStepper = ({
	status,
	styles,
	isDisabled,
	isMyOrder,
	handleChangeStatus,
	copyPackedItemsLength,
	ordersCopyLength,
}) => {
	const hasAccess = useSelector((state) => state.auth.order_module_permissions)?.includes("change_order_status")

	const steps = [
		{
			icon: "AirplaneStarting",
			iconColor: "#CBD5E0",
			separatorColor: "#A0AEC0",
		},
		{ icon: "CreatingIcon", iconColor: "#FAF089", separatorColor: "#F6E05E" },
		{ icon: "BasketCartAdd", iconColor: "#FBD38D", separatorColor: "#F7B768" },
		{ icon: "Package", iconColor: "#63B3ED", separatorColor: "#3182CE" },
		{ icon: "MdAirplanemodeActive", iconColor: "#68D391" },
	]

	const getImage = useCallback((icon) => {
		const Image = require(`../../../../../assets/icons/shop/${icon}`).default
		return <Image boxSize={[3, 4, 4, 5]} color='#333' />
	}, [])

	const handleStepClick = (index) => {
		if (
			!isDisabled &&
			(isMyOrder === 1 ? isMyOrder : hasAccess) &&
			((index + 1 > status && index + 1 === status + 1 && index < 3 && ordersCopyLength) ||
				(status === 3 && index === 3 && copyPackedItemsLength && !ordersCopyLength) ||
				(status === 4 && (index + 1 === 3 || index + 1 === 5)))
		) {
			handleChangeStatus(index + 1)
		}
	}

	const { activeStep, setActiveStep } = useSteps(status)

	useEffect(() => {
		if (status !== activeStep) {
			setActiveStep(status)
		}
	}, [status, activeStep, setActiveStep])

	return (
		<Stepper style={{ ...styles }} size={["sm", "sm", "md"]} index={activeStep} p={[1, 2, 0]}>
			{steps.map((step, index) => (
				<Step key={index} gap={0}>
					<StepIndicator
						boxSize={[5, 6, 7, 8]}
						cursor={
							!isDisabled &&
							(isMyOrder === 1 ? isMyOrder : hasAccess) &&
							((index + 1 > status && index + 1 === status + 1 && index < 3 && ordersCopyLength) ||
								(status === 3 && index === 3 && copyPackedItemsLength && !ordersCopyLength) ||
								(status === 4 && (index + 1 === 3 || index + 1 === 5)))
								? "pointer"
								: "not-allowed"
						}
						onClick={() => handleStepClick(index)}
						style={{
							backgroundColor: index < activeStep ? step.iconColor : "#E5E5DF",
						}}
						border='none'
					>
						<StepStatus
							complete={getImage(step.icon)}
							incomplete={getImage(step.icon)}
							active={getImage(step.icon)}
						/>
					</StepIndicator>

					<StepSeparator
						style={{
							width: "100%",
							backgroundColor: index < activeStep ? step.separatorColor : "#E5E5DF",
						}}
					/>
				</Step>
			))}
		</Stepper>
	)
}

export default OrderStepper
