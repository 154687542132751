import { Slide, toast } from "react-toastify"

const errorUpdateToast = (toastId, message) => {
	return toast.update(toastId, {
		render: message,
		type: "error",
		isLoading: false,
		position: "bottom-center",
		autoClose: 1500,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: true,
		limit: 5,
		transition: Slide,
		style: {
			backgroundColor: "#E53E3E",
		},
		progressStyle: {
			backgroundColor: "#FFF",
		},
	})
}

export default errorUpdateToast
