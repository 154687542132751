import { Box, Button, Divider, Flex, Heading, Text } from "@chakra-ui/react"
import { memo } from "react"
import { Formik, Form, FastField } from "formik"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import SelectField from "../../../../common/SelectField"
import { saveAutoRemoveTime } from "../../../../../actions/modules/orders/ordersSettings/orders-settings-actions"
import i18next from "i18next"

const OrdersSettingsForm = ({ initialData }) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()

	const handleSave = (values, actions) => {
		actions.setSubmitting(true)
		dispatch(saveAutoRemoveTime(values)).then(() => actions.setSubmitting(false))
	}

	return (
		<Formik
			initialValues={{
				time: initialData?.config.time || 1,
			}}
			onSubmit={(values, actions) => {
				handleSave(values, actions)
			}}
		>
			{(formik) => (
				<Form>
					<Box minH={["300px", "400px", "500px", "700px"]}>
						<FastField key={i18next.language} name='time'>
							{() => (
								<>
									<Heading fontSize={[14, 15, 16, 17]} fontWeight={400}>
										{t("OrdersSettings.settings")}
									</Heading>
									<Divider my={2} w='100%' borderColor='#D4D4CD' borderWidth='1px' />
									<Flex alignItems='center' gap={2}>
										<Text fontSize={[13, 14, 15, 16]}>{t("OrdersSettings.autoRemoveTime")}:</Text>
										<SelectField
											disabled={formik.isSubmitting}
											bgColor='#FFF'
											w={["55%", "30%", "15%", "14%"]}
											fontSize={[13, 14, 15, 16]}
											mb={2}
											h={["30px", "35px", "40px"]}
											aria-label='Select auto email time'
											cursor='pointer'
											name='time'
											options={[
												{ id: 1, value: 1, name: 1 },
												{ id: 2, value: 2, name: 2 },
												{ id: 3, value: 3, name: 3 },
												{ id: 4, value: 4, name: 4 },
												{ id: 5, value: 5, name: 5 },
												{ id: 6, value: 6, name: 6 },
												{ id: 7, value: 7, name: 7 },
												{ id: 8, value: 8, name: 8 },
												{ id: 9, value: 9, name: 9 },
												{ id: 10, value: 10, name: 10 },
												{ id: 11, value: 11, name: 11 },
												{ id: 12, value: 12, name: 12 },
											]}
										/>
									</Flex>
								</>
							)}
						</FastField>
						<Flex
							h='100%'
							minH={["202px", "312px", "349px", "552px"]}
							alignItems='end'
							justifyContent='end'
						>
							<Button
								isDisabled={formik.isSubmitting}
								type='submit'
								fontSize={[13, 14, 15, 16]}
								bgColor='green.600'
								_hover={{ backgroundColor: "green.700" }}
								colorScheme='green'
								h={["30px", "35px", "40px"]}
							>
								<Text aria-label='Save button'>{t("OrdersSettings.save")}</Text>
							</Button>
						</Flex>
					</Box>
				</Form>
			)}
		</Formik>
	)
}

export default memo(OrdersSettingsForm)
