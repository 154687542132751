import { Box, Button, Container, Divider, Flex, Text } from "@chakra-ui/react"
import { AddIcon } from "@chakra-ui/icons"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Animation from "../../../../utils/animations/Routes/AnimatedPage"
import { getGroups } from "../../../../actions/modules/users/groups/groups-actions"
import { clearGroups, groupsSorting } from "../../../../store/modules/users/groups/groups-slice"
import GroupsList from "../../../../components/Modules/Users/Groups/GroupsList/GroupsList"
import BackForwardButtons from "../../../../components/common/BackForwardButtons"
import StaticSearch from "../../../../components/common/StaticSearch"
import LoadingHOC from "../../../../components/common/LoadingHOC"
import ModuleHeading from "../../../../components/common/ModuleHeading"
import PermissionsChecked from "../../../../components/common/PermissionsChecked"

const Groups = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const groupsList = useSelector((state) => state.groups.groups)
	const navigate = useNavigate()
	const location = useLocation()
	const [groups, setGroups] = useState()
	const [groupsCopy, setGroupsCopy] = useState()
	const [groupsListLength, setGroupsListLength] = useState()
	const [isDescSort, setIsDescSort] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [paginationLength, setPaginationLength] = useState(0)
	const [searchingTerm, setSearchingTerm] = useState("")

	useEffect(() => {
		dispatch(getGroups())

		return () => dispatch(clearGroups())
	}, [dispatch])

	useEffect(() => {
		if (groupsList && groupsList?.length !== groupsListLength) {
			setGroups(groupsList.slice(0, 25))
			setGroupsCopy(groupsList)
			setPaginationLength(groupsList.length)
			setGroupsListLength(groupsList.length)
			setIsLoading(true)
		}
	}, [groups, groupsList])

	const sortingDesc = (array) =>
		[...array].toSorted((a, b) => {
			if (a.name.toString().toLowerCase() > b.name.toString().toLowerCase()) return -1
			if (a.name.toString().toLowerCase() < b.name.toString().toLowerCase()) return 1
			return 0
		})

	const sortingAsc = (array) =>
		[...array].toSorted((a, b) => {
			if (b.name.toString().toLowerCase() > a.name.toString().toLowerCase()) return -1
			if (b.name.toString().toLowerCase() < a.name.toString().toLowerCase()) return 1
			return 0
		})

	const handleSorting = useCallback(() => {
		let sortedData = []

		if (isDescSort) {
			dispatch(groupsSorting(sortingDesc(groupsList)))
			sortedData = sortingDesc(groupsCopy)
		} else {
			dispatch(groupsSorting(sortingAsc(groupsList)))
			sortedData = sortingAsc(groupsCopy)
		}

		setGroups(sortedData.slice(0, 25))
		setGroupsCopy(sortedData)
		setPaginationLength(sortedData.length)
		setIsDescSort((prevState) => !prevState)
	}, [groupsList, groupsCopy, isDescSort, paginationLength])

	const handleChangePage = (page) => {
		const start = (page - 1) * 25
		const end = start + 25
		const newGroupsList = groupsCopy.slice(start, end)
		setGroups(newGroupsList)
	}

	const handleSearching = (array) => {
		setGroups(array.slice(0, 25))
		setGroupsCopy(array)
		setPaginationLength(array.length)
	}

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box display='flex' justifyContent='space-between' flexWrap='wrap' mt={2}>
					<Box display='inline-flex' alignItems='center' mb={[2, 2, 2, 0]}>
						<BackForwardButtons />
						<ModuleHeading heading={t("Groups.heading")} />
					</Box>
					<Box display='inline-flex' w={["100%", "100%", "auto"]} gap={2}>
						<StaticSearch
							fieldsToFilter={["name"]}
							isDisabled={!groupsList?.length}
							searchText={searchingTerm}
							setSearchText={setSearchingTerm}
							handleSetResults={handleSearching}
							originalArray={groupsList}
						/>
						<PermissionsChecked permissions={"user"} allowedPermissions={["add_group"]}>
							<Button
								aria-label='Add button'
								bgColor='#319795'
								color='#FFF'
								colorScheme='teal'
								minW='30px'
								boxSize={["30px", "35px", "40px"]}
								boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
								onClick={() =>
									navigate(
										location.pathname.endsWith("/")
											? `${location.pathname}add`
											: `${location.pathname}/add`,
									)
								}
							>
								<AddIcon boxSize={5} />
							</Button>
						</PermissionsChecked>
					</Box>
				</Box>
				<Divider borderColor='#FFF' borderWidth={["1px", "2px"]} my={4} />
				<LoadingHOC isLoading={isLoading} styles={{ height: "90%" }}>
					{groups?.length ? (
						<GroupsList
							groups={groups}
							groupsCopy={groupsCopy}
							paginationLength={paginationLength}
							handleChangePage={handleChangePage}
							isDescSort={isDescSort}
							handleSorting={handleSorting}
						/>
					) : (
						<Flex justifyContent='center' alignItems='center' h='80%'>
							<Text fontSize='24px' letterSpacing='4px' fontWeight='600' color='#05050580'>
								{t("Groups.noData")}
							</Text>
						</Flex>
					)}
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default Groups
