import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import {
	addProductToInvDocument,
	addProductToPzDocument,
	addProductToRwDocument,
	changeProductCount,
	changeProductPosition,
	deleteWarehouse,
	getCurrentWarehouse,
	getDocument,
	getInventoryDocument,
	getInventoryMaterials,
	getMaterialGroups,
	getMaterials,
	getMaterialsGroups,
	getMaterialsShop,
	getWarehouses,
	removeProduct,
	updateConfirmAllMaterials,
	updateConfirmMaterial,
	updateFactState,
} from "../../../../actions/modules/warehouse/warehouses/warehouses-actions"
/**
 * Slice for warehouses
 */
const warehousesSlice = createSlice({
	name: "employees",
	initialState: {
		warehouses: null,
		currentWarehouse: null,
		currentDocument: null,
		inventoryMaterialGroups: null,
		inventoryMaterials: null,
		warehouseGroupMaterials: null,
		warehouseMaterials: null,
		materialGroups: null,
		materials: null,
	},
	reducers: {
		clearWarehouses(state) {
			state.warehouses = null
		},
		clearCurrentWarehouse(state) {
			state.currentWarehouse = null
		},
		clearMaterialGroups(state) {
			state.materialGroups = null
		},
		clearWarehouseGroupMaterials(state) {
			state.warehouseGroupMaterials = null
		},
		clearWarehouseMaterials(state) {
			state.warehouseMaterials = null
		},
		clearInventoryMaterialGroups(state) {
			state.materialGroups = null
		},
		clearInventoryMaterials(state) {
			state.inventoryMaterials = null
		},
		clearMaterials(state) {
			state.materials = null
		},
		setSortedGroups(state, action) {
			state.materialGroups = action.payload
		},
		setSortedInventoryDocument(state, action) {
			state.currentDocument.groups = action.payload
		},
		setSortedInventoryMaterials(state, action) {
			state.inventoryMaterials.materials = action.payload
		},
		setSortedMaterials(state, action) {
			state.materials = action.payload
		},
		clearCurrentDocument(state) {
			state.currentDocument = null
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCurrentWarehouse.fulfilled, (state, action) => {
				state.currentWarehouse = action.payload.warehouse
			})
			.addCase(getWarehouses.fulfilled, (state, action) => {
				state.warehouses = action.payload.warehouses
			})
			.addCase(getMaterialGroups.fulfilled, (state, action) => {
				state.warehouseGroupMaterials = action.payload.materialGroups
			})
			.addCase(getInventoryDocument.fulfilled, (state, action) => {
				state.currentDocument = action.payload.document
			})
			.addCase(getInventoryMaterials.fulfilled, (state, action) => {
				state.inventoryMaterials = action.payload.materialGroups[0]
			})
			.addCase(getMaterials.fulfilled, (state, action) => {
				state.warehouseMaterials = action.payload.materialGroups[0]
			})
			.addCase(getMaterialsGroups.fulfilled, (state, action) => {
				state.materialGroups = action.payload.group
			})
			.addCase(getMaterialsShop.fulfilled, (state, action) => {
				state.materials = action.payload.materials
			})
			.addCase(getDocument.fulfilled, (state, action) => {
				state.currentDocument = action.payload.document
			})
			.addCase(changeProductPosition.fulfilled, (state, action) => {
				state.currentDocument.order_rows = action.payload.order_rows
			})
			.addCase(deleteWarehouse.fulfilled, (state, action) => {
				const warehouses = state.warehouses.filter((warehouse) => warehouse.id !== action.meta.arg)
				state.warehouses = warehouses
			})
			.addCase(updateFactState.fulfilled, (state, action) => {
				state.inventoryMaterials.materials = state.inventoryMaterials.materials.map((material) => {
					if (material.product_id !== action.meta.arg.product_id) return material
					return {
						...material,
						fact_state: Number(action.meta.arg.value),
					}
				})
			})
			.addCase(updateConfirmMaterial.fulfilled, (state, action) => {
				state.inventoryMaterials.materials = state.inventoryMaterials.materials.map((material) => {
					if (material.product_id !== action.meta.arg.id) return material
					return {
						...material,
						is_confirmed: action.meta.arg.value,
					}
				})
				state.inventoryMaterials
			})
			.addCase(updateConfirmAllMaterials.fulfilled, (state, action) => {
				state.inventoryMaterials.materials = state.inventoryMaterials.materials.map((material) => ({
					...material,
					is_confirmed: action.meta.arg.confirm,
				}))
			})
			.addCase(changeProductCount.fulfilled, (state, action) => {
				const products = state.currentDocument.order_rows.map((row) => {
					if (row.id === action.meta.arg.id) {
						row = action.payload.order_row
					}
					return row
				})
				state.currentDocument.order_rows = products
			})
			.addCase(removeProduct.fulfilled, (state, action) => {
				const product = state.currentDocument.order_rows
				const orderRows = product?.filter((row) => row.id !== action.meta.arg)
				state.currentDocument.order_rows = orderRows
			})
			.addCase(addProductToInvDocument.fulfilled, (state, action) => {
				const existGroup = state.currentDocument.groups.find((group) => group.id === action.meta.arg[0].id)
				if (!existGroup) {
					const newGroup = {
						id: action.meta.arg[0].id,
						name: action.meta.arg[0].name,
						image: action.meta.arg[0].image || "",
						materials: [
							{
								id: action.meta.arg[1].id,
								name: action.meta.arg[1].name.toString(),
								image: action.meta.arg[1].image || "",
								fact_state: action.meta.arg[1].quantity,
								group_id: action.meta.arg[0].id,
								is_confirmed: 0,
								warehouse_state: 0,
							},
						],
					}
					state.currentDocument.groups = [...state.currentDocument.groups, newGroup]
					return
				}

				const existMaterial = existGroup.materials.find((material) => material.id === action.meta.arg[1].id)
				const currentGroupIndex = state.currentDocument.groups.findIndex((group) => group.id === existGroup.id)
				if (!existMaterial) {
					const newMaterial = {
						id: action.meta.arg[1].id,
						name: action.meta.arg[1].name.toString(),
						image: action.meta.arg[1].image || "",
						fact_state: action.meta.arg[1].quantity,
						group_id: action.meta.arg[0].id,
						is_confirmed: 0,
						warehouse_state: 0,
					}
					state.currentDocument.groups[currentGroupIndex].materials = [
						...state.currentDocument.groups[currentGroupIndex].materials,
						newMaterial,
					]
					return
				}
				const currentMaterialIndex = existGroup.materials.findIndex(
					(material) => material.id === existMaterial.id,
				)
				state.currentDocument.groups[currentGroupIndex].materials[currentMaterialIndex] = {
					...existMaterial,
					fact_state: (existMaterial.fact_state += action.meta.arg[1].quantity),
				}
			})
			.addMatcher(
				isAnyOf(addProductToPzDocument.fulfilled, addProductToRwDocument.fulfilled),
				(state, action) => {
					const product = state.currentDocument
					if (product.order_rows.some((row) => row.id === action.payload.order_row.id)) {
						const productIndex = product.order_rows.findIndex(
							(row) => row.id === action.payload.order_row.id,
						)
						product.order_rows[productIndex] = action.payload.order_row
						state.currentDocument = product
						return
					}
					state.currentDocument.order_rows = [...state.currentDocument.order_rows, action.payload.order_row]
				},
			)
	},
})

export const {
	clearWarehouses,
	clearCurrentWarehouse,
	clearInventoryMaterialGroups,
	clearInventoryMaterials,
	clearWarehouseGroupMaterials,
	clearWarehouseMaterials,
	clearMaterialGroups,
	clearMaterials,
	setSortedInventoryDocument,
	setSortedInventoryMaterials,
	setSortedGroups,
	setSortedMaterials,
	clearCurrentDocument,
} = warehousesSlice.actions

export default warehousesSlice
