import { Flex, Select, Text } from "@chakra-ui/react"
import i18next from "i18next"
import { memo, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

const QuantitySelect = ({ setRecordsPerPage, maxLength, ...props }) => {
	const { t } = useTranslation("global")
	const selectRef = useRef()

	useEffect(() => {
		if (!selectRef) return

		const currentValue = selectRef.current.value
		setRecordsPerPage(currentValue === "all" ? maxLength : Number(currentValue))
	}, [maxLength])

	useEffect(() => {
		setRecordsPerPage(5)
	}, [i18next.language])

	const handleChangeValue = (e) => {
		setRecordsPerPage(e.target.value === "all" ? maxLength : Number(e.target.value))
	}

	return (
		<Flex key={i18next.language} w='100%' alignItems={"center"} justifyContent={"flex-end"} gap={2}>
			<Text fontSize={[13, 14, 15, 16]} color={"#2C7A7B"}>
				{t("Warehouses.positions")}
			</Text>
			<Select
				ref={selectRef}
				w={{ base: "100px", sm: "120px", md: "130px" }}
				size={["xs", "sm", "md"]}
				bgColor={"#FFF"}
				{...props}
				onChange={handleChangeValue}
			>
				<option value={5}>5</option>
				<option value={10}>10</option>
				<option value={15}>15</option>
				<option value='all'>{t("Warehouses.all")}</option>
			</Select>
		</Flex>
	)
}

export default memo(QuantitySelect)
