import { Box, GridItem, Image, Text } from "@chakra-ui/react"
import { memo } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"
import { getMaterialsShop } from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"

const MaterialGroups = ({
	materialGroups,
	isDisabled,
	setCreateOrderState,
	setShowedItems,
	orderId,
	setSearchValue,
	setResources,
}) => {
	const dispatch = useDispatch()
	const { warehouseId } = useParams()

	const handleSelectMaterialGroup = (material) => {
		if (isDisabled) return
		const materialGroup = {
			id: material.id,
			warehouse_id: Number(warehouseId),
			image: material.image,
			name: material.name,
			warehouse_document_id: Number(orderId),
		}
		if (!orderId) delete materialGroup.warehouse_document_id

		setShowedItems([materialGroup])
		setCreateOrderState(1)
		setSearchValue("")
		setResources([])
		dispatch(getMaterialsShop(material.id))
	}

	return materialGroups?.map((material) => (
		<GridItem
			key={material.id}
			minH={["140px", "190px", "200px", "215px", "230px"]}
			minW={["70px", "140px", "140px", "150px", "190px"]}
			bgColor='#FFF'
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
			cursor={isDisabled ? "not-allowed" : "pointer"}
			position='relative'
			overflow='hidden'
			display='flex'
			flexDirection='column'
			py={2}
			onClick={() => handleSelectMaterialGroup(material)}
		>
			<Box position='relative' flex='1' maxH={"100%"} maxW={"100%"}>
				{material.image === null ? (
					<LackImageIcon
						position='absolute'
						top={0}
						left={0}
						display='flex'
						h='100%'
						w='100%'
						objectFit='contain'
						alt='Lack of material group image'
					/>
				) : (
					<Image
						position='absolute'
						top={0}
						left={0}
						display='flex'
						h='100%'
						w='100%'
						objectFit='contain'
						alt='Material group image'
						src={material.image}
					/>
				)}
			</Box>
			<Text
				position='relative'
				px={1}
				zIndex={1}
				bgColor='rgba(255, 255, 255, 0.8)'
				flexShrink={0}
				mt='auto'
				w='auto'
				h='auto'
				textAlign='center'
				fontSize={[13, 14, 15, 16]}
				fontWeight={600}
			>
				{material.name}
			</Text>
		</GridItem>
	))
}

export default memo(MaterialGroups)
