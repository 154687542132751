import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Badge, Box, Button, Divider, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import i18next from "i18next"

import AccordionList from "../../../../common/AccordionList"
import BinIcon from "../../../../../assets/icons/materials/BinIcon"
import RemoveAlert from "../../../../common/RemoveAlert"
import { useDispatch } from "react-redux"
import { deleteDocument } from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import { removeLimitedWarehouse } from "../../../../../store/auth/auth-slice"
import PermissionsChecked from "../../../../common/PermissionsChecked"

const DocumentsList = ({ documents, setDocuments, setDocumentsCopy, setDocumentsLength }) => {
	const { t } = useTranslation("global")
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const [headers, setHeaders] = useState([])
	const [records, setRecords] = useState([])
	const [isDisabledActions, setIsDisabledActions] = useState(false)
	const [isDescSortByNumber, setIsDescSortByNumber] = useState(true)
	const [isDescSortByStatus, setIsDescSortByStatus] = useState(true)
	const [isDescSortByDescription, setIsDescSortByDescription] = useState(true)
	const [isDescSortByCreated, setIsDescSortByCreated] = useState(true)
	const [isDescSortByCreatedDate, setIsDescSortByCreatedDate] = useState(true)
	const [removeId, setRemoveId] = useState(null)
	const [removeDocType, setRemoveDocType] = useState(null)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { warehouseId } = useParams()

	useEffect(() => {
		setHeaders(listHeaders)
		setRecords(getRecords(documents))
	}, [documents, i18next.language])

	const defaultSort = (array, field, isStringField, order) => {
		const compareFunction = (a, b) => {
			const aValue = isStringField ? a[field].toString().toLowerCase() : a[field]
			const bValue = isStringField ? b[field].toString().toLowerCase() : b[field]

			if (aValue < bValue) return !order ? -1 : 1
			if (aValue > bValue) return !order ? 1 : -1
			return 0
		}

		return [...array].sort(compareFunction)
	}

	const sortByDate = (data, field, order) => {
		const sortedData = [...data].sort((a, b) => {
			const dateA = new Date(a[field])
			const dateB = new Date(b[field])

			if (!order) {
				return dateA - dateB
			}

			return dateB - dateA
		})

		return sortedData
	}

	const getSortArray = (array, field, order, isStringField, isDate = false) => {
		const sortArray = isDate ? sortByDate(array, field, order) : defaultSort(array, field, isStringField, order)
		return sortArray
	}

	const handleSorting = (field, order, setIsDescSort, isStringField = true, isDate = false) => {
		const sortedFilterData = {}
		Object.keys(documents).forEach((key) => {
			sortedFilterData[key] = getSortArray(documents[key], field, order, isStringField, isDate)
		})
		setDocuments(sortedFilterData)
		setIsDescSort((prevState) => !prevState)
	}

	const handleNavigateToDocument = (type, id) => {
		const splittedPath = location.pathname.split("/")
		navigate(`/${splittedPath[1]}/${splittedPath[2]}/${splittedPath[3]}/${splittedPath[4]}/${type}/${id}`)
	}

	const handleOpenRemoveAlert = (id, type) => {
		setRemoveId(id)
		setRemoveDocType(type)
		onOpen()
	}

	const setData = (docType) => {
		setDocuments((prevState) => {
			const newState = prevState
			newState[docType] = newState[docType].filter((document) => document.id !== removeId)
			if (newState[docType].length) return { ...newState }
			delete newState[docType]
			return { ...newState }
		})
		setDocumentsCopy((prevState) => {
			const newState = prevState
			newState[docType] = newState[docType].filter((document) => document.id !== removeId)
			if (newState[docType].length) return { ...newState }
			delete newState[docType]
			return { ...newState }
		})
		setDocumentsLength((prevState) => prevState - 1)
	}

	const handleConfirmRemove = () => {
		if (!removeId) return

		setIsDisabledActions(true)
		dispatch(deleteDocument(removeId)).then((res) => {
			if (res?.error) {
				setIsDisabledActions(false)
				return
			}

			setData(removeDocType)
			setRemoveId(null)
			setRemoveDocType(null)
			setIsDisabledActions(false)
			onClose()
			if (removeDocType === "inv") {
				dispatch(removeLimitedWarehouse(warehouseId))
			}
		})
	}

	const handleCloseRemove = () => {
		setRemoveId(null)
		setRemoveDocType(null)
		onClose()
	}

	const listHeaders = useMemo(
		() => [
			{},
			{
				name: t("Warehouses.number"),
				isDescSort: isDescSortByNumber,
				sortingMethod: () => handleSorting("document_number", isDescSortByNumber, setIsDescSortByNumber),
			},
			{
				name: t("Warehouses.status"),
				isDescSort: isDescSortByStatus,
				sortingMethod: () => handleSorting("status", isDescSortByStatus, setIsDescSortByStatus, false),
			},
			{
				name: t("Warehouses.comments"),
				isDescSort: isDescSortByDescription,
				sortingMethod: () => handleSorting("comments", isDescSortByDescription, setIsDescSortByDescription),
			},
			{
				name: t("Warehouses.created"),
				isDescSort: isDescSortByCreated,
				sortingMethod: () => handleSorting("created", isDescSortByCreated, setIsDescSortByCreated),
			},
			{
				name: t("Warehouses.createdDate"),
				isDescSort: isDescSortByCreatedDate,
				sortingMethod: () =>
					handleSorting("document_date", isDescSortByCreatedDate, setIsDescSortByCreatedDate, true, true),
			},
			{},
		],
		[
			isDescSortByNumber,
			isDescSortByStatus,
			isDescSortByDescription,
			isDescSortByCreated,
			setIsDescSortByCreatedDate,
			handleSorting,
		],
	)

	const getCurrentTypeHeader = (type) => {
		switch (type) {
			case "pz":
				return t("Warehouses.pz")
			case "rw":
				return t("Warehouses.rw")
			case "inv":
				return t("Warehouses.inventory")
			default:
				return "UNDEFINED"
		}
	}

	const getCurrentTextColorType = (type) => {
		switch (type) {
			case "pz":
				return "green.600"
			case "rw":
				return "#C53030"
			case "inv":
				return "#3182CE"
			default:
				return "black"
		}
	}

	const getCurrentDividerColorType = (type) => {
		switch (type) {
			case "pz":
				return "#38A169"
			case "rw":
				return "red.600"
			case "inv":
				return "blue.600"
			default:
				return "black"
		}
	}

	const getRecord = (type) => [
		{
			body: (
				<Text
					color={getCurrentTextColorType(type)}
					fontSize={{ base: "12px", md: "13px", lg: "14px" }}
					fontWeight={600}
					wordBreak={"break-word"}
				>
					{getCurrentTypeHeader(type)}
				</Text>
			),
		},
		{},
		{},
		{},
		{},
		{},
		{},
	]

	const getRecords = useCallback(
		(documents) =>
			Object.keys(documents)?.map((key) => ({
				childrens: getRecord(key),
				dividerColor: getCurrentDividerColorType(key),
				panel: (
					<Flex flexDirection={"column"} gap={1} mt={4} w='100%'>
						{documents[key].map((item, docId) => (
							<Box key={docId}>
								{docId !== 0 && <Divider mb={1} borderWidth='1%' borderColor='#D4D4CD' />}
								<Flex justifyContent={"space-between"} gap={"10px"} w={"100%"}>
									<Flex
										w={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										minW={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										gap={1}
										alignItems={"center"}
									/>
									<Flex
										w={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										minW={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										gap={1}
										alignItems={"center"}
									>
										<Text
											wordBreak={"break-word"}
											fontSize={{ base: "12px", md: "13px", lg: "14px" }}
										>
											{item?.document_number}
										</Text>
									</Flex>
									<Flex
										w={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										minW={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										gap={1}
										alignItems={"center"}
									>
										<Badge
											bgColor={item?.status ? "teal" : "orange"}
											color='#FFF'
											fontSize={{ base: "9px", md: "11px", lg: "12px" }}
										>
											{getCurrentStatus(item.status)}
										</Badge>
									</Flex>
									<Flex
										w={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										minW={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										gap={1}
										alignItems={"center"}
									>
										<Text
											wordBreak={"break-word"}
											fontSize={{ base: "12px", md: "13px", lg: "14px" }}
										>
											{item?.comments}
										</Text>
									</Flex>
									<Flex
										w={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										minW={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										gap={1}
										alignItems={"center"}
									>
										<Text
											wordBreak={"break-word"}
											fontSize={{ base: "12px", md: "13px", lg: "14px" }}
										>
											{item?.created}
										</Text>
									</Flex>
									<Flex
										w={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										minW={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										gap={1}
										alignItems={"center"}
									>
										<Badge fontSize={{ base: "9px", md: "11px", lg: "12px" }}>
											{item?.document_date}
										</Badge>
									</Flex>
									<Flex
										w={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										minW={{
											base: "138px",
											sm: "163px",
											md: "193px",
											lg: "243px",
											xl: "293px",
										}}
										gap={2}
										alignItems={"center"}
										justifyContent={"flex-end"}
									>
										{!item?.status && (
											<PermissionsChecked
												permissions={"warehouse"}
												allowedPermissions={["document_remove"]}
											>
												<Button
													isDisabled={isDisabledActions}
													minW='30px'
													boxSize={["30px", "35px"]}
													onClick={() => handleOpenRemoveAlert(item?.id, item?.document_type)}
												>
													<BinIcon color={"red"} boxSize={[5, 6]} />
												</Button>
											</PermissionsChecked>
										)}
										<PermissionsChecked
											permissions={"warehouse"}
											allowedPermissions={[
												item?.status
													? "document_preview"
													: item?.document_type === "inv"
														? "inventory"
														: "document_edit",
											]}
										>
											<Button
												minW='30px'
												boxSize={["30px", "35px"]}
												bgColor={"#E5E5DF"}
												onClick={() => handleNavigateToDocument(item.document_type, item?.id)}
											>
												<ArrowForwardIcon boxSize={[5, 6]} />
											</Button>
										</PermissionsChecked>
									</Flex>
								</Flex>
							</Box>
						))}
					</Flex>
				),
			})),
		[documents],
	)

	const getCurrentStatus = (status) => {
		switch (status) {
			case 0:
				return t("Warehouses.open")
			case 1:
				return t("Warehouses.closed")
			default:
				return "undefined"
		}
	}

	return (
		<>
			<AccordionList headers={headers} records={records} expand />
			<RemoveAlert isOpen={isOpen} onClose={handleCloseRemove} handleConfirmRemove={handleConfirmRemove} />
		</>
	)
}

export default DocumentsList
