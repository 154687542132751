import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react"

const PDFModal = ({ isOpen, onClose, children }) => {
	return (
		<Modal size={"5xl"} isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader fontSize={[13, 14, 15, 16]}>PDF</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={10}>{children}</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default PDFModal
