/* eslint-disable no-undef */
import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"

import InterRegular from "../../../../../assets/fonts/Inter-Regular.ttf"
import InterBold from "../../../../../assets/fonts/Inter-Bold.ttf"

const DocumentPDF = ({ data, documentTypes, documentStatuses, warehouseString, tableHeaders, warehouseName }) => {
	Font.register({
		family: "Inter",
		fonts: [
			{ src: InterRegular, fontWeight: 400 },
			{ src: InterBold, fontWeight: 600 },
		],
	})

	const getCurrentStatus = (status) => {
		switch (status) {
			case 0:
				return documentStatuses[0]
			case 1:
				return documentStatuses[1]
			default:
				return "Undefined"
		}
	}

	const tableFields = ["document_number", "status", "comments", "created", "document_date"]

	const calculateColumnWidth = (numColumns) => {
		return `${100 / numColumns}%`
	}

	const styles = StyleSheet.create({
		page: {
			fontFamily: "Inter",
			padding: 10,
		},
		container: {
			height: "100%",
			border: "1px solid #000000",
			padding: 10,
			gap: 17,
		},
		infoPanel: {
			flexDirection: "row",
			justifyContent: "space-between",
		},
		mainDivider: {
			borderBottomWidth: 3,
			borderBottomColor: "#000000",
			marginBottom: 10,
		},
		tableDivider: {
			borderBottomWidth: 1,
			borderBottomColor: "#000000",
			marginTop: 10,
			marginBottom: 10,
		},
		tableView: {
			alignItems: "center",
		},
		tableCaption: {
			backgroundColor: "#2C5282",
			color: "#FFF",
			display: "flex",
			flexWrap: "wrap",
			textAlign: "center",
			fontWeight: "bold",
			fontSize: 15,
			paddingTop: 2,
			paddingBottom: 2,
			width: "100%",
			borderStyle: "solid",
			borderWidth: 1,
			borderBottomWidth: 0,
		},
		table: {
			display: "table",
			fontSize: 14,
			width: "100%",
			borderStyle: "solid",
			borderWidth: 1,
			borderRightWidth: 0,
			borderBottomWidth: 0,
		},
		tableRow: {
			flexDirection: "row",
			justifyContent: "space-between",
		},
		tableCol: {
			borderStyle: "solid",
			borderWidth: 1,
			borderLeftWidth: 0,
			borderTopWidth: 0,
			padding: 5,
		},
		tableHeaderCell: {
			backgroundColor: "#2C5282",
			color: "#FFF",
			fontSize: 13,
			textAlign: "center",
			fontWeight: "bold",
			width: calculateColumnWidth(tableHeaders?.length),
		},
		tableBodyCell: {
			fontSize: 11,
			textAlign: "center",
			width: calculateColumnWidth(tableHeaders?.length),
		},
	})

	return (
		<Document title={warehouseName}>
			<Page size='A4' style={styles.page}>
				<View style={styles.container}>
					<View style={styles.infoPanel}>
						<View style={{ gap: 15 }}>
							<Text style={{ fontWeight: "bold", fontSize: 20 }}>{process.env.REACT_APP_API_KEY}</Text>
							<Text style={{ fontWeight: "bold", fontSize: 18 }}>
								{warehouseString}: {warehouseName}
							</Text>
						</View>
						<Image
							src={`${process.env.PUBLIC_URL}/logos/${process.env.REACT_APP_API_KEY}-LOGO-192.png`}
							style={{ width: 100, height: 100 }}
						/>
					</View>
					<View style={styles.mainDivider} />
					{data !== undefined &&
						Object.keys(data)?.map(
							(item, index) =>
								data[item].length && (
									<React.Fragment key={index}>
										{index !== 0 && <View style={styles.tableDivider} />}
										<View style={styles.tableView}>
											<View style={styles.tableCaption}>
												<Text>{documentTypes[item]}</Text>
											</View>
											<View style={styles.table}>
												<View style={styles.tableRow}>
													{tableHeaders?.map((header, index) => (
														<View
															key={index}
															style={[styles.tableCol, styles.tableHeaderCell]}
														>
															<Text>{header}</Text>
														</View>
													))}
												</View>
												{data[item]?.map((document, index) => (
													<View key={index} style={styles.tableRow}>
														{tableHeaders?.map((_, index) => (
															<View
																key={index}
																style={[styles.tableCol, styles.tableBodyCell]}
															>
																<Text>
																	{tableFields[index] === "status"
																		? getCurrentStatus(document[tableFields[index]])
																		: document[tableFields[index]]}
																</Text>
															</View>
														))}
													</View>
												))}
											</View>
										</View>
									</React.Fragment>
								),
						)}
				</View>
			</Page>
		</Document>
	)
}

export default DocumentPDF
