import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import { Badge, Image, Text } from "@chakra-ui/react"
import { useDispatch } from "react-redux"

import Pagination from "../../../../common/Pagination"
import RecordsList from "../../../../common/RecordsList"
import { setSortedMaterials } from "../../../../../store/modules/warehouse/warehouses/warehouses-slice"
import DebouncedInput from "./DebouncedInput"
import {
	updateMaterialRack,
	updateMaterialSpaceRack,
} from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"

const MaterialsList = ({
	parameterNames,
	materials,
	setMaterials,
	materialsCopy,
	setMaterialsCopy,
	materialsList,
	recordsPerPage,
}) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const [headers, setHeaders] = useState([])
	const [records, setRecords] = useState([])
	const [isSortDescByMaterialName, setIsSortDescByMaterialName] = useState(true)
	const [isSortDescByQuantity, setIsSortDescByQuantity] = useState(true)
	const [isSortDescByUnitPrice, setIsSortDescByUnitPrice] = useState(true)
	const [isSortDescByRack, setIsSortDescByRack] = useState(true)
	const [isSortDescBySpaceRack, setIsSortDescBySpaceRack] = useState(true)
	const [isSortDescParameters, setSortDescParamters] = useState(parameterNames?.map(() => true))
	const [paginationLength, setPaginationLength] = useState(materialsCopy.length)

	useEffect(() => {
		if (paginationLength === materialsCopy.length) return
		setPaginationLength(materialsCopy.length)
	}, [materialsCopy])

	const sorting = useCallback((array, field, order, isStringField = true) => {
		const compareFunction = (a, b) => {
			const aValue = isStringField ? a[field].toString().toLowerCase() : a[field]
			const bValue = isStringField ? b[field].toString().toLowerCase() : b[field]

			if (aValue < bValue) return !order ? -1 : 1
			if (aValue > bValue) return !order ? 1 : -1
			return 0
		}

		return [...array].sort(compareFunction)
	}, [])

	const concatenatedParameterValues = (values) => {
		let valuesString = ""

		values.forEach((value, index) => {
			valuesString += value
			if (index === values.length - 1) return
			valuesString += " x "
		})

		return valuesString
	}

	const parametersSorting = useCallback((array, index, order) => {
		const compareFunction = (a, b) => {
			const aValue = concatenatedParameterValues(a.parameter_values[index].values).toLowerCase()
			const bValue = concatenatedParameterValues(b.parameter_values[index].values).toLowerCase()

			if (aValue < bValue) return !order ? -1 : 1
			if (aValue > bValue) return !order ? 1 : -1
			return 0
		}

		return [...array].sort(compareFunction)
	}, [])

	const handleSetSortedData = useCallback(
		(setIsDescSort, sortedFullData, sortedFilterData, parameterIndex) => {
			dispatch(setSortedMaterials(sortedFullData))
			setMaterials(sortedFilterData.slice(0, recordsPerPage))
			setMaterialsCopy(sortedFilterData)
			setPaginationLength(sortedFilterData.length)
			parameterIndex || parameterIndex === 0
				? setIsDescSort((prevState) =>
						prevState.map((state, index) => (parameterIndex === index ? !state : state)),
					)
				: setIsDescSort((prevState) => !prevState)
		},
		[recordsPerPage],
	)

	const handleSorting = useCallback(
		(field, isDescSort, setIsDescSort, parameterIndex, isStringField = true) => {
			let sortedMaterials = []
			let sortedMaterialsInitArray = []

			sortedMaterials =
				parameterIndex || parameterIndex === 0
					? parametersSorting(materialsCopy, parameterIndex, isDescSort)
					: sorting(materialsCopy, field, isDescSort, isStringField)
			sortedMaterialsInitArray =
				parameterIndex || parameterIndex === 0
					? parametersSorting(materialsList, parameterIndex, isDescSort)
					: sorting(materialsList, field, isDescSort, isStringField)

			handleSetSortedData(setIsDescSort, sortedMaterialsInitArray, sortedMaterials, parameterIndex)
		},
		[materialsList, materials],
	)

	const handleChangePage = (page) => {
		const start = (page - 1) * recordsPerPage
		const end = start + recordsPerPage
		const newMaterials = materialsCopy.slice(start, end)
		setMaterials(newMaterials)
	}

	const handleSaveRack = (id, value) => {
		dispatch(updateMaterialRack({ id, value }))
	}

	const handleSaveSpaceRack = (id, value) => {
		dispatch(updateMaterialSpaceRack({ id, value }))
	}

	const materialsHeaders = useCallback(
		(parameterNames) => {
			const parametersHeaders = parameterNames.map((parameter, index) => ({
				name: parameter.name,
				isDescSort: isSortDescParameters[index],
				sortingMethod: () => handleSorting("", isSortDescParameters[index], setSortDescParamters, index),
			}))

			return [
				{
					name: t("Warehouses.image"),
				},
				{
					name: t("Warehouses.material"),
					isDescSort: isSortDescByMaterialName,
					sortingMethod: () => handleSorting("name", isSortDescByMaterialName, setIsSortDescByMaterialName),
				},
				...parametersHeaders,
				{
					name: t("Warehouses.quantity"),
					isDescSort: isSortDescByQuantity,
					sortingMethod: () =>
						handleSorting("quantity", isSortDescByQuantity, setIsSortDescByQuantity, null, false),
				},
				{
					name: t("Warehouses.unitPrice"),
					isDescSort: isSortDescByQuantity,
					sortingMethod: () =>
						handleSorting("value", isSortDescByUnitPrice, setIsSortDescByUnitPrice, null, false),
				},
				{
					name: t("Warehouses.rack"),
					isDescSort: isSortDescByRack,
					sortingMethod: () => handleSorting("rack", isSortDescByRack, setIsSortDescByRack, null, false),
				},
				{
					name: t("Warehouses.spaceOnTheRack"),
					isDescSort: isSortDescBySpaceRack,
					sortingMethod: () =>
						handleSorting(
							"space_on_the_rack",
							isSortDescBySpaceRack,
							setIsSortDescBySpaceRack,
							null,
							false,
						),
				},
			]
		},
		[
			isSortDescParameters,
			isSortDescByMaterialName,
			isSortDescByQuantity,
			isSortDescByRack,
			isSortDescBySpaceRack,
			handleSorting,
		],
	)

	const getRecord = (material) => [
		{
			body: material?.image ? (
				<Image src={material.image} alt='Material image' boxSize={"32px"} objectFit={"contain"} />
			) : (
				<LackImageIcon alt='Lack of material image' boxSize={"32px"} objectFit={"contain"} />
			),
		},
		{
			body: <Text wordBreak={"break-word"}>{material.name}</Text>,
		},
		...material.parameter_values.map((parameter) => ({
			body: (
				<Badge
					bgColor={"#F2F2EF"}
					whiteSpace={"pre-wrap"}
					fontSize={{ base: "10px", md: "11px", lg: "12px" }}
					wordBreak={"break-word"}
				>
					{concatenatedParameterValues(parameter.values)}
				</Badge>
			),
		})),
		{
			body: <Text wordBreak={"break-word"}>{material.quantity}</Text>,
		},
		{
			body: <Text wordBreak={"break-word"}>{Number(material.value).toFixed(2)}</Text>,
		},
		{
			body: (
				<DebouncedInput
					h={{ base: "25px", md: "30px" }}
					placeholder={t("Warehouses.enterRack")}
					id={material.product_id}
					value={material.rack}
					handleSave={handleSaveRack}
				/>
			),
		},
		{
			body: (
				<DebouncedInput
					h={{ base: "25px", md: "30px" }}
					placeholder={t("Warehouses.enterSpaceRack")}
					id={material.product_id}
					value={material.space_on_the_rack}
					handleSave={handleSaveSpaceRack}
				/>
			),
		},
	]

	const getMaterialsRecords = (materials) =>
		materials?.map((material) => ({
			childrens: getRecord(material),
		}))

	useEffect(() => {
		setHeaders(materialsHeaders(parameterNames))
		setRecords(getMaterialsRecords(materials))
	}, [materials, i18next.language])

	return (
		<>
			<RecordsList headers={headers} records={records} />
			{!!headers.length && !!records.length && (
				<Pagination
					style={{
						marginTop: "10px",
						paddingBottom: "10px",
					}}
					totalRecords={paginationLength}
					array={materialsCopy}
					recordsPerPage={recordsPerPage}
					onPageChange={(page) => handleChangePage(page)}
				/>
			)}
		</>
	)
}

export default MaterialsList
