import { createIcon } from "@chakra-ui/react"

const ExampleIcon = createIcon({
	displayName: "ExampleIcon",
	viewBox: "0 0 24 24",
	path: (
		<>
			<path
				fill='currentColor'
				d='M11.998 5.074a7.5 7.5 0 106.82 4.37.75.75 0 011.361-.625A9 9 0 1112 3.574v1.5z'
			/>
			<path
				fill='currentColor'
				d='M11.998 7.273V1.375a.376.376 0 01.616-.288l3.54 2.949c.18.15.18.426 0 .576l-3.54 2.949a.375.375 0 01-.616-.288z'
			/>
		</>
	),
})

export default ExampleIcon
