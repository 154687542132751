import { Navigate, Outlet, useLocation } from "react-router-dom"

import { useGetIsInventory } from "../hooks/Modules/Warehouse/useGetIsInventory"

const ProtectedWarehouseRoutes = () => {
	const { isInventory } = useGetIsInventory()
	const location = useLocation()
	const isInventoryPlace = location.pathname.includes("inventory")

	if (isInventoryPlace) {
		return isInventory ? <Outlet /> : <Navigate to='/' />
	}

	return isInventory ? <Navigate to='/' /> : <Outlet />
}

export default ProtectedWarehouseRoutes
