import { Flex, Text, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import * as Yup from "yup"
import { useEffect, useMemo, useState } from "react"

import FormikForm from "../../../../common/FormikForm"
import WhiteBackground from "../../../../common/WhiteBackground"
import TextFieldWithDebounce from "../../../../common/TextFieldWithDebounce"
import SelectWithSearching from "../../../../common/SelectWithSearching"
import TextAreaWithDebounce from "../../../../common/TextAreaWithDebounce"
import TextField from "../../../../common/TextField"
import ActionButtons from "./ActionButtons"
import AlertCancelChanges from "../../../../common/AlertCancelChanges"
import {
	editWarehouseDocument,
	removeDocumentToWarehouse,
} from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"

const TakeItPanel = ({ dropdownsData }) => {
	const { t } = useTranslation("global")
	const { isOpen, onOpen, onClose } = useDisclosure()
	const navigate = useNavigate()
	const location = useLocation()
	const [isEditForm, setIsEditForm] = useState(!!location.state)
	const { warehouseId } = useParams()

	useEffect(() => {
		setIsEditForm(!!location.state)
	}, [location.state])

	const handleConfirmCancel = () => {
		const splittedPath = location.pathname.split("/")
		navigate(
			isEditForm
				? `/${splittedPath[1]}/${splittedPath[2]}/${splittedPath[3]}/${splittedPath[4]}/${splittedPath[5]}/${splittedPath[6]}`
				: `/${splittedPath[1]}/${splittedPath[2]}/${splittedPath[3]}`,
		)
	}

	const getValuesFromLocationState = useMemo(() => {
		if (!location.state) return
		const currentProjectId = dropdownsData.projects.find((item) => item.name === location.state.project).id
		const currentContractorId = dropdownsData.contractors.find((item) => item.name === location.state.contractor).id

		return {
			id: location.state.id,
			warehouse_id: warehouseId,
			document_number: location.state.document_number,
			project_id: currentProjectId,
			contractor_id: currentContractorId,
			comments: location.state.comments,
			document_date: location.state.document_date,
		}
	}, [location.state, dropdownsData])

	const row = (textProp, field) => (
		<Flex
			alignItems='center'
			gap={[1, 5, 7, 10, 16]}
			justifyContent={"space-between"}
			flexWrap={{ base: "wrap", sm: "nowrap" }}
		>
			<Text
				w={{ base: "100%", sm: "150px", md: "175px", lg: "200px" }}
				minW={{ base: "100%", sm: "150px", md: "175px", lg: "200px" }}
				fontSize={[13, 14, 15, 15, 16]}
				color='#2C7A7B'
				wordBreak={"break-word"}
			>
				{t(`Warehouses.${textProp}`)}
			</Text>
			{field}
		</Flex>
	)

	return (
		<FormikForm
			isPatchMethod={isEditForm}
			shouldNavigate={false}
			enableReinitialize
			initialValues={
				isEditForm
					? getValuesFromLocationState
					: {
							warehouse_id: warehouseId,
							document_number: "",
							project_id: "",
							contractor_id: "",
							comments: "",
							document_date: "",
						}
			}
			validationSchema={Yup.object({
				document_number: Yup.string().required(t("Warehouses.documentNameRequired")),
				project_id: Yup.string().required(t("Warehouses.projectIsRequired")),
				document_date: Yup.date()
					.min(dropdownsData?.minimum_date || 0, t("Warehouses.documentDateIsIncorrect"))
					.max(dropdownsData?.maximum_date, t("Warehouses.documentDateIsIncorrect"))
					.required(t("Warehouses.documentDateIsRequired")),
			})}
			onSubmit={isEditForm ? editWarehouseDocument(navigate) : removeDocumentToWarehouse(navigate)}
		>
			<WhiteBackground my={5}>
				<Flex flexDirection={"column"} gap={2}>
					{row(
						"documentName",
						<TextFieldWithDebounce
							mt={["1", "1", "1", "2"]}
							name='document_number'
							fontSize={[13, 14, 15, 15, 16]}
							h={["30px", "35px", "40px"]}
							type='text'
							placeholder={t("Warehouses.addDocumentNumber")}
						/>,
					)}
					{row(
						"project",
						<SelectWithSearching
							name='project_id'
							mt={["1", "1", "1", "2"]}
							placeholder={t("Warehouses.selectProject")}
							fontSize={[13, 14, 15, 15, 16]}
							values={dropdownsData?.projects}
						/>,
					)}
					{row(
						"supplier",
						<SelectWithSearching
							name='contractor_id'
							mt={["1", "1", "1", "2"]}
							placeholder={t("Warehouses.selectSupplier")}
							fontSize={[13, 14, 15, 15, 16]}
							values={dropdownsData?.contractors}
						/>,
					)}
					{row(
						"recipient",
						<Text w='100%' fontSize={[13, 14, 15, 15, 16]} color='gray.500'>
							{dropdownsData?.recipient}
						</Text>,
					)}
					{row(
						"comments",
						<TextAreaWithDebounce
							mt={["1", "1", "1", "2"]}
							name='comments'
							fontSize={[13, 14, 15, 15, 16]}
							placeholder={t("Warehouses.addComments")}
						/>,
					)}
					{row(
						"documentDate",
						<TextField
							mt={["1", "1", "1", "2"]}
							cursor='pointer'
							fontSize={[13, 14, 15, 16]}
							h={["30px", "35px", "40px"]}
							name='document_date'
							min={dropdownsData?.minimum_date}
							max={dropdownsData?.maximum_date}
							type='date'
						/>,
					)}
				</Flex>
			</WhiteBackground>
			<ActionButtons onOpen={onOpen} />
			<AlertCancelChanges isOpen={isOpen} onClose={onClose} handleConfirmCancel={handleConfirmCancel} />
		</FormikForm>
	)
}

export default TakeItPanel
