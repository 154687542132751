import { createAsyncThunk } from "@reduxjs/toolkit"
import { handleRequest } from "../../../../utils/api/api"
import { setLimitedWarehouses } from "../../../../store/auth/auth-slice"

/**
 * get warehouse by id function with dispatch action
 */
export const getCurrentWarehouse = createAsyncThunk("warehouse/getCurrentWarehouse", async (id) => {
	const responseData = await handleRequest({
		endPoint: `api/warehouse/warehouses/${id}`,
		method: "GET",
	})

	return responseData
})

/**
 * get all warehouses function with dispatch action
 */
export const getWarehouses = createAsyncThunk("warehouse/getWarehouses", async () => {
	const responseData = await handleRequest({
		endPoint: "api/warehouse/warehouses",
		method: "GET",
	})

	return responseData
})
/**
 * create new warehouse function with dispatch action
 */
export const createWarehouse = createAsyncThunk("warehouse/createWarehouse", async (data) => {
	const responseData = await handleRequest({
		endPoint: "api/warehouse/warehouses",
		method: "POST",
		body: data,
	})

	return responseData
})

/**
 * update warehouse function with dispatch action
 */
export const updateWarehouse = createAsyncThunk("warehouse/updateWarehouse", async (data) => {
	const warehouseId = data?.id
	delete data.id
	const responseData = await handleRequest({
		endPoint: `api/warehouse/warehouses/${warehouseId}`,
		method: "PATCH",
		body: data,
	})

	return responseData
})

/**
 * get edit warehouse function with dispatch action
 */
export const getEditWarehouse = createAsyncThunk("warehouse/getEditWarehouse", async (id) => {
	const responseData = await handleRequest({
		endPoint: `api/warehouse/warehouseToEdit/${id}`,
	})

	return responseData
})

/**
 * delete warehouse function with dispatch action
 */
export const deleteWarehouse = createAsyncThunk("warehouse/deleteWarehouse", async (id) => {
	const responseData = await handleRequest({
		endPoint: `api/warehouse/warehouses/${id}`,
		method: "DELETE",
	})

	return responseData
})

/**
 * Get material groups function with dispatch action
 */
export const getMaterialGroups = createAsyncThunk("warehouses/getMaterialGroups", async (id) => {
	const responseData = await handleRequest({
		endPoint: `api/warehouse/showWarehouseMaterialGroups/${id}`,
	})
	return responseData
})

/**
 * Get inventory groups function with dispatch action
 */
export const getInventoryDocument = createAsyncThunk("warehouses/getInventoryDocument", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/warehouse/getWarehouseInvDocument/${id}`,
	})
	return responseData
})

/**
 * Get materials function with dispatch action
 */
export const getMaterials = createAsyncThunk("warehouses/getMaterials", async ({ warehouseId, materialId }) => {
	const responseData = await handleRequest({
		endPoint: `api/warehouse/showWarehouseMaterials/${warehouseId}/${materialId}`,
	})
	return responseData
})

/**
 * Get inventory materials function with dispatch action
 */
export const getInventoryMaterials = createAsyncThunk(
	"warehouses/getInventoryMaterials",
	async ({ warehouseId, groupId, documentId }) => {
		const responseData = await handleRequest({
			endPoint: `/api/warehouse/showWarehouseInventoryMaterials/${warehouseId}/${groupId}/${documentId}`,
		})
		return responseData
	},
)

/**
 * Get order by id function with dispatch action
 */
export const getDocument = createAsyncThunk("warehouse/getDocument", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/warehouse/warehouses/documents/${id}`,
	})

	return responseData
})

/**
 * Get order by id function with dispatch action
 */
export const deleteDocument = createAsyncThunk("warehouse/deleteDocument", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/warehouse/warehouses/documents/${id}`,
		method: "DELETE",
	})

	return responseData
})

/**
 * get product configuration function with dispatch action
 */
export const getProductConfiguration = createAsyncThunk("warehouse/getProductConfiguration", async (product) => {
	const responseData = await handleRequest({
		endPoint: "/api/warehouse/productConfiguration",
		method: "POST",
		body: product,
	})

	return responseData
})

/**
 * Add order to pz document function with dispatch action
 */
export const addProductToPzDocument = createAsyncThunk("warehouse/addProductToPzDocument", async (order) => {
	const responseData = await handleRequest({
		endPoint: "/api/warehouse/addProductToPzDocument",
		method: "POST",
		body: order,
	})

	return responseData
})

/**
 * Add order to pz document function with dispatch action
 */
export const addProductToInvDocument = createAsyncThunk("warehouse/addProductToInvDocument", async (order) => {
	const responseData = await handleRequest({
		endPoint: "/api/warehouse/addProductToInvDocument",
		method: "POST",
		body: order,
	})

	return responseData
})

/**
 * Add order to rw document function with dispatch action
 */
export const addProductToRwDocument = createAsyncThunk("warehouse/addProductToRwDocument", async (order) => {
	const responseData = await handleRequest({
		endPoint: "/api/warehouse/addProductToRwDocument",
		method: "POST",
		body: order,
	})

	return responseData
})

/**
 * save document function with dispatch action
 */
export const saveDocument = createAsyncThunk("warehouse/saveDocument", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/warehouse/warehouses/documents/${id}/transaction`,
		method: "POST",
	})

	return responseData
})

/**
 * save rw document function with dispatch action
 */
export const saveRWDocument = createAsyncThunk("warehouse/saveRWDocument", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/warehouse/warehouses/documents/${id}/transactionRW`,
		method: "POST",
	})

	return responseData
})

/**
 * create inventory document function with dispatch action
 */
export const createInventoryDocument = (navigate, dispatch) =>
	createAsyncThunk("warehouse/createInventoryDocument", async (data) => {
		const warehouseId = data.warehouse_id
		delete data["warehouse_id"]
		const responseData = await handleRequest({
			endPoint: `/api/warehouse/warehouses/${warehouseId}/inventory`,
			method: "POST",
			body: data,
		})

		if (!responseData?.message) return
		dispatch(setLimitedWarehouses({ id: warehouseId, document_id: responseData?.document }))
		navigate(`/warehouse/warehouses/${warehouseId}/documents/inv/${responseData?.document}`)
		return responseData
	})

/**
 * update inventory document function with dispatch action
 */
export const updateInventoryDocument = (navigate) =>
	createAsyncThunk("warehouse/updateInventoryDocument", async (data) => {
		const responseData = await handleRequest({
			endPoint: `api/warehouse/updateWarehouseInvDocument/${data.id}`,
			method: "PATCH",
			body: data,
		})

		if (!responseData?.message) return
		navigate("..")
		return responseData
	})

/**
 * get dropdowns data function with dispatch action
 */
export const getWarehouseDropdowns = createAsyncThunk("warehouse/getWarehouseDropdowns", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/warehouse/getFormDropdowns/${id}`,
		method: "GET",
	})

	return responseData
})

/**
 * get dropdowns data function with dispatch action
 */
export const getInventoryCreator = createAsyncThunk("warehouse/getInventoryCreator", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/warehouse/getInventoryCreator/${id}`,
		method: "GET",
	})

	return responseData
})

/**
 * add document function with dispatch action
 */
export const addDocumentToWarehouse = (navigate) =>
	createAsyncThunk("warehouse/addDocumentToWarehouse", async (data) => {
		const warehouseId = data.warehouse_id
		delete data["warehouse_id"]
		const responseData = await handleRequest({
			endPoint: `/api/warehouse/warehouses/${warehouseId}/add`,
			method: "POST",
			body: data,
		})

		navigate(`/warehouse/warehouses/${warehouseId}/documents/pz/${responseData.warehouse}`)
		return responseData
	})

/**
 * remove document function with dispatch action
 */
export const removeDocumentToWarehouse = (navigate) =>
	createAsyncThunk("warehouse/removeDocumentToWarehouse", async (data) => {
		const warehouseId = data.warehouse_id
		delete data["warehouse_id"]
		const responseData = await handleRequest({
			endPoint: `/api/warehouse/warehouses/${warehouseId}/remove`,
			method: "POST",
			body: data,
		})

		navigate(`/warehouse/warehouses/${warehouseId}/documents/rw/${responseData.warehouse}`)
		return responseData
	})

/**
 * eidt warehouse document function with dispatch action
 */
export const editWarehouseDocument = (navigate) =>
	createAsyncThunk("warehouse/editWarehouseDocument", async (data) => {
		const warehouseId = data.warehouse_id
		const documentId = data.id
		delete data["warehouse_id"]
		delete data["id"]
		const responseData = await handleRequest({
			endPoint: `/api/warehouse/warehouses/documents/${documentId}/edit`,
			method: "PATCH",
			body: data,
		})

		navigate(`/warehouse/warehouses/${warehouseId}/documents/pz/${documentId}`)
		return responseData
	})

/**
 * get documents function with dispatch action
 */
export const getDocuments = createAsyncThunk("warehouse/getDocuments", async (id) => {
	const responseData = await handleRequest({
		endPoint: `api/warehouse/warehouses/${id}/documents`,
		method: "GET",
	})

	return responseData
})

/**
 * Change product position function
 */
export const changeProductPosition = createAsyncThunk("warehouse/changeProductPosition", async (data) => {
	const responseData = await handleRequest({
		endPoint: "/api/warehouse/dragAndDrop",
		method: "POST",
		body: data,
	})

	return responseData
})

/**
 * Change product count function
 */
export const changeProductCount = createAsyncThunk("warehouse/changeProductCount", async ({ id, quantity }) => {
	const responseData = await handleRequest({
		endPoint: `/api/warehouse/warehouseRowQuantity/${id}`,
		method: "POST",
		body: {
			quantity: quantity,
		},
	})

	return responseData
})

/**
 * Remove product by id function with dispatch action
 */
export const removeProduct = createAsyncThunk("warehouse/removeProduct", async (id) => {
	const responseData = await handleRequest({
		endPoint: `api/warehouse/deleteProductFromDocument/${id}`,
		method: "DELETE",
	})

	return responseData
})

/**
 * Get materials groups function with dispatch action
 */
export const getMaterialsGroups = createAsyncThunk("warehouse/shop/getMaterialsGroups", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/warehouse/shop",
	})
	return responseData
})

/**
 * Get materials from group function with dispatch action
 */
export const getMaterialsShop = createAsyncThunk("warehouse/shop/getMaterials", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/warehouse/shop/${id}`,
	})
	return responseData
})

/**
 * Update material fact state function with dispatch action
 */
export const updateFactState = createAsyncThunk(
	"warehouse/updateFactState",
	async ({ warehouseId, product_id, value }) => {
		const responseData = await handleRequest({
			endPoint: `/api/warehouse/updateProductInfo/${warehouseId}/${product_id}`,
			method: "PATCH",
			body: { inventory_quantity: value },
		})
		return responseData
	},
)

/**
 * Update material confirm function with dispatch action
 */
export const updateConfirmMaterial = createAsyncThunk(
	"warehouse/updateConfirmMaterial",
	async ({ warehouseId, id, value }) => {
		const responseData = await handleRequest({
			endPoint: `/api/warehouse/updateProductInfo/${warehouseId}/${id}`,
			method: "PATCH",
			body: { is_confirmed: value },
		})
		return responseData
	},
)

/**
 * Update all materials confirm function with dispatch action
 */
export const updateConfirmAllMaterials = createAsyncThunk(
	"warehouse/updateConfirmAllMaterials",
	async ({ warehouseId, products, confirm }) => {
		const responseData = await handleRequest({
			endPoint: `/api/warehouse/isConfirmedForAll/${warehouseId}`,
			method: "PATCH",
			body: { products, is_confirmed: confirm },
		})
		return responseData
	},
)

/**
 * Update material rack function with dispatch action
 */
export const updateMaterialRack = createAsyncThunk("warehouse/updateMaterialRack", async ({ id, value }) => {
	const responseData = await handleRequest({
		endPoint: `/api/warehouse/updateRackInfo/${id}`,
		method: "PATCH",
		body: { value },
	})
	return responseData
})

/**
 * Finish inventory function with dispatch action
 */
export const finishInventory = createAsyncThunk("warehouse/finishInventory", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/warehouse/endInventory/${id}`,
		method: "PATCH",
	})
	return responseData
})

/**
 * Update material space rack function with dispatch action
 */
export const updateMaterialSpaceRack = createAsyncThunk("warehouse/updateMaterialSpaceRack", async ({ id, value }) => {
	const responseData = await handleRequest({
		endPoint: `/api/warehouse/updateSpaceOnTheRackInfo/${id}`,
		method: "PATCH",
		body: { value },
	})
	return responseData
})

/**
 * get actual date function with dispatch action
 */
export const getDateForFiles = createAsyncThunk("warehouse/getDateForFiles", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/warehouse/dateForFiles",
	})
	return responseData
})
