import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Button,
	Divider,
	Flex,
	Heading,
	useBreakpointValue,
} from "@chakra-ui/react"
import { createRef, memo, useEffect, useState } from "react"

import BsSortUp from "../../assets/icons/permissions/BsSortUp"
import BsSortDown from "../../assets/icons/permissions/BsSortDown"

const AccordionList = ({ headers, records, expand, ...props }) => {
	const [accordionButtonRefs, setAccordionButtonRefs] = useState(records?.map(() => createRef()))
	const columnWidthBreakpoints = {
		base: 100,
		sm: 125,
		md: 150,
		lg: 200,
		xl: 250,
	}
	const columnWidth = useBreakpointValue(columnWidthBreakpoints)

	const columnGapBreakpoints = {
		base: 10,
	}
	const columnGap = useBreakpointValue(columnGapBreakpoints)

	useEffect(() => {
		setAccordionButtonRefs(records?.map(() => createRef()))
	}, [records])

	useEffect(() => {
		handleExpandCollapse()
	}, [accordionButtonRefs])

	const handleExpandRecord = (index) => {
		if (!accordionButtonRefs[index]) return
		accordionButtonRefs[index].current.click()
	}

	const handleExpandCollapse = () => {
		if (!accordionButtonRefs) return
		accordionButtonRefs.forEach((ref) => {
			if (!ref.current) return
			if (ref.current.ariaExpanded === (expand ? "true" : "false")) return
			ref.current.click()
		})
	}

	return (
		<Flex flexDirection={"column"} overflowX={"auto"} gap={2} pb={2}>
			<Flex
				justifyContent={"space-between"}
				gap={`${columnGap}px`}
				px={5}
				w={["calc(100% - 38px)", "calc(100% - 43px)"]}
			>
				{headers?.map((header, index) => (
					<Flex
						key={index}
						w={`${columnWidth}px`}
						minW={`${columnWidth}px`}
						gap={1}
						alignItems={"center"}
						justifyContent={header.justifyContent || "flex-start"}
					>
						<Heading
							fontSize={{ base: "12px", md: "13px", lg: "14px" }}
							fontWeight={400}
							whiteSpace={"pre-wrap"}
							wordBreak={"break-all"}
						>
							{header.name}
						</Heading>
						{header.sortingMethod && (
							<Button
								aria-label='Sorting button by group'
								p={3}
								minW={"20px"}
								boxSize={{ base: "20px", lg: "30px" }}
								bgColor='transparent'
								_hover={{
									bgColor: "#FFF",
								}}
								onClick={header.sortingMethod}
							>
								{header.isDescSort ? (
									<BsSortUp boxSize={{ base: "14px", md: "16px", lg: "18px" }} />
								) : (
									<BsSortDown boxSize={{ base: "14px", md: "16px", lg: "18px" }} />
								)}
							</Button>
						)}
					</Flex>
				))}
			</Flex>
			{records?.map((record, recordId) => (
				<Accordion key={recordId} defaultIndex={["*"]} allowMultiple>
					<AccordionItem
						bgColor={"#FFF"}
						borderRadius={"4px"}
						gap={`${columnGap}px`}
						boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
						minW={{
							base: `${record.childrens?.length * (columnWidth + columnGap) + 70}px`,
							md: `${record.childrens?.length * (columnWidth + columnGap) + 75}px`,
						}}
						cursor={"pointer"}
						onClick={() => handleExpandRecord(recordId)}
					>
						<Flex alignItems={"center"} gap={2} {...props} px={5}>
							<Flex w='100%' minH='44px' alignItems={"center"} justifyContent={"space-between"}>
								{record.childrens.map((children, index) => (
									<Flex
										key={index}
										w={`${columnWidth}px`}
										minW={`${columnWidth}px`}
										gap={1}
										alignItems={"center"}
										fontSize={{ base: "12px", md: "13px", lg: "14px" }}
										justifyContent={children.justifyContent || "flex-start"}
									>
										{children.body}
									</Flex>
								))}
							</Flex>
							<AccordionButton
								ref={accordionButtonRefs[recordId]}
								p={0}
								minW='30px'
								boxSize={["30px", "35px"]}
								justifyContent={"center"}
							>
								<AccordionIcon boxSize={[5, 5, 6]} />
							</AccordionButton>
						</Flex>
						<AccordionPanel px={5} cursor={"default"} onClick={(e) => e.stopPropagation()}>
							<Divider borderWidth='1px' borderColor={record?.dividerColor || "#D4D4CD"} />
							{record.panel}
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			))}
		</Flex>
	)
}

export default memo(AccordionList)
