import { createAsyncThunk } from "@reduxjs/toolkit"
import { handleRequest } from "../../../../utils/api/api"

/**
 * Get warehouse settings
 */
export const getWarehouseSettings = createAsyncThunk("warehouseSettings/getWarehouseSettings", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/warehouse/allConfig",
	})
	return responseData
})

/**
 * Add roles to settings
 */
export const addWarehouseRoles = createAsyncThunk("warehouseSettings/addRoles", async (values) => {
	const responseData = await handleRequest({
		endPoint: "/api/warehouse/warehouseGroupPermissions",
		method: "POST",
		body: values,
	})
	return responseData
})

/**
 * Set user role
 */
export const setWarehouseUsersRoles = createAsyncThunk("warehouseSettings/setWarehouseUsersRoles", async (values) => {
	const responseData = await handleRequest({
		endPoint: "/api/warehouse/userWarehouseGroupPermissions ",
		method: "PATCH",
		body: values,
	})
	return responseData
})

/**
 * Set user warehouse access
 */
export const setUserWarehouseAccess = createAsyncThunk("warehouseSettings/setUserWarehouseAccess", async (data) => {
	const responseData = await handleRequest({
		endPoint: "/api/warehouse/accessToWarehouses",
		method: "PATCH",
		body: { user_id: data?.userId, warehouse_id: data?.warehouseId, has_access: data?.access },
	})
	return responseData
})
