import { ChevronDownIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react"
import { memo, useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

const LanguageSelect = ({ options, handleChangeLang, id, isDisabled }) => {
	const { t } = useTranslation("global")
	const optionsRef = useRef(null)
	const selectRef = useRef(null)
	const [selectedOption, setSelectedOption] = useState(0)

	const handleOpenSelect = () => {
		if (options.length === 0) return
		optionsRef.current.style.display = optionsRef.current.style.display === "flex" ? "none" : "flex"
	}

	const handleSelect = (index, lang) => {
		if (selectedOption === index) return
		setSelectedOption(index)
		handleChangeLang(lang, id)
	}

	const getOptionBox = (option) => {
		return (
			<Flex alignItems='center' w='100%' flexWrap={["wrap", "nowrap"]}>
				<Image
					boxSize={["15px", "17px", "20px"]}
					alt='flag'
					src={require(
						`../../../../../assets/icons/shop/${
							option === "pl" ? "PolandFlag" : option === "en" ? "EnglandFlag" : "NetherlandFlag"
						}.webp`,
					)}
				/>
				<Text whiteSpace='pre-wrap' fontSize={[12, 13, 14, 15, 16]} ms={2}>
					{t(
						`Order.${
							option === "pl"
								? "polishLanguage"
								: option === "en"
									? "englishLanguage"
									: "netherlandLanguage"
						}`,
					)}
				</Text>
			</Flex>
		)
	}

	const getOptions = options.map((option, index) => (
		<Button
			key={index}
			display='flex'
			p={0}
			justifyContent='flex-start'
			w='100%'
			h='45px'
			position='relative'
			zIndex={2}
			bgColor={index === selectedOption ? "#F2F2EF" : "#FFF"}
			_hover={{ backgroundColor: "#F2F2EF" }}
			onClick={() => handleSelect(index, option)}
		>
			<Box my={2} mx={3}>
				{getOptionBox(option)}
			</Box>
		</Button>
	))

	const handleOutsideClick = useCallback((event) => {
		if (selectRef.current && !selectRef.current.contains(event.target)) {
			optionsRef.current.style.display = "none"
		}
	}, [])

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick)
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick)
		}
	}, [handleOutsideClick])

	return (
		<Box
			display='flex'
			ref={selectRef}
			w='100%'
			py={["4px", "5px", "8px"]}
			bgColor={"#FFF"}
			_hover={!isDisabled && { backgroundColor: "#F9F9F9" }}
			border='1px solid var(--gray-200, #E2E8F0)'
			boxShadow={isDisabled && "0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)"}
			borderRadius='6px'
			position='relative'
			cursor={!isDisabled && "pointer"}
			onClick={() => !isDisabled && handleOpenSelect()}
		>
			<Flex
				justifyContent={["end", "space-between"]}
				alignItems='center'
				flexWrap={["wrap", "nowrap"]}
				w='100%'
				px={[0, 1, 3]}
			>
				{getOptionBox(options[selectedOption])}
				<ChevronDownIcon display={isDisabled && "none"} boxSize={5} />
			</Flex>
			<Box
				display='none'
				ref={optionsRef}
				bgColor='#FFF'
				borderRadius='6px'
				position='absolute'
				border='1px solid #D4D4CD'
				top={"100%"}
				w='100%'
				h={options.length > 5 ? "250px" : "auto"}
				overflow={"auto"}
			>
				<Box w='100%'>{getOptions}</Box>
			</Box>
		</Box>
	)
}

export default memo(LanguageSelect)
