import i18next from "i18next"
import request from "../request/request"

/* 
/ Request api function
*/
export const handleRequest = async ({ endPoint, method = "GET", body = null, config = {} }) => {
	// eslint-disable-next-line no-useless-catch
	try {
		let response

		config.headers = { ...config.headers, Language: i18next.language }

		const requestOptions = {
			method,
			...config,
		}

		if (method !== "GET" && method !== "DELETE" && body) {
			requestOptions.data = body
		}

		response = await request()({
			url: endPoint,
			...requestOptions,
		})

		const responseData = response.data?.data || response.data
		return responseData
	} catch (error) {
		throw error
	}
}
