import { createSlice, isAnyOf } from "@reduxjs/toolkit"

import {
	addCustomOrder,
	addOrder,
	changeIsPacked,
	changeOrderCount,
	changeOrderPackedCount,
	changeOrderPosition,
	changePackedAll,
	copyFullOrder,
	createNewOrder,
	getMaterials,
	getMaterialsGroups,
	getNewOrderData,
	getOrder,
	removeOrder,
	saveComment,
	updateOrderStatus,
} from "../../../../actions/modules/orders/shop/shop-actions"

/**
 * Slice for shop
 */
const shopSlice = createSlice({
	name: "shop",
	initialState: {
		newOrderData: null,
		currentOrder: null,
		materialsGroups: null,
		materials: null,
	},
	reducers: {
		clearNewOrderData(state) {
			state.newOrderData = null
		},
		clearCurrentOrder(state) {
			state.currentOrder = null
		},
		clearMaterialsGroups(state) {
			state.materialsGroups = null
		},
		clearMaterials(state) {
			state.materials = null
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMaterialsGroups.fulfilled, (state, action) => {
				state.materialsGroups = action.payload.group
			})
			.addCase(getMaterials.fulfilled, (state, action) => {
				state.materials = action.payload.materials
			})
			.addCase(getNewOrderData.fulfilled, (state, action) => {
				state.newOrderData = action.payload.order
			})
			.addCase(createNewOrder.fulfilled, (state, action) => {
				action.meta.arg.navigate(`${action.meta.arg.path}/${action.payload.order}`)
			})
			.addCase(updateOrderStatus.fulfilled, (state, action) => {
				const product = state.currentOrder
				product.status = action.payload.status
				product.order_history = action.payload.history
				product.number = action.payload.order_number
				state.currentOrder = product
			})
			.addCase(removeOrder.fulfilled, (state, action) => {
				const product = state.currentOrder
				product.order_rows = product.order_rows.filter((row) => row.id !== action.meta.arg)
				if (product.order_rows.length === 0) {
					product.status = 10
					product.order_history = action.payload.history
				}
				state.currentOrder = product
			})
			.addCase(changeOrderCount.fulfilled, (state, action) => {
				const products = state.currentOrder.order_rows.map((row) => {
					if (row.id === action.meta.arg.id) {
						row = action.payload.order_row
					}
					return row
				})
				state.currentOrder.order_rows = products
			})
			.addCase(changeOrderPackedCount.fulfilled, (state, action) => {
				const product = state.currentOrder
				product.order_rows.map((row) => {
					if (row.id === action.meta.arg.id) {
						row.count_packed = action.meta.arg.count
					}
					return row
				})
				state.currentOrder = product
			})
			.addCase(changeOrderPackedCount.rejected, (state, action) => {
				action.meta.arg.setOrders(action.meta.arg.originalArray)
			})
			.addCase(saveComment.fulfilled, (state, action) => {
				const products = state.currentOrder.order_rows
				products.map((row) => {
					if (row.id === action.meta.arg.id) {
						row.comment = action.meta.arg.comment
					}
					return row
				})
				state.currentOrder.order_rows = products
			})
			.addCase(changeIsPacked.fulfilled, (state, action) => {
				const products = state.currentOrder.order_rows
				products.map((row) => {
					if (row.id === action.meta.arg.id) {
						row.is_packed = row.is_packed === 0 ? 1 : 0
					}
					return row
				})
				state.currentOrder.order_rows = products
			})
			.addCase(changePackedAll.fulfilled, (state, action) => {
				const products = state.currentOrder.order_rows?.map((row) => ({
					...row,
					is_packed: action.meta.arg.isPacked,
				}))
				state.currentOrder.order_rows = products
			})
			.addCase(changeOrderPosition.fulfilled, (state, action) => {
				state.currentOrder.order_rows = action.payload.order_rows
			})
			.addMatcher(isAnyOf(addOrder.fulfilled, addCustomOrder.fulfilled), (state, action) => {
				const product = state.currentOrder
				if (product.order_rows.some((row) => row.id === action.payload.order_row.id)) {
					product.order_rows.forEach((row) => {
						if (row.id === action.payload.order_row.id) {
							row.count = action.payload.order_row.count
							row.count_packed = action.payload.order_row.count_packed
						}
					})
				} else {
					product.order_rows.push(action.payload.order_row)
					state.currentOrder = product
				}
			})
			.addMatcher(isAnyOf(copyFullOrder.fulfilled, getOrder.fulfilled), (state, action) => {
				state.currentOrder = action.payload.order

				action.meta.arg.navigate && action.meta.arg.navigate(`../${action.payload.order.id}`)
			})
	},
})

export const { clearNewOrderData, clearCurrentOrder, clearMaterialsGroups, clearMaterials } = shopSlice.actions

export default shopSlice
