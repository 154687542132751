import { Flex } from "@chakra-ui/react"
import { memo } from "react"

import GroupExcelButton from "./GroupExcelButton"
import GroupCopyButton from "./GroupCopyButton"
import GroupPDFButton from "./GroupPDFButton"
import GroupPrintButton from "./GroupPrintButton"

const GroupWarehouseActionButtons = ({ data }) => {
	return (
		<Flex gap={2} w={["100%", "auto"]} flexWrap={["wrap", "wrap", "nowrap"]} justifyContent={"end"}>
			<GroupExcelButton jsonData={data} fileName={"example"} />
			<GroupPDFButton data={data} />
			<GroupCopyButton data={data} />
			<GroupPrintButton data={data} />
		</Flex>
	)
}

export default memo(GroupWarehouseActionButtons)
