import { Box, GridItem, Image, Text } from "@chakra-ui/react"
import { memo, useCallback } from "react"

import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"

const Materials = ({ materials, isDisabled, setCreateOrderState, setParametersId, setShowedItems, setSearchValue }) => {
	const handleSelectMaterial = useCallback(
		(material) => {
			if (isDisabled) return
			if (material.parameters.length > 0) {
				if (material.parameters.every((parameter) => parameter.count === 0)) {
					setCreateOrderState(3)
				} else {
					const firstParameterId = material.parameters.findIndex((parameter) => parameter.count > 0)
					setParametersId(firstParameterId)
					setCreateOrderState(2)
				}
			} else {
				setCreateOrderState(3)
			}
			material.quantity = 1
			material.price = 0
			setShowedItems((prevState) => [...prevState, material])
			setSearchValue("")
		},
		[isDisabled],
	)

	return materials?.map((material) => (
		<GridItem
			key={material.id}
			minH={["140px", "190px", "200px", "215px", "230px"]}
			minW={["70px", "140px", "140px", "150px", "190px"]}
			bgColor='#FFF'
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
			cursor={isDisabled ? "not-allowed" : "pointer"}
			position='relative'
			overflow='hidden'
			display='flex'
			flexDirection='column'
			py={2}
			onClick={() => handleSelectMaterial(material)}
		>
			<Box position='relative' flex='1' maxH={"100%"} maxW={"100%"} mx={2}>
				{material.image === null ? (
					<LackImageIcon
						position='absolute'
						top={0}
						left={0}
						display='flex'
						h='100%'
						w='100%'
						objectFit='contain'
						alt='Lack of material image'
					/>
				) : (
					<Image
						position='absolute'
						top={0}
						left={0}
						display='flex'
						h='100%'
						w='100%'
						objectFit='contain'
						alt='Material image'
						src={material.image}
					/>
				)}
			</Box>
			<Text
				position='relative'
				px={1}
				zIndex={1}
				flexShrink={0}
				mt='auto'
				w='auto'
				h='auto'
				textAlign='center'
				fontSize={[13, 14, 15, 16]}
				fontWeight={600}
			>
				{material.name}
			</Text>
		</GridItem>
	))
}

export default memo(Materials)
