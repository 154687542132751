import { Box, Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react"
import { memo, useCallback } from "react"
import { useTranslation } from "react-i18next"

const Parameters = ({
	createOrderState,
	showedItems,
	parametersId,
	selectedParametersCounter,
	isDisabled,
	showedItemsCurrentId,
	setParametersId,
	setShowedItemsCurrentId,
	setSelectedParametersCounter,
	setCreateOrderState,
	setShowedItems,
}) => {
	const { t } = useTranslation("global")

	const handleSelectParameter = useCallback(
		(value, count) => {
			if (isDisabled) return
			const items = showedItems
			if (items[showedItemsCurrentId] === undefined) {
				const item = [
					{
						id: value.id,
						name: value.name,
						parameter_id: value.parameter_id,
						parameterName: showedItems[1].parameters[parametersId].name,
						parameter_material_id: showedItems[1].parameters[parametersId].parameter_material_id,
						counter: 1,
					},
				]
				items[showedItemsCurrentId] = item
			} else {
				const item = {
					id: value.id,
					name: value.name,
					parameter_id: value.parameter_id,
					parameterName: showedItems[1].parameters[parametersId].name,
					parameter_material_id: showedItems[1].parameters[parametersId].parameter_material_id,
					counter: selectedParametersCounter + 1,
				}
				items[showedItemsCurrentId].push(item)
			}
			if (count === selectedParametersCounter + 1) {
				setParametersId((prevstate) => prevstate + 1)
				setShowedItemsCurrentId((prevstate) => prevstate + 1)
				setSelectedParametersCounter(0)
				if (
					items[1].parameters[parametersId + 1] === undefined ||
					items[1].parameters[parametersId + 1].count === 0
				) {
					setCreateOrderState(3)
				}
			} else {
				setSelectedParametersCounter((prevstate) => prevstate + 1)
			}

			setShowedItems(() => [...items])
		},
		[isDisabled, showedItems, showedItemsCurrentId, selectedParametersCounter, parametersId],
	)

	return (
		createOrderState === 2 &&
		showedItems[1].parameters[parametersId] &&
		showedItems[1].parameters[parametersId].count > selectedParametersCounter && (
			<>
				<Heading fontSize={[14, 15, 16, 17]} mt={[7, 8, 9, 10]} mb={[3, 3, 4, 5]}>
					{t("Order.select")} {showedItems[1].parameters[parametersId].name}
				</Heading>
				<Grid w={["180px", "200px", "260px"]} gap={4} mb={[4, 5, 5]}>
					{showedItems[1].parameters[parametersId].values.map((value) => (
						<GridItem
							key={value.id}
							maxW='260px'
							minH='50px'
							bgColor='#FFF'
							borderRadius='4px'
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
							cursor={isDisabled ? "not-allowed" : "pointer"}
							onClick={() => handleSelectParameter(value, showedItems[1].parameters[parametersId].count)}
						>
							<Box my={3}>
								<Flex justifyContent='center' alignItems='center'>
									<Text
										fontSize={[13, 14, 15, 16]}
										maxW={["120px", "140px", "200px"]}
										fontWeight={600}
									>
										{value.name}
									</Text>
								</Flex>
							</Box>
						</GridItem>
					))}
				</Grid>
			</>
		)
	)
}

export default memo(Parameters)
