import { memo } from "react"
import hasPermissions from "../../utils/permissions/hasPermissions"

const PermissionsChecked = ({ permissions, allowedPermissions, children, checkingRequired }) => {
	return checkingRequired || checkingRequired === undefined
		? hasPermissions(permissions, allowedPermissions) && children
		: children
}

export default memo(PermissionsChecked)
