import { Box, Button, Flex, Grid, GridItem, Image, Input, Text } from "@chakra-ui/react"
import { FastField, FieldArray, useFormikContext } from "formik"
import { memo, useRef } from "react"
import LanguageInputs from "../../../../common/LanguageInputs"
import TextFieldWithDebounce from "../../../../common/TextFieldWithDebounce"
import AddImageIcon from "../../../../../assets/icons/materials/AddImageIcon"
import BinIcon from "../../../../../assets/icons/materials/BinIcon"
import { AddIcon, CopyIcon, EditIcon } from "@chakra-ui/icons"
import { useTranslation } from "react-i18next"
import { warningToast } from "../../../../../utils/notifications/warningToast"
import imageCompression from "browser-image-compression"

const NewParameterPanel = ({ handleDragImage, getObjectURLFromImg, language }) => {
	const { t } = useTranslation("global")
	const { values, isSubmitting, setFieldValue } = useFormikContext()
	const newParameterIconRef = useRef(null)
	const addParameterRef = useRef(null)

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault()
			addParameterRef.current.click()
		}
	}

	const handleAddNewParameterIcon = () => {
		newParameterIconRef.current.click()
	}

	const handleUploadNewParameterIcon = async (e) => {
		if (e.target.files.length === 0) return

		const fileSizeInMB = (e.target.files[0].size / (1024 * 1024)).toFixed(0)
		if (fileSizeInMB >= 100) {
			warningToast(t("Materials.tooBigFile"))
			newParameterIconRef.current.value = null
		} else {
			const options = {
				maxSizeMB: 100,
				maxWidthOrHeight: 800,
				useWebWorker: true,
			}
			try {
				const compressedFile = await imageCompression(e.target.files[0], options)
				setFieldValue(
					"newParameter.icon",
					new File([compressedFile], e.target.files[0].name, {
						type: "image/webp",
					}),
				)
				newParameterIconRef.current.value = null
			} catch (error) {
				console.log(error)
			}
		}
	}

	const handleRemoveNewParameterIcon = () => {
		newParameterIconRef.current.value = null
		setFieldValue(`newParameter.icon`, null)
	}

	const handleDropNewParameterIcon = async (e) => {
		e.preventDefault()
		if (e.dataTransfer.files.length === 0) return

		const fileSizeInMB = (e.dataTransfer.files[0].size / (1024 * 1024)).toFixed(0)
		if (fileSizeInMB >= 100) {
			warningToast(t("Materials.tooBigFile"))
			newParameterIconRef.current.value = null
		} else {
			const fileName = e.dataTransfer.files[0].name
			const options = {
				maxSizeMB: 100,
				maxWidthOrHeight: 1200,
				useWebWorker: true,
			}
			try {
				const compressedFile = await imageCompression(e.dataTransfer.files[0], options)
				setFieldValue("newParameter.icon", new File([compressedFile], fileName, { type: "image/webp" }))
				newParameterIconRef.current.value = null
			} catch (error) {
				console.error(error)
			}
		}
	}

	const parameterValuesRows = (
		<FieldArray name={`newParameter.values`}>
			{({ remove, push, form }) => (
				<>
					{form.values.newParameter.values.map((value, index) => (
						<Grid
							key={index}
							templateColumns={["repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(2, 1fr)"]}
							w='100%'
							bgColor='#F2F2EF'
							mb={3}
							p={3}
							pb={1}
							borderRadius='4px'
						>
							<FastField name={`newParameter.values[${index}].name_${language}`}>
								{() => (
									<GridItem colSpan={[2, 2, 1]}>
										<Box display='block' alignItems='center'>
											<Flex gap={2} alignItems='center'>
												<Box w={["100%", "100%", "80%", "50%", "35%"]}>
													<TextFieldWithDebounce
														data-testid={`newParameterValue[${index}]`}
														disabled={isSubmitting}
														h={["30px", "35px", "40px"]}
														fontSize={[13, 14, 15, 16]}
														onKeyPress={handleKeyDown}
														maxLength={191}
														bgColor='#FFF'
														placeholder={t("Materials.parameterValue", {
															lng: language,
														})}
														name={`newParameter.values[${index}].name_${language}`}
													/>
												</Box>
												<LanguageInputs
													name={`newParameter.values[${index}].name`}
													maxLength={191}
													title={t("Materials.parameterValue", {
														lng: language,
													})}
												/>
											</Flex>
										</Box>
									</GridItem>
								)}
							</FastField>
							<GridItem>
								<Box display='flex' w='100%' justifyContent='end'>
									<Button
										aria-label='Copy parameter value button'
										isDisabled={isSubmitting}
										bgColor='transparent'
										minW='30px'
										boxSize={["30px", "35px", "40px"]}
										p={0}
										onClick={() =>
											push({
												name_pl: value.name_pl,
												name_en: value.name_en,
												name_nl: value.name_nl,
											})
										}
									>
										<CopyIcon boxSize={[3, 4, 5]} />
									</Button>
									<Button
										aria-label='Remove parameter value button'
										isDisabled={isSubmitting}
										bgColor='transparent'
										minW='30px'
										boxSize={["30px", "35px", "40px"]}
										p={0}
										onClick={() => remove(index)}
									>
										<BinIcon color='red.500' boxSize={[4, 5, 6]} />
									</Button>
								</Box>
							</GridItem>
						</Grid>
					))}
					<Button
						isDisabled={isSubmitting}
						bgColor='#2B807E'
						_hover={{ backgroundColor: "teal.700" }}
						h={["30px", "35px", "40px"]}
						fontSize={[13, 14, 15, 16]}
						colorScheme='teal'
						color='#FFF'
						my={5}
						onClick={() => push({ name_pl: "", name_en: "", name_nl: "" })}
					>
						<AddIcon boxSize={3} me={2} />
						<Text aria-label='Add new parameter value'>{t("Materials.addNew", { lng: language })}</Text>
					</Button>
				</>
			)}
		</FieldArray>
	)

	return (
		<FastField key={language} name='newParameter'>
			{() => (
				<Grid>
					<GridItem>
						<Box display='flex' mx='auto' alignItems='center'>
							<FastField name='newParameter.icon'>
								{() => (
									<>
										{values.newParameter.icon ? (
											<Box
												mb={3}
												ms={3}
												display='block'
												w={["90px", "110px", "120px", "130px"]}
												h={["100px", "120px", "120px", "135px"]}
												border='1px solid #F2F2EF'
												borderRadius='8px'
												p={2}
												onDragOver={handleDragImage}
												onDrop={handleDropNewParameterIcon}
											>
												<Box w={{ base: "100%" }} h={{ base: "65%" }}>
													<Image
														alt='New parameter icon'
														mx='auto'
														w='100%'
														h='100%'
														objectFit={"contain"}
														src={getObjectURLFromImg(values.newParameter.icon)}
													/>
												</Box>
												<Flex justifyContent={"center"} mt={2}>
													<Button
														aria-label='Edit new parameter icon'
														isDisabled={isSubmitting}
														me={2}
														bgColor='#F2F2EF'
														minW='25px'
														boxSize={["25px", "30px", "32px", "35px"]}
														p={0}
														onClick={handleAddNewParameterIcon}
													>
														<EditIcon boxSize={[3, 4, 4, 5]} />
													</Button>
													<Button
														aria-label='Remove new parameter icon'
														isDisabled={isSubmitting}
														bgColor='#F2F2EF'
														minW='25px'
														boxSize={["25px", "30px", "32px", "35px"]}
														p={0}
														onClick={handleRemoveNewParameterIcon}
													>
														<BinIcon boxSize={[4, 5, 5, 6]} color='red.500' />
													</Button>
												</Flex>
											</Box>
										) : (
											<Box
												mb={3}
												ms={[1, 2, 3]}
												display='flex'
												maxW='180px'
												alignItems='center'
												border='1px solid #F2F2EF'
												borderRadius='8px'
												p={2}
												onDragOver={handleDragImage}
												onDrop={handleDropNewParameterIcon}
											>
												<Text fontSize={[13, 14, 15, 16]} me={4}>
													{t("Materials.addIcon", { lng: language })}
												</Text>
												<Button
													aria-label='Add new parameter button'
													isDisabled={isSubmitting}
													bgColor='#F2F2EF'
													minW='30px'
													boxSize={["30px", "35px", "40px"]}
													mx='auto'
													p={0}
													onClick={handleAddNewParameterIcon}
												>
													<AddImageIcon boxSize={[4, 5, 6]} />
												</Button>
											</Box>
										)}
										<Input
											display='none'
											name='newParameter.icon'
											accept='image/*'
											type='file'
											ref={newParameterIconRef}
											onChange={handleUploadNewParameterIcon}
										/>
									</>
								)}
							</FastField>
							<FastField name={`newParameter.name_${language}`}>
								{() => (
									<Flex w='100%' gap={2}>
										<Box w={["100%", "100%", "60%", "40%", "20%"]} ms={[2, 3, 8]}>
											<TextFieldWithDebounce
												data-testid={"newParameterName"}
												disabled={isSubmitting}
												h={["30px", "35px", "40px"]}
												fontSize={[13, 14, 15, 16]}
												onKeyPress={handleKeyDown}
												maxLength={191}
												w='100%'
												name={`newParameter.name_${language}`}
												placeholder={t("Materials.nameOfTheNewParameter", {
													lng: language,
												})}
											/>
										</Box>
										<LanguageInputs
											name={`newParameter.name`}
											maxLength={191}
											title={t("Materials.nameOfTheNewParameter", {
												lng: language,
											})}
										/>
									</Flex>
								)}
							</FastField>
						</Box>
						<FastField name={`newParameter.values`}>{() => parameterValuesRows}</FastField>
					</GridItem>
					<GridItem justifySelf='end' alignSelf='end'>
						<Button
							data-testid={"addParameterBtn"}
							aria-label='Add parameter button'
							isDisabled={isSubmitting}
							h={["30px", "35px", "40px"]}
							fontSize={[13, 14, 15, 16]}
							ref={addParameterRef}
							colorScheme='green'
							name='addParameterButton'
							color='green.500'
							variant='outline'
						>
							{t("Materials.add", { lng: language })}
						</Button>
					</GridItem>
				</Grid>
			)}
		</FastField>
	)
}

export default memo(NewParameterPanel)
