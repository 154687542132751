import { Flex, Text } from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

const SummaryBar = ({ totalRecords, ...props }) => {
	const { t } = useTranslation("global")
	const [totalValue, setTotalValue] = useState(0)

	useEffect(() => {
		if (!totalRecords) return

		setTotalValue(getTotalValue)
	}, [totalRecords])
	const getTotalValue = useMemo(
		() =>
			totalRecords?.reduce(
				(prevValue, currentValue) =>
					(prevValue += currentValue?.materials.reduce((pv, cv) => (pv += cv.value), 0)),
				0,
			),
		[totalRecords],
	)

	return (
		<Flex
			alignItems={"center"}
			flexWrap={"wrap"}
			gap={["15%", "20%"]}
			w={"100%"}
			fontSize={{ base: "12px", md: "13px", lg: "14px" }}
			fontWeight={600}
			px={5}
			py={2}
			bgColor={"#F2F2EF"}
			borderRadius={4}
			minH={["60px", "40px"]}
			boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
			color='teal.700'
			{...props}
		>
			<Text>{t("Warehouses.summaryWarehouse")}</Text>
			<Text>
				{t("Warehouses.value")}: {totalValue}
			</Text>
		</Flex>
	)
}

export default SummaryBar
