import { createSlice } from "@reduxjs/toolkit"
import { getOrdersHistory } from "../../../../actions/modules/orders/history/history-actions"

/**
 * Slice for history
 */
const historySlice = createSlice({
	name: "history",
	initialState: {
		ordersHistory: null,
		initialValues: null,
	},
	reducers: {
		clearOrdersHistory(state) {
			state.ordersHistory = null
			state.initialValues = null
		},
		sortHistory(state, action) {
			state.ordersHistory = action.payload.ordersHistory
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getOrdersHistory.fulfilled, (state, action) => {
			state.ordersHistory = action.payload.orders
			if (!action.payload.projects && !action.payload.created_by) return
			state.initialValues = {
				projects: [...action.payload.projects],
				created_by: [...action.payload.created_by],
			}
		})
	},
})

export const { clearOrdersHistory, sortHistory } = historySlice.actions

export default historySlice
