import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Heading,
	Image,
	Input,
	InputGroup,
	InputRightElement,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Text,
	Textarea,
	useMediaQuery,
} from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import React, { createRef, memo, useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useMemo } from "react"
import i18next from "i18next"
import { AddIcon, SearchIcon } from "@chakra-ui/icons"

import BsSortDown from "../../../../../assets/icons/permissions/BsSortDown"
import BsSortUp from "../../../../../assets/icons/permissions/BsSortUp"
import {
	addCustomOrder,
	addOrder,
	getMaterials,
	getMaterialsGroups,
} from "../../../../../actions/modules/orders/shop/shop-actions"
import { clearMaterials, clearMaterialsGroups } from "../../../../../store/modules/orders/shop/shop-slice"
import BackIcon from "../../../../../assets/icons/shop/BackIcon"
import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"
import LanguageSelect from "../LanguageSelect/LanguageSelect"
import LanguageIcon from "../../../../../assets/icons/languageInputs/LanguageIcon"
import LoadingHOC from "../../../../common/LoadingHOC"

const OrderMaterialsGroups = ({ setAccordionRef, isDisabled, setDisabledForm, orderId, setIsOrdersGetted }) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const selectedItemsRef = useRef(null)
	const [isSortDesc, setIsSortDesc] = useState(false)
	const [createOrderState, setCreateOrderState] = useState(0)
	const [showedItems, setShowedItems] = useState(null)
	const [showedItemsCurrentId, setShowedItemsCurrentId] = useState(2)
	const [parametersId, setParametersId] = useState(0)
	const [orderCount, setOrderCount] = useState(1)
	const [searchValue, setSearchValue] = useState("")
	const [selectedParametersCounter, setSelectedParametersCounter] = useState(0)
	const [materialsGroupsResources, setMaterialsGroupsResources] = useState(null)
	const [resources, setResources] = useState(null)
	const [availableMaterialsCopy, setAvailableMaterialsCopy] = useState(null)
	const [isCustomProduct, setIsCustomProduct] = useState(false)
	const [isLargerThan1832] = useMediaQuery("(min-width: 1832px)")
	const materialsGroups = useSelector((state) => state.shop.materialsGroups)
	const availableMaterials = useSelector((state) => state.shop.materials)

	const resetOrder = () => {
		setShowedItems(null)
		setCreateOrderState(0)
		setParametersId(0)
		setShowedItemsCurrentId(2)
		setSelectedParametersCounter(0)
		setOrderCount(1)
		setSearchValue("")
	}

	useEffect(() => {
		dispatch(getMaterialsGroups())
		resetOrder()

		return () => {
			dispatch(clearMaterialsGroups())
			dispatch(clearMaterials())
		}
	}, [dispatch, i18next.language])

	useEffect(() => {
		if (!materialsGroups && materialsGroupsResources === materialsGroups) return
		setMaterialsGroupsResources(materialsGroups)
	}, [materialsGroups])

	useEffect(() => {
		if (availableMaterials) {
			const copiedMaterials = availableMaterials.map((material) => {
				const copiedMaterial = { ...material }
				copiedMaterial.parameters = material.parameters.map((parameter) => {
					const copiedParameter = { ...parameter }
					copiedParameter.values = parameter.values.map((value) => {
						const copiedValue = { ...value }
						return copiedValue
					})
					return copiedParameter
				})
				return copiedMaterial
			})
			setResources(copiedMaterials)
			setAvailableMaterialsCopy(copiedMaterials)
		}
	}, [availableMaterials, i18next.language])

	useEffect(() => {
		if (selectedItemsRef.current) {
			selectedItemsRef.current.scrollTop = Number(selectedItemsRef.current.scrollHeight)
		}
	}, [parametersId, showedItems, showedItemsCurrentId, selectedParametersCounter])

	const handleSelectMaterialGroup = useCallback(
		(material) => {
			if (isDisabled) return
			const materialGroup = {
				id: material.id,
				image: material.image,
				name: material.name,
				order_id: Number(orderId),
			}
			setShowedItems([materialGroup])
			setCreateOrderState(1)
			setSearchValue("")
			setResources([])
			dispatch(getMaterials(material.id))
		},
		[dispatch, isDisabled, orderId],
	)

	const handleSelectMaterial = useCallback(
		(material) => {
			if (isDisabled) return
			if (material.parameters.length > 0) {
				if (material.parameters.every((parameter) => parameter.count === 0)) {
					setCreateOrderState(3)
				} else {
					const firstParameterId = material.parameters.findIndex((parameter) => parameter.count > 0)
					setParametersId(firstParameterId)
					setCreateOrderState(2)
				}
			} else {
				setCreateOrderState(3)
			}
			material.count = 1
			setShowedItems((prevState) => [...prevState, material])
			setSearchValue("")
		},
		[isDisabled],
	)

	const handleBackButton = useCallback(() => {
		const items = showedItems

		switch (createOrderState) {
			// State 1
			case 1:
				items.pop()
				setMaterialsGroupsResources(materialsGroups)
				setCreateOrderState(0)
				setSearchValue("")
				dispatch(clearMaterials())
				setParametersId(0)
				break
			// State 2
			case 2:
				if (items.length > 2) {
					if (selectedParametersCounter === 0) {
						if (items[items.length - 1].length > 1) {
							items[items.length - 1].pop()
							setParametersId((prevstate) => prevstate - 1)
							setSelectedParametersCounter(items[items.length - 1].length)
							setShowedItemsCurrentId((prevstate) => prevstate - 1)
						} else {
							items.pop()
							setParametersId((prevstate) => prevstate - 1)
							setShowedItemsCurrentId((prevstate) => prevstate - 1)
						}
					} else {
						if (items[items.length - 1].length > 1) {
							items[items.length - 1].pop()
							setSelectedParametersCounter((prevstate) => prevstate - 1)
						} else {
							items.pop()
							setSelectedParametersCounter(0)
						}
					}
				} else {
					items.pop()
					setSearchValue("")
					setCreateOrderState(1)
					setResources(availableMaterialsCopy)
				}
				break
			// State 3
			case 3:
				if (isCustomProduct) {
					items.pop()
					if (!items.length) {
						setIsCustomProduct(false)
						setCreateOrderState(0)
					}
				} else {
					if (items.length > 2) {
						if (items[items.length - 1].length > 1) {
							items[items.length - 1].pop()
							setSelectedParametersCounter(items[items.length - 1].length)
							setShowedItemsCurrentId((prevstate) => prevstate - 1)
							setParametersId((prevstate) => prevstate - 1)
							setCreateOrderState(2)
						} else {
							items.pop()
							setShowedItemsCurrentId((prevState) => prevState - 1)
							setParametersId((prevstate) => prevstate - 1)
							setCreateOrderState(2)
						}
					} else {
						items.pop()
						setCreateOrderState(1)
						setSearchValue("")
					}
					setOrderCount(1)
				}
				break
			default:
				return
		}
		setShowedItems(() => [...items])
	}, [
		showedItems,
		createOrderState,
		materialsGroups,
		dispatch,
		isCustomProduct,
		selectedParametersCounter,
		availableMaterialsCopy,
	])

	const handleSelectParameter = useCallback(
		(value, count) => {
			if (isDisabled) return
			const items = showedItems
			if (items[showedItemsCurrentId] === undefined) {
				const item = [
					{
						id: value.id,
						name: value.name,
						parameter_id: value.parameter_id,
						parameterName: showedItems[1].parameters[parametersId].name,
						parameter_material_id: showedItems[1].parameters[parametersId].parameter_material_id,
						counter: 1,
					},
				]
				items[showedItemsCurrentId] = item
			} else {
				const item = {
					id: value.id,
					name: value.name,
					parameter_id: value.parameter_id,
					parameterName: showedItems[1].parameters[parametersId].name,
					parameter_material_id: showedItems[1].parameters[parametersId].parameter_material_id,
					counter: selectedParametersCounter + 1,
				}
				items[showedItemsCurrentId].push(item)
			}
			if (count === selectedParametersCounter + 1) {
				setParametersId((prevstate) => prevstate + 1)
				setShowedItemsCurrentId((prevstate) => prevstate + 1)
				setSelectedParametersCounter(0)
				if (
					items[1].parameters[parametersId + 1] === undefined ||
					items[1].parameters[parametersId + 1].count === 0
				) {
					setCreateOrderState(3)
				}
			} else {
				setSelectedParametersCounter((prevstate) => prevstate + 1)
			}

			setShowedItems(() => [...items])
		},
		[isDisabled, showedItems, showedItemsCurrentId, selectedParametersCounter, parametersId],
	)

	const handleCreateCustomProduct = useCallback(() => {
		if (isDisabled) return
		setIsCustomProduct(true)
		setCreateOrderState(3)
		setShowedItems([{ lang: i18next.language, order_id: Number(orderId), count: 1 }])
	}, [isDisabled, orderId])

	const handleChangeCount = (value) => {
		const items = showedItems
		isCustomProduct ? (items[0].count = value) : (items[1].count = value)

		setShowedItems(items)
		setOrderCount(value)
	}

	const handleOrder = useCallback(() => {
		setDisabledForm(true)
		let items = showedItems
		if (isCustomProduct) {
			const count = items[0].count
			if (items[1]) {
				items[1].lang = items[1].selectedLang
				delete items[1].selectedLang
			}
			items = items.filter((item) => item.description)
			items[0].count = count
			items[0].order_id = Number(orderId)
			setIsCustomProduct(false)
		}
		setAccordionRef((prevstate) => {
			if (prevstate === null) return [createRef()]
			else {
				prevstate.push(createRef())
				return prevstate
			}
		})
		setIsOrdersGetted(false)
		resetOrder()
		setMaterialsGroupsResources(materialsGroups)
		isCustomProduct
			? dispatch(addCustomOrder(items)).then(() => setDisabledForm(false))
			: dispatch(addOrder(items)).then(() => setDisabledForm(false))
		dispatch(clearMaterials())
	}, [
		dispatch,
		isCustomProduct,
		materialsGroups,
		orderId,
		setAccordionRef,
		setDisabledForm,
		setIsOrdersGetted,
		showedItems,
	])

	const handleSearching = useCallback(
		(value) => {
			setSearchValue(value)
			if (createOrderState === 0) {
				if (value.length > 0) {
					setMaterialsGroupsResources(
						materialsGroups.filter((group) =>
							group.name.toString().toLowerCase().includes(value.toLowerCase()),
						),
					)
				} else {
					setMaterialsGroupsResources(materialsGroups)
				}
			}
			if (createOrderState === 1) {
				if (value.length > 0) {
					setResources(
						availableMaterialsCopy.filter((resource) =>
							resource.name.toLowerCase().includes(value.toLowerCase()),
						),
					)
				} else {
					setResources(availableMaterialsCopy)
				}
			}
		},
		[createOrderState, materialsGroups, availableMaterialsCopy],
	)

	const handleSort = useCallback(() => {
		setIsSortDesc((prevstate) => !prevstate)
		if (createOrderState === 0) {
			const sortedMaterialsGroups = [...materialsGroupsResources]
			if (isSortDesc) {
				sortedMaterialsGroups.sort((a, b) => a.name.toString().localeCompare(b.name.toString()))
				setMaterialsGroupsResources(sortedMaterialsGroups)
			} else {
				sortedMaterialsGroups.sort((a, b) => b.name.toString().localeCompare(a.name.toString()))

				setMaterialsGroupsResources(sortedMaterialsGroups)
			}
		}
		if (createOrderState === 1) {
			const sortedMaterials = [...resources]
			if (isSortDesc) {
				sortedMaterials.sort((a, b) => a.name.toString().localeCompare(b.name.toString()))
				setResources(sortedMaterials)
			} else {
				sortedMaterials.sort((a, b) => b.name.toString().localeCompare(a.name.toString()))
				setResources(sortedMaterials)
			}
		}
	}, [createOrderState, materialsGroupsResources, isSortDesc, resources])

	const handleAddNewDescription = useCallback(() => {
		if (isDisabled) return
		const languages = i18next.options.store
		const items = showedItems
		let filteredLanguages = languages.filter((lang) => lang !== i18next.language).reverse()
		if (showedItems.length === 2) {
			filteredLanguages = filteredLanguages.filter((lang) => lang !== showedItems[1].selectedLang)[0]
			items.push({ lang: filteredLanguages })
		} else {
			items.push({
				lang: filteredLanguages,
				selectedLang: filteredLanguages[0],
			})
		}
		setShowedItems([...items])
	}, [isDisabled, showedItems])

	const handleChangeDescription = useCallback(
		(e, id) => {
			const items = showedItems
			items[id].description = e.target.value
			setShowedItems([...items])
		},
		[showedItems],
	)

	const handleChangeLang = useCallback(
		(lang, id) => {
			const items = showedItems
			items[id].selectedLang = lang
			setShowedItems([...items])
		},
		[showedItems],
	)

	const handleKeyDown = (event) => {
		if (event.key === "." || event.key === "-" || event.key === "+") {
			event.preventDefault()
		}
	}

	const getCurrentLang = useCallback(
		(lang, id) =>
			!Array.isArray(lang) ? (
				<Box
					display='flex'
					flexWrap='wrap'
					alignItems='center'
					border='1px solid var(--gray-200, #E2E8F0)'
					borderRadius='6px'
					boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
					p={["4px", "5px", "8px"]}
				>
					<Image
						me={2}
						alt='Flag image'
						boxSize={["15px", "17px", "20px"]}
						src={require(
							`../../../../../assets/icons/shop/${
								lang === "pl" ? "PolandFlag" : lang === "en" ? "EnglandFlag" : "NetherlandFlag"
							}.webp`,
						)}
					/>
					<Text fontSize={[12, 13, 14, 15, 16]}>
						{t(
							`Order.${
								lang === "pl"
									? "polishLanguage"
									: lang === "en"
										? "englishLanguage"
										: "netherlandLanguage"
							}`,
						)}
					</Text>
				</Box>
			) : (
				<LanguageSelect
					isDisabled={showedItems && showedItems.length === 3}
					id={id}
					handleChangeLang={handleChangeLang}
					options={lang}
				/>
			),
		[handleChangeLang, showedItems, t],
	)

	const groupsMaterials = useMemo(
		() => (
			<>
				{materialsGroupsResources?.map((material) => (
					<GridItem
						key={material.id}
						minH={["140px", "190px", "200px", "215px", "230px"]}
						minW={["70px", "140px", "140px", "150px", "190px"]}
						bgColor='#FFF'
						borderRadius='4px'
						boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
						cursor={isDisabled ? "not-allowed" : "pointer"}
						position='relative'
						overflow='hidden'
						display='flex'
						flexDirection='column'
						py={2}
						onClick={() => handleSelectMaterialGroup(material)}
					>
						<Box position='relative' flex='1' maxH={"100%"} maxW={"100%"}>
							{material.image === null ? (
								<LackImageIcon
									position='absolute'
									top={0}
									left={0}
									display='flex'
									h='100%'
									w='100%'
									objectFit='contain'
									alt='Lack of material group image'
								/>
							) : (
								<Image
									position='absolute'
									top={0}
									left={0}
									display='flex'
									h='100%'
									w='100%'
									objectFit='contain'
									alt='Material group image'
									src={material.image}
								/>
							)}
						</Box>
						<Text
							position='relative'
							px={1}
							zIndex={1}
							bgColor='rgba(255, 255, 255, 0.8)'
							flexShrink={0}
							mt='auto'
							w='auto'
							h='auto'
							textAlign='center'
							fontSize={[13, 14, 15, 16]}
							fontWeight={600}
						>
							{material.name}
						</Text>
					</GridItem>
				))}
				<GridItem>
					<Flex
						minH={["140px", "190px", "200px", "215px", "230px"]}
						minW={["70px", "140px", "140px", "150px", "190px"]}
						justifyContent='center'
						alignItems='center'
						bgColor='#FFF'
						borderRadius='4px'
						boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
						cursor={isDisabled ? "not-allowed" : "pointer"}
						onClick={handleCreateCustomProduct}
					>
						<Box textAlign='center'>
							<AddIcon
								textAlign='center'
								display='flex'
								justifyContent='center'
								mx='auto'
								boxSize={[6, 6, 7, 9, 10]}
								color='#2B807E'
							/>
							<Text
								fontSize={[11, 13, 14, 15, 16]}
								textAlign='center'
								fontWeight={600}
								my={2}
								color='#2B807E'
								textTransform='uppercase'
								justifyContent='center'
							>
								{t("Order.newProduct")}
							</Text>
							<Text fontSize={[11, 13, 14, 15, 16]} textAlign='center' w='75%' mx='auto'>
								{t("Order.emptyProduct")}
							</Text>
						</Box>
					</Flex>
				</GridItem>
			</>
		),
		[handleCreateCustomProduct, handleSelectMaterialGroup, isDisabled, materialsGroupsResources, t],
	)

	const getShowedItems = useMemo(
		() =>
			showedItems?.map((item, showedItemsId) => (
				<React.Fragment key={showedItemsId}>
					<GridItem
						ref={selectedItemsRef}
						maxH={showedItemsId > 1 && ["136px", "157px", "198px", "210px", "230px"]}
						p={2}
						bgColor='#FFF'
						borderRadius='4px'
						boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
						overflow={!isCustomProduct && showedItemsId > 1 ? "auto" : "hidden"}
						minH={["140px", "190px", "200px", "215px", "230px"]}
						minW={["70px", "140px", "140px", "150px", "190px"]}
						cursor={isDisabled ? "not-allowed" : "pointer"}
						position='relative'
						display='flex'
						flexDirection='column'
						py={2}
					>
						{!isCustomProduct ? (
							showedItemsId < 2 ? (
								<>
									<Box position='relative' flex='1' maxH={"100%"} maxW={"100%"}>
										{item.image === null ? (
											<LackImageIcon
												position='absolute'
												top={0}
												left={0}
												display='flex'
												h='100%'
												w='100%'
												objectFit='contain'
												alt='Lack of show items image'
											/>
										) : (
											<Image
												position='absolute'
												top={0}
												left={0}
												display='flex'
												h='100%'
												w='100%'
												objectFit='contain'
												alt='Show item image'
												src={item.image}
											/>
										)}
									</Box>
									<Text
										position='relative'
										px={1}
										zIndex={1}
										bgColor='rgba(255, 255, 255, 0.8)'
										flexShrink={0}
										mt='auto'
										w='auto'
										h='auto'
										textAlign='center'
										fontSize={[13, 14, 15, 16]}
										fontWeight={600}
									>
										{item.name}
									</Text>
								</>
							) : (
								item.map((parameter, index) => (
									<React.Fragment key={index}>
										{index === 0 && (
											<Flex justifyContent='center' alignItems='center'>
												<Text
													fontSize={[13, 14, 15, 16]}
													textAlign='center'
													maxW={["80px", "100px", "140px", "150px", "180px"]}
													mt={[0, 0, 2]}
													fontWeight={600}
												>
													{parameter.parameterName}
												</Text>
											</Flex>
										)}
										<Box p={[1, 1.5, 2]}>
											<Flex
												key={parameter.id}
												w='100%'
												p={[1, 2, 1.5, 2]}
												justifyContent='center'
												bgColor='#F2F2EF'
												borderRadius='4px'
											>
												<Text
													textAlign='center'
													fontSize={[13, 14, 15, 16]}
													maxW={["30px", "50px", "90px", "90px", "120px"]}
												>
													{parameter.name}
												</Text>
											</Flex>
										</Box>
									</React.Fragment>
								))
							)
						) : (
							<Box
								w={["100%"]}
								maxH={["125px", "100px", "100px", "160px", "130px"]}
								overflow={["auto", "visible"]}
							>
								{getCurrentLang(item.lang, showedItemsId)}
								<Textarea
									isDisabled={isDisabled}
									minH='40px'
									h={["auto", "100%"]}
									resize={"none"}
									fontSize={[13, 14, 15, 16]}
									placeholder={t("Order.addDescription")}
									onChange={(e) => handleChangeDescription(e, showedItemsId)}
									my={3}
								/>
							</Box>
						)}
					</GridItem>
				</React.Fragment>
			)),
		[getCurrentLang, handleChangeDescription, isCustomProduct, isDisabled, showedItems, t],
	)

	const materials = useMemo(
		() =>
			resources?.map((material) => (
				<GridItem
					key={material.id}
					minH={["140px", "190px", "200px", "215px", "230px"]}
					minW={["70px", "140px", "140px", "150px", "190px"]}
					bgColor='#FFF'
					borderRadius='4px'
					boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
					cursor={isDisabled ? "not-allowed" : "pointer"}
					position='relative'
					overflow='hidden'
					display='flex'
					flexDirection='column'
					py={2}
					onClick={() => handleSelectMaterial(material)}
				>
					<Box position='relative' flex='1' maxH={"100%"} maxW={"100%"} mx={2}>
						{material.image === null ? (
							<LackImageIcon
								position='absolute'
								top={0}
								left={0}
								display='flex'
								h='100%'
								w='100%'
								objectFit='contain'
								alt='Lack of material image'
							/>
						) : (
							<Image
								position='absolute'
								top={0}
								left={0}
								display='flex'
								h='100%'
								w='100%'
								objectFit='contain'
								alt='Material image'
								src={material.image}
							/>
						)}
					</Box>
					<Text
						position='relative'
						px={1}
						zIndex={1}
						flexShrink={0}
						mt='auto'
						w='auto'
						h='auto'
						textAlign='center'
						fontSize={[13, 14, 15, 16]}
						fontWeight={600}
					>
						{material.name}
					</Text>
				</GridItem>
			)),
		[handleSelectMaterial, isDisabled, resources],
	)

	const currentResources = useMemo(
		() => (
			<LoadingHOC size='sm' isLoading={availableMaterials}>
				{materials}
			</LoadingHOC>
		),
		[availableMaterials, materials],
	)

	const isCustomProductCorrect = () => {
		const isCorrect = showedItems.some((item) => item.description?.length > 0)
		return isCorrect
	}

	const currentParameters = useMemo(
		() =>
			createOrderState === 2 &&
			showedItems[1].parameters[parametersId] &&
			showedItems[1].parameters[parametersId].count > selectedParametersCounter && (
				<>
					<Heading fontSize={[14, 15, 16, 17]} mt={[7, 8, 9, 10]} mb={[3, 3, 4, 5]}>
						{t("Order.select")} {showedItems[1].parameters[parametersId].name}
					</Heading>
					<Grid w={["180px", "200px", "260px"]} gap={4} mb={[4, 5, 5]}>
						{showedItems[1].parameters[parametersId].values.map((value) => (
							<GridItem
								key={value.id}
								maxW='260px'
								minH='50px'
								bgColor='#FFF'
								borderRadius='4px'
								boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
								cursor={isDisabled ? "not-allowed" : "pointer"}
								onClick={() =>
									handleSelectParameter(value, showedItems[1].parameters[parametersId].count)
								}
							>
								<Box my={3}>
									<Flex justifyContent='center' alignItems='center'>
										<Text
											fontSize={[13, 14, 15, 16]}
											maxW={["120px", "140px", "200px"]}
											fontWeight={600}
										>
											{value.name}
										</Text>
									</Flex>
								</Box>
							</GridItem>
						))}
					</Grid>
				</>
			),
		[createOrderState, showedItems, parametersId, selectedParametersCounter, t, isDisabled, handleSelectParameter],
	)

	const getCurrentItems = useMemo(() => {
		switch (createOrderState) {
			case 0:
				return (
					<LoadingHOC isLoading={materialsGroups} size='sm'>
						{groupsMaterials}
					</LoadingHOC>
				)
			case 1:
				return getShowedItems
			case 2:
				return getShowedItems
			case 3:
				return getShowedItems
			default:
				return (
					<LoadingHOC isLoading={materialsGroups} size='sm'>
						{groupsMaterials}
					</LoadingHOC>
				)
		}
	}, [createOrderState, getShowedItems, groupsMaterials, materialsGroups])

	return (
		<Box>
			<Flex justifyContent='space-between' mt={7}>
				<Box me={2}>
					<Heading fontSize={[14, 15, 16, 17]}>{t("Order.materialsGroups")}</Heading>
					<Text maxW={["260px", "300px", "600px", "350px", "600px"]} fontSize={[13, 14, 15, 16]}>
						{t("Order.selectMaterialGroup")}
					</Text>
				</Box>
				<Box
					display={["flex", "flex", "inline-flex"]}
					flexWrap={["wrap", "wrap", "nowrap", "wrap", "nowrap"]}
					gap={[2]}
					flexDirection={["column", "column", "row", "column", "row"]}
				>
					<Flex gap={[1, 1, 2]} order={[2, 2, 0, 2, 0]} justifyContent={"end"}>
						<Button
							isDisabled={createOrderState === 0 || isDisabled}
							display='flex'
							justifyContent='center'
							alignItems='center'
							bgColor='#F6AD55'
							_hover={{ bgColor: "#F79E31" }}
							borderRadius='4px'
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
							gap={[1, 2]}
							px={[2, 3, 3, 3, 5]}
							h={["30px", "30px", "35px", "40px"]}
							aria-label='Back button'
							onClick={handleBackButton}
						>
							<BackIcon color='#000' boxSize={[4, 4, 5, 6]} />
							<Text
								aria-label='Back button'
								fontSize={[13, 14, 15, 16]}
								display='flex'
								justifyContent='center'
							>
								{t("Order.back")}
							</Text>
						</Button>
						<Button
							isDisabled={
								orderCount <= 0 ||
								createOrderState !== 3 ||
								isDisabled ||
								(isCustomProduct && !isCustomProductCorrect())
							}
							bgColor='#68D391'
							_hover={{ bgColor: "#36DA75" }}
							display='flex'
							justifyContent='center'
							alignItems='center'
							borderRadius='4px'
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
							gap={2}
							px={[2, 3, 3, 3, 5]}
							aria-label='Order button'
							h={["30px", "30px", "35px", "40px"]}
							onClick={handleOrder}
						>
							<AddIcon boxSize={[3, 4, 4, 5, 5]} />
							<Text aria-label='Order button' fontSize={[13, 14, 15, 16]} mx='auto' whiteSpace='pre-wrap'>
								{t("Order.addMaterialToOrder")}
							</Text>
						</Button>
					</Flex>
					<Flex mt={[2, 2, 0]} gap={[1, 1, 2]}>
						<InputGroup>
							<Input
								isDisabled={isDisabled}
								placeholder={t("History.searchPlaceholder")}
								bgColor={"#fff"}
								boxShadow={
									"0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);"
								}
								borderRadius={"4px"}
								fontSize={[13, 14, 15, 16]}
								h={["30px", "30px", "35px", "40px"]}
								value={searchValue}
								onChange={(e) => handleSearching(e.target.value)}
							/>
							<InputRightElement h={["30px", "30px", "35px", "40px"]}>
								<SearchIcon boxSize={[3, 3, 4]} />
							</InputRightElement>
						</InputGroup>
						<Button
							isDisabled={isDisabled}
							aria-label='sort-button'
							bgColor='#fff'
							borderRadius='4px'
							minW={["30px", "30px", "35px"]}
							boxSize={["30px", "30px", "35px", "40px"]}
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
							p={0}
							onClick={handleSort}
						>
							{isSortDesc ? <BsSortDown boxSize={[4, 4, 5]} /> : <BsSortUp boxSize={[4, 4, 5]} />}
						</Button>
					</Flex>
				</Box>
			</Flex>
			<Grid
				templateColumns={[
					"repeat(3, 1fr)",
					"repeat(3, 1fr)",
					"repeat(4, 1fr)",
					"repeat(3, 1fr)",
					isLargerThan1832 ? "repeat(6, 1fr)" : "repeat(4,1fr)",
				]}
				mt={5}
				mb={3}
				gap={3}
			>
				{getCurrentItems}
				{isCustomProduct && showedItems?.length < 3 && (
					<GridItem>
						<Flex
							minH={["140px", "190px", "200px", "215px", "230px"]}
							minW={["90px", "110px", "150px", "150px", "180px"]}
							justifyContent='center'
							alignItems='center'
							bgColor='#FFF'
							borderRadius='4px'
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
							cursor={isDisabled ? "not-allowed" : "pointer"}
							onClick={handleAddNewDescription}
						>
							<Box>
								<LanguageIcon display='flex' boxSize={[4, 5, 6, 7]} mx='auto' alt='Languages icon' />
								<Text
									color='#2B807E'
									fontSize={[13, 14, 15, 16]}
									mx='auto'
									textAlign='center'
									maxW={["70px", "90px", "100px", "100px", "120px"]}
									mt={2}
								>
									{t("Order.addOtherLang")}
								</Text>
							</Box>
						</Flex>
					</GridItem>
				)}
				{createOrderState === 3 && (
					<GridItem>
						<Box
							minH={["140px", "190px", "200px", "215px", "230px"]}
							minW={["70px", "140px", "140px", "150px", "190px"]}
							bgColor='#FFF'
							borderRadius='4px'
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
						>
							<Flex
								minH={["140px", "190px", "200px", "215px", "230px"]}
								minW={["70px", "140px", "140px", "150px", "190px"]}
								justifyContent='center'
								alignItems='center'
							>
								<NumberInput
									isDisabled={isDisabled}
									allowMouseWheel
									size={["sm", "sm", "md"]}
									w={["75%", "50%", "40%"]}
									min={1}
									value={orderCount}
									onChange={(value) => handleChangeCount(Number(value))}
								>
									<NumberInputField onKeyDown={handleKeyDown} />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
							</Flex>
						</Box>
					</GridItem>
				)}
			</Grid>
			{createOrderState === 1 && (
				<>
					<Heading fontSize={[14, 15, 16, 17]} mt={[7, 8, 9]}>
						{t("Order.selectMaterial")}
					</Heading>
					<Grid
						templateColumns={[
							"repeat(3, 1fr)",
							"repeat(3, 1fr)",
							"repeat(4, 1fr)",
							"repeat(3, 1fr)",
							isLargerThan1832 ? "repeat(6, 1fr)" : "repeat(4,1fr)",
						]}
						mt={5}
						mb={[6, 6, 5, 3]}
						gap={3}
					>
						{currentResources}
					</Grid>
				</>
			)}
			{currentParameters}
		</Box>
	)
}

export default memo(OrderMaterialsGroups)
