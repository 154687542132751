import i18next from "i18next"
import { createAsyncThunk } from "@reduxjs/toolkit"

import { handleRequest } from "../../../utils/api/api"

/**
 * Login function with dispatch action
 */
export const login = createAsyncThunk("auth/login", async ({ login, password }) => {
	const responseData = await handleRequest({
		endPoint: "/api/login",
		method: "POST",
		body: {
			login: login,
			password: password,
			language: localStorage.getItem("language") ? i18next.language : undefined,
		},
	})
	return responseData
})

/**
 * Logout function with dispatch action
 */
export const logout = createAsyncThunk("auth/logout", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/logout",
		method: "POST",
	})
	return responseData
})

/**
 * check authentication function with dispatch action
 */
export const auth = createAsyncThunk("auth/session", async () => {
	const responseData = await handleRequest({ endPoint: "/api/session" })
	return responseData
})

/**
 * Login with Microsoft function
 */
export const loginWithMicrosoft = createAsyncThunk("auth/microsoft", async () => {
	const responseData = await handleRequest({ endPoint: "/login/microsoft" })
	return responseData
})
