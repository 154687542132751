import { useMemo } from "react"
import { useSelector } from "react-redux"

const hasPermissions = (permissions, allowedPermissions) => {
	const userRoles = useSelector((state) => state.auth[`${permissions}_module_permissions`])
	const hasAccess = useMemo(
		() => allowedPermissions.some((permission) => userRoles.includes(permission)),
		[allowedPermissions, userRoles],
	)

	return hasAccess
}

export default hasPermissions
