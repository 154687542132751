import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Badge,
	Box,
	Button,
	Checkbox,
	Container,
	Divider,
	Editable,
	EditableInput,
	EditablePreview,
	Flex,
	Grid,
	GridItem,
	Image,
	Input,
	Text,
	useBreakpointValue,
	useDisclosure,
} from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { CheckIcon, CopyIcon } from "@chakra-ui/icons"
import i18next from "i18next"

import {
	changeIsPacked,
	changeOrderCount,
	changeOrderPosition,
	changePackedAll,
	copyFullOrder,
	getOrder,
	removeOrder,
	updateOrderStatus,
} from "../../../../../actions/modules/orders/shop/shop-actions"
import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"
import BsSortDown from "../../../../../assets/icons/permissions/BsSortDown"
import BsSortUp from "../../../../../assets/icons/permissions/BsSortUp"
import BasketCartAdd from "../../../../../assets/icons/shop/BasketCartAdd"
import CommentIcon from "../../../../../assets/icons/shop/CommentIcon"
import CommentIconWithMessage from "../../../../../assets/icons/shop/CommentIconWithMessage"
import CreatingIcon from "../../../../../assets/icons/shop/CreatingIcon"
import ExpandedIcon from "../../../../../assets/icons/shop/ExpandedIcon"
import MdAirplanemodeActive from "../../../../../assets/icons/shop/MdAirplanemodeActive"
import Package from "../../../../../assets/icons/shop/Package"
import MaterialComment from "../../../../../components/Modules/Orders/Shop/MaterialComment/MaterialComment"
import OrderMaterialsGroups from "../../../../../components/Modules/Orders/Shop/OrderMaterialsGroups/OrderMaterialsGroups"
import OrderedMaterials from "../../../../../components/Modules/Orders/Shop/OrderedMaterials/OrderedMaterials"
import OrderDetailsAccordion from "../../../../../components/Modules/Orders/Shop/OrderDetailsAccordion/OrderDetailsAccordion"
import OrderSelect from "../../../../../components/Modules/Orders/Shop/OrderSelect/OrderSelect"
import OrderStepper from "../../../../../components/Modules/Orders/Shop/OrderStepper/OrderStepper"
import PermissionsChecked from "../../../../../components/common/PermissionsChecked"
import SearchBar from "../../../../../components/common/SearchBar"
import { clearCurrentOrder } from "../../../../../store/modules/orders/shop/shop-slice"
import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import EditableControls from "../../../../../components/common/EditableControls"
import BinIcon from "../../../../../assets/icons/materials/BinIcon"
import BackForwardButtons from "../../../../../components/common/BackForwardButtons"
import ModuleHeading from "../../../../../components/common/ModuleHeading"
import LoadingHOC from "../../../../../components/common/LoadingHOC"
import ConfirmModal from "../../../../../components/Modules/Orders/Shop/ConfirmModal/ConfirmModal"

const Order = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const orderDetails = useSelector((state) => state.shop.currentOrder)
	const [language, setLanguage] = useState()
	const [orders, setOrders] = useState([])
	const [ordersCopy, setOrdersCopy] = useState([])
	const [orderState, setOrderState] = useState()
	const [orderHistory, setOrderHistory] = useState([])
	const [isExpanded, setIsExpanded] = useState(false)
	const [accordionRef, setAccordionRef] = useState(null)
	const [packedItems, setPackedItems] = useState([])
	const [copyPackedItems, setCopyPackedItems] = useState([])
	const [isOrderDescSort, setIsOrderDescSort] = useState(false)
	const [isPackedItemsDesc, setIsPackedItemsDesc] = useState(false)
	const [searchText, setSearchText] = useState("")
	const [unpackingSearchText, setUnpackingSearchText] = useState("")
	const [isGettedDetails, setIsGettedDetails] = useState(false)
	const [isListFormat, setIsListFormat] = useState(true)
	const [isOrdersGetted, setIsOrdersGetted] = useState(false)
	const [currentProductId, setCurrentProductId] = useState(0)
	const [disabledForm, setDisabledForm] = useState(false)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { isOpen: isOpenConfirmModal, onOpen: onOpenConfirmModal, onClose: onCloseConfirmModal } = useDisclosure()
	const { orderId } = useParams()
	const editablePreviewRef = useRef()
	const packedItemsAccordionRef = useRef()
	const searchBarBreakpoints = useBreakpointValue({ base: "30px", sm: "30px", md: "35px" })

	useEffect(() => {
		if (orderDetails && i18next.language === language) return
		dispatch(getOrder(orderId))
	}, [dispatch, orderId, orderDetails, i18next.language])

	useEffect(() => {
		setLanguage(i18next.language)
		setIsOrdersGetted(false)
	}, [i18next.language])

	useEffect(() => {
		if (orderDetails) {
			const packedRows = orderDetails.order_rows.filter((order) => order.is_packed)
			const unpackedRows = orderDetails.order_rows.filter((order) => !order.is_packed)
			if (searchText !== "" && orderDetails.status === 10) {
				const results = unpackedRows.filter((item) => {
					return item.name.toString().toLowerCase().includes(searchText.toLowerCase())
				})
				setOrders(results)
			} else {
				setOrders(unpackedRows)
			}
			if (unpackingSearchText !== "" && orderDetails.status !== 10) {
				const results = unpackedRows.filter((item) => {
					return (
						item.name.toString().toLowerCase().includes(unpackingSearchText.toLowerCase()) ||
						item?.group_name?.toString().toLowerCase().includes(unpackingSearchText.toLowerCase())
					)
				})
				setOrders(results)
			} else if (unpackingSearchText === "" && orderDetails.status !== 10) {
				setOrders(unpackedRows)
			}
			setOrdersCopy(unpackedRows)
			if (searchText !== "" && orderDetails.status !== 10) {
				const results = packedRows.filter((item) => {
					return (
						item.name.toString().toLowerCase().includes(searchText.toLowerCase()) ||
						item?.group_name?.toString().toLowerCase().includes(searchText.toLowerCase())
					)
				})
				setPackedItems(results)
			} else {
				setPackedItems(packedRows)
			}
			setCopyPackedItems(packedRows)
			setOrderState(orderDetails.status / 10)
			setOrderHistory(orderDetails.order_history)
			setIsGettedDetails(true)
			setAccordionRef(unpackedRows.map(() => React.createRef()))
			setIsOrdersGetted(true)
			if (packedItemsAccordionRef.current && packedItemsAccordionRef.current.ariaExpanded === "false") {
				packedItemsAccordionRef.current.click()
			}
		}
	}, [orderDetails])

	useEffect(() => {
		return () => dispatch(clearCurrentOrder())
	}, [dispatch])

	useEffect(() => {
		if (packedItemsAccordionRef.current && orderState) {
			if (orderState > 3 && packedItemsAccordionRef.current.ariaExpanded === "false") {
				packedItemsAccordionRef.current.click()
			}
			if (orderState === 3 && packedItemsAccordionRef.current.ariaExpanded === "true") {
				packedItemsAccordionRef.current.click()
			}
		}
	}, [orderState])

	const handleChangeExpand = useCallback(() => {
		accordionRef.forEach((accordion) => {
			if (accordion.current && accordion.current.ariaExpanded === isExpanded.toString()) {
				accordion.current.click()
			}
		})
		setIsExpanded((prevState) => !prevState)
	}, [accordionRef, isExpanded])

	const handleChangeListFormat = () => {
		setIsListFormat((prevState) => !prevState)
	}

	const getStatus = useCallback(
		(status) => {
			switch (status) {
				case 1:
					return (
						<Flex
							bgColor='gray.50'
							borderLeft='4px solid #A0AEC0'
							borderRadius='4px'
							py={[1, 1, 2]}
							alignItems='center'
						>
							<Text fontSize={[13, 14, 15, 16]} ms={2}>
								{t("OrderList.inProgress")}
							</Text>
						</Flex>
					)
				case 2:
					return (
						<Flex
							bgColor='yellow.50'
							borderLeft='4px solid #F6E05E'
							borderRadius='4px'
							py={[1, 1, 2]}
							alignItems='center'
						>
							<Text fontSize={[13, 14, 15, 16]} ms={2}>
								{t("OrderList.completed")}
							</Text>
						</Flex>
					)
				case 3:
					return (
						<Flex
							bgColor='orange.50'
							borderLeft='4px solid #F6AD55'
							borderRadius='4px'
							py={[1, 1, 2]}
							alignItems='center'
						>
							<Text fontSize={[13, 14, 15, 16]} ms={2}>
								{t("OrderList.inPreparation")}
							</Text>
						</Flex>
					)
				case 4:
					return (
						<Flex
							bgColor='blue.50'
							borderLeft='4px solid #3182CE'
							borderRadius='4px'
							py={[1, 1, 2]}
							alignItems='center'
						>
							<Text fontSize={[13, 14, 15, 16]} ms={2}>
								{t("OrderList.readyToShip")}
							</Text>
						</Flex>
					)
				case 5:
					return (
						<Flex
							bgColor='teal.50'
							borderLeft='4px solid #48BB78'
							borderRadius='4px'
							py={[1, 1, 2]}
							alignItems='center'
						>
							<Text fontSize={[13, 14, 15, 16]} ms={2}>
								{t("Order.realized")}
							</Text>
						</Flex>
					)
				default:
					return (
						<Flex
							bgColor='#FFFFFF'
							borderLeft='4px solid #EEE7E7'
							borderRadius='4px'
							py={[1, 1, 2]}
							alignItems='center'
						>
							<Text fontSize={[13, 14, 15, 16]} ms={2}>
								Empty
							</Text>
						</Flex>
					)
			}
		},
		[t],
	)

	const statusHistory = orderHistory?.map((item) => (
		<Grid key={item.id} templateColumns={"repeat(4, 1fr)"} alignSelf='center'>
			<GridItem mt={[0, 2, 4]} colSpan={2} alignSelf='center'>
				{getStatus(item.status / 10)}
			</GridItem>
			<GridItem mx={[4, 8]} mt={[0, 2, 4]} alignSelf='center'>
				<Badge
					whiteSpace={["pre-wrap", "nowrap"]}
					fontSize={[12, 13, 14, 15]}
					bgColor='#F2F2EF'
					fontWeight={400}
				>
					{item.date}
				</Badge>
			</GridItem>
			<GridItem mt={[0, 2, 4]} alignSelf='center'>
				<Text fontSize={[13, 14, 15, 16]}>{item.user}</Text>
			</GridItem>
		</Grid>
	))

	const selectOptions = [
		{
			id: 1,
			value: 1,
			isDisabled: true,
			label: t("OrderList.inProgress"),
			borderColor: "#A0AEC0",
			bgColor: "#F7FAFC",
		},
		{
			id: 2,
			value: 2,
			isDisabled: orderState !== 1 || !ordersCopy.length,
			label: t("OrderList.completed"),
			borderColor: "#F6E05E",
			bgColor: "#FFFFF0",
		},
		{
			id: 3,
			value: 3,
			isDisabled: orderState !== 2 && orderState !== 4,
			label: t("OrderList.inPreparation"),
			borderColor: "#F6AD55",
			bgColor: "#FFFAF0",
		},
		{
			id: 4,
			value: 4,
			isDisabled: orderState !== 3 || (orderState === 3 && ordersCopy.length),
			label: t("OrderList.readyToShip"),
			borderColor: "#3182CE",
			bgColor: "#EBF8FF",
		},
		{
			id: 5,
			value: 5,
			isDisabled: orderState !== 4,
			label: t("Order.realized"),
			borderColor: "#48BB78",
			bgColor: "#F0FFF4",
		},
	]

	const handleOrderCountChange = useCallback(
		(order, value) => {
			// eslint-disable-next-line eqeqeq
			if (
				editablePreviewRef.current.textContent === "" ||
				editablePreviewRef.current.textContent == order.count ||
				value <= 0
			) {
				editablePreviewRef.current.textContent = order.count
			} else {
				setDisabledForm(true)
				dispatch(changeOrderCount({ id: order.id, count: Number(value) })).then(() => setDisabledForm(false))
			}
		},
		[dispatch],
	)

	const handleChangeStatus = (status) => {
		setDisabledForm(true)
		dispatch(updateOrderStatus({ id: orderDetails.id, orderStatus: status * 10 })).then(() => {
			setOrders(ordersCopy)
			setPackedItems(copyPackedItems)
			setSearchText("")
			setUnpackingSearchText("")
			setDisabledForm(false)
		})
	}

	const handleRemoveOrder = useCallback(
		(orderId, id) => {
			setDisabledForm(true)
			dispatch(removeOrder(id)).then(() => setDisabledForm(false))
			setAccordionRef((prevState) => prevState.filter((accordion, index) => index !== orderId))
		},
		[dispatch],
	)

	const handleConfirmChangeStatus = () => {
		setDisabledForm(true)
		dispatch(
			updateOrderStatus({
				id: orderDetails.id,
				orderStatus: (orderState + 1) * 10,
			}),
		).then(() => {
			setOrders(ordersCopy)
			setOrdersCopy(ordersCopy)
			setPackedItems(copyPackedItems)
			setCopyPackedItems(copyPackedItems)
			setDisabledForm(false)
			onCloseConfirmModal()
		})
	}

	const handleCreateOrder = () => {
		setDisabledForm(true)
		dispatch(
			updateOrderStatus({
				id: orderDetails.id,
				orderStatus: (orderState + 1) * 10,
			}),
		).then(() => {
			setOrders(ordersCopy)
			setOrdersCopy(ordersCopy)
			setPackedItems(copyPackedItems)
			setCopyPackedItems(copyPackedItems)
			setDisabledForm(false)
		})
	}

	const handlePacking = (orderId) => {
		setDisabledForm(true)
		setSearchText("")
		dispatch(changeIsPacked({ id: orderId, isPacked: 1 })).then(() => setDisabledForm(false))
	}

	const handleUnpacking = useCallback(
		(orderId) => {
			setDisabledForm(true)
			setUnpackingSearchText("")
			dispatch(changeIsPacked({ id: orderId, isPacked: 0 })).then(() => setDisabledForm(false))
		},
		[dispatch],
	)

	const handleUnpackingAll = () => {
		setDisabledForm(true)
		setUnpackingSearchText("")
		setSearchText("")
		dispatch(changePackedAll({ orderId, isPacked: 0 })).then(() => setDisabledForm(false))
	}

	const handleCopyFullOrder = () => {
		setDisabledForm(true)
		dispatch(copyFullOrder({ id: orderDetails.id, navigate })).then(() => setDisabledForm(false))
	}

	const handleSorting = useCallback(() => {
		setDisabledForm(true)
		const sortedOrders = [...ordersCopy]
		if (isOrderDescSort) {
			sortedOrders.sort((a, b) => a.name.toString().localeCompare(b.name.toString()))
		} else {
			sortedOrders.sort((a, b) => b.name.toString().localeCompare(a.name.toString()))
		}
		const data = {
			order_rows: sortedOrders.map((order, index) => ({
				id: order.id,
				place: index + 1,
			})),
			order_id: orderDetails?.id,
		}
		dispatch(changeOrderPosition(data)).then(() => {
			setIsOrderDescSort((prevState) => !prevState)
			setDisabledForm(false)
		})
	}, [ordersCopy, isOrderDescSort, orderDetails?.id, dispatch])

	const handlePackedItemsSorting = useCallback(() => {
		setDisabledForm(true)
		const sortedOrders = [...copyPackedItems]
		if (isPackedItemsDesc) {
			sortedOrders.sort((a, b) => a.name.toString().localeCompare(b.name.toString()))
		} else {
			sortedOrders.sort((a, b) => b.name.toString().localeCompare(a.name.toString()))
		}
		const data = {
			order_rows: sortedOrders.map((order, index) => ({
				id: order.id,
				place: index + 1,
			})),
			order_id: orderDetails?.id,
		}
		dispatch(changeOrderPosition(data)).then(() => {
			setIsPackedItemsDesc((prevState) => !prevState)
			setDisabledForm(false)
		})
	}, [copyPackedItems, isPackedItemsDesc, orderDetails?.id, dispatch])

	const handleKeyDown = (event) => {
		if (event.key === "." || event.key === "," || event.key === "-" || event.key === "+") {
			event.preventDefault()
		}
	}

	const handleOpenComment = useCallback(
		(orderId) => {
			setCurrentProductId(orderId)
			onOpen()
		},
		[onOpen],
	)

	const getParameters = (order) =>
		order.parameters
			?.map((parameter) => {
				const prefix = ">>"
				const valuesString = parameter.values.map((value) => value.name).join(" × ")

				return `${prefix} ${valuesString}`
			})
			.join(" ")

	const readyToSendItems = useMemo(
		() =>
			packedItems.map((order, orderId) => (
				<GridItem
					key={orderId}
					border='1px solid var(--teal-500, #319795)'
					boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
					borderRadius='4px'
				>
					<Flex display='inline-flex' h={isListFormat ? "100%" : "80%"} w='100%'>
						<PermissionsChecked
							permissions={"order"}
							checkingRequired={!orderDetails?.my_order}
							allowedPermissions={["pack_order"]}
						>
							<Box
								display={orderState === 3 && isListFormat ? "flex" : "none"}
								w={["20px", "25px", "30px"]}
								bgColor={"rgba(49, 151, 149, 0.20)"}
								justifyContent='center'
								alignItems='center'
							>
								<Checkbox
									isDisabled={disabledForm}
									aria-label='Unpacking checkbox'
									size={["sm", "md", "md"]}
									borderColor={"var(--teal-500, #319795)"}
									isChecked={true}
									onChange={() => handleUnpacking(order.id)}
									colorScheme='teal'
								/>
							</Box>
						</PermissionsChecked>
						<Box px={[1, 3]} pt={3} pb={2} h='80%' w='100%'>
							<Flex alignItems='start' justifyContent='space-between'>
								<Flex>
									{!order.is_custom &&
										(order.image ? (
											<Image
												src={order.image}
												alt='materialImage'
												w={["40px", "50px", "70px"]}
												h={["50px", "60px", "80px"]}
											/>
										) : (
											<LackImageIcon
												alt='lack of image'
												w={["40px", "50px", "70px"]}
												h={["50px", "60px", "80px"]}
											/>
										))}
									<Flex
										display={isListFormat ? ["block", "block", "flex"] : "block"}
										gap={isListFormat && [4, 5, 14, 28]}
										ms={isListFormat ? [2, 5, 7] : [2, 2, 3]}
										alignItems={isListFormat && "center"}
									>
										<Flex
											minW={order.is_custom && ["50px", "50px", "70px", "315px"]}
											maxW={["180px", "auto"]}
											alignItems={"center"}
										>
											{order.is_custom ? (
												<>
													<Text fontSize={[13, 14, 15, 16, 17]} color='#2C7A7B' me={3}>
														{t("Order.description")}:{" "}
													</Text>
													<Text
														maxW={
															isListFormat
																? ["60px", "100px", "45px", "70px", "150px"]
																: ["60px", "100px", "120px", "150px", "200px"]
														}
														fontSize={[13, 14, 15, 16, 17]}
														fontWeight={600}
													>
														{order.description}
													</Text>
												</>
											) : (
												<>
													<Text fontSize={[13, 14, 15, 16, 17]} color='#2C7A7B' me={3}>
														{t("Order.material")}:{" "}
													</Text>
													<Text
														maxW={
															isListFormat
																? ["60px", "100px", "45px", "70px", "150px"]
																: ["60px", "100px", "120px", "150px", "200px"]
														}
														fontSize={[13, 14, 15, 16, 17]}
														fontWeight={600}
													>
														{order.name}
													</Text>
												</>
											)}
										</Flex>
										<Flex display={order.is_custom ? "none" : "flex"}>
											<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B' me={3}>
												{t("Order.group")}:{" "}
											</Text>
											<Text fontSize={[13, 14, 15, 16]}>{order.group_name}</Text>
										</Flex>
										<Flex
											flexDirection={
												isListFormat && order.is_custom
													? ["column", "row"]
													: isListFormat
														? ["column", "column", "column", "column", "row"]
														: "column"
											}
											gap={
												isListFormat && order.is_custom
													? [0, 5, 14, 28]
													: isListFormat && [0, 0, 0, 0, 28]
											}
											alignItems={
												isListFormat && order.is_custom
													? ["start", "center"]
													: isListFormat && ["start", "start", "start", "start", "center"]
											}
										>
											<Flex>
												<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
													{t("Order.ordered")}:{" "}
												</Text>
												<Text wordBreak={"break-word"} fontSize={[13, 14, 15, 16]} ms={2}>
													{order.count}
												</Text>
											</Flex>
											<Flex>
												<Text fontSize={[13, 14, 15, 16]} whiteSpace='nowrap' color='#2C7A7B'>
													{t("Order.packedCount")}:
												</Text>
												<Text wordBreak={"break-word"} fontSize={[13, 14, 15, 16]} ms={2}>
													{order.count_packed}
												</Text>
											</Flex>
										</Flex>
									</Flex>
								</Flex>
								<Flex>
									<Button
										isDisabled={disabledForm}
										bgColor='#FFF'
										_hover={{ backgroundColor: "#F2F2EF" }}
										p={0}
										boxSize={["30px", "35px", "40px"]}
										onClick={() => handleOpenComment(orderId)}
										aria-label='Open comment button'
									>
										{order.comment ? (
											<CommentIconWithMessage boxSize={["22px", "22px", "26px"]} />
										) : (
											<CommentIcon boxSize={[5, 5, 6]} />
										)}
									</Button>
								</Flex>
							</Flex>
							<Flex justifyContent='space-between'>
								<Box>
									<Flex display={!order.is_custom && order.description ? "flex" : "none"}>
										<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
											{t("Order.description")}:
										</Text>
										<Text fontSize={[13, 14, 15, 16]} ms={2}>
											{order.description}
										</Text>
									</Flex>
									{order.parameters?.map((parameter, index) => (
										<Flex key={index} my={1}>
											<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
												{parameter.name}:
											</Text>
											{parameter.values.map((value, valueId) => (
												<Text
													key={valueId}
													fontSize={[13, 14, 15, 16]}
													bgColor='#F2F2EF'
													borderRadius='4px'
													px={1}
													ms={2}
												>
													{value.name}
												</Text>
											))}
										</Flex>
									))}
								</Box>
							</Flex>
						</Box>
					</Flex>
					{orderState === 3 && !isListFormat && (
						<PermissionsChecked
							permissions={"order"}
							checkingRequired={!orderDetails?.my_order}
							allowedPermissions={["pack_order"]}
						>
							<Flex h='20%' alignItems='end'>
								<Flex
									h={["25px", "30px", "35px"]}
									w='100%'
									bgColor={"rgba(49, 151, 149, 0.20)"}
									justifyContent='center'
									alignItems='center'
								>
									<Checkbox
										isDisabled={disabledForm}
										aria-label='Unpacking checkbox'
										size={["sm", "md", "md"]}
										borderColor={"var(--teal-500, #319795)"}
										isChecked={true}
										onChange={() => handleUnpacking(order.id)}
										colorScheme='teal'
									/>
									<Text fontSize={[13, 14, 15, 16]} display='inline' color='#2C7A7B' ms={3}>
										{t("Order.packed")}
									</Text>
								</Flex>
							</Flex>
						</PermissionsChecked>
					)}
				</GridItem>
			)),
		[
			packedItems,
			isListFormat,
			orderDetails?.my_order,
			orderState,
			disabledForm,
			t,
			handleUnpacking,
			handleOpenComment,
		],
	)

	const DraggableAccordion = ({ order, index, orderId }) => {
		const [{ isDragging }, drag] = useDrag(() => ({
			type: "ACCORDION",
			item: { orderId: order.id },
			collect: (monitor) => {
				return {
					isDragging: !!monitor.isDragging(),
				}
			},
		}))

		const [, drop] = useDrop(() => ({
			accept: "ACCORDION",
			drop: (monitor) => {
				if (monitor.orderId === index) return
				setDisabledForm(true)
				const newOrdersCopy = [...ordersCopy]
				const draggedOrderCopy = newOrdersCopy.find((order) => order.id === monitor.orderId)
				const draggedOrderCopyIndex = newOrdersCopy.findIndex((order) => order.id === monitor.orderId)
				const newIndex = newOrdersCopy.findIndex((order) => order.id === index)
				newOrdersCopy.splice(draggedOrderCopyIndex, 1)
				newOrdersCopy.splice(newIndex, 0, draggedOrderCopy)

				const data = {
					order_rows: newOrdersCopy.map((order, index) => ({
						id: order.id,
						place: index + 1,
					})),
					order_id: orderDetails.id,
				}
				dispatch(changeOrderPosition(data)).then(() => setDisabledForm(false))
			},
		}))

		return (
			<Accordion
				ref={(node) => drag(drop(node))}
				allowMultiple
				cursor={"pointer"}
				border='1px solid var(--teal-500, #319795)'
				boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
				borderRadius='4px'
				my={[2, 2, 3]}
				style={{ opacity: isDragging ? 0.4 : 1 }}
			>
				<AccordionItem border='none'>
					<Flex
						justifyContent={"space-between"}
						alignItems={"center"}
						gap={[12]}
						px={[2, 3]}
						py={[2, 3]}
						onClick={() => accordionRef[orderId]?.current.click()}
					>
						<Text whiteSpace={"pre-wrap"} wordBreak={"break-all"} fontSize={[13, 14, 15, 16]}>
							{order.parameters?.length ? `${order.name} ${getParameters(order)}` : order.name}
						</Text>
						<Flex alignItems={"center"} gap={2}>
							<Text
								whiteSpace={"pre-wrap"}
								wordBreak={"break-all"}
								textAlign={"end"}
								minW='40px'
								maxW={["80px"]}
								fontSize={[13, 14, 15, 16]}
							>
								{order.count}
							</Text>
							<AccordionButton
								ref={accordionRef[orderId]}
								justifyContent='center'
								minW='35px'
								boxSize={["35px", "35px", "40px"]}
								borderRadius='8px'
								aria-label='Accordion button'
							>
								<AccordionIcon boxSize={[6, 6, 7]} />
							</AccordionButton>
						</Flex>
					</Flex>
					<AccordionPanel p={[1, 0]} px={[2, 3]} pb={[2, 3]}>
						<Divider mb={2} borderWidth='1px' borderColor='#E5E5DF' />
						<Flex gap={4}>
							{!order.is_custom &&
								(order.image ? (
									<Image
										src={order.image}
										alt='materialImage'
										w={["40px", "50px", "70px"]}
										h={["50px", "60px", "80px"]}
									/>
								) : (
									<LackImageIcon
										w={["40px", "50px", "70px"]}
										h={["50px", "60px", "80px"]}
										alt='Lack image icon'
									/>
								))}
							<Flex flexDirection={"column"} gap={2} w='100%'>
								<Flex display={!order.is_custom && order.description ? "flex" : "none"}>
									<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
										{t("Order.description")}:
									</Text>
									<Text
										whiteSpace={"pre-wrap"}
										wordBreak={"break-all"}
										fontSize={[13, 14, 15, 16]}
										ms={2}
									>
										{order.description}
									</Text>
								</Flex>
								<Editable
									display='inline-flex'
									flexWrap={"wrap"}
									alignItems='center'
									me={3}
									defaultValue={order.count}
									onSubmit={(e) => handleOrderCountChange(order, e)}
									fontSize='md'
									isPreviewFocusable={false}
								>
									<Text fontSize={[13, 14, 15, 16]} me={3} color='#2C7A7B'>
										{t("Order.ordered")}:
									</Text>
									<EditablePreview
										whiteSpace={"pre-wrap"}
										wordBreak={"break-all"}
										fontSize={[13, 14, 15, 16]}
										ref={editablePreviewRef}
									/>
									<Input
										h={["25px", "25px", "30px"]}
										w='100px'
										fontSize={[13, 14, 15, 16]}
										type='number'
										onKeyDown={handleKeyDown}
										onPaste={(e) => e.preventDefault()}
										as={EditableInput}
									/>
									<PermissionsChecked
										permissions={"order"}
										checkingRequired={!orderDetails?.my_order}
										allowedPermissions={["edit_order"]}
									>
										<EditableControls isDisabled={disabledForm} />
									</PermissionsChecked>
								</Editable>
								<PermissionsChecked
									permissions={"order"}
									checkingRequired={!orderDetails?.my_order}
									allowedPermissions={["edit_order"]}
								>
									<Flex justifyContent={"end"}>
										<Button
											isDisabled={disabledForm}
											bgColor='#FFF'
											_hover={{ backgroundColor: "#F2F2EF" }}
											minW='35px'
											boxSize={["35px", "35px", "40px"]}
											onClick={() => handleRemoveOrder(orderId, order.id)}
											aria-label='Remove order button'
										>
											<BinIcon color='red.500' boxSize={[5, 5, 6]} />
										</Button>
									</Flex>
								</PermissionsChecked>
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		)
	}

	const draggableAccordionList = useMemo(
		() =>
			orders?.map((order, index) => (
				<DraggableAccordion key={index} index={order.id} order={order} orderId={index} />
			)),
		[orders],
	)

	return (
		<Animation>
			<DndProvider backend={HTML5Backend}>
				<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
					<LoadingHOC isLoading={isGettedDetails}>
						<Flex display={["block", "block", "block", "flex"]} justifyContent={"space-between"}>
							<Box display='inline-flex' mt={2} alignItems='center' mb={[2, 2, 2, 0]}>
								<BackForwardButtons />
								<ModuleHeading
									heading={`${t("Order.heading")} - ${orderDetails?.number}`}
									whiteSpace={["pre-wrap", "nowrap"]}
								/>
							</Box>
							<Box display={"inline-flex"} justifyContent={"end"} w='100%' alignItems='center'>
								<PermissionsChecked
									permissions={"order"}
									checkingRequired={!orderDetails?.my_order}
									allowedPermissions={["copy_order"]}
								>
									<Button
										display='flex'
										isDisabled={disabledForm}
										h={["30px", "35px", "40px"]}
										bgColor='#FFF'
										alignItems='center'
										boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"}
										borderRadius='4px'
										fontSize={[12, 14, 15, 16]}
										px={4}
										me={2}
										aria-label='Copy full order button'
										onClick={handleCopyFullOrder}
									>
										<CopyIcon boxSize={[3, 4]} />
										<Text aria-label='Copy full order button' ms={[1, 2]}>
											{t("Order.copyFullOrder")}
										</Text>
									</Button>
								</PermissionsChecked>
								<PermissionsChecked
									permissions={"order"}
									checkingRequired={!orderDetails?.my_order}
									allowedPermissions={["change_order_status"]}
								>
									<Button
										display={orderState === 5 ? "none" : "flex"}
										h={["30px", "35px", "40px"]}
										isDisabled={
											disabledForm ||
											(orderState === 3
												? ordersCopy.length
												: !ordersCopy.length && !copyPackedItems.length)
										}
										colorScheme='teal'
										bgColor='#2B807E'
										fontSize={[12, 14, 15, 16]}
										color='#FFF'
										alignItems='center'
										boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"}
										borderRadius='4px'
										px={4}
										aria-label='Create order button'
										onClick={orderState === 1 ? onOpenConfirmModal : handleCreateOrder}
									>
										{orderState === 1 && <CreatingIcon boxSize={[4, 5]} />}
										{orderState === 2 && <BasketCartAdd boxSize={5} />}
										{orderState === 3 && <Package boxSize={5} />}
										{orderState === 4 && <MdAirplanemodeActive mt={1} boxSize={5} />}
										{orderState === 1 && (
											<Text aria-label='Create order button' ms={[1, 2]}>
												{t("Order.createOrder")}
											</Text>
										)}
										{orderState === 2 && (
											<Text aria-label='Create order button' ms={2}>
												{t("Order.completeOrder")}
											</Text>
										)}
										{orderState === 3 && (
											<Text aria-label='Create order button' ms={2}>
												{t("Order.forwardToSend")}
											</Text>
										)}
										{orderState === 4 && (
											<Text aria-label='Create order button' ms={2}>
												{t("Order.forwardToRealized")}
											</Text>
										)}
									</Button>
								</PermissionsChecked>
							</Box>
						</Flex>
						<Grid
							templateColumns={["repeat(1,1fr)", "repeat(1,1fr)", "repeat(1,1fr)", "repeat(3,1fr)"]}
							my={5}
						>
							<GridItem colSpan={orderState === 1 ? 2 : 3} me={orderState === 1 && 2}>
								<OrderDetailsAccordion
									orderState={orderState}
									orderDetails={orderDetails}
									disabledForm={disabledForm}
									setDisabledForm={setDisabledForm}
								/>
								<Accordion
									allowMultiple
									my={[3, 3, 4]}
									boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"}
									borderRadius='4px'
									bgColor='#FFF'
								>
									<AccordionItem p={[3, 3, 4]}>
										<Flex w='100%' justifyContent='space-between' alignItems='center'>
											<OrderStepper
												isDisabled={disabledForm}
												isMyOrder={orderDetails?.my_order}
												copyPackedItemsLength={copyPackedItems.length}
												ordersCopyLength={ordersCopy.length}
												handleChangeStatus={
													orderState === 1 ? onOpenConfirmModal : handleChangeStatus
												}
												status={orderState}
												styles={{ width: "95.4%" }}
											/>
											<AccordionButton
												justifyContent='center'
												minW={"30px"}
												boxSize={["30px", "35px", "40px"]}
												aria-label='Accordion button'
											>
												<AccordionIcon boxSize={[6, 6, 7]} />
											</AccordionButton>
										</Flex>
										<AccordionPanel p={0} minH='315px'>
											<Divider my={3} borderWidth='1px' borderColor='#E5E5DF' />
											<Flex alignItems='center'>
												<Text
													fontSize={[13, 14, 15, 16]}
													me={4}
													color='#2C7A7B'
													fontWeight={600}
												>
													{t("Order.status")}
												</Text>
												<OrderSelect
													isDisabled={disabledForm}
													isMyOrder={orderDetails?.my_order}
													options={selectOptions}
													handleChangeStatus={
														orderState === 1 ? onOpenConfirmModal : handleChangeStatus
													}
													value={orderState}
												/>
											</Flex>
											<Text
												fontSize={[13, 14, 15, 16]}
												color='#2C7A7B'
												mt={5}
												fontWeight={600}
												textDecoration='underline'
											>
												{t("Order.orderStatusChangeHistory")}
											</Text>
											<Grid my={2} mb={1} templateColumns={"repeat(4,1fr)"} alignItems='center'>
												<GridItem colSpan={2} mt={1}>
													<Text fontSize={[13, 14, 15, 16]}>{t("Order.status")}</Text>
												</GridItem>
												<GridItem mx={8} mt={1}>
													<Text fontSize={[13, 14, 15, 16]}>{t("Order.date")}</Text>
												</GridItem>
												<GridItem mt={1}>
													<Text fontSize={[13, 14, 15, 16]}>{t("Order.user")}</Text>
												</GridItem>
												<GridItem colSpan={4} maxH='300px' overflowY='auto'>
													{statusHistory}
												</GridItem>
											</Grid>
										</AccordionPanel>
									</AccordionItem>
								</Accordion>
								{(orderState === 3 || orderState === 4 || orderState === 5) && (
									<>
										<Accordion
											allowMultiple
											my={4}
											boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"}
											borderRadius='4px'
											bgColor='#FFF'
										>
											<AccordionItem p={4}>
												<Flex
													display={["block", "flex"]}
													justifyContent={["start", "space-between"]}
													alignItems='center'
													mb={4}
												>
													<Flex display={["flex", "block", "flex"]}>
														<Text
															maxW={["75px", "70px", "100%"]}
															fontSize={[13, 14, 15, 16]}
															fontWeight={600}
															me={[2, 0, 2]}
														>
															{t(
																orderState === 5
																	? "Order.sendedProducts"
																	: "Order.readyForSend",
															)}
														</Text>
														<Text fontSize={[13, 14, 15, 16]} color='#2B807E'>
															({copyPackedItems.length})
														</Text>
													</Flex>
													<Flex justifyContent='end' mt={[2, 0, 0]}>
														<SearchBar
															fieldsToFilter={["name", "group_name"]}
															isDisabled={disabledForm}
															searchText={searchText}
															setSearchText={setSearchText}
															originalArray={copyPackedItems}
															handleSetResults={setPackedItems}
															buttonStyles={{
																height: searchBarBreakpoints,
																width: searchBarBreakpoints,
															}}
															h={searchBarBreakpoints}
														/>
														<PermissionsChecked
															permissions={"order"}
															checkingRequired={!orderDetails?.my_order}
															allowedPermissions={["edit_order"]}
														>
															<Button
																isDisabled={disabledForm || !copyPackedItems.length}
																bgColor='#FFF'
																p={0}
																minW='30px'
																boxSize={["30px", "30px", "35px"]}
																mx={2}
																border='1px solid #D4D4CD'
																borderRadius='4px'
																onClick={handlePackedItemsSorting}
																aria-label='Sorting packed items button'
															>
																{isPackedItemsDesc ? <BsSortUp /> : <BsSortDown />}
															</Button>
														</PermissionsChecked>
														<Button
															bgColor='#FFF'
															p={0}
															minW='30px'
															boxSize={["30px", "30px", "35px"]}
															border='1px solid #D4D4CD'
															borderRadius='4px'
															onClick={handleChangeListFormat}
															aria-label='Change list format button'
														>
															<ExpandedIcon boxSize={[4, 4, 5]} />
														</Button>
														<AccordionButton
															ref={packedItemsAccordionRef}
															ms={2}
															justifyContent='center'
															p={0}
															minW='30px'
															boxSize={["30px", "30px", "35px"]}
															borderRadius='4px'
															aria-label='Accordion button'
														>
															<AccordionIcon boxSize={[5, 6, 7]} />
														</AccordionButton>
													</Flex>
												</Flex>
												<AccordionPanel p={0}>
													{orderState === 3 && !!copyPackedItems?.length && (
														<PermissionsChecked
															permissions={"order"}
															checkingRequired={!orderDetails?.my_order}
															allowedPermissions={["pack_order"]}
														>
															<Button
																mb={3}
																border='1px solid'
																borderColor='#319795'
																bgColor='#D6EAEA'
																color='teal.600'
																w={{ base: "100%", sm: "auto" }}
																h={["30px", "30px", "35px"]}
																fontSize={[13, 14, 15, 16]}
																gap={2}
																_hover={{
																	bgColor: "teal",
																	color: "white",
																}}
																onClick={handleUnpackingAll}
															>
																<CheckIcon
																	p={0.5}
																	border='1px solid'
																	borderRadius={3}
																/>
																{t("Warehouses.unpackAll")}
															</Button>
														</PermissionsChecked>
													)}
													<Grid
														templateColumns={
															isListFormat
																? "repeat(1, 1fr)"
																: [
																		"repeat(1, 1fr)",
																		"repeat(1, 1fr)",
																		"repeat(2, 1fr)",
																		"repeat(2, 1fr)",
																		"repeat(3, 1fr)",
																	]
														}
														gap={[2, 3, 4]}
													>
														{copyPackedItems?.length > 0 && (
															<LoadingHOC isLoading={isOrdersGetted}>
																{readyToSendItems}
															</LoadingHOC>
														)}
													</Grid>
													{isOpen && (
														<MaterialComment
															isMyOrder={orderDetails?.my_order}
															setDisabledForm={setDisabledForm}
															orderState={orderState}
															orderId={packedItems[currentProductId].id}
															isOpen={isOpen}
															onClose={onClose}
															comment={packedItems[currentProductId].comment}
														/>
													)}
												</AccordionPanel>
											</AccordionItem>
										</Accordion>
									</>
								)}
								{orderState === 1 && (
									<PermissionsChecked
										permissions={"order"}
										checkingRequired={!orderDetails?.my_order}
										allowedPermissions={["edit_order"]}
									>
										<OrderMaterialsGroups
											isDisabled={disabledForm}
											setDisabledForm={setDisabledForm}
											setIsOrdersGetted={setIsOrdersGetted}
											setOrders={setOrders}
											orderId={orderId}
											setOrdersCopy={setOrdersCopy}
											setAccordionRef={setAccordionRef}
										/>
									</PermissionsChecked>
								)}
								{(orderState === 2 || orderState === 3) && (
									<OrderedMaterials
										isDisabled={disabledForm}
										handleSorting={handleSorting}
										isDescSort={isOrderDescSort}
										isMyOrder={orderDetails?.my_order}
										setDisabledForm={setDisabledForm}
										unpackingSearchText={unpackingSearchText}
										setUnpackingSearchText={setUnpackingSearchText}
										setSearchText={setSearchText}
										setOrders={setOrders}
										orderState={orderState}
										originalArray={ordersCopy}
										orders={orders}
										handlePacking={handlePacking}
										handleOrderCountChange={handleOrderCountChange}
										searchBarBreakpoints={searchBarBreakpoints}
										isOrdersGetted={isOrdersGetted}
									/>
								)}
							</GridItem>
							<GridItem display={orderState !== 1 && "none"}>
								<Box
									ms={[0, 0, 0, 2]}
									boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"}
									borderRadius='4px'
									bgColor='#FFF'
									maxH='80vh'
									overflowY={"auto"}
									mb={[2, 2, 2, 0]}
									p={[1, 3, 3, 4]}
								>
									<Flex justifyContent='space-between' alignItems='center' mb={4}>
										<Flex gap={2}>
											<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
												{t("Order.order")}
											</Text>
											<Text fontSize={[13, 14, 15, 16]} color='#2B807E'>
												({orders.length})
											</Text>
										</Flex>
										<Box display='inline-flex' gap={2}>
											<SearchBar
												fieldsToFilter={["name"]}
												isDisabled={disabledForm}
												searchText={searchText}
												setSearchText={setSearchText}
												handleSetResults={setOrders}
												originalArray={ordersCopy}
												buttonStyles={{
													height: searchBarBreakpoints,
													width: searchBarBreakpoints,
												}}
												h={searchBarBreakpoints}
												type='search'
											/>
											<Button
												isDisabled={disabledForm || !ordersCopy.length}
												bgColor='#FFF'
												p={0}
												minW='30px'
												boxSize={["30px", "30px", "35px"]}
												border='1px solid #D4D4CD'
												borderRadius='4px'
												onClick={handleSorting}
												aria-label='Sorting button'
											>
												{isOrderDescSort ? <BsSortUp /> : <BsSortDown />}
											</Button>
											<Button
												bgColor='#FFF'
												p={0}
												minW='30px'
												boxSize={["30px", "30px", "35px"]}
												border='1px solid #D4D4CD'
												borderRadius='4px'
												onClick={handleChangeExpand}
												aria-label='Expand accordion button'
											>
												<ExpandedIcon boxSize={[4, 4, 5]} />
											</Button>
										</Box>
									</Flex>
									<LoadingHOC isLoading={isOrdersGetted} size='sm'>
										{draggableAccordionList}
									</LoadingHOC>
								</Box>
							</GridItem>
						</Grid>
					</LoadingHOC>
					<ConfirmModal
						isOpen={isOpenConfirmModal}
						onClose={onCloseConfirmModal}
						handleConfirm={handleConfirmChangeStatus}
					/>
				</Container>
			</DndProvider>
		</Animation>
	)
}

export default Order
