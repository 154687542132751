import { createIcon } from "@chakra-ui/react"

export const DcoumentIcon = createIcon({
	displayName: "DcoumentIcon",
	viewBox: "0 0 16 20",
	path: (
		<>
			<path
				fill='currentColor'
				d='M2.773 1.386a1.386 1.386 0 00-1.387 1.387v13.863a1.387 1.387 0 001.387 1.387h9.704a1.386 1.386 0 001.387-1.387V8.2a.693.693 0 00-.203-.49L7.54 1.59a.693.693 0 00-.49-.203H2.773zM.813.812A2.773 2.773 0 012.772 0H7.05c.551 0 1.08.22 1.47.609l6.12 6.12c.39.39.609.919.609 1.47v8.437a2.773 2.773 0 01-2.773 2.773H2.773A2.773 2.773 0 010 16.636V2.773c0-.736.292-1.44.812-1.96z'
			/>
			<path
				fill='currentColor'
				d='M7.624.347c.383 0 .693.31.693.693v5.199a.693.693 0 00.693.693h5.2a.693.693 0 010 1.386h-5.2a2.08 2.08 0 01-2.08-2.08V1.04c0-.383.311-.693.694-.693zM3.464 11.09c0-.383.311-.693.694-.693h6.932a.693.693 0 110 1.386H4.158a.693.693 0 01-.693-.693zm0 3.466c0-.383.311-.693.694-.693h6.932a.693.693 0 110 1.386H4.158a.693.693 0 01-.693-.693z'
			/>
		</>
	),
})
