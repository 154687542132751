export const FUNCTIONALITIES = {
	ORDERS: {
		MATERIALS: "/materials",
		SHOP: "/shop",
		ORDERLIST: "/order-list",
		HISTORY: "/history",
		SETTINGS: "/settings",
	},
	USERS: {
		GROUPS: "/groups",
		EMPLOYEES: "/employees",
		CONTRACTORS: "/contractors",
		SETTINGS: "/settings",
	},
	WAREHOUSE: {
		MATERIALS: "/materials",
		WAREHOUSES: "/warehouses",
		SETTINGS: "/settings",
	},
}
