import { SearchIcon } from "@chakra-ui/icons"
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react"
import { memo, useCallback } from "react"
import { useTranslation } from "react-i18next"

const StaticSearch = ({
	isDisabled,
	searchText,
	setSearchText,
	originalArray,
	handleSetResults,
	fieldsToFilter,
	dependencies,
	...props
}) => {
	const { t } = useTranslation("global")

	const handleFilterByDependencies = (item) =>
		dependencies.every((field) => {
			const fieldValue = item[field.name]?.toString().toLowerCase()
			return typeof fieldValue && (field.value === "" || fieldValue === field.value.toString().toLowerCase())
		})

	const handleSearching = useCallback(
		(searchValue) => {
			if (!originalArray) return
			setSearchText(searchValue)
			if (searchValue === "") {
				if (!dependencies) handleSetResults(originalArray)
				else {
					const filteredGroups = originalArray.filter((item) => handleFilterByDependencies(item))
					handleSetResults(filteredGroups)
				}
			} else {
				const filteredGroups = originalArray.filter((item) => {
					const searchingResults = fieldsToFilter.some((field) => {
						const fieldValue = item[field]?.toString().toLowerCase()
						return (
							typeof fieldValue && (searchValue === "" || fieldValue.includes(searchValue.toLowerCase()))
						)
					})
					if (!dependencies) return searchingResults

					return searchingResults && handleFilterByDependencies(item)
				})
				handleSetResults(filteredGroups)
			}
		},
		[setSearchText, originalArray, fieldsToFilter, handleSetResults],
	)

	return (
		<InputGroup justifyContent='end'>
			<Input
				aria-label='Search input'
				bgColor={"#fff"}
				w={["200px", "auto"]}
				h={["30px", "35px", "40px"]}
				fontSize={[13, 14, 15, 16]}
				boxShadow={"0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);"}
				borderRadius={"4px"}
				placeholder={t("SearchBar.searchPlaceholder")}
				value={searchText}
				isDisabled={isDisabled}
				onChange={(e) => handleSearching(e.target.value)}
				{...props}
			/>
			<InputRightElement h={["30px", "35px", "40px"]}>
				<SearchIcon />
			</InputRightElement>
		</InputGroup>
	)
}

export default memo(StaticSearch)
