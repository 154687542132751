import errorUpdateToast from "../notifications/updateToasts/errorUpdateToast"
import infoUpdateToast from "../notifications/updateToasts/infoUpdateToast"
import redirectUpdateToast from "../notifications/updateToasts/redirectUpdateToast"
import successUpdateToast from "../notifications/updateToasts/successUpdateToast"
import warnUpdateToast from "../notifications/updateToasts/warnUpdateToast"

const statusHandlers = [
	{
		condition: (status) => status >= 100 && status <= 199,
		handler: ({ toastId, message }) => {
			infoUpdateToast(toastId, message)
		},
	},
	{
		condition: (status) => status >= 200 && status <= 299,
		handler: ({ toastId, message }) => {
			successUpdateToast(toastId, message)
		},
	},
	{
		condition: (status) => status >= 300 && status <= 399,
		handler: ({ toastId, message }) => {
			redirectUpdateToast(toastId, message)
		},
	},
	{
		condition: (status) => status >= 400 && status <= 499,
		handler: ({ toastId, message }) => {
			warnUpdateToast(toastId, message)
		},
	},
	{
		condition: (status) => status >= 500 && status <= 599,
		handler: ({ toastId, message }) => {
			errorUpdateToast(toastId, message)
		},
	},
]

export const matchingHandler = (status) => statusHandlers.find(({ condition }) => condition(status))
