import { Box, createIcon } from "@chakra-ui/react"

const Warehouse = createIcon({
	displayName: "Warehouse",
	viewBox: "0.3 0.5 18 22",
	path: (
		<Box as='svg' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<image
				width='22'
				height='22'
				x='0'
				y='0'
				href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAQAAABuvaSwAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ cwAACxMAAAsTAQCanBgAAAAHdElNRQfoAxQPEhYpZAvoAAABMklEQVQoz5XSvy5EURAG8J/NFkJh NUTlKkQluzrlpUG3olNt0NMpEVHzBiiUIqJQye4LSCQe4PIEezcaqqtwdnN37fozU5zzzflm5pvM oddG7XiUqPnFSg40NZ248CaxJepPnHAk1XSoBCLHEi3nvQmxB5ln24Z7StQkMpdmv+CyukxDHJ6r zoO3I+saMnWbBQ8+LIk1wtOIoeClELkRW5S6IlP9be7QMSt2QPQD8eXraJNX3f9AXvCUJw9jZgA1 aasv5oIH4WzZ99Evq5C7D6mqmLJrqn+LfOWaa3eerAySXuxCG6gMnrPgH5avfAgmA9rrbHCA5mmp dxA59WoMrf4yIrfOrHVw7Fo9v9nuAWvezOfwTreUbnL6baa0lzwnVib83zmUjaPckVBBGRLZHz35 BDgoVxFWStYxAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDI0LTAzLTIwVDE0OjE4OjIyKzAxOjAwxb6G eQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyNC0wMy0yMFQxNDoxODoyMiswMTowMLTjPsUAAAAASUVO RK5CYII='
			/>
		</Box>
	),
})

export default Warehouse
