import { Image, Tab, Text } from "@chakra-ui/react"
import { useSwiper } from "swiper/react"

const AvailableTabs = ({ tab, index }) => {
	const swiper = useSwiper()

	return (
		<Tab
			w='100%'
			minH='55px'
			py={0}
			px={2}
			borderRadius='4px'
			border='none'
			bgColor='rgba(255, 255, 255, 0.50)'
			borderColor='rgba(255, 255, 255, 0.50)'
			position={"relative"}
			fontSize={[13, 14, 15, 16]}
			color={tab.color}
			_selected={{ bgColor: tab.activeBgColor || "#FFF", color: tab.activeColor }}
			onClick={() => swiper.slideTo(index)}
		>
			{tab.icon &&
				(typeof tab.icon === "string" ? (
					<Image src={tab.icon} alt='Tab icon' boxSize={[5, 6, 7]} objectFit={"contain"} />
				) : (
					tab.icon
				))}
			<Text wordBreak={"break-word"} fontSize={[13, 14, 15, 16]} fontWeight={600} ms={2}>
				{tab.name}
			</Text>
		</Tab>
	)
}

export default AvailableTabs
