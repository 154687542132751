import { Tr, Td } from "@chakra-ui/react"
import { Input } from "@chakra-ui/react"
import { Checkbox } from "@chakra-ui/react"
import { useEffect, useState } from "react"

function HoursTableRow({ data, onUpdate }) {
	const [coffee, setCoffee] = useState(data)
	/*const [hoursToWork, setHoursToWork] = useState(data.hours_work)
	const [costPerHour, setCostPerHour] = useState(data.hours_costs)
	const [coffeeHours, setCoffeeHours] = useState(data.hours_coffee)
	const [costPerCoffee, setCostPerCoffee] = useState(data.hours_costs_coffe)
	const [dismissedCoffeeHours, setDismissedCoffeeHours] = useState(data.hours_coffee_daily)
	const [autoCoffee, setAutoCoffee] = useState(data.hours_auto)
	const [weekly, setWeekly] = useState(data.hours_weekly)*/

	useEffect(() => {
		console.log(coffee)
	}, [coffee])

	// eslint-disable-next-line no-unused-vars
	function handleCoffeeChange(e) {
		console.log(e)
		let new_coffee = JSON.parse(JSON.stringify(coffee))
		new_coffee[e.target.id] = e.target.value ?? e.target.checked
		setCoffee(new_coffee)
		handleUpdate(new_coffee)
	}
	// eslint-disable-next-line no-unused-vars
	function handleUpdate(element) {
		onUpdate(element)
		console.table(coffee)
	}
	/*	function handleYearChange(e) {
		setYear(e.target.value)
		handleUpdate()
	}

	function handleHoursToWorkChange(e) {
		setHoursToWork(e.target.value)
		handleUpdate()
	}

	function handleCostPerHourChange(e) {
		setCostPerHour(e.target.value)
		handleUpdate()
	}

	function handleCoffeeHoursChange(e) {
		setCoffeeHours(e.target.value)
		handleUpdate()
	}

	function handleCostPerCoffeeChange(e) {
		setCostPerCoffee(e.target.value)
		handleUpdate()
	}

	function handleDismissedCoffeeHoursChange(e) {
		setDismissedCoffeeHours(e.target.value)
		handleUpdate()
	}

	function handleAutoCoffeeChange(e) {
		setAutoCoffee(e.target.checked)
		handleUpdate()
	}

	function handleWeeklyChange(e) {
		setWeekly(e.target.checked)
		handleUpdate()
	}*/

	return (
		<Tr>
			<Td>
				<Input id='year' type='number' defaultValue={coffee.year} onChange={handleCoffeeChange} />
			</Td>
			<Td>
				<Input id='hours_work' type='number' defaultValue={coffee.hours_work} onChange={handleCoffeeChange} />
			</Td>
			<Td>
				<Input id='hours_costs' type='number' defaultValue={coffee.hours_costs} onChange={handleCoffeeChange} />
			</Td>
			<Td>
				<Input
					id='hours_coffee'
					type='number'
					defaultValue={coffee.hours_coffee}
					onChange={handleCoffeeChange}
				/>
			</Td>
			<Td>
				<Input
					id='hours_costs_coffe'
					type='number'
					defaultValue={coffee.hours_costs_coffe}
					onChange={handleCoffeeChange}
				/>
			</Td>
			<Td>
				<Input
					id='hours_coffee_daily'
					type='number'
					defaultValue={coffee.hours_coffee_daily}
					onChange={handleCoffeeChange}
				/>
			</Td>
			<Td>
				<Checkbox id='hours_auto' isChecked={coffee.hours_auto} onChange={handleCoffeeChange} />
			</Td>
			<Td>
				<Checkbox id='hours_weekly' isChecked={coffee.hours_weekly} onChange={handleCoffeeChange} />
			</Td>
		</Tr>
	)
}

export default HoursTableRow
