import * as Yup from "yup"
import FormikForm from "../../../../common/FormikForm"
import { useTranslation } from "react-i18next"
import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FastField } from "formik"
import { useNavigate } from "react-router-dom"

import WhiteBackground from "../../../../common/WhiteBackground"
import TextField from "../../../../common/TextField"
import LanguageInputs from "../../../../common/LanguageInputs"
import AlertCancelChanges from "../../../../common/AlertCancelChanges"
import { updateWarehouse } from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"

const WarehouseEditForm = ({ initValues }) => {
	const { t, i18n } = useTranslation("global")
	const { isOpen, onOpen, onClose } = useDisclosure()
	const navigate = useNavigate()

	return (
		<FormikForm
			isPatchMethod
			shouldNavigate
			initialValues={initValues || { name_pl: "", name_en: "", name_nl: "" }}
			validationSchema={Yup.object().shape({
				[`name_${i18n.language}`]: Yup.string().required(t("Warehouses.warehouseNameIsRequired")),
			})}
			onSubmit={updateWarehouse}
		>
			<Flex flexDirection={"column"} gap={4} mt={7}>
				<WhiteBackground>
					<Flex flexDirection={"column"} gap={3}>
						<FastField>
							{({ form }) => (
								<Flex
									flexDirection={["column", "row"]}
									alignItems={["start", "center"]}
									gap={[0, 2]}
									w={["100%", "60%", "50%", "30%", "20%"]}
								>
									<Text
										fontSize={[13, 14, 15, 16]}
										mb={["1", "1", "1", "2"]}
										minW={["120px"]}
										color='#2C7A7B'
									>
										{t("Warehouses.warehouseName")}
									</Text>
									<Flex gap={2}>
										<TextField
											isDisabled={form.isSubmitting}
											fontSize={[13, 14, 15, 16]}
											name={`name_${i18n.language}`}
											h={["35px", "35px", "40px"]}
											minW={["100%", "250px"]}
											placeholder={t("Warehouses.addWarehouseName")}
										/>
										<LanguageInputs
											name='name'
											title={t("Warehouses.warehouseName")}
											isDisabled={form.isSubmitting}
										/>
									</Flex>
								</Flex>
							)}
						</FastField>
					</Flex>
				</WhiteBackground>
			</Flex>
			<Flex justifyContent={"end"} mt={7}>
				<Button
					bgColor='red.600'
					_hover={{ backgroundColor: "red.700" }}
					colorScheme='red'
					color='#FFF'
					me={2}
					h={["30px", "35px", "40px"]}
					fontSize={[13, 14, 15, 16]}
					onClick={onOpen}
				>
					<Text aria-label='Cancel button'>{t("Groups.cancel")}</Text>
				</Button>
				<Button
					type='submit'
					bgColor='green.600'
					_hover={{ backgroundColor: "green.700" }}
					colorScheme='green'
					color='#FFF'
					h={["30px", "35px", "40px"]}
					fontSize={[13, 14, 15, 16]}
				>
					<Text aria-label='Save button'>{t("Groups.save")}</Text>
				</Button>
			</Flex>
			<AlertCancelChanges isOpen={isOpen} onClose={onClose} handleConfirmCancel={() => navigate("..")} />
		</FormikForm>
	)
}

export default WarehouseEditForm
