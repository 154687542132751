import { Box, Divider, Heading, Text } from "@chakra-ui/react"
import { memo } from "react"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import * as Yup from "yup"

import FormikForm from "../../../../common/FormikForm"
import WarehouseRoles from "./WarehouseRoles"
import { addWarehouseRoles } from "../../../../../actions/modules/warehouse/settings/settings-actions"

const WarehouseRolesForm = ({ initialData }) => {
	const { t } = useTranslation("global")

	const saveMiddleware = (values) => {
		const data = values.warehouseRoles.map((role) => {
			const newRole = {
				id: role.id,
				name_pl: role.name_pl,
				name_en: role.name_en,
				name_nl: role.name_nl,
				add_warehouse: Number(role.add_warehouse),
				warehouse_preview: Number(role.warehouse_preview),
				document_preview: Number(role.document_preview),
				document_edit: Number(role.document_edit),
				document_remove: Number(role.document_remove),
				inventory: Number(role.inventory),
			}
			!role.id && delete newRole.id
			return newRole
		})
		return { data }
	}

	return (
		<FormikForm
			enableReinitialize={true}
			shouldNavigate={false}
			initialValues={{
				warehouseRoles: initialData?.config.length
					? initialData?.config
					: [{ name_pl: "", name_en: "", name_nl: "" }],
			}}
			validationSchema={Yup.object().shape({
				warehouseRoles: Yup.array().of(
					Yup.object().shape({
						[`name_${i18next.language}`]: Yup.string().required(t("OrdersSettings.roleNameRequired")),
					}),
				),
			})}
			middleware={saveMiddleware}
			onSubmit={addWarehouseRoles}
		>
			<Box minH={["300px", "400px", "500px", "500px"]}>
				<Heading fontSize={[14, 15, 16, 17]} fontWeight={400}>
					{t("UsersSettings.usersRolesInModules")}
				</Heading>
				<Text fontSize={[13, 14, 15, 16]} color='teal.600'>
					{t("UsersSettings.rolesList")}
				</Text>
				<Divider my={2} w='100%' borderColor='#D4D4CD' borderWidth='1px' />
				<WarehouseRoles />
			</Box>
		</FormikForm>
	)
}

export default memo(WarehouseRolesForm)
