import { Box, Button, Container, Divider, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react"
import { AddIcon } from "@chakra-ui/icons"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Animation from "../../../../utils/animations/Routes/AnimatedPage"
import BackForwardButtons from "../../../../components/common/BackForwardButtons"
import EmployeesList from "../../../../components/Modules/Users/Employees/EmployeesList/EmployeesList"
import { clearEmployees, sorting } from "../../../../store/modules/users/employees/employees-slice"
import { getEmployees } from "../../../../actions/modules/users/employees/employees-actions"
import LoadingHOC from "../../../../components/common/LoadingHOC"
import SelectWithFilteration from "../../../../components/common/SelectWithFilteration"
import ModuleHeading from "../../../../components/common/ModuleHeading"
import PermissionsChecked from "../../../../components/common/PermissionsChecked"
import StaticSearch from "../../../../components/common/StaticSearch"

const Employees = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const employeesList = useSelector((state) => state.employees.employees)
	const navigate = useNavigate()
	const location = useLocation()
	const [searchingTerm, setSearchingTerm] = useState("")
	const [employees, setEmployees] = useState()
	const [employeesCopy, setEmployeesCopy] = useState()
	const [filterGroupValue, setFilterGroupValue] = useState("")
	const [filterAccessValue, setFilterAccessValue] = useState("")
	const [isFiredFilterValue, setIsFiredFilterValue] = useState(false)
	const [paginationLength, setPaginationLength] = useState(0)

	useEffect(() => {
		dispatch(getEmployees())

		return () => dispatch(clearEmployees())
	}, [dispatch])

	useEffect(() => {
		if (!employees && employeesList?.employees !== employees) {
			const activeEmployees = employeesList.employees.filter(
				(employee) => employee.is_fired === isFiredFilterValue,
			)
			setEmployees(activeEmployees.slice(0, 25))
			setEmployeesCopy(activeEmployees)
			setPaginationLength(activeEmployees.length || 0)
		}
	}, [employees, employeesList])

	const getSortedArray = useCallback(
		(array, isDesc, property = "name") => {
			const sortedEmployees = array ? [...array] : [...employeesCopy]

			sortedEmployees.sort((a, b) => {
				const valueA = a[property].toString().toLowerCase()
				const valueB = b[property].toString().toLowerCase()

				if (!isDesc) {
					if (valueA < valueB) return -1
					if (valueA > valueB) return 1
					return 0
				}
				if (valueA > valueB) return -1
				if (valueA < valueB) return 1
				return 0
			})

			return sortedEmployees
		},
		[employeesCopy],
	)

	const handleSorting = (isSortDesc, setIsDesc, sortBy) => {
		const sortedArray = getSortedArray(employeesCopy, isSortDesc, sortBy)
		dispatch(sorting(getSortedArray(employeesList.employees, isSortDesc, sortBy)))
		setEmployeesCopy(sortedArray)
		setEmployees(sortedArray.slice(0, 25))
		setIsDesc((prevState) => !prevState)
	}

	const handleChangeDismissed = (e) => {
		let tabIndex = true
		if (e === 0) {
			tabIndex = false
		}
		setIsFiredFilterValue(tabIndex)
		filterData(filterGroupValue, filterAccessValue, tabIndex)
	}

	const filterData = useCallback(
		(group, access, released) => {
			const filtered = employeesList.employees.filter((record) => {
				const matchesSearch = record.name.toLowerCase().includes(searchingTerm.toString().toLowerCase())
				const matchesGroup =
					group === "" || record.group.toString().toLowerCase() === group.toString().toLowerCase()
				const matchesAccess =
					access === "" ||
					record.permissions_for_users.toString().toLowerCase() === access.toString().toLowerCase()
				const matchesReleased = record.is_fired === released

				return matchesSearch && matchesGroup && matchesAccess && matchesReleased
			})
			setEmployeesCopy(filtered)
			setEmployees(filtered.slice(0, 25))
			setPaginationLength(filtered.length)
		},
		[employeesList?.employees, searchingTerm],
	)

	const handleChangePage = (page) => {
		const start = (page - 1) * 25
		const end = start + 25
		const newEmployeesList = employeesCopy.slice(start, end)
		setEmployees(newEmployeesList)
	}

	const handleSetResults = (filteredArray) => {
		setEmployees(filteredArray.slice(0, 25))
		setEmployeesCopy(filteredArray)
		setPaginationLength(filteredArray.length)
	}

	const handleNavigateToCreate = () =>
		navigate(location.pathname.endsWith("/") ? `${location.pathname}add` : `${location.pathname}/add`)

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box
					display='flex'
					justifyContent='space-between'
					flexWrap={{ base: "wrap", md: "nowrap" }}
					mt={2}
					gap={[2]}
				>
					<Box display='inline-flex' alignItems='flex-start' mb={[2, 2, 2, 2, 0]}>
						<BackForwardButtons />
						<ModuleHeading heading={t("Employees.heading")} />
					</Box>
					<Box
						display='inline-flex'
						w={"100%"}
						flexDirection={{ base: "column", md: "row" }}
						alignItems={{ base: "end", md: "center" }}
						justifyContent={"end"}
						flexWrap={"wrap"}
						gap={5}
					>
						<Flex gap={2} w='100%' justifyContent={"flex-end"}>
							<StaticSearch
								fieldsToFilter={["name"]}
								isDisabled={!employeesList?.employees.length}
								searchText={searchingTerm}
								setSearchText={setSearchingTerm}
								handleSetResults={handleSetResults}
								originalArray={employeesList?.employees}
								dependencies={[
									{ name: "group", value: filterGroupValue },
									{ name: "permissions_for_users", value: filterAccessValue },
									{ name: "is_fired", value: isFiredFilterValue },
								]}
							/>
							<PermissionsChecked permissions={"user"} allowedPermissions={["add_user"]}>
								<Button
									aria-label='Add button'
									bgColor='#319795'
									color='#FFF'
									colorScheme='teal'
									minW='30px'
									boxSize={["30px", "35px", "40px"]}
									boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
									onClick={handleNavigateToCreate}
								>
									<AddIcon boxSize={5} />
								</Button>
							</PermissionsChecked>
						</Flex>

						<Flex alignItems={"center"} gap={2}>
							<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
								{t("Employees.group")}
							</Text>
							<SelectWithFilteration
								isDisabled={!employeesList?.groups.length}
								name='group'
								value={filterGroupValue}
								setFilterValue={setFilterGroupValue}
								searchingBy={{ name: "name", value: searchingTerm }}
								filterFields={[
									{ name: "group", value: filterGroupValue },
									{ name: "permissions_for_users", value: filterAccessValue },
									{ name: "is_fired", value: isFiredFilterValue },
								]}
								values={employeesList?.groups}
								array={employeesList?.employees}
								placeholder={t("Employees.select")}
								handleSetResults={handleSetResults}
							/>
						</Flex>
						<Flex alignItems={"center"} gap={2}>
							<Text
								fontSize={[13, 14, 15, 16]}
								color='#2C7A7B'
								whiteSpace={"pre-wrap"}
								wordBreak={"break-all"}
							>
								{t("Employees.permissionsForUsers")}
							</Text>
							<SelectWithFilteration
								isDisabled={!employeesList?.permissions_for_users.length}
								name='permissions_for_users'
								value={filterAccessValue}
								setFilterValue={setFilterAccessValue}
								searchingBy={{ name: "name", value: searchingTerm }}
								filterFields={[
									{ name: "group", value: filterGroupValue },
									{ name: "permissions_for_users", value: filterAccessValue },
									{ name: "is_fired", value: isFiredFilterValue },
								]}
								values={employeesList?.permissions_for_users}
								array={employeesList?.employees}
								placeholder={t("Employees.select")}
								handleSetResults={handleSetResults}
							/>
						</Flex>
					</Box>
				</Box>
				<Divider borderColor='#FFF' borderWidth={["1px", "2px"]} my={4} />
				<Tabs onChange={(index) => handleChangeDismissed(index)}>
					<TabList>
						<Tab>{t("Employees.employees")}</Tab>
						<PermissionsChecked permissions={"user"} allowedPermissions={["check_fired_users"]}>
							<Tab>{t("Employees.dismissed")}</Tab>
						</PermissionsChecked>
					</TabList>

					<TabPanels>
						<TabPanel>
							<LoadingHOC isLoading={employees}>
								{employees?.length ? (
									<EmployeesList
										employees={employees}
										employeesCopy={employeesCopy}
										paginationLength={paginationLength}
										handleChangePage={handleChangePage}
										handleSorting={handleSorting}
										isFired={false}
									/>
								) : (
									<Flex justifyContent='center' alignItems='center' h='80%'>
										<Text fontSize='24px' letterSpacing='4px' fontWeight='600' color='#05050580'>
											{t("Employees.noData")}
										</Text>
									</Flex>
								)}
							</LoadingHOC>
						</TabPanel>
						<TabPanel>
							<LoadingHOC isLoading={employees}>
								{employees?.length ? (
									<EmployeesList
										employees={employees}
										employeesCopy={employeesCopy}
										paginationLength={paginationLength}
										handleChangePage={handleChangePage}
										handleSorting={handleSorting}
										isFired={true}
									/>
								) : (
									<Flex justifyContent='center' alignItems='center' h='80%'>
										<Text fontSize='24px' letterSpacing='4px' fontWeight='600' color='#05050580'>
											{t("Employees.noData")}
										</Text>
									</Flex>
								)}
							</LoadingHOC>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Container>
		</Animation>
	)
}

export default Employees
