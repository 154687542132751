import { Container, Flex, Heading } from "@chakra-ui/layout"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import BackForwardButtons from "../../../../../components/common/BackForwardButtons"
import EmployeeTabs from "../../../../../components/Modules/Users/Employees/EmployeeTabs/EmployeeTabs"
import {
	createNewEmployee,
	getEmployeeFormInitialDates,
} from "../../../../../actions/modules/users/employees/employees-actions"
import { changeInitDataLanguage, clearInitialDates } from "../../../../../store/modules/users/employees/employees-slice"

const AddEmployee = () => {
	const { t, i18n } = useTranslation("global")
	const dispatch = useDispatch()

	const [initialValues] = useState({
		first_name: "",
		last_name: "",
		initials: "",
		email: "",
		phone: "",
		login: "",
		password: "",
		contractor: "",
		coffee: "",
		permissions_for_users: "",
		function: "",
		group: "",
		language: "",
		employed_since: "",
		fired: "",
		date_of_birth: "",
		pesel: "",
		orders_for_others: false,
		subject_to_ISO: false,
		background_color: "",
		text_color: "",
	})
	const employeeInitialDates = useSelector((state) => state.employees.initialDates)

	useEffect(() => {
		dispatch(changeInitDataLanguage())
	}, [i18n.language])

	useEffect(() => {
		dispatch(getEmployeeFormInitialDates())

		return () => dispatch(clearInitialDates())
	}, [])

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Flex alignItems={"center"} gap={3} mb={5}>
					<BackForwardButtons />
					<Heading
						whiteSpace={"pre-wrap"}
						wordBreak={"break-all"}
						fontWeight={600}
						fontSize={["15", "16", "17", "18", "19"]}
					>
						{t("Employees.addHeading")}
					</Heading>
				</Flex>
				<EmployeeTabs
					initialValues={initialValues}
					employeeCoffee={initialValues}
					employeeInitialDates={employeeInitialDates}
					onSubmit={createNewEmployee}
				/>
			</Container>
		</Animation>
	)
}

export default AddEmployee
