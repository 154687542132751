import { extendTheme } from "@chakra-ui/react"

const theme = {
	config: {},
	fonts: {
		body: `'Inter', system-ui`,
	},
	styles: {
		global: {
			".permissions-accordion-item > .chakra-collapse": {
				overflow: "initial !important",
			},
			".material-checkbox > span > div > svg": {
				strokeWidth: "1 !important",
			},
			".materials-checkbox > span": {
				_disabled: {
					backgroundColor: "#FFF",
				},
			},
			code: {},
			body: {
				backgroundColor: "#E5E5DF",
				WebkitFontSmoothing: "antialiased",
				MozOsxFontSmoothing: "grayscale",
			},
			breakPoints: {
				sm: "30em",
				md: "48em",
				lg: "62em",
				xl: "80em",
				"2xl": "96em",
			},
		},
	},
}

export default extendTheme(theme)
