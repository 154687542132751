import { Box, Container, Divider } from "@chakra-ui/react"
import Animation from "../../../../utils/animations/Routes/AnimatedPage"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getOrdersSettings } from "../../../../actions/modules/orders/ordersSettings/orders-settings-actions"
import { clearOrdersSettings } from "../../../../store/modules/orders/orders-settings/order-settings-slice"
import OrdersUsersInGroupForm from "../../../../components/Modules/Orders/OrdersSettings/OrdersUsersInGroupForm/OrdersUsersInGroupForm"
import OrdersUsersRolesForm from "../../../../components/Modules/Orders/OrdersSettings/OrdersUsersRolesForm/OrdersUsersRolesForm"
import OrdersAutoEmailForm from "../../../../components/Modules/Orders/OrdersSettings/OrdersAutoEmailForm/OrdersAutoEmailForm"
import OrdersSettingsForm from "../../../../components/Modules/Orders/OrdersSettings/OrdersSettingsForm/OrdersSettingsForm"
import TabsFrame from "../../../../components/common/TabsFrame"
import LoadingHOC from "../../../../components/common/LoadingHOC"
import BackForwardButtons from "../../../../components/common/BackForwardButtons"
import ModuleHeading from "../../../../components/common/ModuleHeading"

const OrdersSettings = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const ordersSettings = useSelector((state) => state.ordersSettings.ordersSettings)
	const [settings, setSettings] = useState()
	const [isGettedSettings, setIsGettedSettings] = useState(false)

	useEffect(() => {
		dispatch(getOrdersSettings())
		return () => dispatch(clearOrdersSettings())
	}, [dispatch])

	useEffect(() => {
		if (ordersSettings && ordersSettings !== settings) {
			setSettings(ordersSettings)
			setIsGettedSettings(true)
		}
	}, [ordersSettings, settings])

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box display='inline-flex' alignItems='center' mb={[2, 2, 2, 0]}>
					<BackForwardButtons />
					<ModuleHeading heading={t("OrdersSettings.heading")} />
				</Box>
				<Box h={["25px", "auto"]}>
					<Divider borderColor='#FFF' borderWidth={["1px", "2px"]} my={[2, 3, 4]} />
				</Box>
				<LoadingHOC isLoading={isGettedSettings}>
					<TabsFrame
						tabs={[
							{ name: t("OrdersSettings.usersRoles") },
							{ name: t("OrdersSettings.usersInGroups") },
							{ name: t("OrdersSettings.autoEmail") },
							{ name: t("OrdersSettings.settings") },
						]}
					>
						<OrdersUsersRolesForm
							initialData={ordersSettings?.order_group_permissions}
							userInGroups={ordersSettings?.user_order_group_permissions}
						/>
						<OrdersUsersInGroupForm
							initialData={ordersSettings?.user_order_group_permissions}
							groups={ordersSettings?.order_group_permissions}
						/>
						<OrdersAutoEmailForm initialData={ordersSettings?.automatic_mail} />
						<OrdersSettingsForm initialData={ordersSettings?.deleting_after_time} />
					</TabsFrame>
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default OrdersSettings
