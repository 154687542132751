import { Button } from "@chakra-ui/react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const InventoryGroupCopyButton = ({ data, groupName, parameterNames, isDisabled }) => {
	const { t } = useTranslation("global")
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)

	const copyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {})
			.catch((error) => {
				console.error("Błąd podczas kopiowania do schowka: ", error)
			})
	}

	const getConcatenateParameterValues = (values) => {
		let concatenateValues = ""
		values.forEach((value, index) => {
			concatenateValues += value
			if (values.length - 1 === index) return
			concatenateValues += " X "
		})

		return concatenateValues
	}

	const formattedData = useMemo(() => {
		if (!data) return
		const dataKeys = [t("Warehouses.material")]
		parameterNames?.forEach((parameter) => dataKeys.push(parameter.name))
		dataKeys.push(t("Warehouses.warehouseState"), t("Warehouses.factState"))

		const columnWidths = {}
		const materialMaxWidth = Math.max(...data.map((item) => item.name.length))
		columnWidths[t("Warehouses.material")] = Math.max(t("Warehouses.material").length, materialMaxWidth)
		parameterNames?.forEach((parameter, index) => {
			const parameterMaxWidth = Math.max(
				...data.map((item) => getConcatenateParameterValues(item.parameter_values[index].values).length),
			)
			columnWidths[parameter.name] = Math.max(parameter.name.length, parameterMaxWidth)
		})
		const warehouseStateMaxWidth = Math.max(...data.map((item) => item.warehouse_state.toString().length))
		columnWidths[t("Warehouses.warehouseState")] = Math.max(
			t("Warehouses.warehouseState").length,
			warehouseStateMaxWidth,
		)
		const factStateMaxWidth = Math.max(...data.map((item) => item.fact_state.toString().length))
		columnWidths[t("Warehouses.factState")] = Math.max(t("Warehouses.factState").length, factStateMaxWidth)

		let formattedText = `${currentWarehouse?.name}\n\n${groupName}\n`

		formattedText += dataKeys.map((header) => header.padEnd(columnWidths[header])).join("\t") + "\n"

		data.forEach((item) => {
			let body = ""
			body += String(item.name).padEnd(columnWidths[t("Warehouses.material")])
			body += "\t"
			item.parameter_values.forEach((parameter, index) => {
				;(body += String(getConcatenateParameterValues(parameter.values)).padEnd(
					columnWidths[parameterNames[index]?.name],
				)),
					(body += "\t")
			})
			body += String(item.warehouse_state).padEnd(columnWidths[t("Warehouses.warehouseState")])

			body += "\t"
			body += String(item.fact_state).padEnd(columnWidths[t("Warehouses.factState")])

			body += "\n"

			formattedText += body
		})

		return formattedText
	}, [data])

	const handleCopyClick = () => {
		copyToClipboard(formattedData)
	}

	return (
		<Button
			isDisabled={isDisabled}
			fontSize={[13, 14, 15, 16]}
			fontWeight={"400"}
			h={["25px", "35px", "40px"]}
			bgColor={"#FFF"}
			borderRadius={"4px"}
			boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
			onClick={handleCopyClick}
		>
			{t("Warehouses.copy")}
		</Button>
	)
}

export default InventoryGroupCopyButton
