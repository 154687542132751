import { Icon } from "@chakra-ui/react"

const BasketCartAdd = (props) => (
	<Icon viewBox='0 0 23 23' {...props}>
		<path
			fill='currentColor'
			d='M11.187 9.976h1.587V7.548h2.38v-1.62h-2.38V3.5h-1.587v2.429H8.806v1.619h2.38v2.428zm-3.175 7.286c-.873 0-1.58.729-1.58 1.619S7.14 20.5 8.013 20.5c.873 0 1.587-.729 1.587-1.619s-.714-1.62-1.587-1.62zm7.936 0c-.873 0-1.579.729-1.579 1.619s.706 1.619 1.58 1.619c.872 0 1.587-.729 1.587-1.619s-.715-1.62-1.588-1.62zm-7.063-4.048h5.913c.595 0 1.119-.332 1.388-.834l3.064-5.674-1.381-.777-3.071 5.666H9.226L5.846 4.31H3.25v1.619h1.587l2.857 6.144-1.071 1.975c-.58 1.085.183 2.404 1.389 2.404h9.524v-1.619H8.012l.873-1.619z'
		/>
	</Icon>
)

export default BasketCartAdd
