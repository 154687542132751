import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { memo } from "react"

import MaterialGroupDetailsShow from "../MaterialGroupShow/MaterialGroupDetailsShow"
import MaterialGroupDetailsEdit from "../MaterialGroupEdit/MaterialGroupDetailsEdit"
import { updateMaterial } from "../../../../../actions/modules/orders/materials/materials-actions"
import { warningToast } from "../../../../../utils/notifications/warningToast"

const MaterialGroupDetailsForm = ({ language, material, isEditPreview, initialTab, defaultInitialTab }) => {
	const [t] = useTranslation("global")
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()

	const calculateTotalFilesSize = useCallback((values) => {
		let totalSize = 0

		if (values.image) {
			if (typeof values.image === "object") totalSize += Number(values.image.size)
		}

		values.materials.forEach((material) => {
			if (material.image) {
				if (typeof material.image === "object") totalSize += Number(material.image.size)
			}
			material.parameter_counter.forEach((parameter) => {
				if (!parameter.icon) return
				if (typeof parameter.icon === "object") totalSize += Number(parameter.icon.size)
			})
		})

		values.parameters.forEach((parameter) => {
			if (!parameter.icon) return
			if (typeof parameter.icon === "object") totalSize += Number(parameter.icon.size)
		})

		const totalSizeInMB = (totalSize / (1024 * 1024)).toFixed(0)

		return Number(totalSizeInMB)
	}, [])

	const handleSave = useCallback(
		(values, actions) => {
			actions.setSubmitting(true)

			const data = { ...values }
			delete data.newParameter

			if (calculateTotalFilesSize(data) < 100) {
				const navigateLink = location.pathname.split("/")
				dispatch(updateMaterial(values, language)).then(() => {
					actions.setSubmitting(false)
					navigate(`/${navigateLink[1]}/${navigateLink[2]}`)
				})
			} else {
				warningToast(t("Materials.exceededFilesLimit"))
				actions.setSubmitting(false)
			}
		},
		[calculateTotalFilesSize, dispatch, language, location.pathname, navigate, t],
	)

	return (
		<Formik
			initialValues={material}
			enableReinitialize={true}
			validateOnChange={false}
			validationSchema={Yup.object().shape({
				parameters: Yup.array().of(
					Yup.object().shape({
						[`name_${language}`]: Yup.string().required(
							t("Materials.parameterNameRequireError", { lng: language }),
						),
						values: Yup.array().of(
							Yup.object().shape({
								[`name_${language}`]: Yup.string().required(
									t("Materials.parameterValueRequireError", { lng: language }),
								),
							}),
						),
					}),
				),
				materials: Yup.array().of(
					Yup.object().shape({
						[`name_${language}`]: Yup.string().required(
							t("Materials.materialNameRequired", { lng: language }),
						),
					}),
				),
				[`name_${language}`]: Yup.string().required(t("Materials.groupNameRequired", { lng: language })),
			})}
			onSubmit={(values, actions) => {
				handleSave(values, actions)
			}}
		>
			{(formik) => (
				<Form
					onSubmit={(e) => {
						e.preventDefault()
						formik.setSubmitting(true)
						setTimeout(() => {
							formik.handleSubmit()
						}, 310)
					}}
					onClick={(e) => {
						if (e.target.name === "addParameterButton") {
							if (formik.values.newParameter[`name_${language}`] === "") {
								formik.setFieldTouched(`newParameter.name_${language}`, true, false)
								formik.setFieldError(
									`newParameter.name_${language}`,
									t("Materials.nameOfTheParameterRequireErrror", {
										lng: language,
									}),
								)
								return
							} else {
								formik.setFieldError(`newParameter.name_${language}`, "")
							}
							if (formik.values.newParameter.values.every((value) => value[`name_${language}`] === "")) {
								formik.values.newParameter.values.forEach((value, index) => {
									formik.setFieldError(
										`newParameter.values[${index}].name_${language}`,
										t("Materials.parameterValueRequireError", {
											lng: language,
										}),
									)
									formik.setFieldTouched(
										`newParameter.values[${index}].name_${language}`,
										true,
										false,
									)
								})
								return
							} else {
								formik.values.newParameter.values.forEach((value, index) => {
									formik.setFieldError(`newParameter.values[${index}].name_${language}`, "")
								})
							}

							const newParameter = formik.values.newParameter
							newParameter.values = newParameter.values.filter(
								(value) => value[`name_${language}`] !== "",
							)
							formik.setFieldValue("parameters", [
								...formik.values.parameters,
								formik.values.newParameter,
							])

							formik.setFieldValue(
								"materials",
								formik.values.materials.map((material) => {
									const parameter_counter = material.parameter_counter || []
									parameter_counter.push(newParameter)
									return {
										...material,
										parameter_counter,
									}
								}),
							)

							formik.setFieldValue("newParameter", {
								name_pl: "",
								name_en: "",
								name_nl: "",
								icon: null,
								values: [{ name_pl: "", name_en: "", name_nl: "" }],
							})
						}
					}}
				>
					{isEditPreview ? (
						<MaterialGroupDetailsEdit language={language} initialTab={defaultInitialTab} />
					) : (
						<MaterialGroupDetailsShow language={language} initialTab={initialTab} />
					)}
				</Form>
			)}
		</Formik>
	)
}

export default memo(MaterialGroupDetailsForm)
