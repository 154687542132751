import { Flex, Switch, Text, useBreakpointValue } from "@chakra-ui/react"
import { memo, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import { useDispatch } from "react-redux"

import StaticSearch from "../../../../common/StaticSearch"
import { setUserWarehouseAccess } from "../../../../../actions/modules/warehouse/settings/settings-actions"

const AccessToWarehouse = ({ data }) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const [searchingTerm, setSearchingTerm] = useState("")
	const [configCopy, setConfigCopy] = useState(data.data)
	const [config, setConfig] = useState(data.data)
	const columnWidthBreakpoints = {
		base: 100,
		sm: 125,
		md: 150,
		lg: 200,
		xl: 250,
	}
	const columnWidth = useBreakpointValue(columnWidthBreakpoints)
	const columnGapBreakpoints = {
		base: 10,
	}
	const columnGap = useBreakpointValue(columnGapBreakpoints)

	useEffect(() => {
		if (!data.data) return
		const newConfig = data.data.filter((item) =>
			item.name.toString().toLowerCase().includes(searchingTerm.toLowerCase()),
		)
		setConfigCopy(data.data)
		setConfig(newConfig)
	}, [data])

	const handleSearching = (filteredUsers) => setConfig(filteredUsers)

	const handleChangeAccess = (userId, warehouseId, access) => {
		dispatch(setUserWarehouseAccess({ userId, warehouseId, access }))
	}

	const usersAccess = useMemo(
		() =>
			config?.map((record, index) => (
				<Flex
					key={index}
					minW={{
						base: `${(data?.warehouses?.length + 1) * (columnWidth + columnGap)}px`,
						md: `${(data?.warehouses?.length + 1) * (columnWidth + columnGap)}px`,
					}}
					bgColor='#F2F2EF'
					borderRadius='4px'
				>
					<Flex
						justifyContent={"space-between"}
						gap={`${columnGap}px`}
						px={5}
						minH={{ base: "35px", md: "40px" }}
						w='100%'
					>
						<Flex
							w={`${columnWidth}px`}
							minW={`${columnWidth}px`}
							gap={1}
							alignItems={"center"}
							justifyContent={"flex-start"}
						>
							<Text
								fontSize={{ base: "12px", md: "13px", lg: "14px" }}
								fontWeight={400}
								whiteSpace={"pre-wrap"}
								wordBreak={"break-all"}
							>
								{record.name}
							</Text>
						</Flex>
						{record.access?.map((item, index) => (
							<Flex
								key={index}
								w={`${columnWidth}px`}
								minW={`${columnWidth}px`}
								gap={1}
								alignItems={"center"}
								justifyContent={"center"}
							>
								<Switch
									aria-label='Access switch'
									size={["sm", "sm", "md"]}
									colorScheme='green'
									isChecked={item.has_access}
									onChange={(e) => handleChangeAccess(record.id, item.id, e.target.checked)}
								/>
							</Flex>
						))}
					</Flex>
				</Flex>
			)),
		[columnWidth, columnGap, config, i18next.language],
	)

	return (
		<Flex flexDir={"column"}>
			<Text ms={3} fontSize={[13, 14, 15, 16]}>
				{t("WarehouseSettings.warehouseAccess")}
			</Text>
			<Text ms={3} fontSize={[13, 14, 15, 16]} color='teal.600'>
				{t("WarehouseSettings.warehouseAccessDescription")}
			</Text>
			<Flex justifyContent={"flex-end"} w='100%' mt={3}>
				<StaticSearch
					fieldsToFilter={["name"]}
					isDisabled={!configCopy?.length}
					searchText={searchingTerm}
					setSearchText={setSearchingTerm}
					handleSetResults={handleSearching}
					originalArray={configCopy}
				/>
			</Flex>
			<Flex
				flexDirection={"column"}
				mt={5}
				overflowX={"auto"}
				maxH={["300px", "325px", "350px", "400px", "700px"]}
				gap={{ base: 2, sm: 3 }}
				pb={2}
			>
				<Flex
					justifyContent={"space-between"}
					position={"sticky"}
					top='0'
					zIndex={2}
					py={{ base: 2, sm: 3, md: 5 }}
					bgColor={"#FFF"}
					gap={`${columnGap}px`}
					px={5}
					w='100%'
					minW={{
						base: `${(data?.warehouses?.length + 1) * (columnWidth + columnGap)}px`,
						md: `${(data?.warehouses?.length + 1) * (columnWidth + columnGap)}px`,
					}}
					color='teal.600'
				>
					<Flex
						w={`${columnWidth}px`}
						minW={`${columnWidth}px`}
						gap={1}
						alignItems={"center"}
						justifyContent={"flex-start"}
					>
						<Text
							fontSize={{ base: "12px", md: "13px", lg: "14px" }}
							fontWeight={400}
							whiteSpace={"pre-wrap"}
							wordBreak={"break-all"}
						>
							{t("UsersSettings.user")}
						</Text>
					</Flex>
					{data?.warehouses?.map((header, index) => (
						<Flex
							key={index}
							w={`${columnWidth}px`}
							minW={`${columnWidth}px`}
							gap={1}
							alignItems={"center"}
							justifyContent={"center"}
						>
							<Text
								fontSize={{ base: "12px", md: "13px", lg: "14px" }}
								fontWeight={400}
								whiteSpace={"pre-wrap"}
								wordBreak={"break-all"}
							>
								{header.name}
							</Text>
						</Flex>
					))}
				</Flex>
				{usersAccess}
			</Flex>
		</Flex>
	)
}

export default memo(AccessToWarehouse)
