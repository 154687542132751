import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import {
	getMaterial,
	getMaterialsGroups,
	removeMaterialGroup,
} from "../../../../actions/modules/orders/materials/materials-actions"

/**
 * Slice for materials
 */
const materialsSlice = createSlice({
	name: "materials",
	initialState: {
		materialsGroups: [],
		openMaterial: null,
	},
	reducers: {
		setSortedMaterialsGroups(state, action) {
			state.materialsGroups = action.payload
		},
		clearMaterialsGroups(state) {
			state.materialsGroups = []
		},
		clearOpenMaterial(state) {
			state.openMaterial = null
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMaterial.fulfilled, (state, action) => {
				state.openMaterial = action.payload.group
			})
			.addMatcher(isAnyOf(getMaterialsGroups.fulfilled, removeMaterialGroup.fulfilled), (state, action) => {
				state.materialsGroups = action.payload.group
			})
	},
})

export const { setSortedMaterialsGroups, clearMaterialsGroups, clearOpenMaterial } = materialsSlice.actions

export default materialsSlice
