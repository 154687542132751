import { Navigate, Outlet } from "react-router-dom"

import { useSelector } from "react-redux"

const HasFunctionalityRoutes = ({ allowedFunctionality, moduleUrl }) => {
	const permissions = useSelector((state) => state.auth.modules)
	const functionalities = permissions?.find((permission) => permission.url === moduleUrl).module_functionalities

	return functionalities?.find((functionality) => allowedFunctionality.includes(functionality.url)) ? (
		<Outlet />
	) : (
		<Navigate to='/' />
	)
}

export default HasFunctionalityRoutes
