import axios from "axios"
import i18next from "i18next"

import { URLS_WITHOUT_NOTIFICATION } from "./urlsWithoutNotification"
import loadingToast from "../notifications/loadingToast"
import { matchingHandler } from "./statusHandler"

/**
 * Get default message to request message.
 */
function getDefaultMessage() {
	switch (i18next.language) {
		case "pl":
			return "Zakończono pomyślnie"
		case "en":
			return "Operation completed successfully"
		case "nl":
			return "Operatie succesvol voltooid"
		default:
			return "Operation completed successfully"
	}
}

/**
 * Creates an axios instance with the base url and the withCredentials option set to true.
 * Also adds an interceptor to handle errors.
 * @param {Function} toast - The toast function from Chakra UI.
 */
function request() {
	let toastId
	const instance = axios.create({
		// eslint-disable-next-line no-undef
		baseURL: process.env[`REACT_APP_API_KEY_${process.env.REACT_APP_API_KEY}`],
		withCredentials: true,
	})

	instance.interceptors.request.use((config) => {
		const notify = !URLS_WITHOUT_NOTIFICATION.some((url) => url === config.url)

		if (notify) toastId = loadingToast()
		return config
	})

	instance.interceptors.response.use(
		(response) => {
			if (toastId) {
				const message = response.data.data?.message || getDefaultMessage()
				matchingHandler(response.status).handler({ toastId, message })
			}
			return response
		},
		(error) => {
			const message = error.response.data.data.message || error.response.statusText
			matchingHandler(error.response.status).handler({ toastId, message })

			throw error
		},
	)

	return instance
}

export default request
