import { Button, Heading, Image, Text, VStack } from "@chakra-ui/react"
import { Formik } from "formik"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import * as Yup from "yup"

import { changePassword } from "../../../../actions/auth/resetPassword/resetPassword-actions"
import PasswordField from "../../../common/PasswordField"

const ResetPasswordForm = () => {
	const [t] = useTranslation("global")
	const dispatch = useDispatch()
	const [searchParams] = useSearchParams()
	const { token } = useParams()
	const navigate = useNavigate()

	const email = searchParams.get("email")

	const handleSendEmail = (values, actions) => {
		actions.setSubmitting(true)
		dispatch(changePassword({ email, token, values, navigate }))
			.then(() => actions.setSubmitting(false))
			.catch(() => actions.setSubmitting(false))
	}

	return (
		<Formik
			initialValues={{
				password: "",
				confirmationPassword: "",
			}}
			validationSchema={Yup.object({
				password: Yup.string()
					.required(t("RememberPassword.changePassword.main.passwordRequired"))
					.min(8, t("RememberPassword.changePassword.main.passwordTooShort"))
					.test(
						"has-lowercase",
						t("RememberPassword.changePassword.main.passwordWithoutSmallLetter"),
						(value) => /[a-z]/.test(value),
					)
					.test(
						"has-uppercase",
						t("RememberPassword.changePassword.main.passwordWithoutBigLetter"),
						(value) => /[A-Z]/.test(value),
					)
					.test(
						"has-special-char",
						t("RememberPassword.changePassword.main.passwordWithoutSpecialChar"),
						(value) => /[!@#$%^&*()\-=_+[\]{}|\\;:'",.<>/?]/.test(value),
					),
				confirmationPassword: Yup.string()
					.required(t("RememberPassword.changePassword.main.passwordRequired"))
					.oneOf([Yup.ref("password"), null], t("RememberPassword.changePassword.main.passwordsNotEqual")),
			})}
			onSubmit={(values, actions) => {
				handleSendEmail(values, actions)
			}}
		>
			{(formik) => (
				<VStack
					as='form'
					w='80%'
					gap='0'
					position='relative'
					left='50%'
					transform='translateX(-50%)'
					onSubmit={formik.handleSubmit}
				>
					<Heading
						as='h1'
						w='90%'
						fontSize={["10", "14", "14", "16", "20"]}
						textAlign='center'
						pt={["1", "1", "1", "4", "5"]}
					>
						{t("RememberPassword.changePassword.main.heading")}
					</Heading>
					<Text
						w='100%'
						fontSize={["10", "10", "12", "12", "14"]}
						textAlign='center'
						pt={["1", "1", "1", "2", "3"]}
						pb={["1", "3", "4", "5", "6"]}
					>
						{t("RememberPassword.changePassword.main.instructionParagraph")}
					</Text>
					<PasswordField
						fontSize={["10", "14", "14", "14", "14"]}
						name='password'
						h={["30px", "35px", "45px"]}
						placeholder={t("RememberPassword.changePassword.main.passwordInput")}
						disabled={formik.isSubmitting}
					/>

					<Text
						w='100%'
						fontSize={["8", "8", "9", "10", "12"]}
						color='#727272'
						pb={["1", "1", "1", "2", "2"]}
					>
						{t("RememberPassword.changePassword.main.validationMessage")}
					</Text>

					<PasswordField
						fontSize={["8", "7", "14", "13", "13"]}
						whiteSpace='pre-wrap'
						name='confirmationPassword'
						h={["30px", "35px", "45px"]}
						placeholder={t("RememberPassword.changePassword.main.confirmationPasswordInput")}
						disabled={formik.isSubmitting}
					/>
					<Button
						fontSize={["10", "14", "14", "14", "16"]}
						type='submit'
						w='100%'
						h={["30px", "35px", "45px"]}
						bg='black'
						color='white'
						colorScheme='teal'
					>
						{t("RememberPassword.changePassword.main.resetButton")}
						<Image
							src={require("../../../../assets/icons/login/Vector.png")}
							loading='lazy'
							alt='right-arrow'
							ml={2}
						/>
					</Button>
				</VStack>
			)}
		</Formik>
	)
}

export default ResetPasswordForm
