import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons"
import { ButtonGroup, IconButton, useEditableControls } from "@chakra-ui/react"

const EditableControls = ({ isDisabled }) => {
	const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls()

	return isEditing ? (
		<ButtonGroup
			isDisabled={isDisabled}
			display='inline-flex'
			ms={2}
			alignItems='center'
			justifyContent='center'
			size='sm'
		>
			<IconButton
				isDisabled={isDisabled}
				minW={"25px"}
				boxSize={["25px", "25px", "30px"]}
				bgColor='#F2F2EF'
				icon={<CheckIcon boxSize={[3, 3, 4]} />}
				{...getSubmitButtonProps()}
			/>
			<IconButton
				isDisabled={isDisabled}
				minW={"25px"}
				boxSize={["25px", "25px", "30px"]}
				bgColor='#F2F2EF'
				icon={<CloseIcon boxSize={[3, 3, 4]} />}
				{...getCancelButtonProps()}
			/>
		</ButtonGroup>
	) : (
		<IconButton
			isDisabled={isDisabled}
			minW={"30px"}
			boxSize={["30px", "30px", "35px"]}
			bgColor='#F2F2EF'
			ms={2}
			icon={<EditIcon boxSize={4} />}
			{...getEditButtonProps()}
		/>
	)
}

export default EditableControls
