import React, { memo, useCallback } from "react"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { getDateForFiles } from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"

const InventoryExcelButton = ({ jsonData, isDisabled }) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)

	const handleExportExcel = () => {
		dispatch(getDateForFiles()).then((res) => {
			exportToExcel(res.payload?.date)
		})
	}

	const exportToExcel = useCallback(
		(date) => {
			const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
			const fileExtension = ".xlsx"

			const wsData = XLSX.utils.aoa_to_sheet([
				[currentWarehouse?.name],
				[
					t("Warehouses.materialGroup"),
					t("Warehouses.material"),
					t("Warehouses.warehouseState"),
					t("Warehouses.factState"),
				],
			])

			jsonData.forEach((item) => {
				const record = [item.name]

				XLSX.utils.sheet_add_aoa(wsData, [record], {
					origin: "A",
				})
				item.materials.forEach((material) =>
					XLSX.utils.sheet_add_aoa(wsData, [[material.name, material.warehouse_state, material.fact_state]], {
						origin: "B",
					}),
				)
			})

			const groupsRowMaxWidth = jsonData.reduce((w, r) => Math.max(w, r.name.length), 20)
			const productsRowMaxWidth = jsonData.reduce(
				(w, r) => r.materials.reduce((wM, rM) => Math.max(wM, rM.name.length), 20),
				20,
			)
			const warehouseStateRowMaxWidth = jsonData.reduce(
				(w, r) => r.materials.reduce((wM, rM) => Math.max(wM, rM.warehouse_state.toString().length), 20),
				20,
			)
			const factStateRowMaxWidth = jsonData.reduce(
				(w, r) => r.materials.reduce((wM, rM) => Math.max(wM, rM.fact_state.toString().length), 20),
				20,
			)
			wsData["!cols"] = [
				{ wch: groupsRowMaxWidth },
				{ wch: productsRowMaxWidth },
				{ wch: warehouseStateRowMaxWidth },
				{ wch: factStateRowMaxWidth },
			]

			const wb = XLSX.utils.book_new()
			XLSX.utils.book_append_sheet(wb, wsData, "data")
			const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
			const dataBlob = new Blob([excelBuffer], { type: fileType })
			FileSaver.saveAs(dataBlob, `${currentWarehouse?.name}_${date}` + fileExtension)
		},
		[jsonData],
	)

	return (
		<Button
			isDisabled={isDisabled}
			fontSize={[13, 14, 15, 16]}
			fontWeight={"400"}
			h={["25px", "35px", "40px"]}
			bgColor={"#FFF"}
			borderRadius={"4px"}
			boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
			onClick={handleExportExcel}
		>
			Excel
		</Button>
	)
}

export default memo(InventoryExcelButton)
