import {
	Button,
	FormControl,
	FormErrorMessage,
	Input,
	InputGroup,
	InputLeftElement,
	useDisclosure,
} from "@chakra-ui/react"
import { Field, useField } from "formik"
import { memo, useCallback, useEffect, useRef, useState } from "react"
import { debounce } from "lodash"

import ColorPicker from "./ColorPicker"

const InputColor = ({ bottom, ...props }) => {
	const [field, meta, helpers] = useField(props)
	const [value, setValue] = useState(field.value)
	const { isOpen, onToggle } = useDisclosure()
	const boxRef = useRef()
	const triangleRef = useRef()
	const buttonRef = useRef()
	const inputGroupRef = useRef()

	const fieldProps = {
		...field,
		...props,
		value: value,
		onChange: (e) => setValue(e.target.value),
		onBlur: () => {},
	}
	delete fieldProps?.bottom
	delete fieldProps?.name

	const handleSetColor = debounce((value) => helpers.setValue(value), 5)

	useEffect(() => {
		if (field.value !== value) {
			setValue(field.value)
		}
	}, [field.value])

	const handleOnChangeValue = useCallback(
		debounce((value) => helpers.setValue(value), 300),
		[helpers],
	)

	useEffect(() => {
		handleOnChangeValue(value)
	}, [value])

	const handleOutsideClick = useCallback(
		(event) => {
			if (
				boxRef.current &&
				!boxRef.current.contains(event.target) &&
				triangleRef.current &&
				!triangleRef.current.contains(event.target) &&
				buttonRef.current &&
				!buttonRef.current.contains(event.target) &&
				isOpen
			) {
				onToggle()
			}
		},
		[isOpen, onToggle],
	)

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick)
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick)
		}
	}, [handleOutsideClick])

	return (
		<FormControl mb={["1", "1", "1", "2"]} isInvalid={meta.error && meta.touched}>
			<InputGroup zIndex={1} ref={inputGroupRef}>
				<InputLeftElement h='100%'>
					<Button
						aria-label='Color button'
						ref={buttonRef}
						isDisabled={props.isDisabled}
						p={0}
						minW={["23px", "23px", "25px"]}
						h={["23px", "23px", "25px"]}
						colorScheme={"none"}
						border={
							(field.value === "" ||
								(field.value.charAt(0) === "#" && field.value.toLowerCase().split("f").length >= 3)) &&
							"1px solid #000"
						}
						borderRadius={"4px"}
						bgColor={field.value || "#FFFFFF"}
						onClick={onToggle}
					/>
				</InputLeftElement>
				<Field as={Input} ps={"40px"} h={["35px", "35px", "40px"]} autoComplete='false' {...fieldProps} />
			</InputGroup>
			<ColorPicker
				isOpen={isOpen}
				color={field.value}
				setColor={handleSetColor}
				boxRef={boxRef}
				triangleRef={triangleRef}
				inputGroupRef={inputGroupRef}
				bottom={bottom}
			/>
			<FormErrorMessage my='0'>{meta.error}</FormErrorMessage>
		</FormControl>
	)
}

export default memo(InputColor)
