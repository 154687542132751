import Animation from "../../utils/animations/Routes/AnimatedPage"
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Container,
	Divider,
	Heading,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Text,
	useMediaQuery,
} from "@chakra-ui/react"
import { SearchIcon } from "@chakra-ui/icons"
import { useTranslation } from "react-i18next"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import BsSortDown from "../../assets/icons/permissions/BsSortDown"
import BsSortUp from "../../assets/icons/permissions/BsSortUp"
import SettingsDotsIcon from "../../assets/icons/permissions/SettingsDotsIcon"
import Switch from "../../components/common/Switch"
import {
	getAvailableModules,
	setApplicationFunctionalitie,
	setApplicationModules,
} from "../../actions/permissions/permissions-actions"
import {
	clearAvailableModules,
	filterAvailableModules,
	sortAvailableModules,
} from "../../store/permissions/permissions.slice"
import BackForwardButtons from "../../components/common/BackForwardButtons"
import LoadingHOC from "../../components/common/LoadingHOC"
import i18next from "i18next"

const AvailablePermissions = () => {
	const [t] = useTranslation("global")
	const dispatch = useDispatch()
	const [isExtraSmallWidth] = useMediaQuery("(max-width: 480px)")
	const [isSmallWidth] = useMediaQuery("(max-width: 768px)")
	const [isSortDesc, setIsSortDesc] = useState(false)
	const [isGetAvailableModules, setIsGetAvailableModules] = useState(false)
	const availableModules = useSelector((state) => state.permissions.availableModules)

	useEffect(() => {
		dispatch(getAvailableModules()).then(() => setIsGetAvailableModules(true))
		return () => dispatch(clearAvailableModules())
	}, [dispatch])

	const handleSearch = useCallback(
		(e) => {
			dispatch(filterAvailableModules(e))
		},
		[dispatch],
	)

	const handleSortItems = useCallback(() => {
		setIsSortDesc((prevState) => !prevState)
		dispatch(sortAvailableModules(isSortDesc ? "desc" : "asc"))
	}, [dispatch, isSortDesc])

	const getIconsList = useCallback((icon_name, color, fontColor) => {
		const Image = require(`../../assets/icons/permissions/${icon_name ? icon_name : "ExampleIcon"}`).default
		return (
			<Icon w={["35px", "40px"]} h={["35px", "40px"]} p={2} color={fontColor} borderRadius={24} bgColor={color}>
				<Image />
			</Icon>
		)
	}, [])

	const handleFunctionalitySwitch = useCallback(
		(moduleId, moduleFunctionalityId, state) => {
			dispatch(
				setApplicationFunctionalitie({
					moduleId,
					moduleFunctionalityId,
					state,
				}),
			)
		},
		[dispatch],
	)

	const handleModuleSwitch = useCallback(
		(moduleId, state) => {
			dispatch(setApplicationModules({ moduleId, state }))
		},
		[dispatch],
	)

	const moduleFunctionalities = useCallback(
		(moduleId, functionalities, color, fontColor) => {
			return functionalities.map((functionality) => (
				<Box key={functionality.id} display='flex' justifyContent='space-between' my={[5, 5, 6]} mx={10}>
					<Box display='flex' alignItems='center'>
						{getIconsList(functionality.icon_name, color, fontColor)}
						<Text ms={2} fontSize={["15", "15", "16"]}>
							{functionality[`name_${i18next.language}`]}
						</Text>
					</Box>
					<Switch
						style={{
							width: isExtraSmallWidth ? "30px" : isSmallWidth ? "35px" : "38px",
							height: isExtraSmallWidth ? "20px" : isSmallWidth ? "21px" : "22px",
							marginTop: "10px",
						}}
						state={functionality.visibility_state}
						onChange={() =>
							handleFunctionalitySwitch(moduleId, functionality.id, functionality.visibility_state)
						}
					/>
				</Box>
			))
		},
		[getIconsList, handleFunctionalitySwitch, isExtraSmallWidth, isSmallWidth],
	)

	const modules = availableModules.map((module) => (
		<AccordionItem
			key={module.id}
			position='relative'
			my={[2, 2, 3]}
			bgColor='#FFF'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
			borderRadius='4px'
		>
			<h2>
				<AccordionButton px={[4, 4, 5]} py={[2, 3, 4]}>
					{getIconsList(module.icon_name, module.primary_color, module.font_color)}
					<Box as='span' flex='1' ms={2} textAlign='left'>
						{module[`name_${i18next.language}`]}
					</Box>
					<AccordionIcon ms={3} boxSize={6} />
				</AccordionButton>
				<Switch
					style={{
						position: "absolute",
						top: isExtraSmallWidth ? "16px" : isSmallWidth ? "21px" : "25px",
						right: "57px",
						width: isExtraSmallWidth ? "30px" : isSmallWidth ? "35px" : "38px",
						height: isExtraSmallWidth ? "20px" : isSmallWidth ? "21px" : "22px",
					}}
					state={module.visibility_state}
					onChange={() => handleModuleSwitch(module.id, module.visibility_state)}
				/>
			</h2>
			<AccordionPanel px={4} py={0}>
				<Divider borderColor='#E5E5DF' borderWidth={1} />
				{moduleFunctionalities(
					module.id,
					module.module_functionalities,
					module.primary_color,
					module.font_color,
				)}
			</AccordionPanel>
		</AccordionItem>
	))

	return (
		<Animation>
			<Container w='100vw' h='85vh' maxW='100%' p={3}>
				<Box display='flex' justifyContent='space-between' flexWrap='wrap'>
					<Box display='inline-flex' alignItems='center' mb={[2, 2, 2, 0]}>
						<BackForwardButtons />
						<Heading fontSize={["15", "15", "16"]} ms={2}>
							{t("Permissions.availablePermissionsHeading")}
						</Heading>
					</Box>
					<Box display='inline-flex' h={["35px", "auto"]}>
						<InputGroup>
							<Input
								placeholder={t("Permissions.searchInputPlaceholder")}
								bgColor={"#fff"}
								h={["35px", "40px"]}
								boxShadow={
									"0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);"
								}
								borderRadius={"4px"}
								onChange={(e) => handleSearch(e.target.value)}
							/>
							<InputRightElement>
								<SearchIcon />
							</InputRightElement>
						</InputGroup>
						<Button
							aria-label='Sort button'
							bgColor='#fff'
							boxSize={["auto", "40px"]}
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
							mx={3}
							onClick={handleSortItems}
						>
							{isSortDesc ? <BsSortDown boxSize={5} /> : <BsSortUp boxSize={5} />}
						</Button>
						<Button
							aria-label='Settings button'
							bgColor='#fff'
							boxSize={["auto", "40px"]}
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
						>
							<SettingsDotsIcon boxSize={5} />
						</Button>
					</Box>
				</Box>
				<LoadingHOC isLoading={isGetAvailableModules}>
					<Box display='flex' justifyContent='space-between' mx={3} mt={5} fontSize={["15", "15", "16"]}>
						<Text>{t("Permissions.moduleName")}</Text>
						<Text>{t("Permissions.permissionsEdit")}</Text>
					</Box>
					<Accordion allowMultiple>{modules}</Accordion>
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default AvailablePermissions
