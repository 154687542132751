import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { Badge, Box, Button, Divider, Flex, Image, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import AccordionList from "../../../../common/AccordionList"
import Pagination from "../../../../common/Pagination"
import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"

const InventoryGroupsList = ({
	groups,
	setGroups,
	groupsCopy,
	setGroupsCopy,
	status,
	recordsPerPage,
	getConcatenatedMaterials,
	getGroupsFromConcatenatedMaterial,
}) => {
	const { t } = useTranslation("global")
	const navigate = useNavigate()
	const location = useLocation()
	const [headers, setHeaders] = useState([])
	const [records, setRecords] = useState([])
	const [isDescSortByGroup, setIsDescSortByGroup] = useState(true)
	const [isDescSortByMaterial, setIsDescSortByMaterial] = useState(true)
	const [isDescSortByWarehouseState, setIsDescSortByWarehouseState] = useState(true)
	const [isDescSortByFactState, setIsDescSortByFactState] = useState(true)
	const [paginationLength, setPaginationLength] = useState(groupsCopy?.length)
	const { documentId } = useParams()

	useEffect(() => {
		const materialsLength = getConcatenatedMaterials(groupsCopy).length
		if (paginationLength === materialsLength) return
		setPaginationLength(materialsLength)
	}, [groupsCopy])

	const sorting = (array, field, order, isStringField = true) => {
		const compareFunction = (a, b) => {
			const aValue = isStringField ? a[field].toString().toLowerCase() : a[field]
			const bValue = isStringField ? b[field].toString().toLowerCase() : b[field]

			if (aValue < bValue) return !order ? -1 : 1
			if (aValue > bValue) return !order ? 1 : -1
			return 0
		}

		return [...array].sort(compareFunction)
	}

	const getGroupsByRecordsQuantity = (array) => {
		const materials = getConcatenatedMaterials(array).slice(0, recordsPerPage)
		return getGroupsFromConcatenatedMaterial(materials)
	}

	const handleSetSortedData = (setIsDescSort, sortedFilterData) => {
		setGroups(getGroupsByRecordsQuantity(sortedFilterData))
		setGroupsCopy(sortedFilterData)
		setPaginationLength(getConcatenatedMaterials(groupsCopy).length)
		setIsDescSort((prevState) => !prevState)
	}

	const handleMaterialGroupsSort = useCallback(() => {
		let sortedFilterData = []

		sortedFilterData = sorting(groupsCopy, "name", isDescSortByGroup)

		handleSetSortedData(setIsDescSortByGroup, sortedFilterData)
	}, [groupsCopy, isDescSortByGroup, recordsPerPage])

	const nestedSorting = useCallback(
		(field, isDescSort, setIsDescSort) => {
			let sortedMaterials = []

			const materials = getConcatenatedMaterials(groupsCopy)

			sortedMaterials = sorting(materials, field, isDescSort, false)

			sortedMaterials = getGroupsFromConcatenatedMaterial(sortedMaterials)

			handleSetSortedData(setIsDescSort, sortedMaterials)
		},
		[groupsCopy, recordsPerPage],
	)

	const handleNavigateToGroup = (id) => {
		const splittedPath = location.pathname.split("inv")
		navigate(`${splittedPath[0]}inv/${documentId}/${id}`)
	}

	const listHeaders = useMemo(
		() => [
			{
				name: t("Warehouses.materialGroup"),
				isDescSort: isDescSortByGroup,
				sortingMethod: handleMaterialGroupsSort,
			},
			{
				name: t("Warehouses.material"),
				isDescSort: isDescSortByMaterial,
				sortingMethod: () => nestedSorting("name", isDescSortByMaterial, setIsDescSortByMaterial),
			},
			{
				name: t("Warehouses.warehouseState"),
				isDescSort: isDescSortByWarehouseState,
				sortingMethod: () =>
					nestedSorting("warehouse_state", isDescSortByWarehouseState, setIsDescSortByWarehouseState),
			},
			{
				name: t("Warehouses.factState"),
				isDescSort: isDescSortByFactState,
				sortingMethod: () => nestedSorting("fact_state", isDescSortByFactState, setIsDescSortByFactState),
			},
			{},
		],
		[isDescSortByGroup, isDescSortByMaterial, isDescSortByWarehouseState, isDescSortByFactState, nestedSorting],
	)

	const getGroupFieldSum = useCallback((group, field) => {
		let sum = 0
		group.materials?.forEach((material) => (sum += material[field]))
		return sum
	}, [])

	const getGroupsFromPage = (start, end) => {
		const materials = getConcatenatedMaterials(groupsCopy).slice(start, end)
		return getGroupsFromConcatenatedMaterial(materials)
	}

	const handleChangePage = (page) => {
		const start = (page - 1) * recordsPerPage
		const end = start + recordsPerPage

		setGroups(getGroupsFromPage(start, end))
	}

	const getCurrentBadgeColor = (isConfirmed) => {
		switch (isConfirmed) {
			case 0:
				return "red"
			case 1:
				return "orange"
			case 2:
				return "green"
			default:
				return "red"
		}
	}

	const getGroupBadgeColor = useCallback((group) => {
		const isAllMaterialConfirmed = group.materials.every((material) => material.is_confirmed === 2)
		const isAllMaterialUnconfirmed = group.materials.every((material) => !material.is_confirmed)

		if (isAllMaterialConfirmed) return "green"
		if (isAllMaterialUnconfirmed) return "red"

		return "orange"
	}, [])

	const getRecord = (group) => [
		{
			body: (
				<Text fontSize={{ base: "12px", md: "13px", lg: "14px" }} fontWeight={600} wordBreak={"break-word"}>
					{group.name}
				</Text>
			),
		},
		{},
		{
			body: (
				<Text fontSize={{ base: "12px", md: "13px", lg: "14px" }} wordBreak={"break-all"}>
					{getGroupFieldSum(group, "warehouse_state")}
				</Text>
			),
		},
		{
			body: status ? (
				<Text fontSize={{ base: "12px", md: "13px", lg: "14px" }} wordBreak={"break-all"}>
					{getGroupFieldSum(group, "fact_state")}
				</Text>
			) : (
				<Badge
					colorScheme={getGroupBadgeColor(group)}
					color='#333'
					fontSize={{ base: "12px", md: "13px", lg: "14px" }}
					wordBreak={"break-all"}
				>
					{getGroupFieldSum(group, "fact_state")}
				</Badge>
			),
		},
		{},
	]

	const getRecords = useCallback(
		(groups) =>
			groups?.map((group) => ({
				childrens: getRecord(group),
				panel: (
					<Flex flexDirection={"column"} gap={1} mt={4} w='100%'>
						{group.materials?.map((material, index) => (
							<Box key={index}>
								{index !== 0 && <Divider mb={1} borderWidth='1%' borderColor='#D4D4CD' />}
								<Flex justifyContent={"space-between"} gap={"10px"} w={"100%"}>
									<Flex
										w={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										minW={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										gap={1}
										alignItems={"center"}
									>
										<Flex boxSize={"32px"}>
											{material?.image ? (
												<Image
													src={material.image}
													alt='Material image'
													w='100%'
													h='100%'
													objectFit={"contain"}
												/>
											) : (
												<LackImageIcon
													alt='Lack of material image'
													w='100%'
													h='100%'
													objectFit={"contain"}
												/>
											)}
										</Flex>
									</Flex>
									<Flex
										w={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										minW={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										gap={1}
										alignItems={"center"}
									>
										<Text
											wordBreak={"break-word"}
											fontSize={{ base: "12px", md: "13px", lg: "14px" }}
										>
											{material.name}
										</Text>
									</Flex>
									<Flex
										w={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										minW={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										gap={1}
										alignItems={"center"}
									>
										<Text
											wordBreak={"break-all"}
											fontSize={{ base: "12px", md: "13px", lg: "14px" }}
										>
											{material.warehouse_state}
										</Text>
									</Flex>
									<Flex
										w={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										minW={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										gap={1}
										alignItems={"center"}
									>
										{status ? (
											<Text
												wordBreak={"break-all"}
												fontSize={{ base: "12px", md: "13px", lg: "14px" }}
											>
												{material.fact_state}
											</Text>
										) : (
											<Badge
												wordBreak={"break-all"}
												fontSize={{ base: "12px", md: "13px", lg: "14px" }}
												colorScheme={getCurrentBadgeColor(material.is_confirmed)}
												color='#333'
											>
												{material.fact_state}
											</Badge>
										)}
									</Flex>
									<Flex
										w={{
											base: "100px",
											sm: "125px",
											md: "150px",
											lg: "200px",
											xl: "250px",
										}}
										minW={{
											base: "138px",
											sm: "163px",
											md: "193px",
											lg: "243px",
											xl: "293px",
										}}
										gap={1}
										alignItems={"center"}
										justifyContent={"flex-end"}
									>
										<Button
											minW='30px'
											boxSize={["30px", "35px"]}
											bgColor={"#E5E5DF"}
											onClick={() => handleNavigateToGroup(material.id)}
										>
											<ArrowForwardIcon boxSize={[5, 6]} />
										</Button>
									</Flex>
								</Flex>
							</Box>
						))}
					</Flex>
				),
			})),
		[groups],
	)

	useEffect(() => {
		setHeaders(listHeaders)
		setRecords(getRecords(groups))
	}, [groups, i18next.language])

	return (
		<>
			<AccordionList headers={headers} records={records} expand />
			{!!headers.length && !!records.length && (
				<Pagination
					key={recordsPerPage}
					style={{
						marginTop: "10px",
						paddingBottom: "10px",
					}}
					totalRecords={paginationLength}
					array={groupsCopy}
					recordsPerPage={recordsPerPage}
					onPageChange={(page) => handleChangePage(page)}
				/>
			)}
		</>
	)
}

export default memo(InventoryGroupsList)
