import { Heading } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import ExampleIcon from "../../assets/icons/header/ExampleIcon"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

const ModuleHeading = ({ heading, ...props }) => {
	const modules = useSelector((state) => state.auth.modules)
	const location = useLocation()
	const [currentModule, setCurrentModule] = useState(modules.find((module) => location.pathname.includes(module.url)))
	const [headingIcon, setHeadingIcon] = useState(
		currentModule.module_functionalities.find((functionalitie) => location.pathname.includes(functionalitie.url))
			.icon_name,
	)

	useEffect(() => {
		const module = modules.find((module) => location.pathname.includes(module.url))
		setCurrentModule(module)
		setHeadingIcon(
			module.module_functionalities.find((functionalitie) => location.pathname.includes(functionalitie.url))
				.icon_name,
		)
	}, [location.pathname])

	const getHeadingIcon = () => {
		const boxSize = 5
		const me = 2
		if (!headingIcon) return <ExampleIcon boxSize={boxSize} me={me} />
		const Image = require(`../../assets/icons/header/${headingIcon}`).default
		return <Image boxSize={boxSize} me={me} />
	}

	return (
		<Heading
			display='flex'
			alignItems='center'
			bgColor={currentModule.primary_color || "#FFF"}
			color={currentModule.font_color || "#333"}
			whiteSpace={"nowrap"}
			py={2}
			px={3}
			borderRadius='24px'
			fontSize={["14", "14", "16"]}
			boxShadow={"0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)"}
			ms={2}
			fontWeight={600}
			{...props}
		>
			{getHeadingIcon()}
			{heading}
		</Heading>
	)
}

export default memo(ModuleHeading)
