import { Slide, toast } from "react-toastify"
import ForwardArrow from "../../../assets/icons/notification/ForwardArrow"

const redirectUpdateToast = (toastId, message) => {
	return toast.update(toastId, {
		render: message,
		type: "info",
		isLoading: false,
		position: "bottom-center",
		autoClose: 1500,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: true,
		limit: 5,
		transition: Slide,
		progressStyle: {
			backgroundColor: "#FFF",
		},
		icon: <ForwardArrow boxSize={25} />,
	})
}

export default redirectUpdateToast
