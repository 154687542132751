import Animation from "../utils/animations/Routes/AnimatedPage"
import Header from "../components/Header/Header"
import Main from "../components/Main/Main"

const Layout = () => {
	return (
		<Animation>
			<Header />
			<Main />
		</Animation>
	)
}

export default Layout
