import { ChevronDownIcon } from "@chakra-ui/icons"
import { FixedSizeList as List } from "react-window"
import { Box, Button, Flex, FormControl, FormErrorMessage, Input, Text, useBreakpointValue } from "@chakra-ui/react"
import { useField, useFormikContext } from "formik"
import { memo, useCallback, useEffect, useRef, useState } from "react"

const OptionItem = ({ index, style, data }) => {
	const { handleSelect, field, options } = data
	const option = options[index]

	return (
		<Button
			key={option.id}
			position='relative'
			zIndex={10}
			display='flex'
			p={0}
			justifyContent='flex-start'
			fontSize={[13, 14, 15, 16]}
			w='100%'
			h={["30px", "35px", "40px"]}
			bgColor={option.name === field.value && option.name !== "" ? "#F2F2EF" : "#FFF"}
			_hover={{ backgroundColor: "rgba(0,0,0,0.03)" }}
			onMouseDown={() => handleSelect(option)}
			style={style}
		>
			<Box my={2} mx={3}>
				{<Text mx={2}>{option.name}</Text>}
			</Box>
		</Button>
	)
}

const SelectWithSearching = ({ values = [], isDisabled, onChangeProject, iconStyles, inputStyles, ...props }) => {
	const [field, meta, helpers] = useField(props)
	const [options, setOptions] = useState(values)
	const optionsRef = useRef(null)
	const inputRef = useRef(null)
	const chevronRef = useRef(null)
	const [searchText, setSearchText] = useState("")
	const { setFieldValue } = useFormikContext()
	const heightBreakpoints = {
		base: 150,
		sm: 150,
		md: 200,
		lg: 250,
		xl: 302,
	}
	const height = useBreakpointValue(heightBreakpoints)

	useEffect(() => {
		const name = values.filter((value) => value.id === field.value)[0]?.name
		if (name && name !== searchText) {
			setSearchText(values.filter((value) => value.id === field.value)[0]?.name)
		}
	}, [field.value])

	useEffect(() => {
		if (searchText === "" && field.value) {
			const name = values.filter((value) => value.id === field.value)
			setSearchText(name[0]?.name)
		}
	}, [field.value, searchText, values])

	const handleOutsideClick = useCallback((event) => {
		if (
			inputRef.current &&
			!inputRef.current.contains(event.target) &&
			chevronRef.current &&
			!chevronRef.current.contains(event.target) &&
			optionsRef.current &&
			!optionsRef.current.contains(event.target)
		) {
			optionsRef.current.style.display = "none"
		}
	}, [])

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick)
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick)
		}
	}, [handleOutsideClick])

	const clearInput = useCallback(() => {
		setSearchText("")
		helpers.setValue("")
		setOptions(values)
		if (searchText === "") return
	}, [helpers, searchText, values])

	const handleOpenSelect = () => {
		if (isDisabled) return
		clearInput()
		optionsRef.current.style.display = optionsRef.current.style.display === "flex" ? "none" : "flex"
		if (optionsRef.current.style.display === "flex") {
			inputRef.current.focus()
		}
	}

	const handleInputClick = () => {
		if (optionsRef.current.style.display === "none" || optionsRef.current.style.display === "") {
			clearInput()
		}
		optionsRef.current.style.display = "flex"
	}

	const handleSelect = useCallback(
		(option) => {
			setFieldValue(field.name, option.id)
			setSearchText(option.name)
			optionsRef.current.style.display = "none"
			if (onChangeProject) onChangeProject(option.building_address)
		},
		[field.name, onChangeProject, setFieldValue],
	)

	const handleSearching = (event) => {
		optionsRef.current.style.display = "flex"
		setSearchText(event.target.value)
		if (event.target.value === "") return setOptions(values)
		const filteredOptions = values.filter((option) =>
			option.name.toString().toLowerCase().includes(event.target.value.toLowerCase()),
		)
		setOptions(filteredOptions)
	}

	return (
		<FormControl mb={["1", "1", "1", "2"]} isInvalid={meta.error && meta.touched}>
			<Box role='Input group' {...props} position={"relative"}>
				<Input
					isDisabled={isDisabled}
					display='inline-flex'
					position='relative'
					zIndex={0}
					ref={inputRef}
					autoComplete='off'
					list='empty-datalist'
					value={searchText}
					h={props.h || props.height || ["30px", "35px", "40px", "45px"]}
					pe={10}
					fontSize={[13, 14, 15, 16]}
					placeholder={props.placeholder}
					style={inputStyles}
					onChange={handleSearching}
					onClick={handleInputClick}
				/>
				<Flex
					w={props.w || props.width ? ["15%"] : ["12%", "10%", "6%", "5%", "3%"]}
					h={props.h || props.height || ["35px", "35px", "42px", "45px"]}
					cursor={isDisabled ? "not-allowed" : "pointer"}
					ref={chevronRef}
					onClick={handleOpenSelect}
					zIndex={1}
					position='absolute'
					right={0}
					top={0}
					justifyContent='center'
					opacity={isDisabled ? 0.2 : 1}
					alignItems='center'
					style={iconStyles}
				>
					<ChevronDownIcon boxSize={[5, 5, 6]} />
				</Flex>
				<Box
					display='none'
					ref={optionsRef}
					bgColor='#FFF'
					borderRadius={"6px"}
					position='absolute'
					top='100%'
					w='100%'
					zIndex={2}
				>
					<List
						height={options?.length > 5 ? height : options.length * 40}
						display='none'
						itemCount={options.length}
						itemSize={39}
						width='100%'
						style={{ border: "1px solid #D4D4CD", borderRadius: "6px" }}
						itemData={{ options, handleSelect, field }}
					>
						{OptionItem}
					</List>
				</Box>
			</Box>
			<FormErrorMessage fontSize='15px' my='0'>
				{meta.error}
			</FormErrorMessage>
		</FormControl>
	)
}

export default memo(SelectWithSearching)
