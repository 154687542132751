import { ChevronDownIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Text } from "@chakra-ui/react"
import { memo, useCallback, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"

const OrderSelect = ({ options, isMyOrder, isDisabled, value, handleChangeStatus }) => {
	const hasAccess = useSelector((state) => state.auth.order_module_permissions).includes("change_order_status")
	const optionsRef = useRef(null)
	const selectRef = useRef(null)
	const [selectedOption, setSelectedOption] = useState(1)

	useEffect(() => {
		value && setSelectedOption(value)
	}, [value])

	const handleOpenSelect = () => {
		if (options.length === 0) return
		optionsRef.current.style.display = optionsRef.current.style.display === "flex" ? "none" : "flex"
	}

	const handleSelect = (value) => {
		if (selectedOption === value) return
		setSelectedOption(value)
		handleChangeStatus(value)
	}

	const getOptionBox = (option) => {
		return (
			<Flex
				bgColor={option.bgColor}
				borderLeft={`4px solid ${option.borderColor}`}
				borderRadius='4px'
				py={[0, 0, 1]}
				justifyContent='space-between'
				alignItems='center'
			>
				<Text fontSize={[13, 14, 15, 16]} mx={2}>
					{option.label}
				</Text>
			</Flex>
		)
	}

	const getOptions = options.map((option) => (
		<Button
			key={option.id}
			isDisabled={option.isDisabled}
			display='flex'
			p={0}
			justifyContent='flex-start'
			w='100%'
			h={["30px", "35px", "40px", "45px"]}
			bgColor={option.id === selectedOption ? "#F2F2EF" : "#FFF"}
			_hover={{ backgroundColor: "#F2F2EF" }}
			onClick={() => handleSelect(option.value)}
		>
			<Box my={2} mx={3}>
				{getOptionBox(option)}
			</Box>
		</Button>
	))

	const handleOutsideClick = useCallback((event) => {
		if (selectRef.current && !selectRef.current.contains(event.target)) {
			optionsRef.current.style.display = "none"
		}
	}, [])

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick)
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick)
		}
	}, [handleOutsideClick])

	return (
		<Flex ref={selectRef} w='100%' h={["30px", "35px", "40px", "45px"]} position='relative'>
			<Button
				display='flex'
				isDisabled={(!isMyOrder && !hasAccess) || isDisabled}
				ref={selectRef}
				w='100%'
				h={["30px", "35px", "40px", "45px"]}
				p={0}
				bgColor={isMyOrder || hasAccess ? "#FFF" : "#F2F2EF"}
				_hover={{ backgroundColor: "#F9F9F9" }}
				borderRadius='6px'
				border='1px solid #D4D4CD'
				py={2}
				position='relative'
				cursor='pointer'
				onClick={handleOpenSelect}
			>
				<Flex justifyContent='space-between' alignItems='center' w='100%' px={3}>
					{getOptionBox(options[selectedOption - 1])}
					<ChevronDownIcon boxSize={5} />
				</Flex>
			</Button>
			<Box
				display='none'
				ref={optionsRef}
				bgColor='#FFF'
				borderRadius='6px'
				position='absolute'
				border='1px solid #D4D4CD'
				top={["29px", "34px", "39px", "43px"]}
				w='100%'
				h={options.length > 5 ? ["150px", "170px", "200px", "250px"] : ["150px", "170px", "200px", "auto"]}
				overflow={"auto"}
			>
				<Box w='100%'>{getOptions}</Box>
			</Box>
		</Flex>
	)
}

export default memo(OrderSelect)
