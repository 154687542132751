import { Button, Divider, Flex, Input, Text, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { FastField, FieldArray } from "formik"
import * as Yup from "yup"

import FormikForm from "../../../../common/FormikForm"
import WhiteBackground from "../../../../common/WhiteBackground"
import TextField from "../../../../common/TextField"
import LanguageInputs from "../../../../common/LanguageInputs"
import AlertCancelChanges from "../../../../common/AlertCancelChanges"
import InputColor from "../../../../common/InputColor"

const GroupForm = ({ initialValues, onSubmit, isPatchMethod = false }) => {
	const { t } = useTranslation("global")
	const navigate = useNavigate()
	const location = useLocation()
	const { isOpen, onOpen, onClose } = useDisclosure()

	const handleConfirmCancel = () => {
		const path = location.pathname.split("/")
		navigate(`/${path[1]}/${path[2]}`)
	}

	return (
		<FormikForm
			isPatchMethod={isPatchMethod}
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				// [`name_${i18next.language}`]: Yup.string().required(t("Groups.nameIsRequired")),
				name: Yup.string().required(t("Groups.nameIsRequired")),
				background_color: Yup.string().matches(/^#(?:[0-9a-fA-F]{3}){1,2}$/, t("Groups.invalidColorFormat")),
				text_color: Yup.string().matches(/^#(?:[0-9a-fA-F]{3}){1,2}$/, t("Groups.invalidColorFormat")),
			})}
			onSubmit={onSubmit}
		>
			<Flex flexDirection={"column"} gap={4} mt={7}>
				<WhiteBackground>
					<Flex flexDirection={"column"} gap={3}>
						<FieldArray>
							{({ form }) => (
								<>
									<Flex
										flexDirection={["column", "row"]}
										alignItems={["start", "center"]}
										gap={[0, 2]}
										w={["100%", "60%", "50%", "30%", "20%"]}
									>
										<Text
											fontSize={[13, 14, 15, 16]}
											mb={["1", "1", "1", "2"]}
											minW={["120px"]}
											color='#2C7A7B'
										>
											{t("Groups.groupName")}
										</Text>
										<Flex gap={2}>
											<TextField
												isDisabled={form.isSubmitting}
												fontSize={[13, 14, 15, 16]}
												name={"name"}
												h={["35px", "35px", "40px"]}
												minW={["100%", "250px"]}
												placeholder={t("Groups.addName")}
											/>
											<LanguageInputs
												name='name'
												title={t("Groups.groupName")}
												isDisabled={true}
											/>
										</Flex>
									</Flex>
									<Divider mb={1} w='100%' borderColor='#D4D4CD' borderWidth={"1%"} />
									<Flex
										flexDirection={["column", "row"]}
										alignItems={["start", "center"]}
										gap={[0, 2]}
										w={["100%", "60%", "50%", "30%", "20%"]}
									>
										<Text
											fontSize={[13, 14, 15, 16]}
											mb={["1", "1", "1", "2"]}
											minW={["120px"]}
											color='#2C7A7B'
										>
											{t("Groups.example")}
										</Text>
										<Input
											aria-label='Color example input'
											minW={["250px"]}
											fontSize={[13, 14, 15, 16]}
											h={["35px", "35px", "40px"]}
											readOnly
											bgColor={form.values.background_color || "transparent"}
											color={form.values.text_color || "#000"}
											value={t("Groups.example")}
										/>
									</Flex>
									<Flex
										flexDirection={["column", "row"]}
										alignItems={["start", "center"]}
										gap={[0, 2]}
										w={["100%", "60%", "50%", "30%", "20%"]}
									>
										<Text
											fontSize={[13, 14, 15, 16]}
											mb={["1", "1", "1", "2"]}
											minW={["120px"]}
											color='#2C7A7B'
										>
											{t("Groups.backgroundColor")}
										</Text>

										<InputColor
											fontSize={[13, 14, 15, 16]}
											isDisabled={form.isSubmitting}
											bottom={false}
											name='background_color'
											minW='250px'
											placeholder={t("Groups.addBackgroundColor")}
										/>
									</Flex>
								</>
							)}
						</FieldArray>
						<FastField name='textColor'>
							{({ form }) => (
								<Flex
									flexDirection={["column", "row"]}
									alignItems={["start", "center"]}
									gap={[0, 2]}
									w={["100%", "60%", "50%", "30%", "20%"]}
								>
									<Text
										fontSize={[13, 14, 15, 16]}
										mb={["1", "1", "1", "2"]}
										minW={["120px"]}
										color='#2C7A7B'
									>
										{t("Groups.textColor")}
									</Text>
									<InputColor
										fontSize={[13, 14, 15, 16]}
										isDisabled={form.isSubmitting}
										bottom={false}
										name='text_color'
										minW='250px'
										placeholder={t("Groups.addTextColor")}
									/>
								</Flex>
							)}
						</FastField>
					</Flex>
				</WhiteBackground>
			</Flex>
			<Flex justifyContent={"end"} mt={7}>
				<Button
					bgColor='red.600'
					_hover={{ backgroundColor: "red.700" }}
					colorScheme='red'
					color='#FFF'
					me={2}
					h={["30px", "35px", "40px"]}
					fontSize={[13, 14, 15, 16]}
					onClick={onOpen}
				>
					<Text aria-label='Cancel button'>{t("Groups.cancel")}</Text>
				</Button>
				<Button
					type='submit'
					bgColor='green.600'
					_hover={{ backgroundColor: "green.700" }}
					colorScheme='green'
					color='#FFF'
					h={["30px", "35px", "40px"]}
					fontSize={[13, 14, 15, 16]}
				>
					<Text aria-label='Save button'>{t("Groups.save")}</Text>
				</Button>
			</Flex>
			<AlertCancelChanges isOpen={isOpen} onClose={onClose} handleConfirmCancel={handleConfirmCancel} />
		</FormikForm>
	)
}

export default GroupForm
