import { Box, Divider, Heading, Text } from "@chakra-ui/layout"
import { Button, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import HoursTableRow from "./HoursTableRow"
import LoadingHOC from "../../../../common/LoadingHOC"
import { t } from "i18next"

const HoursForm = ({ coffee, onSubmit }) => {
	onSubmit = (data) => {
		console.log("data", data)
	}
	/*	useEffect(() => {
		console.log("newCoffee", coffee)
	}, [coffee])*/
	return (
		<Box
			w='100%'
			minH={["300px", "400px", "500px", "700px"]}
			bgColor='#FFF'
			p={[4, 6]}
			mb={5}
			borderTopRadius={["none", "none", "none", "4px"]}
			borderBottomRadius={"4px"}
			boxShadow=' 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
		>
			<Heading fontSize={[14, 15, 16, 17]} fontWeight={400}>
				Godziny
			</Heading>
			<Text fontSize={[13, 14, 15, 16]} color='teal.600'>
				Tutaj można dodać jakąś treść
			</Text>
			<TableContainer>
				<LoadingHOC isLoading={coffee}>
					<Table variant='simple'>
						<Thead>
							<Tr>
								<Th>{t("Employees.year")}</Th>
								<Th>{t("Employees.hoursToWork")}</Th>
								<Th>{t("Employees.costPerHour")}</Th>
								<Th>{t("Employees.hoursToWork")}</Th>
								<Th>{t("Employees.costPerCoffee")}</Th>
								<Th>{t("Employees.dismissedCoffeeHours")}</Th>
								<Th>{t("Employees.autoCoffee")}</Th>
								<Th>{t("Employees.weekly")}</Th>
							</Tr>
						</Thead>
						<Tbody>
							{coffee?.map((item) => (
								<HoursTableRow
									key={item.id}
									data={item}
									onUpdate={(changedRow) => onSubmit(changedRow)}
								/>
							))}
						</Tbody>
					</Table>
				</LoadingHOC>
			</TableContainer>
			<Button colorScheme='blue' onClick={(e) => console.table(coffee, e)}>
				Save
			</Button>
			<Divider my={2} w='100%' borderColor='#D4D4CD' borderWidth='1px' />
		</Box>
	)
}
export default HoursForm
