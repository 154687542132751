import { Navigate, Outlet } from "react-router-dom"

import { useSelector } from "react-redux"

const HasPermisssionOptionsRoutes = ({ allowedOptions }) => {
	const permissionOptions = useSelector((state) => state.auth.permission_options)

	return permissionOptions?.find((permission) => allowedOptions?.includes(permission.url)) ? (
		<Outlet />
	) : (
		<Navigate to='/' />
	)
}

export default HasPermisssionOptionsRoutes
