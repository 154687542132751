import { Button } from "@chakra-ui/react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const GroupCopyButton = ({ data }) => {
	const { t } = useTranslation("global")
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)

	const copyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {})
			.catch((error) => {
				console.error("Błąd podczas kopiowania do schowka: ", error)
			})
	}

	const formattedData = useMemo(() => {
		if (!data || !data[0]?.name) return
		const dataKeys = [
			t("Warehouses.materialGroup"),
			t("Warehouses.material"),
			t("Warehouses.quantity"),
			t("Warehouses.value"),
		]

		const columnWidths = {}

		const materialGroupMaxWidth = Math.max(...data.map((item) => item.name?.length))
		columnWidths[t("Warehouses.materialGroup")] = Math.max(
			t("Warehouses.materialGroup").length,
			materialGroupMaxWidth,
		)

		let materialNamesLength = []
		data.forEach((item) => item.materials.forEach((material) => materialNamesLength.push(material.name.length)))
		const materialMaxWidth = Math.max(...materialNamesLength)
		columnWidths[t("Warehouses.material")] = Math.max(t("Warehouses.material").length, materialMaxWidth)

		let quantityLength = []
		data.forEach((item) =>
			item.materials.forEach((material) => quantityLength.push(material.quantity.toString().length)),
		)
		const quantityMaxWidth = Math.max(...quantityLength)
		columnWidths[t("Warehouses.quantity")] = Math.max(t("Warehouses.quantity").length, quantityMaxWidth)

		let valuesLength = []
		data.forEach((item) =>
			item.materials.forEach((material) => valuesLength.push(material.value.toString().length)),
		)
		const valuesMaxWidth = Math.max(...valuesLength)
		columnWidths[t("Warehouses.value")] = Math.max(t("Warehouses.value").length, valuesMaxWidth)

		let formattedText = `${currentWarehouse?.name}\n\n`

		formattedText += dataKeys.map((header) => header.padEnd(columnWidths[header])).join("\t") + "\n"

		data.forEach((item) => {
			let body = ""
			body += String(item.name).padEnd(columnWidths[t("Warehouses.materialGroup")])
			body += "\t"
			item.materials.forEach((material, index) => {
				body += String(material.name).padEnd(columnWidths[t("Warehouses.material")])
				body += "\t"
				body += String(material.quantity).padEnd(columnWidths[t("Warehouses.quantity")])
				body += "\t"
				body += String(material.value).padEnd(columnWidths[t("Warehouses.value")])
				body += "\n"
				if (index === item.materials.length - 1) return
				body += "".padEnd(columnWidths[t("Warehouses.materialGroup")])
				body += "\t"
			})

			body += "\n"

			formattedText += body
		})

		return formattedText
	}, [data])

	const handleCopyClick = () => {
		copyToClipboard(formattedData)
	}

	return (
		<Button
			isDisabled={!data?.length}
			fontSize={[13, 14, 15, 16]}
			fontWeight={"400"}
			h={["25px", "35px", "40px"]}
			bgColor={"#FFF"}
			borderRadius={"4px"}
			boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
			onClick={handleCopyClick}
		>
			{t("Warehouses.copy")}
		</Button>
	)
}

export default GroupCopyButton
