import { Button, Heading, Image, VStack } from "@chakra-ui/react"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import * as Yup from "yup"

import { login } from "../../../../actions/auth/auth/auth-actions"
import TextField from "../../../common/TextField"
import PasswordField from "../../../common/PasswordField"
import { useLocation, useNavigate } from "react-router-dom"
import { memo } from "react"

const LoginForm = ({ setIsDisabled }) => {
	const { t } = useTranslation("global")
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useDispatch()

	/**
	 *Login function with dispatch action
	 */
	const handleLogin = (values, actions) => {
		actions.setSubmitting(true)
		setIsDisabled(true)
		dispatch(login(values)).then((data) => {
			if (data.payload && location.state?.from) navigate(location.state.from)
			actions.setSubmitting(false)
			setIsDisabled(false)
		})
	}

	return (
		<Formik
			initialValues={{
				login: "",
				password: "",
			}}
			validationSchema={Yup.object({
				login: Yup.string().required(t("Login.main.loginRequired")),
				password: Yup.string().required(t("Login.main.passwordRequired")),
				// .min(8, t("Login.main.passwordTooShort"))
				// .test("has-lowercase", t("Login.main.passwordWithoutSmallLetter"), (value) => /[a-z]/.test(value))
				// .test("has-uppercase", t("Login.main.passwordWithoutBigLetter"), (value) => /[A-Z]/.test(value))
				// .test("has-special-char", t("Login.main.passwordWithoutSpecialChar"), (value) =>
				// 	/[!@#$%^&*()\-=_+[\]{}|\\;:'",.<>/?]/.test(value),
				// ),
			})}
			onSubmit={(values, actions) => {
				handleLogin(values, actions)
			}}
		>
			{(formik) => (
				<VStack
					as='form'
					w='70%'
					gap='0'
					position='relative'
					left='50%'
					transform='translateX(-50%)'
					onSubmit={formik.handleSubmit}
				>
					<Heading
						as='h1'
						w={["100px", "140px", "150px", "160px", "180px"]}
						fontSize={["10", "14", "15", "16", "20"]}
						textAlign='center'
						py={["1", "1", "1", "4", "6"]}
					>
						{t("Login.main.header")}
					</Heading>
					<TextField
						data-testid={"login"}
						fontSize={["10", "14", "14", "14", "16"]}
						name='login'
						type='text'
						placeholder={t("Login.main.loginInput")}
						h={["30px", "35px", "45px"]}
						disabled={formik.isSubmitting}
					/>

					<PasswordField
						data-testid={"password"}
						fontSize={["10", "14", "14", "14", "16"]}
						name='password'
						placeholder={t("Login.main.passwordInput")}
						h={["30px", "35px", "45px"]}
						disabled={formik.isSubmitting}
					/>

					<Button
						data-testid={"logInButton"}
						isDisabled={formik.isSubmitting}
						fontSize={["10", "14", "14", "14", "16"]}
						type='submit'
						w='100%'
						h={["30px", "35px", "45px"]}
						bg='black'
						color='white'
						colorScheme='teal'
					>
						{t("Login.main.loginButton")}
						<Image
							src={require("../../../../assets/icons/login/Vector.png")}
							loading='lazy'
							boxSize={["7.5px", "10px", "3"]}
							alt='right-arrow'
							ml={2}
						/>
					</Button>
				</VStack>
			)}
		</Formik>
	)
}

export default memo(LoginForm)
