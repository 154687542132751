import { Button } from "@chakra-ui/react"
import i18next from "i18next"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const DocumentCopyButton = ({ data, isDisabled }) => {
	const { t } = useTranslation("global")
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)

	const getCurrentStatus = (status) => {
		switch (status) {
			case 0:
				return t("Warehouses.open")
			case 1:
				return t("Warehouses.closed")
			default:
				throw new Error("Undefined status")
		}
	}

	const copyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {})
			.catch((error) => {
				console.error("Błąd podczas kopiowania do schowka: ", error)
			})
	}

	const formattedData = useMemo(() => {
		if (!data) return
		const getColumnWidths = (data, fields, headers) => {
			const columnWidths = {}

			headers.forEach((header, index) => {
				columnWidths[fields[index]] = header.length
			})

			fields.forEach((field) => {
				Object.values(data).forEach((array) => {
					array.forEach((item) => {
						if (Object.prototype.hasOwnProperty.call(item, field)) {
							const length =
								field === "status"
									? getCurrentStatus(item[field])?.toString().length
									: item[field]?.toString().length
							columnWidths[field] = Math.max(columnWidths[field], length + 2, 20)
						}
					})
				})
			})

			return columnWidths
		}

		const fieldsToCheck = ["document_number", "status", "comments", "created", "document_date"]
		const headers = [
			t("Warehouses.document_number"),
			t("Warehouses.status"),
			t("Warehouses.comments"),
			t("Warehouses.created"),
			t("Warehouses.document_date"),
		]
		const columnWidths = getColumnWidths(data, fieldsToCheck, headers)
		const documentTypeWidth = [t("Warehouses.pz"), t("Warehouses.rw"), t("Warehouses.inv")]
		const maxDocumentTypeWidth = Math.max(...documentTypeWidth.map((str) => str.toString().length + 2))

		let formattedText = `${currentWarehouse?.name}\n\n`

		formattedText += "".padEnd(maxDocumentTypeWidth)
		formattedText += "\t"

		formattedText +=
			fieldsToCheck.map((field, index) => headers[index].padEnd(columnWidths[field])).join("\t") + "\n"

		Object.keys(data).forEach((item) => {
			let body = ""
			body += String(t(`Warehouses.${item}`)).padEnd(maxDocumentTypeWidth)
			data[item].forEach((document) => {
				body += "\n"
				body += "".padEnd(maxDocumentTypeWidth)
				body += "\t"
				body += String(document.document_number).padEnd(columnWidths.document_number)
				body += "\t"
				body += String(getCurrentStatus(document.status)).padEnd(columnWidths.status)
				body += "\t"
				body += String(document.comments).padEnd(columnWidths.comments)
				body += "\t"
				body += String(document.created).padEnd(columnWidths.created)
				body += "\t"
				body += String(document.document_date).padEnd(columnWidths.document_date)
			})

			body += "\n"

			formattedText += body
		})

		return formattedText
	}, [data, i18next.language])

	const handleCopyClick = () => {
		copyToClipboard(formattedData)
	}

	return (
		<Button
			isDisabled={isDisabled}
			fontSize={[13, 14, 15, 16]}
			fontWeight={"400"}
			h={["25px", "35px", "40px"]}
			bgColor={"#FFF"}
			borderRadius={"4px"}
			boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
			onClick={handleCopyClick}
		>
			{t("Warehouses.copy")}
		</Button>
	)
}

export default DocumentCopyButton
